import React from 'react'

import { Promotion, PromotionInput } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox, Text } from '@stage-ui/core'
import { PromotionActions } from 'actions'
import AcceptDialog from 'components/AcceptDialog'
import Content from 'components/Content'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { useLocalizedString } from 'hooks/useLocalizedString'

interface PromotionViewControllersProps {
  goBack: () => void
  input: PromotionInput
  promotion?: Promotion
}

function PromotionViewControllers(props: PromotionViewControllersProps) {
  const { promotion, input, goBack } = props
  const actionError = useSelector(({ promotion }) => promotion.actionError, objectEqual)
  const ls = useLocalizedString()
  const actionButtonLabel = !promotion?.id ? ls.text.add : ls.text.save

  const _create = () => PromotionActions.create(input, goBack)
  const _update = () =>
    promotion?.id && PromotionActions.update(promotion.id, input, goBack)
  const _delete = () => promotion?.id && PromotionActions.delete(promotion.id)

  const onActionClick = promotion?.id ? _update : _create

  return (
    <Content>
      {actionError && (
        <Flexbox pb="m">
          <Text size="s" color="error" children={actionError} />
        </Flexbox>
      )}
      <Flexbox>
        {promotion && (
          <AcceptDialog
            action={_delete}
            title={ls.text.promotionDelete}
            children={ls.text.delete}
            color="error"
            text={ls.text.promotionDeleteConfirmation}
          />
        )}
        <Flexbox flex={1} justifyContent="flex-end" alignItems="center">
          <Button
            children={ls.text.cancel}
            mx="0.5rem"
            decoration="text"
            onClick={goBack}
          />
          <Button
            children={actionButtonLabel}
            textColor="onPrimary"
            onClick={onActionClick}
          />
        </Flexbox>
      </Flexbox>
    </Content>
  )
}

export default PromotionViewControllers
