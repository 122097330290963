import React from 'react'

import { Button, Checkbox, Flexbox, Table, Text } from '@stage-ui/core'
import { TableLoadingStab } from 'components/TableLoadingStab'
import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

import { useLocalizedString } from 'hooks/useLocalizedString'
import TableTypes from '@stage-ui/core/data/Table/types'
import { Device } from '@foods-n-goods/server/generated/schema'

import { CheckmarkSquare, Edit, MinusSquare, Square } from '@stage-ui/icons'

import { updateDeviceDialog } from '../shared/form'

function NameCell(props: { ctx: TableTypes.TableCellContext<Device> }) {
  const { ctx } = props
  const ls = useLocalizedString()

  return (
    <Flexbox alignItems="center">
      <Flexbox column ml="xs">
        <Text weight={600}>{ctx.row.name}</Text>
        <Flexbox>
          <Text size="xs" weight={600} color="gray400">
            {ctx.row.host}
          </Text>
          {ctx.row.active ? (
            <CheckmarkSquare ml="0.125rem" type="filled" color={'green500'} />
          ) : null}
        </Flexbox>
      </Flexbox>
    </Flexbox>
  )
}

function LinkedDeviceName(props: { ctx: TableTypes.TableCellContext<Device> }) {
  const { ctx } = props
  const ls = useLocalizedString()

  if (!ctx.row.linkedDevice?.name) {
    return '-'
  }
  return (
    <Flexbox alignItems="center">
      <Flexbox column ml="xs">
        <Text weight={600}>{ctx.row.linkedDevice?.name}</Text>
        <Flexbox>
          {/* <Text size="xs" weight={600} color="gray400">
            {ctx.row.linkedDevice?.host}
          </Text>
          {ctx.row.active ? (
            <CheckmarkSquare ml="0.125rem" type="filled" color={'green500'} />
          ) : null} */}
        </Flexbox>
      </Flexbox>
    </Flexbox>
  )
}

function PrinterDevices() {
  const ls = useLocalizedString()
  const { loading, data } = useSelector(
    ({ devices }) => ({
      loading: devices.loading,
      data: devices.data.filter((device) => device.type === 'PRINTER'),
    }),
    objectEqual,
  )
  const columns: TableTypes.TableColumn<Device>[] = [
    { key: 'id', title: 'ID', width: '4rem', sort: 'ASC' },
    {
      key: 'name',
      width: 200,
      title: ls.text.name,
      sort: 'ASC',
      render: (ctx) => <NameCell ctx={ctx} />,
    },
    {
      key: 'bound',
      width: 200,
      title: ls.text.cameraLinkedToScales,
      render: (ctx) => <LinkedDeviceName ctx={ctx} />,
    },
    {
      key: '_',
      title: '',
      render: (ctx) => (
        <Flexbox justifyContent="flex-end">
          <Button
            ml="s"
            label={ls.text.change}
            decoration="plain"
            size="s"
            onClick={() => {
              updateDeviceDialog(ctx.row)
            }}
            rightChild={<Edit />}
          />
        </Flexbox>
      ),
    },
  ]
  return (
    TableLoadingStab(loading) || (
      <Table
        columns={columns}
        data={data}
        footer={
          data.length === 0 && (
            <Text size="s" color="gray500">
              {ls.text.noData}
            </Text>
          )
        }
      />
    )
  )
}

export default PrinterDevices
