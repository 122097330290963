import React from 'react'

import { Client } from '@foods-n-goods/server/generated/schema'
import { Button, modal } from '@stage-ui/core'
import { Email, MessageCircle } from '@stage-ui/icons'
import { useMouseDrop } from 'components/MouseDrop'
import { RoomEditForm } from 'pages/dialogs/Configurate'
import { history } from 'Router/MainView'

import { openClientPushMessageModal } from 'components/Modals/ClientPushMessage'
import { useLocalizedString } from 'hooks/useLocalizedString'

interface ClientControlsProps {
  client: Client
}

export function ClientControls(props: ClientControlsProps) {
  const { client } = props
  const ls = useLocalizedString()
  const clientBroadCastTooltip = useMouseDrop(ls.text.clientChatSendMessageToClient, {
    drop: {
      w: '13rem',
      align: 'bottom',
      justify: 'end',
    },
    popover: {
      align: 'top',
      arrowOffset: '5.25rem',
    },
  })

  const showRoomEditForm = (find?: string) => {
    modal({
      title: ls.text.clientChatCreate,
      overlayClose: false,
      overrides: (t) => ({
        window: () => [
          {
            padding: '1.5rem',
            background: t.color.gray[50].hex(),
          },
        ],
      }),
      render: (close) => (
        <RoomEditForm
          onClose={() => {
            close()
            history.push('/dialogs?dialogSection=withClient')
          }}
          find={find}
        />
      ),
    })
  }

  const createChatWithClient = () => {
    showRoomEditForm(String(client.name || ''))
  }

  return (
    <>
      <Button
        rightChild={<MessageCircle />}
        onClick={createChatWithClient}
        label={ls.text.clientChatTitle}
      />
      <Button
        size="m"
        color="yellow400"
        textColor="surface"
        onClick={() => openClientPushMessageModal(client)}
        w="2.5rem"
        h="2.5rem"
        p={0}
        {...clientBroadCastTooltip}
      >
        <Email size="l" />
      </Button>
    </>
  )
}
