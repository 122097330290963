import React, { useState } from 'react'

import { Container, ContainerInput } from '@foods-n-goods/server/generated/schema'
import { Button, modal, Flexbox, Grid, TextField } from '@stage-ui/core'
import { Trash2 } from '@stage-ui/icons'
import { DictionariesActions } from 'actions'
import { getLocalizedString, useLocalizedString } from 'hooks/useLocalizedString'

function Form(props: { item?: Container; close: () => void }) {
  const { item, close } = props
  const ls = useLocalizedString()
  const [payload, setPayload] = useState<ContainerInput>({
    line: item?.line || 1,
    name: item?.name || '',
  })

  const onSave = async () => {
    if (item) {
      DictionariesActions.updateContainer({ id: item.id, payload }, close)
    } else {
      DictionariesActions.createContainer({ payload }, close)
    }
  }

  const onDelete = () => {
    if (item) {
      DictionariesActions.deleteContainer(item, close)
    }
  }

  return (
    <Flexbox column>
      <Grid gap="1rem" templateColumns="1fr 1fr">
        <TextField
          value={payload.name || ''}
          placeholder={ls.text.p1}
          onChange={(e) => setPayload({ ...payload, name: e.target.value })}
          label={ls.text.name}
        />
      </Grid>
      <Flexbox mt="1rem" justifyContent="space-between">
        <Button
          decoration="text"
          color="red500"
          children={ls.text.delete}
          rightChild={<Trash2 />}
          disabled={!item}
          onClick={onDelete}
        />
        <Flexbox justifyContent="flex-end">
          <Button
            decoration="text"
            color="gray500"
            children={ls.text.cancel}
            onClick={close}
          />
          <Button ml="0.25rem" children={ls.text.save} onClick={onSave} />
        </Flexbox>
      </Flexbox>
    </Flexbox>
  )
}

export const createContainerDialog = () => {
  modal({
    title: getLocalizedString().text.pallet,
    render: (close) => <Form close={close} />,
    overlayClose: false,
    style: {
      window: {
        width: '25rem',
        padding: '1rem',
      },
    },
  })
}

export const updateContainerDialog = (item: Container) => {
  modal({
    title: getLocalizedString().text.pallet,
    render: (close) => <Form item={item} close={close} />,
    overlayClose: false,
    style: {
      window: {
        width: '25rem',
        padding: '1rem',
      },
    },
  })
}
