import React, { useEffect, useRef, useState } from 'react'

import { Flexbox, Grid, Text, TextField, useTheme } from '@stage-ui/core'
import TextFieldTypes from '@stage-ui/core/control/TextField/types'
import TableTypes from '@stage-ui/core/data/Table/types'
import { Checkmark, Close } from '@stage-ui/icons'
import { OrderActions } from 'actions'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { OrderedPosition } from '..'

export function OrderPositionCommentCell(
  ctx: TableTypes.TableCellContext<OrderedPosition>,
) {
  const { row } = ctx
  const { id, comment: initialComment } = row

  const theme = useTheme()
  const ref = useRef<TextFieldTypes.Ref>(null)

  const updateComment = (comment: string) => {
    OrderActions.updateComment({ id, comment })
  }
  const ls = useLocalizedString()
  const [modify, setModify] = useState(false)
  const [comment, setComment] = useState('')

  useEffect(() => {
    if (initialComment) {
      setComment(initialComment)
    }
  }, [])

  const save = (
    e:
      | React.KeyboardEvent<HTMLInputElement>
      | React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation()
    setModify(false)
    updateComment(comment)
  }

  const cancel = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    setModify(false)
    setComment(initialComment || '')
  }

  if (!modify) {
    return (
      <Flexbox
        column
        py="s"
        onClick={(e) => {
          e.stopPropagation()
          setModify(true)
        }}
      >
        <Text size="xs" weight={500} color="gray500" display="block" w="100%">
          {comment || ls.text.addComment}
        </Text>
      </Flexbox>
    )
  }

  return (
    <TextField
      autoFocus
      ref={ref}
      w="100%"
      color="gray900"
      backgroundColor="onPrimary"
      placeholder={ls.text.enterComment}
      value={comment}
      onChange={(e) => setComment(e.target.value)}
      onEnter={save}
      onClick={(e) => {
        e.stopPropagation()
      }}
      rightChild={
        <Grid autoFlow="column" gap="0.25rem">
          <Checkmark size="1.5rem" color="green600" onClick={save} />
          <Close size="1.5rem" color="gray500" onClick={cancel} />
        </Grid>
      }
      style={{
        borderRadius: '0.25rem',
        borderColor: theme.color.gray[200].hex(),
        borderWidth: '0.0625rem',
        borderStyle: 'solid',
        ':focus-within': {
          borderColor: theme.color.green[500].hex(),
          backgroundColor: theme.color.white.hex(),
        },
      }}
      overrides={{
        field: {
          border: 'none',
          boxShadow: 'none',
        },
        input: {
          fontSize: '0.75rem',
          fontWeight: 500,
          '&::placeholder': {
            color: theme.color.gray[400].hex(),
            fontWeight: 500,
            fontSize: 'xs',
          },
        },
      }}
    />
  )
}
