import React, { useMemo } from 'react'

import { Flexbox, Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import useSelector from 'hooks/useSelector'
import { PurchaseProduct } from '@foods-n-goods/server/generated/schema'
import { objectEqual } from 'utils/objectEqual'
import { useLocalizedString } from 'hooks/useLocalizedString'

export function OrderQuantityCell(ctx: TableTypes.TableCellContext<PurchaseProduct>) {
  const { row } = ctx
  const ls = useLocalizedString()
  const units = useSelector((state) => state.app.meta?.units, objectEqual)
  const unitName = useMemo(() => {
    if (!units) {
      return null
    }
    const unit = units.find((u) => u.value === Number(row.unitId))
    if (!unit || !unit.code) {
      return null
    }
    return ls.string[unit.code]
  }, [ls.id, units, row.unitId])

  const quantity = row.unitId === '1' ? row.orderQuantity.toFixed(3) : row.orderQuantity

  return (
    <Flexbox>
      <Text size="s" color="gray900" w="4rem">
        {quantity}
      </Text>
      <Text size="s" ml="xs" color="gray400">
        {unitName}
      </Text>
    </Flexbox>
  )
}
