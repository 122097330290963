import React from 'react'

import { Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { PurchaseExtended } from 'pages/purchases/data'
import { useLocalizedString } from 'hooks/useLocalizedString'

export function QuantityCell(ctx: TableTypes.TableCellContext<PurchaseExtended>) {
  const { row } = ctx
  const ls = useLocalizedString()
  return (
    <Text
      size="s"
      color={row._isExpandable ? 'gray900' : 'gray600'}
      weight={row._isExpandable ? 700 : 500}
    >
      {row.product.unit.value === 1 ? row.quantity.toFixed(3) : row.quantity}{' '}
      {ls.string[row.product.unit.code]}
    </Text>
  )
}
