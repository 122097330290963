import { Block, Text } from '@stage-ui/core'
import { useLocalizedString } from 'hooks/useLocalizedString'

interface EmptyDataProps {
  text?: string
}

function NoData(props: EmptyDataProps) {
  const ls = useLocalizedString()
  return (
    <Block decoration="surface" py="1.25rem" textAlign="center">
      <Text children={props.text || ls.text.noData} />
    </Block>
  )
}

export default NoData
