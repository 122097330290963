import React from 'react'

import {
  Product,
  ProductAttributeInput,
  ProductInput,
} from '@foods-n-goods/server/generated/schema'

import { Block, Button, Flexbox, modal, Paragraph } from '@stage-ui/core'

import { Table } from 'components/Table'

import { Edit2, PlusCircle, Trash2 } from '@stage-ui/icons'

import actionNotify from 'utils/actionNotify'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { AttributeEditDialog } from './AttriburteEditDialog'

interface ProductViewAttributesProps {
  productId?: Product['id']
  attributes: ProductInput['attributes']
  onChange: (attrs: ProductAttributeInput[]) => void
}

function ProductViewAttributes(props: ProductViewAttributesProps) {
  const { attributes, productId, onChange } = props
  const ls = useLocalizedString()
  if (!productId) return null

  return (
    <Block decoration="surface" borderColor="gray100" p="l" mb="m">
      <Paragraph weight={500} size="l" color="gray900" mb="m">
        {ls.text.additionalParams}
      </Paragraph>
      <Table
        data={attributes}
        columns={[
          {
            key: 'name',
            title: ls.text.key,
          },
          {
            key: 'value',
            title: ls.text.value,
          },
          {
            key: 'actions',
            title: '',
            width: 50,
            render: (ctx) => (
              <Flexbox>
                <Button
                  decoration="text"
                  label={<Edit2 />}
                  ml="s"
                  onClick={() => {
                    modal({
                      title: ls.text.attributeEdit,
                      overlayClose: false,
                      render: (close) => (
                        <AttributeEditDialog
                          attribute={ctx.row}
                          onClose={(attr) => {
                            if (attr) {
                              onChange(
                                attributes.map((a) => {
                                  if (a.name === attr.name) {
                                    return attr
                                  }
                                  return a
                                }),
                              )
                            }
                            close()
                          }}
                        />
                      ),
                    })
                  }}
                />
                <Button
                  decoration="text"
                  color="red500"
                  label={<Trash2 />}
                  ml="s"
                  onClick={() => {
                    onChange(attributes.filter((a) => a.name !== ctx.row.name))
                  }}
                />
              </Flexbox>
            ),
          },
        ]}
      />
      <Button
        mt="m"
        children={ls.text.add}
        leftChild={<PlusCircle />}
        onClick={() =>
          modal({
            title: ls.text.attributeNew,
            overlayClose: false,
            render: (close) => (
              <AttributeEditDialog
                onClose={(attr) => {
                  if (attr) {
                    const index = attributes.findIndex(
                      (a) => a.name.toUpperCase() === attr.name.toUpperCase(),
                    )
                    if (index < 0) {
                      onChange(attributes.concat(attr))
                    } else {
                      actionNotify({
                        title: ls.text.error,
                        message: ls.text.attributeErrorAlreadyExists,
                        type: 'error',
                      })
                    }
                  }
                  close()
                }}
              />
            ),
          })
        }
      />
    </Block>
  )
}

export default ProductViewAttributes
