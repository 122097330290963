import React, { FC, useRef, useState } from 'react'

import { Button, Drop, Flexbox } from '@stage-ui/core'
import { MoreVertical } from '@stage-ui/icons'
import { useLocalizedString } from 'hooks/useLocalizedString'

interface ActionCellProps {
  children?: (close: () => void) => React.ReactNode
  open: () => void
  disabled?: boolean
}

const CActionCell: FC<ActionCellProps> = ({ children, open, disabled }) => {
  const [opened, setOpened] = useState(false)
  const ref = useRef(null)
  const close = () => setOpened(false)
  const ls = useLocalizedString()
  return (
    <>
      <Button
        ref={ref}
        color={disabled ? 'light' : undefined}
        decoration="text"
        children={<MoreVertical size="l" p={0} />}
        onClick={(e) => {
          e.stopPropagation()
          if (disabled) {
            return
          }
          setOpened(!opened)
        }}
        overrides={
          disabled
            ? {
                container: {
                  ':hover:not(:disabled)': {
                    background: 'transparent',
                    cursor: 'not-allowed',
                  },
                },
              }
            : undefined
        }
      />
      <Drop
        onClickOutside={(e, outside) => {
          if (outside) setOpened(false)
        }}
        visible={opened}
        target={ref}
        spacing={4}
        justify="end"
        align="bottom"
        style={{
          zIndex: 190,
        }}
      >
        <Flexbox
          borderColor="lightest"
          borderWidth="1px"
          borderStyle="solid"
          decoration="mediumShadow"
          column
          style={{
            button: {
              justifyContent: 'flex-start',
              width: '100%',
            },
          }}
        >
          <Button decoration="text" children={ls.text.open} onClick={open} />
          {children?.(close)}
        </Flexbox>
      </Drop>
    </>
  )
}

export default CActionCell
