import React from 'react'

import { Promotion } from '@foods-n-goods/server/generated/schema'
import { Button } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { MoreVertical } from '@stage-ui/icons'
import { useMouseDropMenu } from 'components/MouseMenuDrop'
import { Image } from 'components/Image'
import { useHistory } from 'react-router-dom'
import { getLocalizedString, useLocalizedString } from 'hooks/useLocalizedString'

function ContextCell(ctx: TableTypes.TableCellContext<Promotion>) {
  const history = useHistory()
  const ls = useLocalizedString()
  const promotionsContextMenu = useMouseDropMenu<HTMLButtonElement>(
    [
      {
        text: ls.text.details,
        value: 'DETAILS',
        icon: 'Pricetag',
      },
    ],
    ({ value }) => {
      if (value === 'DETAILS') {
        return history.push(`/promotions/${ctx.row.id}`)
      }
    },
  )

  return (
    <Button
      id={`promotion_row_action_${ctx.row.id}`}
      decoration="text"
      color="gray500"
      p={0}
      w="2rem"
      h="2rem"
      {...promotionsContextMenu}
    >
      <MoreVertical size="1.5rem" />
    </Button>
  )
}

export const getColumns = (): TableTypes.TableColumn<Promotion>[] => {
  const ls = getLocalizedString()
  return [
    {
      key: 'image',
      width: 72,
      render: (ctx) => <Image url={ctx.row.image || ''} published={ctx.row.published} />,
    },
    { key: 'name', title: ls.text.name },
    { key: '_context', width: 32, render: ContextCell },
  ]
}
