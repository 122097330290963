import React, { useEffect } from 'react'

import { Order } from '@foods-n-goods/server/generated/schema'
import { Flexbox, Paragraph, Table, useTheme } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { List } from '@stage-ui/icons'
import { ClientActions } from 'actions'
import { PageSwitch } from 'components/PageSwitch'
import useSelector from 'hooks/useSelector'

import { openOrderModal } from 'components/Modals/OrderView'

import { objectEqual } from 'utils/objectEqual'

import { useLocalizedString } from 'hooks/useLocalizedString'

import dayjs from '@foods-n-goods/client/utils/dayjs'

import { OrderCreateCell } from './components/Table/OrderCreateCell'
import { OrderIdCell } from './components/Table/OrderIdCell'
import { OrderPayDelayCell } from './components/Table/OrderPayDelayCell'
import { OrderPercentPaidCell } from './components/Table/OrderPercentPaidCell'
import { OrderStatusCell } from './components/Table/OrderStatusCell'
import { OrderStatusLabelCell } from './components/Table/OrderStatusLabelCell'
import { OrderTotalCell } from './components/Table/OrderTotalCell'

type ClientHistoryProps = {
  isModal: boolean
}

export function ClientHistory(props: ClientHistoryProps) {
  const { isModal } = props
  const { color } = useTheme()

  const { filter, clientId } = useSelector(
    (state) => ({
      filter: state.client.filter,
      clientId: state.client.currentlyViewed?.id,
    }),
    objectEqual,
  )

  const { clientOrders, loading } = useSelector(
    ({ client }) => ({
      loading: client.loading === 'idle' || client.loading === 'pending',
      clientOrders: client.clientOrders,
    }),
    objectEqual,
  )
  const ls = useLocalizedString()
  const fetchClientOrders = () => {
    if (!clientId) return

    ClientActions.fetchClientOrders({
      filter: {
        includePositions: true,
        clientId,
        ...(filter.detailsOrderCreateDateStart &&
          filter.detailsOrderCreateDateEnd && {
            createDateStart: dayjs(filter.detailsOrderCreateDateStart)
              .startOf('day')
              .toDate(),
            createDateEnd: dayjs(filter.detailsOrderCreateDateEnd).endOf('day').toDate(),
          }),
        ...(filter.detailsOrderStatus && { statusId: Number(filter.detailsOrderStatus) }),
      },
      page: filter.detailsOrderPage,
      pageSize: filter.detailsOrderPageSize,
    })
  }

  useEffect(() => {
    fetchClientOrders()
  }, [
    clientId,
    filter.detailsOrderStatus,
    filter.detailsOrderCreateDateStart,
    filter.detailsOrderCreateDateEnd,
    filter.detailsOrderPage,
    filter.detailsOrderPageSize,
  ])

  const historyColumns: TableTypes.TableColumn<Order>[] = [
    {
      key: 'id',
      title: ls.text.orderNumber,
      width: 64,
      primary: true,
      render: OrderIdCell,
    },
    { key: 'date', title: ls.text.createDate, width: 90, render: OrderCreateCell },
    { key: 'status', title: ls.text.statusInternal, width: 32, render: OrderStatusCell },
    {
      key: 'statusLabel',
      title: ls.text.statusForClient,
      width: 64,
      render: OrderStatusLabelCell,
    },
    { key: 'total', title: ls.text.totalAmount, width: 48, render: OrderTotalCell },
    { key: 'payDelay', title: ls.text.payDelay, width: 64, render: OrderPayDelayCell },
    {
      key: 'percentPaid',
      title: ls.text.percentPaid,
      width: 64,
      render: OrderPercentPaidCell,
    },
  ]

  if (!clientOrders || !clientOrders.records.length) {
    return (
      <Flexbox
        column
        centered
        p="m"
        decoration="surface"
        borderRadius="s"
        borderColor="gray200"
      >
        <List size="1.5rem" color="gray300" />
        <Paragraph size="s" color="gray400">
          {loading ? ls.text.loading : ls.text.listEmpty}
        </Paragraph>
      </Flexbox>
    )
  }

  const onRowClick = (ctx: TableTypes.TableRowContext<Order>) => {
    openOrderModal(ctx.row.id)
  }

  return (
    <Flexbox column h="100%">
      <Table
        columns={historyColumns}
        data={clientOrders.records}
        onRowClick={onRowClick}
        overrides={{
          headCell: () => [
            {
              backgroundColor: color.background.hex(),
              position: isModal ? 'relative' : 'sticky',
              top: isModal ? 0 : '10.3125rem',
              zIndex: 6,
            },
          ],
        }}
      />
      <PageSwitch
        pageSize={filter.detailsOrderPageSize}
        page={filter.detailsOrderPage}
        total={clientOrders.total}
        onChange={({ page, pageSize }) =>
          ClientActions.setFilter({
            detailsOrderPage: page,
            detailsOrderPageSize: pageSize,
          })
        }
      />
    </Flexbox>
  )
}
