import ClientSystem, { OrderStatusCode } from '@foods-n-goods/client/system/types'
import { Order, OrderPage } from '@foods-n-goods/server/generated/schema'
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

export type OrderDelivery = Order & { sequenceOrder: number }

export type OrderDeliveryCoordinator = {
  clientName: string
  courierName?: string
  client: OrderDelivery['client']
  orders: OrderDelivery[]
}
export interface OrderPageDelivery extends OrderPage {
  records: Array<OrderDelivery>
}

/**
 * Database stores sequence as float value we should
 * update sequence to integer in terms of courier
 */
export const sequenceHelper = (
  orders: Draft<OrderDelivery>[],
  courierId?: string,
): void => {
  const tmpCouriers: Record<
    string,
    Array<{ id: string; index: number; sequence: number; clientId: string }>
  > = {}
  orders.forEach((order, index) => {
    const id = order.courier?.id
    if (id) {
      if (courierId && courierId !== id) return // skip
      if (!tmpCouriers[id]) tmpCouriers[id] = [] // ensure array

      tmpCouriers[id].push({
        id: order.id,
        index,
        clientId: order.client.id,
        sequence: order.sequence || 0,
      })
    }
  })
  // each courier in array
  Object.keys(tmpCouriers).forEach((id) => {
    // sort by sequence or id if sequence equal
    tmpCouriers[id].sort((a, b) => {
      if (a.sequence === b.sequence) {
        return a.id > b.id ? 1 : -1
      }
      return a.sequence > b.sequence ? 1 : -1
    })

    // add sequenceOrder
    let tmpSeq = 0
    const clientSeq: Record<string, number> = {}

    tmpCouriers[id].forEach((courierOrder) => {
      if (!clientSeq[courierOrder.clientId]) {
        clientSeq[courierOrder.clientId] = ++tmpSeq
      }

      orders[courierOrder.index].sequenceOrder = clientSeq[courierOrder.clientId]
    })
  })
}

export const coordinatorData = (
  state: DeliveryState,
  data: OrderDelivery[],
): Array<OrderDeliveryCoordinator> => {
  const modifiedData: OrderDeliveryCoordinator[] = []
  const logisticsShowAll = state.filter.coordinatorShowAll

  data.forEach((order) => {
    // skip unnecessary orders
    if (
      !logisticsShowAll &&
      ![
        OrderStatusCode.SORTING,
        OrderStatusCode.AWAIT_COURIER,
        OrderStatusCode.PICKED_UP,
        OrderStatusCode.DELIVERY,
      ].includes(order.status.value)
    ) {
      return
    }

    const clientIndex = modifiedData.findIndex((o) => o.client.id === order.client.id)
    if (clientIndex >= 0) {
      const existsOrder = modifiedData[clientIndex].orders.findIndex(
        (or) => or.id === order.id,
      )
      if (existsOrder < 0) {
        modifiedData[clientIndex].orders = [order].concat(
          modifiedData[clientIndex].orders.slice(),
        )
      }
    } else {
      modifiedData.push({
        clientName:
          order.client.markets[0]?.alias || order.client.name || order.client.identifier,
        courierName: order.courier
          ? order.courier?.name || order.courier?.login || order.courier?.id
          : `\u04FF`,
        client: order.client,
        orders: [order],
      })
    }
  })

  return modifiedData
}

export type LogisticsOrderStatus =
  | 'all'
  | 'warehouse'
  | 'delivery'
  | 'done'
  | 'unassigned'
  | 'coordinator'
  | 'coordinatorAll'

export type LogisticsCourierStatus = 'all' | 'online' | 'hasOrders'

export type DeliveryFilter = {
  section: string
  date: string

  ordersTab: LogisticsOrderStatus
  ordersPage: number
  ordersPageSize: number

  couriersTab: LogisticsCourierStatus
  courierId?: string

  coordinatorShowAll: boolean
  sortBySequance: boolean
}

const initialDeliveryFilter: DeliveryFilter = {
  section: localStorage.getItem('delivery.section') || 'orders',
  date: dayjs().format('YYYY-MM-DD'),

  ordersTab: 'all',
  ordersPage: 1,
  ordersPageSize: 40,

  couriersTab: 'all',
  coordinatorShowAll: !!localStorage.getItem('delivery.coordinatorShowAll'),
  sortBySequance: !!localStorage.getItem('delivery.sortBySequance'),
}

export interface DeliveryState {
  loading: ClientSystem.Loading
  error: RequestError | null

  orderPage: OrderPageDelivery
  coordinatorData: OrderDeliveryCoordinator[]

  ordersSelected: string[]
  coordinatorCheckedOrders: string[]
  currentOrder: string | null

  couriersSelected: string[]
  currentCourier: string | null

  filter: DeliveryFilter

  courierRoute: null | {
    time: number
    length: number
    timeWithTrafficJam: string
    segments: {
      time: number
      length: number
      timeWithTrafficJam: string
    }[]
  }
}

export const initialState: DeliveryState = {
  loading: 'idle',
  error: null,

  orderPage: {
    page: 1,
    pageSize: 40,
    records: [],
    total: 0,
  },

  coordinatorData: [],
  coordinatorCheckedOrders: [],

  ordersSelected: [],
  currentOrder: null,

  couriersSelected: [],
  currentCourier: null,

  courierRoute: null,
  filter: initialDeliveryFilter,
}

const deliverySlice = createSlice({
  name: 'delivery',
  initialState,
  reducers: {
    clear(state) {
      return initialState
    },
    setOrdersSelected(state, action: PayloadAction<string[]>) {
      state.ordersSelected = action.payload
    },
    setCurrentOrder(state, action: PayloadAction<string | null>) {
      state.currentOrder = action.payload
    },
    setCouriersSelected(state, action: PayloadAction<string[]>) {
      state.couriersSelected = action.payload
    },
    setCurrentCourier(state, action: PayloadAction<string | null>) {
      state.currentCourier = action.payload
    },
    ordersFetch(state) {
      state.error = null
      state.loading = 'pending'
    },
    ordersResolve(state, action: PayloadAction<OrderPageDelivery>) {
      state.loading = 'resolved'
      state.orderPage = action.payload
      state.coordinatorData = coordinatorData(state, action.payload.records)
      state.coordinatorCheckedOrders = state.coordinatorCheckedOrders.filter((id) => {
        return !action.payload.records.find((x) => x.id === id)
      })

      sequenceHelper(state.orderPage.records)
    },
    ordersReject(state, action: PayloadAction<DeliveryState['error']>) {
      state.loading = 'rejected'
      state.error = action.payload
    },
    ordersUpdate(state, action: PayloadAction<OrderDelivery[]>) {
      state.orderPage.records = state.orderPage.records.map(
        (order) => action.payload.find(({ id }) => id === order.id) || order,
      )
      state.coordinatorData = coordinatorData(state, state.orderPage.records)
      sequenceHelper(state.orderPage.records)
    },
    coordinatorCheckOrder(state, action: PayloadAction<string>) {
      const index = state.coordinatorCheckedOrders.findIndex(
        (id) => id === action.payload,
      )
      if (index < 0) {
        state.coordinatorCheckedOrders.push(action.payload)
      } else {
        state.coordinatorCheckedOrders = state.coordinatorCheckedOrders.filter(
          (id) => id !== action.payload,
        )
      }
    },
    coordinatorClearOrders(state) {
      state.coordinatorCheckedOrders = []
    },
    courierRouteSet(state, action: PayloadAction<DeliveryState['courierRoute'] | null>) {
      state.courierRoute = action.payload
    },
    setFilter(state, action: PayloadAction<Partial<DeliveryFilter>>) {
      if (action.payload.section) {
        localStorage.setItem('delivery.section', action.payload.section)
      }
      if (action.payload.coordinatorShowAll !== undefined) {
        localStorage.setItem(
          'delivery.coordinatorShowAll',
          action.payload.coordinatorShowAll ? '1' : '',
        )
      }
      if (action.payload.sortBySequance !== undefined) {
        localStorage.setItem(
          'delivery.sortBySequance',
          action.payload.coordinatorShowAll ? '1' : '',
        )
      }
      state.filter = {
        ...state.filter,
        ...action.payload,
      }
    },
    clearFilter(state) {
      state.filter = initialDeliveryFilter
    },
  },
})

export default deliverySlice
