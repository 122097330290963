import React, { useState } from 'react'

import {
  Order,
  OrderAddProduct,
  Product,
  SelectItemWithCode,
} from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox } from '@stage-ui/core'
import { OrderActions } from 'actions'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { OrderAddPositionsSearch } from './SearchProducts'
import { OrderAddPositionsSubtotal } from './Subtotal'
import { OrderAddPositionsTable } from './Table'

type OrderAddPositionsModalProps = {
  close: () => void
  order: Order
}

export type OrderAddProductCustom = Omit<OrderAddProduct, 'unitId' | 'st'> & {
  name: Product['name']
  unit: SelectItemWithCode
  price: Product['price']
  availableUnits: (SelectItemWithCode & { weight: number })[]
}

export const OrderAddPositionsModal: React.FC<OrderAddPositionsModalProps> = (props) => {
  const { close, order } = props
  const ls = useLocalizedString()
  const [addProducts, setAddProducts] = useState<OrderAddProductCustom[]>([])

  const onProductSelect = (product: Product) => {
    setAddProducts((addedProducts) => {
      if (addedProducts.some((ap) => ap.id === product.id)) {
        return addProducts.filter((ap) => ap.id !== product.id)
      }
      return addProducts.concat({
        id: product.id,
        name: product.name,
        quantity: '1',
        unit: product.unit,
        price: product.price,
        availableUnits: product.avarageWeight
          .map((aw) => ({
            text: aw.unitName,
            value: Number(aw.unitId),
            weight: aw.value,
          }))
          .concat([
            { text: ls.string[product.unit.code], value: product.unit.value, weight: 1 },
          ]),
      })
    })
  }

  const onProductDelete = (product: OrderAddProductCustom) => {
    setAddProducts((addedProducts) => addedProducts.filter((ap) => ap.id !== product.id))
  }

  const onProductUpdate = (product: OrderAddProductCustom) => {
    setAddProducts((addedProducts) =>
      addedProducts.map((ap) => (ap.id === product.id ? product : ap)),
    )
  }

  const performAddProducts = async () => {
    const data = addProducts
      .filter((ap) => parseFloat(ap.quantity) > 0)
      .map((ap) => ({
        id: ap.id,
        quantity: ap.quantity,
        unitId: ap.unit.value,
      }))

    if (!data.length) return

    await OrderActions.addProducts(
      {
        id: order.id,
        products: data,
      },
      close,
    )
  }

  return (
    <Flexbox column>
      <OrderAddPositionsSearch
        order={order}
        addProducts={addProducts}
        onProductSelect={onProductSelect}
      />
      <OrderAddPositionsTable
        addProducts={addProducts}
        onProductDelete={onProductDelete}
        onProductUpdate={onProductUpdate}
      />
      <Flexbox justifyContent="space-between">
        <OrderAddPositionsSubtotal addProducts={addProducts} />
        <Flexbox justifyContent="flex-end" alignItems="flex-end">
          <Button
            decoration="text"
            color="gray500"
            label={ls.text.cancel}
            onClick={close}
            mr="m"
          />
          <Button label={ls.text.add} onClick={performAddProducts} size="m" w="8rem" />
        </Flexbox>
      </Flexbox>
    </Flexbox>
  )
}
