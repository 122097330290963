import React, { useEffect, useState } from 'react'

import {
  Block,
  Button,
  Checkbox,
  DatePicker,
  Flexbox,
  ScrollView,
  Spinner,
  TextField,
} from '@stage-ui/core'

import { ReportAvailable } from '@foods-n-goods/server/generated/schema'

import ReportActions from 'actions/report'
import actionNotify from 'utils/actionNotify'
import { getLocalizedString, useLocalizedString } from 'hooks/useLocalizedString'
import DatePickerTypes from '@stage-ui/core/control/DatePicker/types'

type NewReportConfigureProps = {
  close: () => void
  report: ReportAvailable
}

const getParam = (
  param: ReportAvailable['params'][0],
  onChange: (value: string) => void,
) => {
  if (param.type === 'STRING') {
    return (
      <TextField
        label={`${param.label}${param.required ? ' *' : ''}`}
        placeholder={param.placeholder || ''}
        defaultValue={param.value ? param.value : undefined}
        hint={param.hint}
        onChange={({ target }) => onChange(target.value)}
      />
    )
  }
  if (param.type === 'NUMBER') {
    return (
      <TextField
        type="number"
        label={`${param.label}${param.required ? ' *' : ''}`}
        placeholder={param.placeholder || ''}
        defaultValue={param.value ? param.value : undefined}
        hint={param.hint}
        onChange={({ target }) => onChange(target.value)}
      />
    )
  }
  if (param.type === 'DATE') {
    const ls = getLocalizedString()
    return (
      <DatePicker
        locale={ls.id?.split('-')[0] as DatePickerTypes.Props['locale']}
        label={`${param.label}${param.required ? ' *' : ''}`}
        placeholder={param.placeholder || ''}
        defaultValue={param.value ? new Date(param.value) : undefined}
        hint={param.hint}
        onChange={(value) => onChange(value?.toISOString() || '')}
      />
    )
  }
  if (param.type === 'BOOL') {
    return (
      <Checkbox
        label={`${param.label}${param.required ? ' *' : ''}`}
        checked={param.value === '1'}
        onChange={(value) => onChange(value ? '1' : '0')}
      />
    )
  }
  return null
}

let payload: Record<string, string> = {}

export function NewReportConfigure(props: NewReportConfigureProps) {
  const { close, report } = props
  const ls = useLocalizedString()
  const [busy, setBusy] = useState(false)

  useEffect(() => {
    payload = {}
    report.params.forEach(({ id, value }) => {
      if (value) {
        payload[id] = value
      }
    })
  }, [])

  return (
    <Flexbox column>
      <Flexbox
        column
        h="40vh"
        alignItems="flex-start"
        borderColor="transparent"
        borderWidth="0.0625rem"
        borderStyle="solid"
      >
        <ScrollView mode="scroll" xBarPosition="none" w="100%" barOffset={4}>
          {report.params.map((param) => (
            <Block
              pb="m"
              key={param.label}
              children={getParam(param, (value) => {
                payload[param.id] = value
              })}
            />
          ))}
        </ScrollView>
      </Flexbox>

      <Flexbox justifyContent="space-between" mt="l">
        <Flexbox flex={1} justifyContent="flex-end">
          <Button
            mr="m"
            label={ls.text.cancel}
            color="gray500"
            textColor="gray500"
            decoration="text"
            onClick={close}
            pr="m"
          />
          <Button
            label={busy ? <Spinner /> : ls.text.reportCreate}
            onClick={() => {
              if (busy) {
                return
              }
              setBusy(true)
              ReportActions.reportProcess({
                reportId: report.id,
                payload: JSON.stringify(payload),
              })
                .then(() => {
                  close()
                })
                .catch((error) => {
                  actionNotify({
                    title: ls.text.reportCreation,
                    message: (error as Error).message,
                    type: 'error',
                  })
                  setBusy(false)
                })
            }}
          />
        </Flexbox>
      </Flexbox>
    </Flexbox>
  )
}
