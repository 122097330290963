import React from 'react'

import { OrderStatusCode } from '@foods-n-goods/client/system/types'
import { Flexbox } from '@stage-ui/core'
import { StatusIndicator } from 'components/StatusIndicator'
import useSelector from 'hooks/useSelector'
import { useLocalizedString } from 'hooks/useLocalizedString'

export function DeliveryCount() {
  const statCounters = useSelector(
    (state) => state.order.statCounters,
    (p, n) =>
      p.ordersNotAssigned === n.ordersNotAssigned &&
      p.ordersAssigned === n.ordersAssigned &&
      p.ordersNotDelivered === n.ordersNotDelivered,
  )
  const ls = useLocalizedString()
  return (
    <Flexbox alignItems="center">
      {!!statCounters.ordersNotAssigned && (
        <StatusIndicator
          text={String(statCounters.ordersNotAssigned)}
          status={{
            text: String(statCounters.ordersNotAssigned),
            value: OrderStatusCode.AWAIT_COURIER,
          }}
          hint={ls.text.noCourier}
          ml="xs"
          p="0 s"
          h="1.25rem"
          type="fill"
        />
      )}
      {!!statCounters.ordersAssigned && (
        <StatusIndicator
          text={String(statCounters.ordersAssigned)}
          status={{
            text: String(statCounters.ordersAssigned),
            value: OrderStatusCode.PICKED_UP,
          }}
          hint={ls.text.courierAssign}
          ml="xs"
          p="0 s"
          h="1.25rem"
          type="fill"
        />
      )}
      {!!statCounters.ordersNotDelivered && (
        <StatusIndicator
          text={String(statCounters.ordersNotDelivered)}
          status={{
            text: String(statCounters.ordersNotDelivered),
            value: OrderStatusCode.NOT_DELIVERED,
          }}
          hint={ls.text.ordersNotDelivered}
          ml="xs"
          p="0 s"
          h="1.25rem"
          type="fill"
        />
      )}
    </Flexbox>
  )
}
