import React from 'react'

import { Product, ProductInput } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox } from '@stage-ui/core'
import { ProductActions } from 'actions'
import AcceptDialog from 'components/AcceptDialog'
import Content from 'components/Content'
import { useLocalizedString } from 'hooks/useLocalizedString'

interface ProductViewControllersProps {
  goBack: () => void
  input: ProductInput
  product?: Product
}

function ProductViewControllers(props: ProductViewControllersProps) {
  const { product, input, goBack } = props
  const ls = useLocalizedString()
  const actionButtonLabel = !product?.id ? ls.text.add : ls.text.save

  const _create = () => ProductActions.create(input, goBack)
  const _update = () => product?.id && ProductActions.update(product.id, input, goBack)
  const _delete = () => product?.id && ProductActions.delete(product.id)

  const onActionClick = product?.id ? _update : _create

  return (
    <Content>
      <Flexbox>
        {product && (
          <AcceptDialog
            action={_delete}
            title={ls.text.productDelete}
            children={ls.text.delete}
            color="error"
            text={ls.text.productDeleteWarning.replace('[1]', product.name || '')}
          />
        )}
        <Flexbox flex={1} justifyContent="flex-end" alignItems="center">
          <Button
            children={ls.text.cancel}
            mx="0.5rem"
            decoration="text"
            onClick={goBack}
          />
          <Button
            children={actionButtonLabel}
            textColor="onPrimary"
            onClick={onActionClick}
          />
        </Flexbox>
      </Flexbox>
    </Content>
  )
}

export default ProductViewControllers
