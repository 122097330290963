import React, { useMemo } from 'react'

import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { Block, Flexbox, Paragraph, Select } from '@stage-ui/core'
import { AppActions } from 'actions'

import { WeightRounding } from '@foods-n-goods/server/generated/schema'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { getOnOffLabels, getWeightRoundingLabels } from '../misc'

function Warehouse() {
  const ls = useLocalizedString()
  const { marketConfig } = useSelector(
    (state) => ({
      marketConfig: state.app.marketConfig,
    }),
    objectEqual,
  )
  const onOffLabels = useMemo(getOnOffLabels, [ls.id])
  const weightRoundingLabels = useMemo(getWeightRoundingLabels, [ls.id])
  return (
    <Block decoration="surface" borderColor="gray100" p="l">
      <Paragraph weight={500} size="l" color="gray900" mb="m">
        {ls.text.warehouseWorker}
      </Paragraph>
      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.3} mr="m">
          {ls.text.warehouseSelectionPermission}
        </Paragraph>
        <Select
          decoration="none"
          options={onOffLabels}
          defaultValues={[
            onOffLabels.find(
              (opt) => opt.value === (marketConfig?.warehouseEditWarehouses || '1'),
            )!,
          ]}
          onChange={(_, value) => {
            AppActions.marketConfigSet({
              warehouseEditWarehouses: value?.value as string,
            })
          }}
        />
      </Flexbox>

      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.3} mr="m">
          {ls.text.weightRounding}
        </Paragraph>
        <Select
          decoration="none"
          options={weightRoundingLabels}
          defaultValues={[
            weightRoundingLabels.find(
              (opt) => opt.value === (marketConfig?.weightRounding || 'dontRound'),
            )!,
          ]}
          onChange={(_, value) => {
            AppActions.marketConfigSet({
              weightRounding: value?.value as WeightRounding,
            })
          }}
        />
      </Flexbox>
      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.3} mr="m">
          {ls.text.completeItemCountHide}
        </Paragraph>
        <Select
          decoration="none"
          options={onOffLabels}
          defaultValues={[
            onOffLabels.find(
              (opt) =>
                opt.value === (marketConfig?.warehouseControlHideDonePositions || '0'),
            )!,
          ]}
          onChange={(_, value) => {
            AppActions.marketConfigSet({
              warehouseControlHideDonePositions: value?.value as string,
            })
          }}
        />
      </Flexbox>
    </Block>
  )
}

export default Warehouse
