import React, { useCallback, useEffect, useMemo } from 'react'

import { PurchasesActions } from 'actions'
import { Section } from 'components/Section'

import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

import { useLocalizedString } from 'hooks/useLocalizedString'

import dayjs from 'dayjs'

import { PurchasesHeader } from './components/Header'
import { PurchasesTable } from './components/Table'
import { getPurchasesMenuItems, PurchasesToolbar } from './components/Toolbar'
import { PurchasesByProductTable } from './components/ByProductsTable'

export function Purchases() {
  const filter = useSelector((state) => state.purchases.filter, objectEqual)
  const ls = useLocalizedString()
  const purchasesMenuItems = useMemo(getPurchasesMenuItems, [ls.id])
  const fetchPurchases = useCallback(
    (silent: boolean = false) => {
      if (!filter.createDateStart || !filter.createDateEnd) return

      const statusIds = purchasesMenuItems.find((i) => i.id === filter.section)?.statusIds

      PurchasesActions.fetch(
        {
          page: filter.page,
          pageSize: filter.pageSize,
          search: filter.search,
          filter: {
            dateStart: dayjs(filter.createDateStart, 'YYYY-MM-DD')
              .startOf('day')
              .toDate(),
            dateEnd: dayjs(filter.createDateEnd, 'YYYY-MM-DD').endOf('day').toDate(),
            staffId: filter.purchaserId || undefined,
            statusIds: statusIds || undefined,
          },
        },
        silent,
      )
    },
    [filter],
  )

  useEffect(() => {
    if (filter.section !== 'byProducts') {
      fetchPurchases()
    }
  }, [filter])

  useEffect(() => {
    return () => {
      PurchasesActions.clearFilter()
    }
  }, [])

  return (
    <Section title={ls.text.purchases} titleComponent={<PurchasesHeader />}>
      <PurchasesToolbar />
      {filter.section !== 'byProducts' && <PurchasesTable />}
      {filter.section === 'byProducts' && <PurchasesByProductTable />}
    </Section>
  )
}
