import React from 'react'

import { Button, Flexbox, modal } from '@stage-ui/core'

import TwoDots from 'components/Icons/TwoDots'
import { useMouseDropMenu } from 'components/MouseMenuDrop'
import { PurchasesActions } from 'actions'

import useSelector from 'hooks/useSelector'
import { PurchaseCreateIncomesPayload } from '@foods-n-goods/server/generated/schema'
import { objectEqual } from 'utils/objectEqual'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { PurchaseCreateModal } from '../CreatePurchases'

export function PurchasesToolbarContextMenu() {
  const ls = useLocalizedString()
  const section = useSelector((state) => state.purchases.filter.section, objectEqual)
  const handlePurchasesCreate = () => {
    modal({
      title: ls.text.addProductToPurchase,
      w: '42rem',
      overlayClose: false,
      render: (close) => <PurchaseCreateModal close={close} />,
    })
  }

  const payload = useSelector((state) => {
    const p: PurchaseCreateIncomesPayload[] = []
    state.purchases.byProducts.forEach((item) => {
      const productId = Number(item.productId)
      const quantity = item.purchaseQuantity
      if (quantity) {
        p.push({ productId, quantity })
      }
    })
    return p
  }, objectEqual)

  const handleSync = () => {
    modal({
      title: ls.text.purchasesSync,
      subtitle: ls.text.purchasesSyncSubtitle,
      render: (close) => (
        <Flexbox pt="xl" justifyContent="flex-end">
          <Button
            mr="m"
            label={ls.text.cancel}
            decoration="text"
            onClick={() => {
              close()
            }}
          />
          <Button
            label={ls.text.continue}
            onClick={() => {
              close()
              PurchasesActions.createPurchaseIncomes(payload)
            }}
          />
        </Flexbox>
      ),
    })
  }

  const ctxMenu = useMouseDropMenu<HTMLButtonElement>(
    [
      {
        text: ls.text.purchaseCreate,
        icon: 'Plus',
        value: 'CREATE',
      },
      {
        text: ls.text.clearPurchasedItems,
        value: 'DELETE_COMPLETED',
        icon: 'Trash2',
        color: 'red500',
        hidden:
          !!section &&
          ['inRepurchase', 'failed', 'canceled', 'byProducts'].includes(section),
      },
      {
        text: ls.text.syncPurchases,
        value: 'SYNC',
        icon: 'Sync',
        hidden: !payload.length || section !== 'byProducts',
      },
    ],
    ({ value }) => {
      if (!value) return

      if (value === 'SYNC') handleSync()
      if (value === 'DELETE_COMPLETED') PurchasesActions.deleteCompleted()
      if (value === 'CREATE') handlePurchasesCreate()
    },
  )

  return (
    <Button decoration="text" color="gray500" p={0} w="100%" h="2.5rem" {...ctxMenu}>
      <TwoDots fillColor="gray900" size="1.5rem" />
    </Button>
  )
}
