import React, { useState, useEffect } from 'react'

import { Flexbox, Button } from '@stage-ui/core'
import { ArrowheadRight, ArrowheadLeft } from '@stage-ui/icons'
import localStorage from 'utils/localStorage'
import { useLocalizedString } from 'hooks/useLocalizedString'

interface PaginationProps {
  pageCount: number
  onChange: (page: number) => void
}

function Pagination(props: PaginationProps) {
  const { onChange, pageCount } = props
  const ls = useLocalizedString()
  const getCurrentPage = () => {
    setPathToLocalStorage()
    const currentPage = localStorage.getPreference('currentPage')
    if (
      !currentPage ||
      localStorage.getPreference('currentLocation')?.slice(0, 6) !==
        window.location.pathname.slice(0, 6)
    ) {
      localStorage.setPreference('currentPage', 1)
      localStorage.setPreference('currentLocation', window.location.pathname)
      return 1
    }
    return parseInt(currentPage)
  }

  const setPathToLocalStorage = () => {
    if (!localStorage.getPreference('currentLocation')) {
      localStorage.setPreference('currentLocation', window.location.pathname)
    }
  }

  const [currentPage, setCurrentPage] = useState(getCurrentPage())

  useEffect(() => {
    onChange(currentPage)
  }, [currentPage])

  const onPreviousPage = () => {
    setCurrentPage((prevCurrentPage: number) => {
      localStorage.setPreference('currentPage', prevCurrentPage - 1)
      return prevCurrentPage - 1
    })
  }
  const onNextPage = () => {
    setCurrentPage((prevCurrentPage: number) => {
      localStorage.setPreference('currentPage', prevCurrentPage + 1)
      return prevCurrentPage + 1
    })
  }

  const onSpecificPage = (pageNumber: number) => {
    setCurrentPage(() => {
      localStorage.setPreference('currentPage', pageNumber)
      return pageNumber
    })
  }

  const maxVisiblePagesAmount = 5
  const leftRightAmount = (maxVisiblePagesAmount - 1) / 2
  const isAllPagesVisible = pageCount <= maxVisiblePagesAmount

  const visiblePages = isAllPagesVisible
    ? [...new Array(pageCount)].map((_, i) => i + 1)
    : [...new Array(maxVisiblePagesAmount)].map((_, i) => {
        if (currentPage - leftRightAmount < 1) {
          return i + 1
        }
        if (currentPage + leftRightAmount > pageCount) {
          return pageCount - maxVisiblePagesAmount + i + 1
        }
        return currentPage - leftRightAmount + i
      })

  return (
    <Flexbox alignItems="center" flex={1} justifyContent="center" mt="2rem">
      <Button
        children={ls.text.toStart}
        decoration="text"
        color="onBackground"
        mr="s"
        disabled={currentPage === 1}
        onClick={() => onSpecificPage(1)}
      />
      <Button
        decoration="text"
        children={<ArrowheadLeft size="l" />}
        color="onBackground"
        ml="s"
        disabled={currentPage === 1}
        onClick={onPreviousPage}
      />
      {!isAllPagesVisible && currentPage - leftRightAmount > 1 && (
        <Button decoration="text" children="..." disabled onClick={onNextPage} />
      )}
      {visiblePages.map((pageNumber) => {
        return (
          <Button
            mr="s"
            decoration="text"
            color="onBackground"
            key={pageNumber}
            children={pageNumber}
            disabled={currentPage === pageNumber}
            onClick={() => onSpecificPage(pageNumber)}
          />
        )
      })}
      {!isAllPagesVisible && currentPage + leftRightAmount < pageCount && (
        <Button decoration="text" children="..." disabled onClick={onNextPage} />
      )}
      <Button
        decoration="text"
        children={<ArrowheadRight size="l" />}
        color="onBackground"
        ml="s"
        disabled={currentPage === pageCount}
        onClick={onNextPage}
      />
    </Flexbox>
  )
}

export default Pagination
