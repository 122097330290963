import React from 'react'

import { Report } from '@foods-n-goods/server/generated/schema'
import TableTypes from '@stage-ui/core/data/Table/types'
import { Text } from '@stage-ui/core'
import dayjs from 'dayjs'

export function CellDate(ctx: TableTypes.TableCellContext<Report>) {
  const { value } = ctx

  if (!value) {
    return null
  }
  return (
    <Text color="gray500" size="s">
      {dayjs((value as Date) || '').format('DD.MM.YYYY HH:mm')}
    </Text>
  )
}
