import { PromotionInput } from '@foods-n-goods/server/generated/schema'
import { getLocalizedString } from 'hooks/useLocalizedString'
import request from 'requests/request'
import { AppStore, PromotionStore } from 'store'
import actionNotify from 'utils/actionNotify'

export default {
  async getAll() {
    try {
      PromotionStore.loading()
      const promotions = await request('promotions')
      PromotionStore.resolve(promotions)
    } catch (error) {
      PromotionStore.reject(error)
    }
  },

  async create(payload: PromotionInput, _cb?: () => void) {
    try {
      AppStore.pending(true)
      const response = await request('promotionCreate', { payload })
      PromotionStore.actionCreateResolve(response)

      actionNotify({
        title: response.name,
        message: getLocalizedString().text.promotionCreateSuccess,
      })

      _cb?.()
    } catch (error) {
      PromotionStore.actionReject(error.message)
    } finally {
      AppStore.pending(false)
    }
  },

  async delete(id: string) {
    try {
      AppStore.pending(true)
      const response = await request('promotionDelete', { id })
      PromotionStore.actionDeleteResolve(response)

      actionNotify({
        title: response.name,
        message: getLocalizedString().text.promotionDeleteSuccess,
        image: response.image,
      })
    } catch (error) {
      PromotionStore.actionReject(error.message)
    } finally {
      AppStore.pending(false)
    }
  },

  async update(id: string, payload: PromotionInput, _cb: () => void) {
    try {
      AppStore.pending(true)
      const response = await request('promotionUpdate', { id, payload })
      PromotionStore.actionUpdateResolve(response)

      actionNotify({
        title: response.name,
        message: getLocalizedString().text.promotionUpdateSuccess,
        image: response.image,
      })

      _cb()
    } catch (error) {
      PromotionStore.actionReject(error.message || error)
    } finally {
      AppStore.pending(false)
    }
  },

  async imageUpdate(id: string, payload: string) {
    try {
      AppStore.pending(true)
      const response = await request('promotionImage', { id, payload })
      PromotionStore.actionUpdateResolve(response)
    } catch (error) {
      PromotionStore.actionReject(error.message || error)
    } finally {
      AppStore.pending(false)
    }
  },
}
