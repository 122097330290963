import React from 'react'

import { Flexbox } from '@stage-ui/core'

import { OrderDelivery, OrderPageDelivery } from 'store/delivery'
import { useLocalizedString } from 'hooks/useLocalizedString'

type PrintTableProps = {
  data: OrderPageDelivery
  header?: string
}

export const printTableId = 'print_table'

export const sortByCourierId = (orders: OrderDelivery[]): OrderDelivery[] =>
  [...orders].sort((a, b) => Number(a.courier?.id || 0) - Number(b.courier?.id || 0))

const tableStyle: React.CSSProperties = {
  padding: '4px 8px',
  border: '1px solid #000',
  textAlign: 'center',
}

const PrintTable: React.FC<PrintTableProps> = (props) => {
  const { data, header } = props
  const ls = useLocalizedString()
  const sortedData = sortByCourierId(data.records)
  return (
    <Flexbox id={printTableId} display="none">
      <table>
        <thead>
          {header && (
            <tr>
              <th style={tableStyle} colSpan={4}>
                {header}
              </th>
            </tr>
          )}
          <tr>
            <th style={tableStyle}>{ls.text.invoice}</th>
            <th style={tableStyle}>{ls.text.courier}</th>
            <th style={tableStyle}>{ls.text.client1}</th>
            <th style={tableStyle}>{ls.text.interval}</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row) => {
            const { exInvoiceId, courier, client, preferDeliveryInterval } = row
            return (
              <tr key={exInvoiceId}>
                <td style={tableStyle}>{exInvoiceId || '-'}</td>
                <td style={tableStyle}>{courier?.name || courier?.login || '-'}</td>
                <td style={tableStyle}>
                  {client.name || client.markets[0].alias || '-'}
                </td>
                <td style={tableStyle}>{preferDeliveryInterval}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </Flexbox>
  )
}

export default PrintTable
