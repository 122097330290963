import React from 'react'

import { DeliveryIntervalData } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { Edit } from '@stage-ui/icons'

import { getLocalizedString } from 'hooks/useLocalizedString'

import { updateDeliveryIntervalDataDialog } from './form'

export const getColumns = (): TableTypes.TableColumn<DeliveryIntervalData>[] => {
  const ls = getLocalizedString()
  return [
    { key: 'id', title: 'ID', width: '4rem' },
    { key: 'interval', width: 200, title: ls.text.interval },
    {
      key: '_',
      title: '',
      render: (ctx) => (
        <Flexbox justifyContent="flex-end">
          <Button
            ml="s"
            label={ls.text.edit}
            decoration="plain"
            size="s"
            onClick={() => {
              updateDeliveryIntervalDataDialog(ctx.row)
            }}
            rightChild={<Edit />}
          />
        </Flexbox>
      ),
    },
  ]
}
