import React from 'react'

import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs'
import { useLocation } from 'react-router-dom'
import { useLocalizedString } from 'hooks/useLocalizedString'

export function SectionHeader() {
  const location = useLocation()
  const ls = useLocalizedString()
  const sectionPath = location.pathname.split('/')[1]

  return (
    <BreadCrumbs
      path=""
      selectedItem=""
      sectionName={ls.text.reports}
      sectionPath={sectionPath}
    />
  )
}
