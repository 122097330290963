import React from 'react'

import {
  MutationProductReviewModerateArgs,
  ProductReview,
} from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox, Text } from '@stage-ui/core'
import { ReviewActions } from 'actions'
import Content from 'components/Content'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'

import { useLocalizedString } from 'hooks/useLocalizedString'

import ReviewAnswer from '../actions/Answer'

interface ReviewViewControllersProps {
  goBack: () => void
  review: ProductReview
}

export type UpdateArgs = Omit<MutationProductReviewModerateArgs, 'id'>

function ReviewViewControllers(props: ReviewViewControllersProps) {
  const {
    goBack,
    review: { id, published, answer },
  } = props
  const actionError = useSelector(({ review }) => review.actionError, objectEqual)
  const ls = useLocalizedString()
  const performUpdate = (args: UpdateArgs) =>
    ReviewActions.update(
      {
        id,
        published,
        answer,
        ...args,
      },
      goBack,
    )

  const performPublish = () => performUpdate({ published: true })
  const performAnswer = (answer: string) => performUpdate({ answer })

  return (
    <Content>
      {actionError && (
        <Flexbox pb="m">
          <Text size="s" color="error" children={actionError} />
        </Flexbox>
      )}
      <Flexbox>
        {!published && (
          <Button
            children={ls.text.publish}
            color="warning"
            onClick={performPublish}
            mr="0.5rem"
          />
        )}
        <ReviewAnswer action={performAnswer} />
        <Flexbox ml="2rem" flex={1} justifyContent="flex-end" alignItems="center">
          <Button
            children={ls.text.cancel}
            mx="0.5rem"
            decoration="text"
            onClick={goBack}
          />
        </Flexbox>
      </Flexbox>
    </Content>
  )
}

export default ReviewViewControllers
