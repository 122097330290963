import React from 'react'

import { Order } from '@foods-n-goods/server/generated/schema'
import { Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import dayjs from 'dayjs'

export function OrderListTableCreateDateCell(ctx: TableTypes.TableCellContext<Order>) {
  const { row } = ctx
  const { createDate } = row

  const currentDisplayMode = useSelector((state) => state.order.displayMode, objectEqual)

  return (
    <Text color="gray500" size={currentDisplayMode === 'short' ? 'xs' : 's'}>
      {dayjs(createDate).format('DD MMMM')}
    </Text>
  )
}
