import { getLocalizedString } from 'hooks/useLocalizedString'

import actionNotify from './actionNotify'

export function printElement(id?: string) {
  if (!id) {
    window.print()
    return
  }
  const element = document.getElementById(id)
  if (!element) {
    return actionNotify({
      title: '',
      message: getLocalizedString().text.elementNotFound,
      type: 'error',
    })
  }

  const printWindow = window.open('', 'PRINT', 'height=500,width=800')
  if (!printWindow) return false

  printWindow.document.write(`<html><head><title>${document.title}</title>`)

  const styles = document.head.querySelectorAll('style')

  styles.forEach((st) => {
    const node = st.cloneNode(true)
    printWindow.document.head.append(node)
  })

  printWindow.document.write('</head><body>')
  printWindow.document.write('<div id="print">')
  printWindow.document.write(element.innerHTML)
  printWindow.document.write('</div>')
  printWindow.document.write('</body></html>')

  printWindow.document.close() // necessary for IE >= 10
  printWindow.focus() // necessary for IE >= 10*/

  printWindow.print()
  printWindow.close()

  return true
}
