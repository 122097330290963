import React from 'react'

import { Flexbox, Grid, useTheme } from '@stage-ui/core'
import MenuNavigation from 'components/MenuNavigation'

import { Client } from '@foods-n-goods/server/generated/schema'

import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { ClientActions } from 'actions'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { ClientDetailsFilter } from '../../History/components/Toolbar/ClientHistoryFilter'

import { ClientControls } from './ClientPushMessage'

type ClientDetailsToolBarProps = {
  client: Client
  isModal: boolean
}

export function ClientDetailsToolBar(props: ClientDetailsToolBarProps) {
  const { client, isModal } = props
  const theme = useTheme()
  const filter = useSelector((state) => state.client.filter, objectEqual)
  const ls = useLocalizedString()
  const menuItems = [
    { id: '', title: ls.text.clientMainInfo },
    { id: 'history', title: ls.text.clientOrdersHistory },
  ]

  return (
    <Flexbox
      justifyContent="space-between"
      mb={0}
      pb="xl"
      style={{
        position: 'sticky',
        top: isModal ? 0 : '6rem',
        zIndex: 7,
        backgroundColor: theme.color.gray[50].hex(),
      }}
    >
      <MenuNavigation
        items={menuItems}
        currentSection={filter.detailsSection}
        onChange={(detailsSection) => ClientActions.setFilter({ detailsSection })}
      />
      <Grid
        flexShrink={0}
        autoFlow="column"
        templateColumns="minmax(auto, min-content)"
        gap="0 0.5rem"
      >
        {filter.detailsSection === 'history' && <ClientDetailsFilter />}
        <ClientControls client={client} />
      </Grid>
    </Flexbox>
  )
}
