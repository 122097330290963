import React from 'react'

import { Order } from '@foods-n-goods/server/generated/schema'
import { Flexbox, Text } from '@stage-ui/core'
import { ColorText } from 'components/ColorText'
import { useMouseDrop } from 'components/MouseDrop'
import { OrderListTableSynchronizationCell } from 'pages/orders/OrderList/components/Table/components/OrderSynchronizationCell'
import { useLocalizedString } from 'hooks/useLocalizedString'
import dayjs from 'dayjs'
import { useCurrency } from 'hooks/useCurrency'

type OrderCardDetailsProps = {
  order: Order
}

export function OrderCardDetails(props: OrderCardDetailsProps) {
  const { order } = props
  const ls = useLocalizedString()
  const hintAmount = useMouseDrop(ls.text.orderTotal)
  const hintAmount2 = useMouseDrop(
    order.amountAfter ? ls.text.total1 : ls.text.orderTotal,
  )
  const { valueFormatted: amount } = useCurrency(order.amount)
  const { valueFormatted: amountAfter } = useCurrency(order.amountAfter || order.amount)

  return (
    <Flexbox w="24rem" ml="1.625rem" column>
      <Flexbox
        p="1.25rem"
        backgroundColor="gray700"
        style={{
          borderTopRightRadius: '0.25rem',
          borderTopLeftRadius: '0.25rem',
        }}
      >
        <ColorText
          h="1.5rem"
          w="1.5rem"
          backgroundColor={(c) => c.yellow.A400}
          color="gray900"
          size="xs"
          mr="m"
          weight={600}
          style={{
            cursor: 'default',
          }}
        >
          {order && <OrderListTableSynchronizationCell order={order} disaplayAlways />}
        </ColorText>
        <ColorText
          size="s"
          mr="s"
          p="0.125rem s"
          weight="600"
          color={(c) => c.yellow.A400}
          backgroundColor="transparent"
          borderRadius="s"
          borderColor={(c) => c.yellow.A400}
        >
          {order.cash ? ls.text.cash : ls.text.accounting}
        </ColorText>
        <Flexbox flex={1} justifyContent="flex-end" alignItems="center">
          {!!order.amountAfter && (
            <Text
              size="s"
              color="gray200"
              weight={400}
              mr={order.amount > 99999 ? 's' : 'm'}
              {...hintAmount}
            >
              {amount}
            </Text>
          )}
          <Text size="l" color="surface" weight={500} {...hintAmount2}>
            {amountAfter}
          </Text>
        </Flexbox>
      </Flexbox>
      <Flexbox
        p="1.25rem"
        column
        backgroundColor="gray100"
        borderWidth="0.0625rem"
        borderStyle="solid"
        borderColor="gray200"
        style={{
          borderTop: 0,
          borderBottomRightRadius: '0.25rem',
          borderBottomLeftRadius: '0.25rem',
        }}
      >
        <Flexbox justifyContent="space-between" mb="m">
          <Text size="s" color="gray500" mr="s">
            {ls.text.createDate}
          </Text>
          <Text size="s" color="gray900" align="right">
            {dayjs(order.createDate).format('DD.MM.YYYY (HH:mm)')}
          </Text>
        </Flexbox>
        <Flexbox justifyContent="space-between" mb="m">
          <Text size="s" color="gray500" mr="s">
            {ls.text.delivery}
          </Text>
          <Text size="s" color="gray900" align="right">
            {order.preferredDeliveryDate && (
              <>
                {dayjs(order.preferredDeliveryDate).format('DD.MM.YYYY')}
                {order.preferDeliveryInterval && ` (${order.preferDeliveryInterval})`}
              </>
            )}
          </Text>
        </Flexbox>
        <Flexbox justifyContent="space-between" mb="m">
          <Text size="s" color="gray500" mr="s">
            {ls.text.order1}
          </Text>
          <Text size="s" color="gray900" align="right">
            <Text size="s" color="gray500">
              {order.id}
            </Text>
            {order.exId && (
              <>
                {' - '}
                <Text size="s" color="gray900">
                  {order.exId}
                </Text>
              </>
            )}
          </Text>
        </Flexbox>
        <Flexbox justifyContent="space-between" mb="m">
          <Text size="s" color="gray500" mr="s">
            {ls.text.implementation}
          </Text>
          <Text size="s" color="gray900" align="right">
            {order.exInvoiceId ?? '-'}
          </Text>
        </Flexbox>
        <Flexbox justifyContent="space-between">
          <Text size="s" color="gray500" mr="s">
            {ls.text.operator}
          </Text>
          <Text size="s" color="gray900" align="right">
            {order.managerName ?? '-'}
          </Text>
        </Flexbox>
      </Flexbox>
    </Flexbox>
  )
}
