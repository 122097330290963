import React, { useCallback } from 'react'

import { AsYouType, CountryCode } from 'libphonenumber-js'
import formatFixedPointNumber from 'utils/formatFixedPointNumber'

import { useLocalizedString } from 'hooks/useLocalizedString'

import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'

import { getCurrencySymbol } from 'hooks/useCurrency'

import RifmField, { RifmFieldProps } from './RifmField'

type FieldType = 'rubles' | 'digits' | 'phone' | 'mail' | 'integer' | 'formatInteger'

interface ViewInputFieldProps extends Omit<RifmFieldProps, 'type'> {
  type?: FieldType
  digits?: number
}

export const parseDigits = (s: string) => (s.match(/[\d+]+/g) || []).join('')

const integerAccept = /\d+/g
const parseInteger = (s: string) => (s.match(integerAccept) || []).join('')

const formatInteger = (s: string) => {
  const parsed = parseInteger(s)
  const number = Number.parseInt(parsed, 10)
  if (Number.isNaN(number)) {
    return ''
  }
  return number.toLocaleString('ru')
}

function ViewInputField(props: ViewInputFieldProps) {
  const { type, digits, ...rest } = props
  const ls = useLocalizedString()
  const { currencyCode, country } = useSelector(
    (state) => ({
      currencyCode: state.app.marketConfig?.currency,
      country: state.app.marketConfig?.country,
    }),
    objectEqual,
  )
  const formatPhone = useCallback((s: string) => {
    const digits = parseDigits(s)
    return new AsYouType((country as CountryCode) || undefined).input(digits)
  }, [])
  const currency = getCurrencySymbol(currencyCode, ls)
  /* Default options */
  rest.options = {
    accept: /./g,
    format: (v) => v,
  }

  if (type === 'rubles') {
    rest.options = {
      accept: /[\d.,]+/g,
      format: (v) => formatFixedPointNumber(v, 2),
      replace: (v) => (v.match(/\d+[.,]?\d*/) || []).join().replace(',', '.'),
    }
  }

  if (digits && type === 'digits') {
    rest.options = {
      accept: /[\d.,]+/g,
      format: (v) => formatFixedPointNumber(v, digits),
      replace: (v) => (v.match(/\d+[.,]?\d*/) || []).join().replace(',', '.'),
    }
  }

  if (type === 'integer' || type === 'formatInteger') {
    rest.onChange = (value) => props.onChange(parseInteger(value))
    rest.options = {
      ...rest.options,
      accept: /\d/g,
    }
    if (type === 'formatInteger') {
      rest.options = {
        ...rest.options,
        format: formatInteger,
      }
    }
  }

  if (type === 'rubles') {
    rest.leftChild = currency
  }

  if (type === 'phone') {
    rest.mask =
      rest.value.length < 6 && /[^\d]+/.test(rest.value[3])
        ? undefined
        : rest.value.length >= 14

    rest.options = {
      accept: /[\d+]/g,
      format: formatPhone,
    }
  }

  return <RifmField labelType="outside" {...rest} />
}

export default ViewInputField
