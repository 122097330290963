import React, { useMemo } from 'react'

import { Table, Text } from '@stage-ui/core'
import { TableLoadingStab } from 'components/TableLoadingStab'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { getColumns } from './columns'

function ScaleDevices() {
  const ls = useLocalizedString()
  const { loading, data } = useSelector(
    ({ devices }) => ({
      loading: devices.loading,
      data: devices.data.filter((device) => device.type === 'SCALE'),
    }),
    objectEqual,
  )
  const columns = useMemo(getColumns, [ls.id])
  return (
    TableLoadingStab(loading) || (
      <Table
        columns={columns}
        data={data}
        footer={
          data.length === 0 && (
            <Text size="s" color="gray500">
              {ls.text.noData}
            </Text>
          )
        }
      />
    )
  )
}

export default ScaleDevices
