import React, { useEffect, useMemo, useState } from 'react'

import { Order } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox, modal, ScrollView, Text } from '@stage-ui/core'
import { OrderActions } from 'actions'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import FilterCommon from 'components/common/FilterCommon'

import { OrderStatusCode } from '@foods-n-goods/client/system/types'

import { getLocalizedString, useLocalizedString } from 'hooks/useLocalizedString'

import { OrderCourierChangeModalRow } from './components/CourierRow'

type OrderCourierChangeModalProps = {
  close: () => void
  orders: Array<Order>
}

export function OrderCourierChangeModal(props: OrderCourierChangeModalProps) {
  const { close, orders } = props
  const ls = useLocalizedString()
  const [staffId, setStaffId] = useState<string>('')
  const [search, setSearch] = useState<string>('')

  useEffect(() => {
    if (orders.length === 1 && orders[0].courier) {
      setStaffId(orders[0].courier.id)
    }
  }, [])

  const couriers = useSelector(
    ({ staff }) =>
      staff.data
        .filter((s) => {
          if (!search) return s.role.code === 'COURIER'
          return (
            s.role.code === 'COURIER' &&
            s.name?.toLocaleLowerCase().match(search.trim().toLocaleLowerCase())
          )
        })
        .sort((a, b) => ((a.name || '') > (b.name || '') ? 1 : -1)),
    objectEqual,
  )

  const ordersWithCouriers = useMemo(
    () => orders.some((order) => !!order.courier),
    [orders, couriers],
  )

  const changeAvailableStatuses = [
    OrderStatusCode.NEW,
    OrderStatusCode.PAYED,
    OrderStatusCode.SORTING,
    OrderStatusCode.AWAIT_COURIER,
    OrderStatusCode.OUT_OF_STOCK,
    OrderStatusCode.PURCHASE_PENDING,
    OrderStatusCode.PURCHASE_CONFIRMED,
    OrderStatusCode.PURCHASE_FAILED,
  ]

  const handleUnset = async () => {
    const ordersToUnset = orders
      .filter(
        (order) =>
          !!order.courier && changeAvailableStatuses.includes(order.status.value),
      )
      .map((order) => order.id)

    await OrderActions.setCourier({ ids: ordersToUnset })
    close()
  }

  const handleSet = async () => {
    if (!staffId) {
      return handleUnset()
    }

    const ordersToSet = orders
      .filter((order) => changeAvailableStatuses.includes(order.status.value))
      .map((order) => order.id)

    await OrderActions.setCourier({ ids: ordersToSet, staffId })
    close()
  }

  const handleSearch = (st: string) => {
    setSearch(st)
  }

  return (
    <Flexbox column>
      <Flexbox flex={1} justifyContent="space-between" alignItems="center" mb="l">
        <FilterCommon
          onSearch={handleSearch}
          overrides={{ width: '15rem', fontSize: '14px' }}
        />
        <Text size="s" ml="s" color="gray500">
          {ls.text.ordersSelected.replace('[1]', String(orders.length))}
        </Text>
      </Flexbox>
      <Flexbox
        column
        h="40vh"
        alignItems="flex-start"
        backgroundColor="white"
        borderRadius="m"
        borderColor="gray200"
        borderWidth="0.0625rem"
        borderStyle="solid"
        style={{ overflow: 'hidden' }}
      >
        {couriers.length === 0 && (
          <Flexbox
            column
            centered
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <Text color="gray400" size="s" weight={500}>
              {search ? ls.text.notFound : ls.text.outOfCouriers}
            </Text>
          </Flexbox>
        )}
        <ScrollView mode="scroll" xBarPosition="none" w="100%" barOffset={4}>
          <OrderCourierChangeModalRow
            courier={null}
            currentCourier={staffId}
            orders={orders}
            onChange={(id: string) => setStaffId(id)}
          />
          {couriers.map((courier) => (
            <OrderCourierChangeModalRow
              key={courier.id}
              courier={courier}
              currentCourier={staffId}
              orders={orders}
              onChange={(id: string) => setStaffId(id)}
            />
          ))}
        </ScrollView>
      </Flexbox>

      <Flexbox justifyContent="space-between" mt="l">
        <Flexbox flex={1} justifyContent="flex-end">
          <Button
            mr="m"
            label={ls.text.cancel}
            color="gray500"
            textColor="gray500"
            decoration="text"
            onClick={close}
            pr="m"
          />
          <Button w="9rem" label={ls.text.confirm} onClick={handleSet} />
        </Flexbox>
      </Flexbox>
    </Flexbox>
  )
}

export const openOrderCourierChangeModal = (
  orders: Order[],
  e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  e?.stopPropagation()
  e?.preventDefault()

  modal({
    title: getLocalizedString().text.courierAssign,
    w: '30rem',
    overlayClose: false,
    didClose: () => OrderActions.clearSelected(),
    render: (close) => <OrderCourierChangeModal close={close} orders={orders} />,
  })
}
