import React from 'react'

import { Flexbox, Text, Header, useTheme, Divider, Block } from '@stage-ui/core'
import { At, Phone } from '@stage-ui/icons'
import { useMouseDrop } from 'components/MouseDrop'
import { Staff } from '@foods-n-goods/server/generated/schema'
import { openStaffUpdateModal } from 'components/Modals/StaffUpdate'

import Avatar from 'components/Avatar'
import { StaffActions } from 'actions'
import { useLocalizedString } from 'hooks/useLocalizedString'

type StaffDetailsHeaderProps = {
  staff: Staff
  additionalAvailable: boolean
  modal: boolean
}

export function StaffDetailsHeader(props: StaffDetailsHeaderProps) {
  const { staff, additionalAvailable, modal } = props
  const { color } = useTheme()
  const ls = useLocalizedString()
  const phoneHint = useMouseDrop(ls.text.phoneEdit, {
    drop: {
      align: 'bottom',
    },
    popover: {
      align: 'top',
    },
  })
  const emailHint = useMouseDrop(ls.text.emailEdit, {
    drop: {
      align: 'bottom',
    },
    popover: {
      align: 'top',
    },
  })

  const onAvatarUpload = (id: string, payload: string): Promise<void> => {
    return StaffActions.photoUpdate(id, payload)
  }

  return (
    <Flexbox
      flex={1}
      justifyContent="space-between"
      backgroundColor="background"
      pb="xl"
      pr={additionalAvailable ? 0 : '25rem'}
      style={{
        position: modal ? 'relative' : 'sticky',
        top: modal ? 'unset' : '6rem',
        zIndex: 2,
      }}
    >
      <Avatar
        size="8rem"
        onUpload={onAvatarUpload}
        user={{
          id: staff.id,
          name: staff.name || staff.login || ls.string[staff.role.code],
          color: staff.color?.code || '#333',
          online: false,
          photo: staff.photo,
          updateDate: staff.updateDate,
        }}
      />
      <Flexbox flex={1} ml="2.5rem" column justifyContent="space-between">
        <Flexbox column>
          <Flexbox alignItems="center">
            <Block
              w="0.75rem"
              h="0.75rem"
              borderRadius="50%"
              backgroundColor={staff.online ? 'green500' : 'red500'}
            />
            <Text ml="s" size="s" color={staff.online ? 'green500' : 'red500'}>
              {staff.online ? ls.text.online1 : ls.text.offline}
            </Text>
          </Flexbox>
          <Flexbox mt="s">
            <Header size="xs" flexShrink={0} ellipsis style={{ maxWidth: '25rem' }}>
              {staff.name}
            </Header>
            <Divider mx="s" vertical h="100%" w="0.0625rem" color="gray900" />
            <Header size="xs" weight="400 !important">
              {ls.string[staff.role.code]}
            </Header>
          </Flexbox>
        </Flexbox>

        <Flexbox>
          <Flexbox alignItems="center">
            <Flexbox
              {...phoneHint}
              h="2.5rem"
              w="2.5rem"
              borderWidth="1px"
              borderStyle="solid"
              borderRadius="50%"
              borderColor="gray200"
              centered
              onClick={() => openStaffUpdateModal(staff, 'contacts', 'phone')}
              style={{
                ':hover': {
                  backgroundColor: color.white.hex(),
                },
              }}
            >
              <Phone size="l" color="gray500" />
            </Flexbox>
            <Text ml="s" color="gray500" size="s">
              {staff.phone ? `+7 ${staff.phone}` : ls.text.noPhone}
            </Text>
          </Flexbox>

          <Flexbox alignItems="center" ml="4.5rem">
            <Flexbox
              {...emailHint}
              h="2.5rem"
              w="2.5rem"
              borderWidth="1px"
              borderStyle="solid"
              borderRadius="50%"
              borderColor="gray200"
              centered
              onClick={() => openStaffUpdateModal(staff, 'contacts', 'email')}
              style={{
                ':hover': {
                  backgroundColor: color.white.hex(),
                },
              }}
            >
              <At size="l" color="gray500" />
            </Flexbox>
            <Text ml="s" color="gray500" size="s">
              {staff.email || ls.text.noEmail}
            </Text>
          </Flexbox>
        </Flexbox>
      </Flexbox>
    </Flexbox>
  )
}
