import React, { useState } from 'react'

import { Button, Flexbox, Text, TextField } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { Edit, Save, Close } from '@stage-ui/icons'
import { VariablesActions } from 'actions'
import { Variable } from '@foods-n-goods/server/generated/schema'
import { getLocalizedString, useLocalizedString } from 'hooks/useLocalizedString'

function EditValue(props: { ctx: TableTypes.TableCellContext }) {
  const { ctx } = props
  const { name, value: _value } = ctx.row as Variable
  const close = () => ctx.setModify(false)
  const ls = useLocalizedString()
  const [value, setValue] = useState(_value)

  const save = () => VariablesActions.update({ name, value }, close)
  // https://www.platigorod.ru/oferta.pdf
  return (
    <Flexbox alignItems="center" justifyContent="space-between">
      <TextField
        value={value}
        placeholder={ls.text.paramValue}
        onChange={(e) => setValue(e.target.value)}
      />
      <Button
        ml="2rem"
        flexShrink={0}
        decoration="text"
        color="warning"
        children={<Save color="warning" size="l" p={0} />}
        onClick={save}
      />
      <Button
        ml="0.25rem"
        flexShrink={0}
        decoration="text"
        color="light"
        children={<Close color="light" size="l" p={0} />}
        onClick={close}
      />
    </Flexbox>
  )
}

function DisplayValue(props: { ctx: TableTypes.TableCellContext }) {
  const {
    ctx: { row, value, setModify },
  } = props
  return (
    <Flexbox alignItems="center" justifyContent="space-between">
      <Text children={value} css={{ wordBreak: 'break-word' }} />
      <Button
        ml="2rem"
        flexShrink={0}
        decoration="text"
        children={<Edit size="l" p={0} />}
        onClick={() => setModify(true)}
      />
    </Flexbox>
  )
}

export const getColumns = (): TableTypes.TableColumn[] => {
  const ls = getLocalizedString()
  return [
    { key: 'name', title: ls.text.param, width: '12rem' },
    {
      key: 'value',
      title: ls.text.value,
      render: (ctx) =>
        ctx.isModify ? <EditValue ctx={ctx} /> : <DisplayValue ctx={ctx} />,
    },
  ]
}
