import React from 'react'

import { Staff, StaffInput } from '@foods-n-goods/server/src/shared/staff'
import { Block, Divider, Flexbox, ScrollView, Text } from '@stage-ui/core'
import { Done } from '@stage-ui/icons'
import { useLocalizedString } from 'hooks/useLocalizedString'
import { supportedLocales } from '@foods-n-goods/client/localization'

type StaffLocalizationUpdateProps = {
  value?: Staff['localization'] | null
  onChange: KeyValueChangeFunction<StaffInput>
}

export const StaffLocalizationUpdate: React.FC<StaffLocalizationUpdateProps> = (
  props,
) => {
  const { value, onChange } = props
  const ls = useLocalizedString()
  const defaultLanguage = { id: -1, label: ls.text.notSelected, value: null }
  const langList = [
    defaultLanguage,
    ...supportedLocales.map((locale, index) => ({
      id: index,
      label: locale.text,
      value: locale.value,
    })),
  ]

  return (
    <Block
      decoration="surface"
      borderRadius="m"
      borderColor="gray200"
      h="30vh"
      style={{
        overflow: 'hidden',
      }}
    >
      <ScrollView xBarPosition="none" size="xs" barOffset={4}>
        {langList.map((locale, index) => (
          <React.Fragment key={locale.value}>
            <Flexbox
              p="s m"
              backgroundColor={locale.value === value ? 'green50' : 'transparent'}
              onClick={() => {
                onChange('localization', locale.value)
              }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Flexbox alignItems="center" flex={1}>
                <Text color="gray900">{locale.label}</Text>
              </Flexbox>
              {locale.value === value && <Done color="primary" size="1.25rem" />}
            </Flexbox>
            {langList.length !== index + 1 && <Divider h="0.0625rem" color="gray200" />}
          </React.Fragment>
        ))}
      </ScrollView>
    </Block>
  )
}
