import {
  Order,
  OrderedProduct,
  OrderQualityItem,
} from '@foods-n-goods/server/generated/schema'
import { getLocalizedString } from 'hooks/useLocalizedString'

export function makeOrderQualityItem(
  order: Order,
  position: OrderedProduct,
): OrderQualityItem {
  const clientName =
    order.client.markets[0]?.alias || order.client.name || order.client.identifier

  const packParams = order.packParams || {}
  const ls = getLocalizedString()
  return {
    id: position.id.toString(),
    exInvoiceId: position.exInvoiceId,
    lineDate: position.lineDate,
    packDate: position.packDate,
    tareWeight: position.tareWeight || 0,
    clientName,
    productName: position.name || '',
    orderId: order.id,
    warehouseWorkerName: position.warehouseWorker?.name || '',
    quantitySupposed: position.wishfulUnitQuantity
      ? `${position.quantity} ${ls.string[position.unit.code]}`
      : '',
    quantityBefore: `${position.wishfulUnitQuantity || position.quantity} ${
      position.wishfulUnitName || ls.string[position.unit.code]
    }`,
    quantityAfter: `${String(position.quantityAfter)} ${ls.string[position.unit.code]}`,
    containers: order.containers.map((c) => c.name).join(', ') || '',
    photo: position.photo ? position.photo : '',
    packParams,
  }
}
