import React from 'react'

import { Flexbox, Text } from '@stage-ui/core'
import dayjs from '@foods-n-goods/client/utils/dayjs'

type DateTimeProps = {
  date?: Date | null
  short?: boolean
}

export function DateTime(props: DateTimeProps) {
  const { date, short = false } = props

  if (!date) return null

  const topFormat = short ? 'HH:mm' : 'HH:mm:ss'
  const bottomFormat = short ? 'DD MMMM' : 'DD MMMM, YYYY'

  return (
    <Flexbox column>
      <Text color="gray900">{dayjs(date).format(topFormat)}</Text>
      <Text size="xs" color="gray500">
        {dayjs(date).format(bottomFormat)}
      </Text>
    </Flexbox>
  )
}
