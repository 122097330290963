import React from 'react'

import { Block, Flexbox, Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { OrderDeliveryCoordinator } from 'store/delivery'
// import { useMouseDrop } from 'components/MouseDrop'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { useMouseDrop } from 'components/MouseDrop'
import { openOrderCourierChangeModal } from 'components/Modals/OrderCourierChange'
import { useStaff } from 'hooks/useStaff'
import { useLocalizedString } from 'hooks/useLocalizedString'

export function LogisticsCoordinatorCourierCell(
  ctx: TableTypes.TableCellContext<OrderDeliveryCoordinator>,
) {
  const { row } = ctx

  const order = row.orders[0]
  const courier = useStaff(order?.courier?.id)

  const coordinatorData = useSelector(
    (state) => state.delivery.coordinatorData,
    objectEqual,
  )

  const assignedClients = coordinatorData.filter((data) =>
    data.orders.some((order) => order.courier && order.courier?.id === courier?.id),
  ).length

  const deliveryAddress = row.orders[0]?.deliveryAddress
  const ls = useLocalizedString()
  const courierHint = useMouseDrop(
    !!assignedClients &&
      ls.text.assignedClientPoints.replace('[1]', String(assignedClients)),
    {
      drop: {
        align: 'top',
        justify: 'center',
      },
      popover: {
        align: 'bottom',
        arrowOffset: '-2.5rem',
        style: {
          maxWidth: '20rem',
        },
      },
    },
  )

  return (
    <Flexbox
      w="inherit"
      alignItems="center"
      justifyContent="space-between"
      attributes={{
        'data-id': row.orders[0].id,
        'data-hover': 'false',
      }}
      style={(t) => [
        {
          '&[data-hover="true"]': {
            background: t.color.gray[100].hex(),
          },
          ...(deliveryAddress.address
            ? {}
            : {
                background: `${t.color.red[100].hex()} !important`,
              }),
          '@media print': {
            // @ts-ignore
            backgroundColor: row.filled
              ? 'rgb(190,190,190) !important'
              : '#fff !important',
            fontWeight: '800',
          },
        },
      ]}
    >
      <Flexbox
        w="8rem"
        style={{
          '@media print': {
            width: 'inherit',
          },
        }}
      >
        <Text
          ellipsis
          size="xs"
          color={courier?.color?.code || 'gray300'}
          style={{
            '@media print': {
              whiteSpace: 'normal',
            },
          }}
        >
          {courier ? courier?.name || courier?.login : ls.text.courierAssign}
        </Text>
      </Flexbox>
      <Block
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
        {...courierHint}
        onClick={(e) => {
          if (row.orders[0].status.value <= 4) {
            e.stopPropagation()
            openOrderCourierChangeModal([order])
          }
        }}
      />
    </Flexbox>
  )
}
