import React from 'react'

import { Button } from '@stage-ui/core'
import { ListIn, ListOut } from '@stage-ui/icons'
import { OrderActions } from 'actions'
import { useMouseDrop } from 'components/MouseDrop'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { useLocalizedString } from 'hooks/useLocalizedString'

export function OrderListToolbarRowModeSwitch() {
  const currentDisplayMode = useSelector((state) => state.order.displayMode, objectEqual)
  const ls = useLocalizedString()
  const hint = useMouseDrop(ls.text.heightOfRows)

  const toggleDisplayMode = () => {
    OrderActions.setTableDisplayMode(currentDisplayMode === 'full' ? 'short' : 'full')
  }

  const Icon = currentDisplayMode === 'full' ? ListIn : ListOut

  return (
    <Button
      {...hint}
      w="2.5rem"
      h="2.5rem"
      p={0}
      color="gray200"
      onClick={toggleDisplayMode}
    >
      <Icon size="1.25rem" color="gray500" />
    </Button>
  )
}
