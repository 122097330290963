import React, { useEffect } from 'react'

import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs'
import { Section } from 'components/Section'
import { Route, Switch, useLocation } from 'react-router-dom'
import store from 'store'

import { StaffActions } from 'actions'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { StaffList } from './List'
import { StaffDetails } from './Details'

export function Staff() {
  const location = useLocation()
  const ls = useLocalizedString()
  const staffId = location.pathname.split('/')[2]
  const sectionPath = location.pathname.split('/')[1]

  const getStaffName = (id: string) => {
    const staff = store.getState().staff.data.find((c) => c.id === id)
    return staff?.name || staff?.login || ls.text.staffId.replace('[1]', id)
  }

  useEffect(() => {
    return () => {
      StaffActions.clearFilter()
    }
  }, [])

  return (
    <Section
      titleComponent={
        <BreadCrumbs
          path={staffId}
          selectedItem={getStaffName(staffId)}
          sectionName={ls.text.staff}
          sectionPath={sectionPath}
        />
      }
    >
      <Switch location={location}>
        <Route exact path="/staff" component={StaffList} />
        <Route exact path="/staff/:id" component={StaffDetails} />
      </Switch>
    </Section>
  )
}
