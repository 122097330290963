/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { createContext, useContext, useLayoutEffect, useState } from 'react'

import { LocalizedStringID } from '@foods-n-goods/client/localization/locale'
import { supportedLocales, cache, loadLanguage } from '@foods-n-goods/client/localization'
import dayjs from '@foods-n-goods/client/utils/dayjs'
import store, { AppStore } from 'store'
import { LocalizedString } from '@foods-n-goods/client/localization/locale.json'
import { Maybe } from '@foods-n-goods/server/generated/schema'

const Context = createContext<LocalizedString | undefined>(undefined)

export const localizeMessageWithParams = (
  messageCode: string,
  // eslint-disable-next-line @typescript-eslint/default-param-last
  parameters: Maybe<string[]> | undefined,
  translations: LocalizedString['string'],
  currencySymbol: string,
): string => {
  const baseMessage = translations[messageCode]

  if (!parameters || !Array.isArray(parameters) || !parameters.length) {
    return baseMessage
  }

  return parameters.reduce((result, param, index) => {
    const replacement = param === '{{CURRENCY}}' ? currencySymbol : translations[param]

    return result.replace(`[${index + 1}]`, replacement)
  }, baseMessage)
}

const getCurrentLanguageId = () => {
  const prefId = store.getState().app.localization
  let id = prefId || 'ru-RU'
  const browserLocale = navigator.language.split('-')[0]
  if (!prefId && (!window.location.host.endsWith('ru') || browserLocale !== 'en')) {
    const locale = supportedLocales.find((x) => x.value.startsWith(browserLocale))
    id = locale?.value || 'en-EN'
  }

  return (
    supportedLocales
      .map((x) => x.value)
      .find((x) => {
        if (x === id) {
          return true
        }
        if (id.length === 2) {
          if (x.startsWith(id)) {
            return true
          }
        }
        return false
      }) || 'ru-RU'
  )
}

// eslint-disable-next-line import/no-mutable-exports
export let setLocalization: (id: LocalizedStringID) => void

const safeHandler: ProxyHandler<LocalizedString> = {
  get: (target, prop, receiver) => {
    // @ts-expect-error
    if (Reflect.has(target, prop) && typeof target[prop] !== 'object') {
      return Reflect.get(target, prop, receiver)
    }
    if (Reflect.has(target, prop)) {
      const nextTarget = Reflect.get(target, prop, receiver)
      return new Proxy(nextTarget, safeHandler)
    }
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${prop || 'unknown'}`
  },
}

/**
 * Hint: Use when there is no React context, other way
 * use hook useLocalizedString
 */
export const getLocalizedString = (): LocalizedString => {
  const currentLangId = getCurrentLanguageId()
  const cachedStrings = cache[currentLangId]
  // Add validation
  if (!cachedStrings) {
    throw new Error('localization cache error')
  }
  return new Proxy<LocalizedString>(cachedStrings, safeHandler)
}

/**
 * Hint: If there is not react context you may use
 * getLocalizedString function
 */
export const useLocalizedString = (): LocalizedString => {
  const localizedString = useContext(Context)
  if (localizedString === undefined) {
    throw new Error('useLocalizedString must be used within a LocalizedStringProvider')
  }
  return new Proxy<LocalizedString>(localizedString, safeHandler)
}

export const LocalizedStringProvider = (props: { children: React.ReactNode }) => {
  const { children } = props
  const [langID, setLangID] = useState<LocalizedStringID>(getCurrentLanguageId())
  const [localizedString, setString] = useState<LocalizedString | null>(null)

  setLocalization = (id) => {
    AppStore.localizationSet(id)
    loadLanguage(id).then(() => {
      setLangID(id)
    })
  }

  useLayoutEffect(() => {
    dayjs.locale(langID)
    loadLanguage(langID).then((lang) => {
      dayjs.locale(lang.id)
      setString(lang)
    })
  }, [langID])
  if (!localizedString) {
    return null
  }

  return <Context.Provider value={localizedString}>{children}</Context.Provider>
}
