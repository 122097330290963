import React from 'react'

import { ProductReview } from '@foods-n-goods/server/generated/schema'
import Content from 'components/Content'
import ViewField from 'components/Fields/ViewField'
import ViewGridBlock from 'components/ViewGridBlock'
import { useLocalizedString } from 'hooks/useLocalizedString'

interface ReviewViewAnswersProps {
  review: ProductReview
}

function ReviewViewAnswers(props: ReviewViewAnswersProps) {
  const {
    review: { staff, answer },
  } = props
  const ls = useLocalizedString()
  if (!staff) return null

  return (
    <Content>
      <ViewGridBlock templateColumns="repeat(4, 1fr)" title={ls.text.responses}>
        <ViewField text={ls.text.author} value={staff.name || staff.login} />
        <ViewField text={ls.text.response} value={answer} gridColumnEnd="span 3" />
      </ViewGridBlock>
    </Content>
  )
}

export default ReviewViewAnswers
