import React from 'react'

import { Grid, TextField } from '@stage-ui/core'
import ViewInputField from 'components/Fields/ViewInputField'
import { Staff, StaffInput } from '@foods-n-goods/server/generated/schema'
import { useLocalizedString } from 'hooks/useLocalizedString'

type StaffVehicleUpdateProps = {
  autoFocusField?: keyof StaffInput
  vehicleModel?: Staff['vehicleModel']
  vehicleRegPlate?: Staff['vehicleRegPlate']
  vehicleLoadCapacity?: Staff['vehicleLoadCapacity']
  vehicleVolumeCapacity?: Staff['vehicleVolumeCapacity']
  onChange: KeyValueChangeFunction<StaffInput>
}

export function StaffVehicleUpdate(props: StaffVehicleUpdateProps) {
  const {
    autoFocusField,
    vehicleModel,
    vehicleRegPlate,
    vehicleLoadCapacity,
    vehicleVolumeCapacity,
    onChange,
  } = props
  const ls = useLocalizedString()
  return (
    <Grid templateColumns="repeat(6, 1fr)" gap="1rem">
      <TextField
        autoFocus={!autoFocusField || autoFocusField === 'vehicleModel'}
        defaultValue={vehicleModel || ''}
        label={ls.text.carModel}
        placeholder={ls.text.carModelPlaceholder}
        gridColumn="span 4"
        onChange={(e) => onChange('vehicleModel', e.target.value)}
      />
      <ViewInputField
        autoFocus={autoFocusField === 'vehicleRegPlate'}
        label={ls.text.carRegPlate}
        placeholder={ls.text.carRegPlatePlaceholder}
        value={vehicleRegPlate || ''}
        maxLength={9}
        gridColumn="span 2"
        onChange={(value) => onChange('vehicleRegPlate', value)}
      />
      <ViewInputField
        autoFocus={autoFocusField === 'vehicleLoadCapacity'}
        rightChild={ls.text.kg}
        type="integer"
        maxLength={5}
        label={ls.text.cargoCapacity}
        placeholder="1000"
        value={vehicleLoadCapacity || ''}
        gridColumn="span 3"
        onChange={(value) => onChange('vehicleLoadCapacity', parseFloat(value))}
      />
      <ViewInputField
        autoFocus={autoFocusField === 'vehicleVolumeCapacity'}
        rightChild={ls.text.squareMeters}
        maxLength={2}
        label={ls.text.cargoVolume}
        placeholder="5"
        value={vehicleVolumeCapacity || ''}
        gridColumn="span 3"
        onChange={(value) => onChange('vehicleVolumeCapacity', parseFloat(value))}
      />
    </Grid>
  )
}
