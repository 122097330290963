import React, { useState } from 'react'

import { Order } from '@foods-n-goods/server/generated/schema'
import { Button, DatePicker, Flexbox, Select } from '@stage-ui/core'
import { Clock } from '@stage-ui/icons'
import { OrderActions } from 'actions'
import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

import { useLocalizedString } from 'hooks/useLocalizedString'

import dayjs from 'dayjs'

import DatePickerTypes from '@stage-ui/core/control/DatePicker/types'

import { DropSelect } from './DropSelect'

type OrderDeliveryDateChangeModalProps = {
  onClose: () => void
  order: Order
}

export function OrderDeliveryDateChangeModal(props: OrderDeliveryDateChangeModalProps) {
  const { onClose, order } = props
  const ls = useLocalizedString()
  const intervals = useSelector(
    (state) => state.app.meta?.deliveryIntervals || [],
    objectEqual,
  )

  const initialIntervalValue =
    intervals.find((i) => i.text === order.preferDeliveryInterval)?.value || null

  const [preferredDeliveryDate, setPreferredDeliveryDate] = useState<Date>(
    order.preferredDeliveryDate || dayjs().toDate(),
  )
  const [preferDeliveryInterval, setPreferDeliveryInterval] = useState<number | null>(
    initialIntervalValue,
  )

  const handleConfirm = async () => {
    if (!preferredDeliveryDate || !preferDeliveryInterval) return

    const intervalText = intervals.find((i) => i.value === preferDeliveryInterval)?.text

    if (!intervalText) return

    const success = await OrderActions.orderUpdateDeliveryData({
      id: order.id,
      preferredDeliveryDate: preferredDeliveryDate.toString(),
      preferDeliveryInterval: intervalText,
    })
    if (success) {
      onClose()
    }
  }

  return (
    <Flexbox column>
      <DatePicker
        locale={ls.id?.split('-')[0] as DatePickerTypes.Props['locale']}
        size="s"
        borderColor="gray200"
        clearable
        type="day"
        label={ls.text.deliveryDate}
        value={dayjs(preferredDeliveryDate).toDate()}
        format="DD MMM"
        placeholder={ls.text.noDate}
        minValue={dayjs().toDate()}
        maxValue={dayjs().add(1, 'month').toDate()}
        mb="m"
        onChange={(dt) => {
          if (dt) {
            setPreferredDeliveryDate(dt)
          }
        }}
      />

      <Select
        size="s"
        label={ls.text.interval}
        placeholder={ls.text.interval}
        options={intervals}
        borderColor="gray200"
        leftChild={<Clock size="1.25rem" color="gray400" />}
        values={intervals.filter((i) => i.value === preferDeliveryInterval)}
        onChange={(o) => setPreferDeliveryInterval(o[0]?.value as number)}
        renderOption={({ text, value }) => (
          <DropSelect
            text={text}
            value={value as number}
            selectedInterval={preferDeliveryInterval}
          />
        )}
      />
      <Flexbox justifyContent="flex-end" mt="xl">
        <Button
          color="gray500"
          textColor="gray500"
          decoration="text"
          label={ls.text.cancel}
          mr="m"
          onClick={close}
        />
        <Button label={ls.text.confirm} onClick={handleConfirm} />
      </Flexbox>
    </Flexbox>
  )
}
