import React, { useRef, useState } from 'react'

import { Block, Button, Flexbox, Modal } from '@stage-ui/core'
import ButtonTypes from '@stage-ui/core/control/Button/types'
import ModalTypes from '@stage-ui/core/layout/Modal/types'
import ViewInputField from 'components/Fields/ViewInputField'
import { useLocalizedString } from 'hooks/useLocalizedString'

interface ReviewAnswerProps {
  close?: () => void
  action: (answer: string) => void
}

function ReviewAnswer(props: ButtonTypes.Props & ReviewAnswerProps) {
  const { id, close, action, ...buttonProps } = props
  const ls = useLocalizedString()
  const [answer, setAnswer] = useState<string>('')
  const modalRef = useRef<ModalTypes.Ref>(null)

  const openModal = () => {
    close?.()
    modalRef.current?.open()
  }
  const closeModal = () => modalRef.current?.close()

  const performAnswer = () => action(answer)

  return (
    <>
      <Button
        color="primary"
        children={ls.text.replyToReview}
        onClick={openModal}
        {...buttonProps}
      />
      <Modal
        ref={modalRef}
        title={ls.text.response}
        subtitle={ls.text.toReview}
        w="30rem"
      >
        <Block>
          <ViewInputField
            mt="1rem"
            label={ls.text.responseText}
            labelType="outside"
            value={answer}
            onChange={(value) => setAnswer(value)}
          />
          <Flexbox justifyContent="flex-end" mt="3rem">
            <Button children={ls.text.cancel} decoration="text" onClick={closeModal} />
            <Button
              ml="0.5rem"
              onClick={performAnswer}
              color={props.color}
              children={ls.text.reply}
            />
          </Flexbox>
        </Block>
      </Modal>
    </>
  )
}

export default ReviewAnswer
