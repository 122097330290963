import React from 'react'

import { OrderQualityItem } from '@foods-n-goods/server/generated/schema'
import { Flexbox, Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'

import { Timer } from '@stage-ui/icons'

import { getLocalizedString } from 'hooks/useLocalizedString'

import dayjs from 'dayjs'

import QualityPalleteCell from './QualityPalleteCell'
import QualityQuantityCell from './QualityQuantityCell'

export const getQualityOrderPositions =
  (): TableTypes.TableColumn<OrderQualityItem>[] => {
    const ls = getLocalizedString()
    return [
      {
        key: 'orderId',
        title: ls.text.number,
        width: 80,
        render: (ctx) => (
          <Text size="s" color="gray500">
            #{ctx.row.orderId}
          </Text>
        ),
      },
      {
        key: 'product',
        title: ls.text.product1,
        width: 220,
        render: (ctx) => <Text size="s">{ctx.row.productName}</Text>,
      },
      {
        key: 'client',
        title: ls.text.client1,
        render: (ctx) => (
          <Text size="s" color="gray600">
            {ctx.row.clientName}
          </Text>
        ),
      },
      {
        key: 'packDate',
        title: ls.text.picking1,
        width: 100,
        render: (ctx) => (
          <Flexbox alignItems="center">
            <Timer color="gray500" mr="s" />
            <Text size="s" color="gray500">
              {dayjs(ctx.row.packDate).locale('ru').format('DD.MM.YY')}
            </Text>
          </Flexbox>
        ),
      },
      {
        key: 'containers',
        title: ls.text.pallet,
        width: 80,
        render: QualityPalleteCell,
      },
      {
        key: 'quantityBefore',
        title: ls.text.quantityInOrder,
        width: 80,
        render: (ctx) => <QualityQuantityCell quantity={ctx.row.quantityBefore} />,
      },
      {
        key: 'quantityAfter',
        title: ls.text.quantityPicked,
        width: 80,
        render: (ctx) => <QualityQuantityCell quantity={ctx.row.quantityAfter} />,
      },
      {
        key: 'quantitySupposed',
        title: ls.text.weight,
        width: 80,
        render: (ctx) => <QualityQuantityCell quantity={ctx.row.quantitySupposed} />,
      },
      {
        key: 'tareWeight',
        title: ls.text.container,
        width: 80,
        render: (ctx) => <QualityQuantityCell quantity={String(ctx.row.tareWeight)} />,
      },
      {
        key: 'warehouseWorker',
        title: ls.text.warehouseWorker,
        width: 150,
        render: (ctx) => (
          <Text size="s" color="gray600">
            {ctx.row.warehouseWorkerName}
          </Text>
        ),
      },
    ]
  }
