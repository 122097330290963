import React from 'react'

import { Order } from '@foods-n-goods/server/generated/schema'
import { Flexbox, Stepper, Text, useTheme } from '@stage-ui/core'
import StepperTypes from '@stage-ui/core/control/Stepper/types'
import { ExternalLink } from '@stage-ui/icons'
import { StatusIndicator } from 'components/StatusIndicator'
import { CourierSelect } from 'components/CourierSelect'
import { useMouseDrop } from 'components/MouseDrop'
import { openOrderStatusChangeModal } from 'components/Modals/OrderStatusChange'
import { OrderActions } from 'actions'
import { useLocalizedString } from 'hooks/useLocalizedString'
import dayjs from 'dayjs'

interface OrderStatusDetailsProps {
  order: Order
}

const StatusText = (props: { label: string; date?: Date | null }) => {
  const { label, date } = props
  return (
    <Flexbox column centered>
      <Text size="s">{label}</Text>
      {!!date && <Text size="xs">{dayjs(date).format('DD.MM.YYYY HH:mm')}</Text>}
    </Flexbox>
  )
}

export function OrderStatusDetails(props: OrderStatusDetailsProps) {
  const theme = useTheme()
  const { order } = props
  const { workflow, status } = order
  const ls = useLocalizedString()
  const steps: Array<StepperTypes.StepperData & { statuses: Array<number> }> = [
    {
      label: '1',
      text: <StatusText label={ls.text.newOrder1} date={order.createDate} />,
      statuses: [1, 2],
    },
    {
      label: '2',
      text: <StatusText label={ls.text.orderInPicking} date={order.sendToSortDate} />,
      statuses: [3, 10, 11, 12, 13],
    },
    {
      label: '3',
      text: <StatusText label={ls.text.orderAwaitCourier} date={order.packDate} />,
      statuses: [4],
    },
    {
      label: '4',
      text: <StatusText label={ls.text.orderInDelivery} />,
      statuses: [4, 5, 6, 8, 9],
    },
    {
      label: '5',
      text: <StatusText label={ls.text.orderDelivered} date={order.deliveredDate} />,
      statuses: [7],
    },
  ]

  const currentStep = steps.findIndex((s) => s.statuses.includes(status.value))

  const handleStatusChange = () => {
    openOrderStatusChangeModal([order])
  }

  const hintChangeDate = useMouseDrop(ls.text.lastOrderUpdate)

  return (
    <Flexbox column flex={1}>
      <Flexbox column flex={1}>
        <Stepper
          current={currentStep === steps.length - 1 ? currentStep + 1 : currentStep}
          size="s"
          data={steps}
          overrides={{
            step: (state) => [
              {
                width: '1.75rem',
                height: '1.75rem',
                borderColor: theme.color.gray[400].hex(),
                color: theme.color.gray[400].hex(),
                ' svg': {
                  width: '1.25rem',
                },
              },
              state.active && {
                color: theme.color.primary.hex(),
                borderColor: theme.color.primary.hex(),
              },
              state.complete && {
                borderColor: theme.color.primary.hex(),
                color: theme.color.white.hex(),
              },
            ],
            stepText: (state) => [
              {
                color: theme.color.gray[400].hex(),
              },
              state.active && {
                color: theme.color.primary.hex(),
              },
              state.complete && {
                color: theme.color.primary.hex(),
              },
            ],
          }}
        />
      </Flexbox>
      <Flexbox alignItems="center" justifyContent="space-between" mt="m">
        <Flexbox flex={1}>
          <StatusIndicator
            onClick={handleStatusChange}
            status={status}
            justifyContent="space-between"
            mr="m"
          />
          <CourierSelect
            order={order}
            style={{
              maxWidth: '12.5rem',
              marginRight: '1rem',
            }}
          />
        </Flexbox>
        {workflow[0]?.createDate && (
          <Flexbox alignItems="center" justifyContent="flex-end" {...hintChangeDate}>
            <Text size="s" weight={400} color="gray400">
              {dayjs(workflow[0].createDate).format('DD.MM.YYYY HH:mm')}
            </Text>
            <ExternalLink
              ml="s"
              color="gray400"
              onClick={() => OrderActions.setFilter({ section: 'history' })}
            />
          </Flexbox>
        )}
      </Flexbox>
    </Flexbox>
  )
}
