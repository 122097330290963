import {
  ProductGroupInput,
  QueryProductGroupArgs,
} from '@foods-n-goods/server/generated/schema'
import { getLocalizedString } from 'hooks/useLocalizedString'
import request from 'requests/request'
import store, { AppStore, CategoryStore } from 'store'
import actionNotify from 'utils/actionNotify'

export default {
  async fetch(args: QueryProductGroupArgs = {}) {
    try {
      CategoryStore.loading()
      const response = await request('productGroup', {
        ...store.getState().category.productCategoryArgs,
        ...args,
      })
      CategoryStore.resolve(response)
    } catch (error) {
      CategoryStore.reject(error)
    }
  },

  async create(payload: ProductGroupInput, _cb?: () => void) {
    const ls = getLocalizedString()
    try {
      AppStore.pending(true)
      await request('productGroupCreate', { payload })
      CategoryStore.actionCreateResolve()
      const categories = await request('productGroup', {
        ...store.getState().category.productCategoryArgs,
      })
      CategoryStore.resolve(categories)
      actionNotify({
        title: payload.name,
        message: ls.text.productGroupCreateSuccess,
        type: 'success',
      })

      _cb?.()
    } catch (error) {
      actionNotify({
        title: ls.text.productGroupCreateError,
        message: `${error.message}`,
        type: 'error',
      })
      CategoryStore.actionReject(error.message)
    } finally {
      AppStore.pending(false)
    }
  },

  async delete(id: string) {
    const ls = getLocalizedString()
    try {
      await request('productGroupDelete', { id })
      CategoryStore.actionDeleteResolve()
      const categories = await request('productGroup')
      CategoryStore.resolve(categories)
      actionNotify({
        title: ls.text.productGroup.replace('[1]', id),
        message: ls.text.productGroupDeleteSuccess,
        type: 'success',
      })
    } catch (error) {
      actionNotify({
        title: ls.text.productGroup.replace('[1]', id),
        message: ls.text.productGroupDeleteError.replace('[1]', String(error.message)),
        type: 'error',
      })
      CategoryStore.actionReject(error.message)
    }
  },

  async update(id: string, payload: ProductGroupInput, _cb: () => void) {
    const ls = getLocalizedString()
    try {
      AppStore.pending(true)
      await request('productGroupUpdate', { id, payload })
      CategoryStore.actionUpdateResolve()
      const categories = await request('productGroup')
      CategoryStore.resolve(categories)
      actionNotify({
        title: ls.text.productGroup.replace('[1]', id),
        message: ls.text.productGroupUpdateSuccess,
        type: 'success',
      })

      _cb()
    } catch (error) {
      actionNotify({
        title: ls.text.productGroup.replace('[1]', id),
        message: ls.text.productGroupUpdateError.replace('[1]', String(error.message)),
        type: 'error',
      })
      CategoryStore.actionReject(error.message || error)
    } finally {
      AppStore.pending(false)
    }
  },

  async imageUpdate(id: string, payload: string) {
    const ls = getLocalizedString()
    try {
      AppStore.pending(true)
      await request('productGroupImage', { id, payload })
      CategoryStore.actionUpdateResolve()
      const categories = await request('productGroup')
      CategoryStore.resolve(categories)
      actionNotify({
        title: ls.text.productGroup.replace('[1]', id),
        message: ls.text.productGroupImageUpdateSuccess,
        type: 'success',
      })
    } catch (error) {
      actionNotify({
        title: ls.text.productGroup.replace('[1]', id),
        message: ls.text.productGroupImageUpdateError.replace('[1]', id),
        type: 'error',
      })
      CategoryStore.actionReject(error.message || error)
    } finally {
      AppStore.pending(false)
    }
  },
}
