import React from 'react'

import { ProductInput, SelectItemWithCode } from '@foods-n-goods/server/generated/schema'
import { Select } from '@stage-ui/core'
import Content from 'components/Content'
import ViewInputField from 'components/Fields/ViewInputField'
import ViewGridBlock from 'components/ViewGridBlock'
import { useLocalizedString } from 'hooks/useLocalizedString'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import SelectTypes from '@stage-ui/core/control/Select/types'

interface ProductViewPricingProps {
  units: Array<SelectItemWithCode>
  unit: ProductInput['unit']
  price: ProductInput['price']
  weight?: ProductInput['weight']
  scaleable: ProductInput['scaleable']
  onChange: KeyValueChangeFunction<ProductInput>
}

function ProductViewPricing(props: ProductViewPricingProps) {
  const { onChange, units, unit, price, weight, scaleable } = props
  const ls = useLocalizedString()
  const currencyCode = useSelector(
    (state) => state.app.marketConfig?.currency || 'RUB',
    objectEqual,
  )
  const scaleableOptions = [
    { text: ls.text.inPackage, value: 0 },
    { text: ls.text.byWeight, value: 1 },
  ]

  const unitsOptions: SelectTypes.Option<number>[] = units.map((u) => ({
    text: ls.string[u.code],
    value: u.value,
    code: u.code,
  }))
  const currentUnit = { text: ls.string[unit.code], value: unit.value, code: unit.code }

  return (
    <Content>
      <ViewGridBlock title={ls.text.pricing}>
        <Select
          label={ls.text.productUnit}
          labelType="outside"
          options={unitsOptions || []}
          values={unit ? [currentUnit] : []}
          onChange={(option) => {
            const unit = units.find((u) => u.value === option[0].value)
            if (!unit) {
              return
            }
            onChange('unit', unit)
          }}
        />
        <ViewInputField
          label={ls.text.priceWithCurrency.replace('[1]', ls.string[currencyCode])}
          value={price}
          onChange={(value) => onChange('price', value)}
          gridRow="2"
          type="rubles"
        />
        <ViewInputField
          label={ls.text.weightVolume}
          gridRow="2"
          value={weight || ''}
          onChange={(value) => onChange('weight', value)}
          digits={2}
          type="digits"
        />
        <Select
          label={ls.text.shipment}
          disabled={unit.value === 1}
          labelType="outside"
          options={[
            { text: ls.text.inPackage, value: 0 },
            { text: ls.text.byWeight, value: 1 },
          ]}
          values={
            unit.value === 1
              ? [scaleableOptions[1]]
              : [scaleableOptions[scaleable ? 1 : 0]]
          }
          onChange={(option) => onChange('scaleable', !!option[0].value)}
        />
      </ViewGridBlock>
    </Content>
  )
}

export default ProductViewPricing
