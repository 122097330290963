import React from 'react'

import TableTypes from '@stage-ui/core/data/Table/types'
import { Text } from '@stage-ui/core'
import { Order } from '@foods-n-goods/server/generated/schema'
import { OrderStatusCode } from '@foods-n-goods/client/system/types'
import { useLocalizedString } from 'hooks/useLocalizedString'
import dayjs from '@foods-n-goods/client/utils/dayjs'

export function OrderPayDelayCell(ctx: TableTypes.TableCellContext<Order>) {
  const { row } = ctx
  const { payed, status, createDate } = row
  const ls = useLocalizedString()
  const getDateInWords = dayjs
    .duration(dayjs().diff(dayjs(createDate), 'day'), 'days')
    .humanize()

  if (!payed && Number(status.value) === OrderStatusCode.DELIVERED) {
    return <Text size="s">{getDateInWords}</Text>
  }

  return <Text size="s">{ls.text.noPayDelay}</Text>
}
