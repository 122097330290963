import React from 'react'

import { ProductInput } from '@foods-n-goods/server/generated/schema'
import { Flexbox, Paragraph, Switch, Text } from '@stage-ui/core'
import { useLocalizedString } from 'hooks/useLocalizedString'

interface ProductViewHeaderProps {
  published: ProductInput['published']
  onChange: KeyValueChangeFunction<ProductInput>
}

function ProductViewHeader(props: ProductViewHeaderProps) {
  const { published, onChange } = props
  const ls = useLocalizedString()
  return (
    <Flexbox justifyContent="space-between" h="2rem" alignItems="center">
      <Paragraph
        m={0}
        children={ls.text.main}
        transform="uppercase"
        color="gray400"
        weight={700}
        size="1.125rem"
      />
      <Flexbox alignItems="center">
        <Text
          size="s"
          children={published ? ls.text.published : ls.text.notPublished}
          color={published ? 'primary' : 'light'}
        />
        <Switch
          ml="0.5rem"
          checked={published}
          onChange={() => onChange('published', !published)}
        />
      </Flexbox>
    </Flexbox>
  )
}

export default ProductViewHeader
