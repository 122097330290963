/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useMemo } from 'react'

import { Block, Flexbox, Paragraph, Select, TextField } from '@stage-ui/core'
import { AppActions } from 'actions'
import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { getAlertTypeOptions, getOnOffLabels, getScaleAcceptOptions } from '../misc'

export function GeneralSorting() {
  const ls = useLocalizedString()
  const { marketConfig } = useSelector(
    (state) => ({
      marketConfig: state.app.marketConfig,
    }),
    objectEqual,
  )
  const scaleAcceptOptions = useMemo(getScaleAcceptOptions, [ls.id])
  const onOffLabels = useMemo(getOnOffLabels, [ls.id])
  const alertTypeOptions = useMemo(getAlertTypeOptions, [ls.id])
  return (
    <Block decoration="surface" borderColor="gray100" p="l">
      <Paragraph weight={500} size="l" color="gray900" mb="m">
        {ls.text.pickingSettings}
      </Paragraph>
      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.3} mr="m">
          {ls.text.weightShortageAction}
        </Paragraph>
        <Select
          decoration="none"
          options={scaleAcceptOptions}
          defaultValues={[
            scaleAcceptOptions.find(
              (opt) => opt.value === (marketConfig?.scaleUnderWeightType || '0'),
            )!,
          ]}
          onChange={(_, value) => {
            AppActions.marketConfigSet({
              scaleUnderWeightType: value?.value as string,
            })
          }}
        />
      </Flexbox>
      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.3} mr="m">
          {ls.text.weightDeficitThreshold}
        </Paragraph>
        <TextField
          decoration="none"
          p={0}
          defaultValue={marketConfig?.scaleUnderWeightPercent || 1}
          onChange={(e) => {
            AppActions.marketConfigSet({
              scaleUnderWeightPercent: e.target.value || '0',
            })
          }}
        />
      </Flexbox>
      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.3} mr="m">
          {ls.text.overweightAction}
        </Paragraph>
        <Select
          decoration="none"
          options={scaleAcceptOptions}
          defaultValues={[
            scaleAcceptOptions.find(
              (opt) => opt.value === (marketConfig?.scaleOverWeightType || '0'),
            )!,
          ]}
          onChange={(_, value) => {
            AppActions.marketConfigSet({
              scaleOverWeightType: value?.value as string,
            })
          }}
        />
      </Flexbox>
      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.3} mr="m">
          {ls.text.excessAction}
        </Paragraph>
        <Select
          decoration="none"
          options={scaleAcceptOptions}
          defaultValues={[
            scaleAcceptOptions.find(
              (opt) => opt.value === (marketConfig?.overStType || '0'),
            )!,
          ]}
          onChange={(_, value) => {
            AppActions.marketConfigSet({
              overStType: value?.value as string,
            })
          }}
        />
      </Flexbox>
      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.3} mr="m">
          {ls.text.overweightThreshold}
        </Paragraph>
        <TextField
          decoration="none"
          p={0}
          defaultValue={marketConfig?.scaleOverWeightPercent || 1}
          onChange={(e) => {
            AppActions.marketConfigSet({
              scaleOverWeightPercent: e.target.value || '0',
            })
          }}
        />
      </Flexbox>
      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.3} mr="m">
          {ls.text.excessThreshold}
        </Paragraph>
        <TextField
          decoration="none"
          p={0}
          defaultValue={marketConfig?.overStPercent || 0}
          onChange={(e) => {
            AppActions.marketConfigSet({
              overStPercent: e.target.value || '0',
            })
          }}
        />
      </Flexbox>
      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.3} mr="m">
          {ls.text.fetchItems}
        </Paragraph>
        <Select
          decoration="none"
          options={alertTypeOptions}
          defaultValues={[
            alertTypeOptions.find(
              (opt) => opt.value === (marketConfig?.warehousePositionsAlertType || '0'),
            )!,
          ]}
          onChange={(_, value) => {
            AppActions.marketConfigSet({
              warehousePositionsAlertType: value?.value as string,
            })
          }}
        />
      </Flexbox>
      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.3} mr="m">
          {ls.text.containerAutoSelect}
        </Paragraph>
        <Select
          decoration="none"
          options={onOffLabels}
          defaultValues={[
            onOffLabels.find(
              (opt) => opt.value === (marketConfig?.warehouseControlAutoTare || '0'),
            )!,
          ]}
          onChange={(_, value) => {
            AppActions.marketConfigSet({
              warehouseControlAutoTare: value?.value as string,
            })
          }}
        />
      </Flexbox>
      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.3} mr="m">
          {ls.text.partialItemConfirmationWithPurchase}
        </Paragraph>
        <Select
          decoration="none"
          options={onOffLabels}
          defaultValues={[
            onOffLabels.find(
              (opt) =>
                opt.value === (marketConfig?.warehouseControlSubmitWithPurchase || '0'),
            )!,
          ]}
          onChange={(_, value) => {
            AppActions.marketConfigSet({
              warehouseControlSubmitWithPurchase: value?.value as string,
            })
          }}
        />
      </Flexbox>
      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.3} mr="m">
          {ls.text.manualInput}
        </Paragraph>
        <Select
          decoration="none"
          options={onOffLabels}
          defaultValues={[
            onOffLabels.find(
              (opt) => opt.value === (marketConfig?.warehouseControlManualInput || '0'),
            )!,
          ]}
          onChange={(_, value) => {
            AppActions.marketConfigSet({
              warehouseControlManualInput: value?.value as string,
            })
          }}
        />
      </Flexbox>
      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.3} mr="m">
          {ls.text.autoComplete}
        </Paragraph>
        <Select
          decoration="none"
          options={onOffLabels}
          defaultValues={[
            onOffLabels.find(
              (opt) => opt.value === (marketConfig?.warehouseControlAutoSubmit || '0'),
            )!,
          ]}
          onChange={(_, value) => {
            AppActions.marketConfigSet({
              warehouseControlAutoSubmit: value?.value as string,
            })
          }}
        />
      </Flexbox>
    </Block>
  )
}
