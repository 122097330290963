import React from 'react'

import { Client } from '@foods-n-goods/server/generated/schema'
import { Flexbox, Paragraph, Table, useTheme } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { List } from '@stage-ui/icons'
import { ClientActions } from 'actions'
import { PageSwitch } from 'components/PageSwitch'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { useHistory, useLocation } from 'react-router-dom'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { AddressCell } from './components/AddressCell'
import { AvatartCell } from './components/AvatarCell'
import { ContextCell } from './components/ContextCell'
import { DebtCell } from './components/DebtCell'
import { NameCell } from './components/NameCell'
import { RatingCell } from './components/RatingCell'
import { RegisterCell } from './components/RegisterCell'

export function ClientListTable() {
  const history = useHistory()
  const { color } = useTheme()
  const location = useLocation()
  const params = location.search
  const ls = useLocalizedString()
  const columns: TableTypes.TableColumn<Client>[] = [
    {
      key: 'avatar',
      width: 48,
      render: AvatartCell,
    },
    {
      key: 'name',
      primary: true,
      title: ls.text.client?.replace('[1]', '').trim(),
      render: NameCell,
    },
    {
      key: 'address',
      title: ls.text.address,
      render: AddressCell,
      width: '320',
    },
    {
      key: 'createDate',
      title: ls.text.signUp,
      width: 160,
      render: RegisterCell,
    },
    {
      key: 'debt',
      title: ls.text.debt,
      width: 160,
      render: DebtCell,
    },
    {
      key: 'icon',
      title: ls.text.rating,
      width: 80,
      render: RatingCell,
    },
    { key: '_context', width: 32, render: ContextCell },
  ]
  const { loading, clientPage } = useSelector(
    ({ client }) => ({
      clientPage: client.clientPage,
      loading: client.loading === 'idle' || client.loading === 'pending',
    }),
    objectEqual,
  )

  const onRowClick = (ctx: TableTypes.TableRowContext<Client>) => {
    if (loading) return
    ClientActions.fetchById(ctx.row.id)
    history.push(`clients/${ctx.row.id}${params}`)
  }

  return (
    <Flexbox column h="100%">
      {clientPage.total === 0 && (
        <Flexbox
          column
          centered
          p="m"
          decoration="surface"
          borderRadius="s"
          borderColor="gray200"
        >
          <List size="1.5rem" color="gray300" />
          <Paragraph size="s" color="gray400">
            {loading ? ls.text.loading : ls.text.listEmpty}
          </Paragraph>
        </Flexbox>
      )}
      {clientPage.total > 0 && (
        <Table
          data={clientPage.records}
          columns={columns}
          onRowClick={onRowClick}
          overrides={{
            container: {
              userSelect: 'none',
            },
            row: (props) => [
              props.selected && {
                backgroundColor: `${color.green[50].hex()} !important`,
              },
            ],
            headCell: () => [
              {
                backgroundColor: color.background.hex(),
                position: 'sticky',
                top: '9.5625rem',
                zIndex: 6,
              },
            ],
          }}
        />
      )}
      <PageSwitch pageSize={clientPage.pageSize} total={clientPage.total} />
    </Flexbox>
  )
}
