import React from 'react'

import { OrderStatusCode } from '@foods-n-goods/client/system/types'
import { Button, modal } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { ArrowCircleUp, Trash2 } from '@stage-ui/icons'
import { useMouseDrop } from 'components/MouseDrop'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { OrderedPosition } from '..'

import { OrderPositionDeleteModal } from './DeleteModal'
import { OrderPositionRestoreModal } from './RestoreModal'

export function OrderPositionDeleteCell(
  ctx: TableTypes.TableCellContext<OrderedPosition>,
) {
  const { row } = ctx
  const ls = useLocalizedString()
  const { id, orderId, deleteDate, status, parentStatusValue } = row

  const deleteDisabled =
    !!deleteDate ||
    [
      OrderStatusCode.PICKED_UP,
      OrderStatusCode.DELIVERY,
      OrderStatusCode.DELIVERED,
      OrderStatusCode.CANCELED,
      OrderStatusCode.REJECTED,
      OrderStatusCode.NOT_DELIVERED,
    ].includes(status.value)

  const positionStopStatuses = [
    OrderStatusCode.PICKED_UP,
    OrderStatusCode.DELIVERY,
    OrderStatusCode.DELIVERED,
  ]

  const orderStopStatuses = positionStopStatuses.concat([
    OrderStatusCode.CANCELED,
    OrderStatusCode.REJECTED,
    OrderStatusCode.NOT_DELIVERED,
  ])

  const restoreDisabled =
    !deleteDate ||
    !!positionStopStatuses.includes(status.value) ||
    !!(parentStatusValue && orderStopStatuses.includes(parentStatusValue))

  const deleteHintText =
    parentStatusValue && parentStatusValue === OrderStatusCode.NEW
      ? ls.text.removeItem
      : ls.text.cancelOrder

  const deleteHint = useMouseDrop(!deleteDisabled && deleteHintText)
  const restoreHint = useMouseDrop(!restoreDisabled && ls.text.returnItemInOrder)

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    modal({
      title: ls.text.removeItem,
      overlayClose: false,
      render: (close) => (
        <OrderPositionDeleteModal
          close={close}
          id={id}
          orderId={orderId}
          disabled={deleteDisabled}
        />
      ),
    })
  }

  const handleRestorePosition = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    modal({
      title: ls.text.returnItemInOrder,
      overlayClose: false,
      render: (close) => (
        <OrderPositionRestoreModal close={close} id={id} disabled={restoreDisabled} />
      ),
    })
  }

  if (deleteDate) {
    return (
      <Button
        disabled={restoreDisabled}
        p={0}
        w="2.5rem"
        h="2.5rem"
        decoration="outline"
        onClick={handleRestorePosition}
        {...restoreHint}
      >
        <ArrowCircleUp size="1.5rem" />
      </Button>
    )
  }

  return (
    <Button
      disabled={deleteDisabled}
      p={0}
      w="2.5rem"
      h="2.5rem"
      decoration="outline"
      color="red500"
      onClick={handleDelete}
      {...deleteHint}
    >
      <Trash2 size="1.5rem" />
    </Button>
  )
}
