import { LocalizedStringID } from '@foods-n-goods/client/locale'
import ClientSystem from '@foods-n-goods/client/system/types'
import {
  MarketConfig,
  MarketIndividual,
  Meta,
  Staff,
} from '@foods-n-goods/server/generated/schema'
import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { getLocalizedString } from 'hooks/useLocalizedString'

export const getMonths = () => {
  const ls = getLocalizedString()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return [
    ls.text.january,
    ls.text.february,
    ls.text.march,
    ls.text.april,
    ls.text.may,
    ls.text.june,
    ls.text.july,
    ls.text.august,
    ls.text.september,
    ls.text.october,
    ls.text.november,
    ls.text.december,
  ]
}

export type SettingsFilter = {
  section?: string
}

export interface AppState {
  loading: ClientSystem.Loading
  error: RequestError | null
  user: Staff | null
  userLoading: ClientSystem.Loading
  userError: RequestError | null
  meta: Meta | null
  metaLoadingStatus: ClientSystem.Loading
  metaError: RequestError | null
  marketConfig: MarketConfig | null
  marketConfigLoadingStatus: ClientSystem.Loading
  marketConfigError: RequestError | null
  marketIndividuals: MarketIndividual[]
  marketIndividualsLoadingStatus: ClientSystem.Loading
  marketIndividualsError: RequestError | null
  pending: boolean
  filter: SettingsFilter
  localization: string | null
}

export const initialState: AppState = {
  loading: 'idle',
  error: null,
  user: null,
  userLoading: 'idle',
  userError: null,
  meta: null,
  metaLoadingStatus: 'idle',
  metaError: null,
  marketConfig: null,
  marketConfigLoadingStatus: 'idle',
  marketConfigError: null,
  marketIndividuals: [],
  marketIndividualsLoadingStatus: 'idle',
  marketIndividualsError: null,
  pending: false,
  filter: {},
  localization: null
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    loading(state) {
      state.loading = 'pending'
    },
    resolve(state) {
      state.loading = 'resolved'
      state.error = initialState.error
    },
    reject(state, action: PayloadAction<AppState['error']>) {
      state.loading = 'rejected'
      state.error = action.payload
    },

    // META
    metaLoading(state) {
      state.metaLoadingStatus = 'pending'
    },
    metaResolve(state, action: PayloadAction<AppState['meta']>) {
      state.metaLoadingStatus = 'resolved'
      state.meta = action.payload
      state.metaError = initialState.metaError
    },
    metaReject(state, action: PayloadAction<AppState['metaError']>) {
      state.metaLoadingStatus = 'rejected'
      state.metaError = action.payload
      state.metaError = action.payload
    },

    // MarketConfig
    marketConfigLoading(state) {
      state.marketConfigLoadingStatus = 'pending'
    },
    marketConfigResolve(state, action: PayloadAction<AppState['marketConfig']>) {
      state.marketConfigLoadingStatus = 'resolved'
      state.marketConfig = action.payload
      state.marketConfigError = initialState.metaError
    },
    marketConfigReject(state, action: PayloadAction<AppState['marketConfigError']>) {
      state.marketConfigLoadingStatus = 'rejected'
      state.marketConfigError = action.payload
      state.error = action.payload
    },

    // MarketConfig
    marketIndividualsLoading(state) {
      state.marketIndividualsLoadingStatus = 'pending'
    },
    marketIndividualsResolve(
      state,
      action: PayloadAction<AppState['marketIndividuals']>,
    ) {
      state.marketIndividualsLoadingStatus = 'resolved'
      state.marketIndividuals = action.payload
      state.marketIndividualsError = initialState.metaError
    },
    marketIndividualsReject(
      state,
      action: PayloadAction<AppState['marketIndividualsError']>,
    ) {
      state.marketIndividualsLoadingStatus = 'rejected'
      state.marketIndividualsError = action.payload
      state.error = action.payload
    },

    // AUTH
    userLoading(state) {
      state.userLoading = 'pending'
    },
    userResolve(state, action: PayloadAction<AppState['user']>) {
      state.userLoading = 'resolved'
      state.userError = initialState.userError
      state.user = action.payload
    },
    userReject(state, action?: PayloadAction<AppState['userError']>) {
      state.userLoading = 'rejected'
      state.userError = action?.payload || null
    },
    userUpdate(state, action:PayloadAction<Partial<Staff>>){
      if(!state.user){
        return
      }
      state.user = {...state.user, ...action.payload}
    },
    localizationSet(state, action: PayloadAction<AppState['localization']>){
      state.localization = action.payload
    },

    // PENDING
    pending(state, action: PayloadAction<AppState['pending']>) {
      state.pending = action.payload
    },

    clear() {
      return initialState
    },
    updateMarketFlags(state, action: PayloadAction<{ value: number }>) {
      if (!state.meta) {
        return
      }
      state.meta.markets = state.meta.markets.map((market) => {
        if (market.value !== action.payload.value) {
          return market
        }
        return {
          ...market,
          ...action.payload,
        }
      })
    },

    setFilter(state, action: PayloadAction<Partial<SettingsFilter>>) {
      state.filter = {
        ...state.filter,
        ...action.payload,
      }
    },

    clearFilter(state) {
      state.filter = {}
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(appSlice.actions.marketConfigResolve, appSlice.actions.userResolve, appSlice.actions.userUpdate),
        (state) => {
          const newLocale = state.user?.localization || state.marketConfig?.localization || null
          state.localization = newLocale
        }
      )
  },
})

export default appSlice
