import React from 'react'

import { Flexbox, Paragraph } from '@stage-ui/core'
import { AlertTriangle } from '@stage-ui/icons'
import { useLocalizedString } from 'hooks/useLocalizedString'

export function Unavailable() {
  const ls = useLocalizedString()
  return (
    <Flexbox alignItems="center" justifyContent="center" h="100%">
      <Flexbox column alignItems="center">
        <AlertTriangle size="10rem" color="gray300" />
        <Paragraph weight={600} size="xl" color="gray700" mt="1rem">
          {ls.text.serviceUnavailableTitle}
        </Paragraph>
        <Paragraph weight={600} color="gray500" mt="1rem">
          {ls.text.serviceUnavailableDescription}
        </Paragraph>
      </Flexbox>
    </Flexbox>
  )
}
