import React from 'react'

import { Flexbox, Paragraph, Spinner } from '@stage-ui/core'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { useLocalizedString } from 'hooks/useLocalizedString'

function LoadingOverlay() {
  const pending = useSelector(({ app }) => app.pending, objectEqual)
  const ls = useLocalizedString()
  return (
    <Flexbox
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        transition: '0.25s ease-in-out',
        opacity: pending ? 1 : 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        pointerEvents: pending ? 'all' : 'none',
        visibility: pending ? 'visible' : 'hidden',
        zIndex: 9999,
        transform: `scale(${pending ? 1 : 1.1})`,
      }}
      alignItems="center"
      justifyContent="center"
    >
      <Spinner mr="1rem" color="onPrimary" size="1.5rem" />
      <Paragraph size="l" color="onPrimary" children={ls.text.loading} />
    </Flexbox>
  )
}

export default LoadingOverlay
