import React, { useEffect, useState } from 'react'

import { MarketUser, Staff, StaffInput } from '@foods-n-goods/server/generated/schema'
import { Block, Divider, Flexbox, ScrollView, Text } from '@stage-ui/core'
import { Done } from '@stage-ui/icons'
import request from 'requests/request'
import FilterCommon from 'components/common/FilterCommon'
import declOfNum from 'utils/declOfNum'
import { useLocalizedString } from 'hooks/useLocalizedString'

type StaffExternalMarketSourceUpdateProps = {
  value?: Staff['exIndividualId'] | null
  onChange: KeyValueChangeFunction<StaffInput>
}

export function StaffExternalMarketSourceUpdate(
  props: StaffExternalMarketSourceUpdateProps,
) {
  const { value, onChange } = props
  const ls = useLocalizedString()
  const [search, setSearch] = useState('')
  const [marketUsers, setMarketUsers] = useState<MarketUser[]>([])

  const getMarketUsers = async () => {
    const clients = await request('marketUsers')
    if (clients) {
      const data = clients.slice().sort((a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      })
      setMarketUsers(data)
    }
  }

  useEffect(() => {
    getMarketUsers()
  }, [])

  const data = marketUsers.filter((mu) => {
    if (search) {
      return `${mu.name} - ${mu.inn}`.toUpperCase().match(search.toUpperCase())
    }
    return true
  })

  return (
    <Flexbox column>
      <Flexbox alignItems="center" justifyContent="space-between">
        <FilterCommon onSearch={(v) => setSearch(v)} />
        <Text color="gray500" size="s">{`${data.length} ${declOfNum(data.length, [
          ls.text.record1,
          ls.text.record2,
          ls.text.record3,
        ])}`}</Text>
      </Flexbox>
      <Block
        mt="m"
        decoration="surface"
        borderRadius="m"
        borderColor="gray200"
        h="30vh"
        style={{
          overflow: 'hidden',
        }}
      >
        <ScrollView xBarPosition="none" size="xs" barOffset={4}>
          {data.map((marketUser, index) => (
            <React.Fragment key={marketUser.exId}>
              <Flexbox
                p="s m"
                backgroundColor={marketUser.exId === value ? 'green50' : 'transparent'}
                onClick={() => {
                  onChange('exIndividualId', marketUser.exId)
                }}
                alignItems="center"
                justifyContent="space-between"
              >
                <Flexbox column>
                  <Text size="s" color="gray900" weight={600}>
                    {marketUser.name}
                  </Text>
                  <Text size="xs" color="gray500" weight={600}>
                    {marketUser.inn}
                  </Text>
                </Flexbox>
                {marketUser.exId === value && <Done color="primary" size="1.25rem" />}
              </Flexbox>
              {data.length !== index + 1 && <Divider h="0.0625rem" color="gray200" />}
            </React.Fragment>
          ))}
        </ScrollView>
      </Block>
    </Flexbox>
  )
}
