import React, { useState } from 'react'

import { OrderStatusCode } from '@foods-n-goods/client/system/types'
import { Staff } from '@foods-n-goods/server/generated/schema'
import { StaffRole } from '@foods-n-goods/server/src/shared/enum'
import {
  Block,
  Button,
  Divider,
  Flexbox,
  ScrollView,
  Spinner,
  Text,
} from '@stage-ui/core'
import { Award, Done, Info } from '@stage-ui/icons'
import { PurchasesActions } from 'actions'
import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { PurchaseExtended, usePurchasesData } from '../data'

type PurchaseUpdatePurchaserModalProps = {
  purchaseRow: PurchaseExtended
  close: () => void
}

export const PurchaseUpdatePurchaserModal: React.FC<PurchaseUpdatePurchaserModalProps> = (
  props,
) => {
  const { purchaseRow, close } = props
  const ls = useLocalizedString()
  const [purchaserId, setPurchaserId] = useState<Staff['id']>(purchaseRow.buyer?.id || '')

  const { purchasers, staffLoading } = useSelector(
    (state) => ({
      purchasers: state.staff.data.filter((s) =>
        [StaffRole.PURCHASE, StaffRole.MAIN_PURCHASE].includes(s.role.value),
      ),
      staffLoading: state.staff.loading,
    }),
    objectEqual,
  )

  const { filteredData } = usePurchasesData()

  const handleSave = () => {
    if (!purchaserId) return

    const purchasesToChange = purchaseRow._isExpandable
      ? filteredData.filter(
          (p) =>
            purchaseRow.product.id === p.product.id &&
            p.status.value !== OrderStatusCode.PURCHASE_CONFIRMED,
        )
      : [purchaseRow]

    for (const p of purchasesToChange) {
      PurchasesActions.setPurchaser(p.id, purchaserId)
    }

    close()
  }

  return (
    <Flexbox column>
      {purchaseRow._isExpandable && (
        <Flexbox decoration="surface" p="m" backgroundColor="gray100" mb="l">
          <Info color="orange500" size="1.25rem" mr="s" />
          <Text size="s" color="orange500">
            {ls.text.purchaserChangeWarning}
          </Text>
        </Flexbox>
      )}
      <Block
        decoration="surface"
        borderRadius="m"
        borderColor="gray200"
        h="30vh"
        style={{
          overflow: 'hidden',
        }}
      >
        <ScrollView xBarPosition="none" size="xs" h="100%" barOffset={4}>
          {purchasers.map((purchaser, index) => (
            <React.Fragment key={purchaser.id}>
              <Flexbox
                p="s"
                backgroundColor={purchaser.id === purchaserId ? 'green50' : 'transparent'}
                onClick={() => setPurchaserId(purchaser.id)}
                alignItems="center"
                justifyContent="space-between"
              >
                <Flexbox alignItems="center">
                  <Text mr="s" size="s" color="gray900" weight={600}>
                    {purchaser.name}
                  </Text>
                  {purchaser.role.value === StaffRole.MAIN_PURCHASE && (
                    <Award type="filled" color="yellow400" size="1.125rem" />
                  )}
                </Flexbox>
                {purchaser.id === purchaserId && <Done color="primary" size="1.25rem" />}
                {purchaseRow.buyer?.id === purchaser.id &&
                  purchaseRow.buyer?.id !== purchaserId && (
                    <Done color="gray400" size="1.25rem" />
                  )}
              </Flexbox>
              {purchasers.length !== index && <Divider color="gray200" />}
            </React.Fragment>
          ))}
          {staffLoading !== 'resolved' && (
            <Flexbox centered p="m">
              <Spinner />
            </Flexbox>
          )}
        </ScrollView>
      </Block>
      <Flexbox mt="l" justifyContent="flex-end">
        <Button
          decoration="text"
          color="gray500"
          label={ls.text.cancel}
          onClick={close}
          mr="m"
        />
        <Button disabled={!purchaserId} label={ls.text.confirm} onClick={handleSave} />
      </Flexbox>
    </Flexbox>
  )
}
