import React from 'react'

import {
  Block,
  Button,
  Divider,
  Flexbox,
  Paragraph,
  Text,
  useTheme,
} from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { Car, CheckmarkCircle, CloseCircle, MessageCircle, Pin } from '@stage-ui/icons'
import { ColorText } from 'components/ColorText'
import { openClientModal } from 'components/Modals/ClientView'
import { openOrderModal } from 'components/Modals/OrderView'
import { useMouseDrop } from 'components/MouseDrop'
import { useMouseDropMenu } from 'components/MouseMenuDrop'
import { OrderDelivery } from 'store/delivery'

import TwoDots from 'components/Icons/TwoDots'

import { mapStatusToColor } from 'components/StatusIndicator'

import { OrderStatusCode } from '@foods-n-goods/client/system/types'

import { openOrderCourierChangeModal } from 'components/Modals/OrderCourierChange'
import { useStaff } from 'hooks/useStaff'
import { useLocalizedString } from 'hooks/useLocalizedString'
import dayjs from 'dayjs'

export function OrderCell(ctx: TableTypes.TableCellContext<OrderDelivery>) {
  const { row } = ctx
  const {
    preferDeliveryInterval,
    preferredDeliveryDate,
    client,
    deliveryAddress,
    comment,
    sequenceOrder,
    deliveredDate,
    status,
  } = row

  const courier = useStaff(row.courier?.id)
  const ls = useLocalizedString()
  const date = preferredDeliveryDate ? dayjs(preferredDeliveryDate).format('DD.MM') : '-'

  const theme = useTheme()
  const statusColors = mapStatusToColor(status.value, 'gradient', theme)

  const addressHint = useMouseDrop(deliveryAddress.address || ls.text.addressNotSpecified)
  const commentaryHint = useMouseDrop(comment)
  const courierHint = useMouseDrop(ls.text.courierAssign)
  const statusHint = useMouseDrop(status.text)

  const contextMenu = useMouseDropMenu<HTMLButtonElement>(
    [
      { text: ls.text.courierAssign, icon: 'Car', value: 'COURIER' },
      { text: ls.text.orderDetailsLabel, icon: 'ShoppingCart', value: 'ORDER' },
      { text: ls.text.clientCard, icon: 'People', value: 'CLIENT' },
    ],
    ({ value }) => {
      if (value === 'COURIER') {
        openOrderCourierChangeModal([row])
      }
      if (value === 'ORDER') {
        openOrderModal(row.id)
      }
      if (value === 'CLIENT') {
        openClientModal(row.client.id)
      }
    },
  )

  return (
    <Flexbox
      p="m"
      attributes={{
        'data-id': row.id,
        'data-hover': 'false',
      }}
      style={(t) => [
        {
          '&[data-hover="true"]': {
            background: t.color.gray[100].hex(),
          },
          ...(deliveryAddress.address
            ? {}
            : {
                background: `${t.color.red[100].hex()} !important`,
              }),
        },
      ]}
    >
      <Block w="5rem">
        <Flexbox mb="s" alignItems="center">
          {/* {!isDelivered && !isNotDelivered && <ColorDot color={courier?.color?.code} />} */}
          <Flexbox
            centered
            flexShrink={0}
            decoration="surface"
            w="0.875rem"
            h="0.875rem"
            mr="xs"
            borderRadius="3rem"
            style={{
              background: statusColors.background,
              borderColor: statusColors.borderColor,
            }}
            {...statusHint}
          >
            {status.value === OrderStatusCode.DELIVERED && (
              <CheckmarkCircle color="primary" />
            )}
            {status.value === OrderStatusCode.NOT_DELIVERED && (
              <CloseCircle color="surface" />
            )}
          </Flexbox>
          {!!sequenceOrder && (
            <ColorText
              h="1.125rem"
              p={0}
              ml="xs"
              pl="xs"
              pr="s"
              size="xs"
              backgroundColor={courier?.color?.code || 'gray200'}
              color={courier?.color ? 'white' : 'gray500'}
              hint={courier?.name || courier?.login || ls.text.courierAssigned}
              onClick={(e) => {
                e.stopPropagation()
                openOrderCourierChangeModal([row])
              }}
            >
              <Flexbox>
                <Car mr="xs" />
                {sequenceOrder}
              </Flexbox>
            </ColorText>
          )}
          {!sequenceOrder && (
            <Car
              color="gray500"
              ml="xs"
              size="1.25rem"
              {...courierHint}
              onClick={(e) => {
                e.stopPropagation()
                openOrderCourierChangeModal([row])
              }}
            />
          )}
        </Flexbox>
        <Text size="xs" color="gray500">
          #{row.id}
        </Text>
      </Block>
      <Block flex={1}>
        <Flexbox justifyContent="space-between">
          <Paragraph color="gray900" size="s" mb="s">
            {client.name || '-'}
          </Paragraph>
          <Button
            flexShrink={0}
            id={`order_row_action_${row.id}`}
            mr="-0.25rem"
            mt="-0.25rem"
            decoration="text"
            color="gray500"
            p={0}
            w="1.5rem"
            h="1.5rem"
            {...contextMenu}
          >
            <TwoDots w="1.25rem" fillColor={theme.color.gray[500].hex()} />
          </Button>
        </Flexbox>
        <Flexbox>
          {!!preferredDeliveryDate && (
            <Flexbox alignItems="center">
              <Text color="gray900" size="xs">
                {date}
              </Text>
            </Flexbox>
          )}
          {!!preferDeliveryInterval && (
            <Divider vertical color="gray400" mx="s" h="1rem" />
          )}
          {!!preferDeliveryInterval && (
            <Flexbox alignItems="center" mr="m">
              <Text color="gray900" size="xs">
                {preferDeliveryInterval}
              </Text>
            </Flexbox>
          )}
          {!!deliveredDate && (
            <Flexbox alignItems="center" mr="m">
              <Text color="green500" weight={500} size="xs">
                {ls.text.deliveredAt.replace(
                  '[1]',
                  dayjs(deliveredDate).format('DD.MM HH:mm'),
                )}
              </Text>
            </Flexbox>
          )}
          <Block flex={1} />
          <Pin
            size="1.125rem"
            type={deliveryAddress.address ? 'outline' : 'filled'}
            color={deliveryAddress.address ? 'gray400' : 'red500'}
            {...addressHint}
          />
          <MessageCircle
            size="1.125rem"
            color={comment ? 'blue400' : 'gray400'}
            ml="s"
            {...commentaryHint}
          />
        </Flexbox>
      </Block>
    </Flexbox>
  )
}
