import React, { useMemo } from 'react'

import { ReviewActions } from 'actions'
import { rowsPrePage } from 'actions/order'
import { PageTable } from 'components/PageTable'
import Pagination from 'components/Pagination'
import useSelector from 'hooks/useSelector'
import { useHistory } from 'react-router-dom'

import { objectEqual } from 'utils/objectEqual'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { getColumns } from './misc'

function ReviewsTable() {
  const history = useHistory()
  const ls = useLocalizedString()
  const { data, total, currentPage } = useSelector(
    ({ review, app }) => ({
      data: review.data,
      total: app.meta?.productReviewsCount,
      currentPage: review.page,
    }),
    objectEqual,
  )
  const columns = useMemo(getColumns, [ls.id])
  return (
    <PageTable
      noDataText={ls.text.reviewsNotFound}
      data={data}
      columns={columns}
      pages={
        (data.length >= rowsPrePage || currentPage > 1) && (
          <Pagination
            pageCount={Math.ceil((total || 0) / rowsPrePage)}
            onChange={(page) => {
              ReviewActions.setPage(page)
            }}
          />
        )
      }
      onRowClick={(ctx) => {
        const { row } = ctx
        history.push(`/reviews/${row.id}`)
      }}
    />
  )
}

export default ReviewsTable
