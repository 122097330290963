/* eslint-disable import/no-mutable-exports */
import React, { createRef, useEffect } from 'react'

import { StaffRole } from '@foods-n-goods/server/src/shared/enum'
import { Block, Flexbox, ScrollView } from '@stage-ui/core'
import ScrollViewTypes from '@stage-ui/core/layout/ScrollView/types'
import * as Icons from '@stage-ui/icons'
import Sidebar from 'components/Sidebar'
import useSelector from 'hooks/useSelector'
import * as Pages from 'pages'
import StatisticPage from 'pages/home/StatisticPage'
import { Redirect, useHistory, useLocation } from 'react-router-dom'

import { objectEqual } from 'utils/objectEqual'

import { useLocalizedString } from 'hooks/useLocalizedString'

import PrivateRoute from './PrivateRoute'

type MenuItem = {
  value: string
  title: string
  children: React.ReactNode
  icon: keyof typeof Icons
  description?: string
  locationSearch?: string
}
export let history: any = null

export const usePrivateRoutes = (): MenuItem[] => {
  const user = useSelector((state) => state.app.user, objectEqual)
  const ls = useLocalizedString()
  if (!user) {
    return []
  }

  const userRole = user.role.value as StaffRole

  let routes: MenuItem[] = [
    {
      value: '/home',
      title: ls.text.home,
      children: <StatisticPage />,
      icon: 'Home',
      description: ls.text.home,
    },
    {
      value: '/dialogs',
      title: ls.text.messages,
      children: <Pages.Dialogs />,
      icon: 'MessageCircle',
      description: ls.text.chatWithClients,
    },
    {
      value: '/orders',
      title: ls.text.orders1,
      children: <Pages.Orders />,
      icon: 'ShoppingCart',
      description: ls.text.listAndQuickControl,
    },
    {
      value: '/purchases',
      title: ls.text.purchases,
      children: <Pages.Purchases />,
      icon: 'Cube',
      description: ls.text.purchasesList,
    },
    {
      value: '/logistics',
      title: ls.text.logistic,
      children: <Pages.Logistics />,
      icon: 'Navigation2',
      description: ls.text.ordersTracking,
    },
    {
      value: '/quality',
      title: ls.text.qualityControl,
      children: <Pages.Quality />,
      icon: 'Camera',
      description: ls.text.photoReport,
    },
    {
      value: '/products',
      title: ls.text.items,
      children: <Pages.Products />,
      icon: 'ShoppingBag',
      description: ls.text.listOfProducts,
    },
    {
      value: '/clients',
      title: ls.text.clients,
      children: <Pages.Clients />,
      icon: 'PersonDone',
      description: ls.text.clientsAndContacts,
    },
    { value: '/staff', title: ls.text.staff, children: <Pages.Staff />, icon: 'People' },
    {
      value: '/reports',
      title: ls.text.reports,
      children: <Pages.Reports />,
      icon: 'BookOpen',
    },
    {
      value: '/settings',
      title: ls.text.settings,
      children: <Pages.Settings />,
      icon: 'Settings2',
    },
    {
      value: '/variables',
      title: ls.text.configs,
      children: <Pages.Variables />,
      icon: 'Settings',
    },
    {
      value: '/journal',
      title: ls.text.journal,
      children: <Pages.Journal />,
      icon: 'Calendar',
    },
  ]
  if ([StaffRole.SUPERUSER].includes(userRole)) {
    routes = routes.filter((r) => !['/purchases'].includes(r.value))
  }
  if (![StaffRole.SUPERUSER].includes(userRole)) {
    routes = routes.filter((r) => !['/variables', '/journal'].includes(r.value))
  }
  if (![StaffRole.SUPERUSER, StaffRole.ADMIN].includes(userRole)) {
    routes = routes.filter((r) => !['/staff', '/products', '/settings'].includes(r.value))
  }
  if ([StaffRole.PURCHASE, StaffRole.COURIER, StaffRole.WAREHOUSE].includes(userRole)) {
    routes = routes.filter((r) => ['/home', '/dialogs'].includes(r.value))
  }
  if ([StaffRole.MAIN_PURCHASE].includes(userRole)) {
    routes = routes.filter((r) => ['/home', '/dialogs', '/purchases'].includes(r.value))
  }
  return routes
}

export const scrollViewRef = createRef<ScrollViewTypes.Ref>()

function MainView() {
  const user = useSelector((state) => state.app.user, objectEqual)
  const privateRoutes = usePrivateRoutes()
  const location = useLocation()
  history = useHistory()

  useEffect(() => {
    scrollViewRef.current?.scrollTop()
  }, [location.pathname])

  return (
    <Flexbox h="100%">
      <Sidebar />
      <Block flexBasis="100%" h="100%" style={{ boxSizing: 'border-box' }}>
        <ScrollView ref={scrollViewRef} size="s" barOffset={4} xBarPosition="none">
          {privateRoutes.map((r) => (
            <PrivateRoute key={r.value} path={r.value}>
              {(r as any).children}
            </PrivateRoute>
          ))}
          {!user && <Redirect to="/" />}
        </ScrollView>
      </Block>
    </Flexbox>
  )
}

export default MainView
