import React, { useState } from 'react'

import { Staff, StaffInput } from '@foods-n-goods/server/generated/schema'
import { Block, Divider, Flexbox, ScrollView, Text } from '@stage-ui/core'
import useSelector from 'hooks/useSelector'
import { Done } from '@stage-ui/icons'
import { objectEqual } from 'utils/objectEqual'
import FilterCommon from 'components/common/FilterCommon'
import declOfNum from 'utils/declOfNum'
import { useLocalizedString } from 'hooks/useLocalizedString'

type StaffExternalSourceUpdateProps = {
  value?: Staff['exId'] | null
  onChange: KeyValueChangeFunction<StaffInput>
}

export function StaffExternalSourceUpdate(props: StaffExternalSourceUpdateProps) {
  const { value, onChange } = props
  const ls = useLocalizedString()
  const [search, setSearch] = useState('')

  const individualsData = useSelector(
    (state) => state.app.marketIndividuals || [],
    objectEqual,
  )
  const individuals = individualsData
    .filter((i) => {
      if (search) {
        return `${i.category} ${i.fullName}`.toUpperCase().match(search.toUpperCase())
      }
      return true
    })
    .slice()
    .sort((a, b) => {
      if (a.category.trim().toLowerCase() === b.category.trim().toLowerCase()) {
        return a.fullName.toLowerCase() > b.fullName.toLowerCase() ? 1 : -1
      }
      return a.category.toLowerCase() > b.category.toLowerCase() ? 1 : -1
    })

  return (
    <Flexbox column>
      <Flexbox alignItems="center" justifyContent="space-between">
        <FilterCommon onSearch={(v) => setSearch(v)} />
        <Text color="gray500" size="s">{`${individuals.length} ${declOfNum(
          individuals.length,
          [ls.text.record1, ls.text.record2, ls.text.record3],
        )}`}</Text>
      </Flexbox>
      <Block
        mt="m"
        decoration="surface"
        borderRadius="m"
        borderColor="gray200"
        h="30vh"
        style={{
          overflow: 'hidden',
        }}
      >
        <ScrollView xBarPosition="none" size="xs" barOffset={4}>
          {individuals.map((individual, index) => (
            <React.Fragment key={individual.exId}>
              <Flexbox
                p="s m"
                backgroundColor={individual.exId === value ? 'green50' : 'transparent'}
                onClick={() => {
                  onChange('exId', individual.exId)
                }}
                alignItems="center"
                justifyContent="space-between"
              >
                <Flexbox alignItems="center">
                  <Text mr="s" size="s" color="gray900" weight={600}>
                    {`[${individual.category}] ${individual.fullName}`}
                  </Text>
                </Flexbox>
                {individual.exId === value && <Done color="primary" size="1.25rem" />}
              </Flexbox>
              {individuals.length !== index + 1 && (
                <Divider h="0.0625rem" color="gray200" />
              )}
            </React.Fragment>
          ))}
        </ScrollView>
      </Block>
    </Flexbox>
  )
}
