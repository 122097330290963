import React, { useEffect } from 'react'

import { Client } from '@foods-n-goods/server/generated/schema'
import { Block, Flexbox, Spinner, Text } from '@stage-ui/core'
import { ClientActions } from 'actions'
import useSelector from 'hooks/useSelector'
import { useParams } from 'react-router-dom'

import { objectEqual } from 'utils/objectEqual'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { ClientDetailsToolBar } from './components/Toolbar'
import { ClientGeneralInformation } from './GeneralInformation'
import { ClientHistory } from './History'

interface ClientDetailsProps {
  onClose?: () => void
  id?: Client['id']
}

export function ClientDetails(props: ClientDetailsProps) {
  const { id, onClose } = props
  const params: { id: string } = useParams()
  const ls = useLocalizedString()
  const filter = useSelector((state) => state.client.filter, objectEqual)
  const currentlyViewedClient = useSelector(
    ({ client }) => client.currentlyViewed,
    objectEqual,
  )

  const clientId = id || params.id

  const prefetch = () => {
    if (!clientId || !!currentlyViewedClient) return
    ClientActions.fetchById(clientId)
  }

  useEffect(() => {
    prefetch()
    return () => {
      ClientActions.clearCurrentlyViewed()
      ClientActions.clearDetailsFilter()
    }
  }, [])

  if (!currentlyViewedClient) {
    return (
      <Flexbox centered p="xl">
        <Spinner mr="s" />
        <Text color="gray500" size="s">
          {ls.text.clientCardLoading}
        </Text>
      </Flexbox>
    )
  }

  return (
    <Block mb="xl">
      <ClientDetailsToolBar client={currentlyViewedClient} isModal={!!onClose} />
      {!filter.detailsSection && (
        <ClientGeneralInformation client={currentlyViewedClient} isModal={!!onClose} />
      )}
      {filter.detailsSection === 'history' && <ClientHistory isModal={!!onClose} />}
    </Block>
  )
}
