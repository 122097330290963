import React, { useState } from 'react'

import { DeliveryIntervalData } from '@foods-n-goods/server/generated/schema'
import { Button, modal, Flexbox, Grid, TextField } from '@stage-ui/core'
import { Trash2 } from '@stage-ui/icons'
import { DictionariesActions } from 'actions'
import { getLocalizedString, useLocalizedString } from 'hooks/useLocalizedString'

function Form(props: { item?: DeliveryIntervalData; close: () => void }) {
  const { item, close } = props
  const ls = useLocalizedString()
  const [payload, setPayload] = useState<string>(item?.interval || '')

  const onSave = async () => {
    if (item) {
      DictionariesActions.updateDeliveryInterval(
        { id: item.id, interval: payload },
        close,
      )
    } else {
      DictionariesActions.createDeliveryInterval({ interval: payload }, close)
    }
  }

  const onDelete = () => {
    if (item) {
      DictionariesActions.deleteDeliveryInterval(item, close)
    }
  }

  return (
    <Flexbox column>
      <Grid gap="1rem" templateColumns="1fr 1fr">
        <TextField
          value={payload || ''}
          placeholder="10:00 - 11:00"
          onChange={(e) => setPayload(e.target.value)}
          label={ls.text.interval}
        />
      </Grid>
      <Flexbox mt="1rem" justifyContent="space-between">
        <Button
          decoration="text"
          color="red500"
          children={ls.text.delete}
          rightChild={<Trash2 />}
          disabled={!item}
          onClick={onDelete}
        />
        <Flexbox justifyContent="flex-end">
          <Button
            decoration="text"
            color="gray500"
            children={ls.text.cancel}
            onClick={close}
          />
          <Button ml="0.25rem" children={ls.text.save} onClick={onSave} />
        </Flexbox>
      </Flexbox>
    </Flexbox>
  )
}

export const createDeliveryIntervalDataDialog = () => {
  modal({
    title: getLocalizedString().text.deliveryInterval,
    render: (close) => <Form close={close} />,
    overlayClose: false,
    style: {
      window: {
        width: '25rem',
        padding: '1rem',
      },
    },
  })
}

export const updateDeliveryIntervalDataDialog = (item: DeliveryIntervalData) => {
  modal({
    title: getLocalizedString().text.deliveryInterval,
    render: (close) => <Form item={item} close={close} />,
    overlayClose: false,
    style: {
      window: {
        width: '25rem',
        padding: '1rem',
      },
    },
  })
}
