/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react'

import { Report } from '@foods-n-goods/server/generated/schema'
import { Button } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { MoreVertical } from '@stage-ui/icons'
import { MouseDropMenuItem, useMouseDropMenu } from 'components/MouseMenuDrop'
import { useLocalizedString } from 'hooks/useLocalizedString'

export function CellContext(ctx: TableTypes.TableCellContext<Report>) {
  const { row } = ctx
  const { id } = row
  const ls = useLocalizedString()
  const result = JSON.parse(row.result || '{}')
  const menuItems: MouseDropMenuItem[] = []

  if (result.xlsx) {
    menuItems.push({ text: ls.text.exportToExcel, value: 'xlsx', icon: 'CloudDownload' })
  }
  if (result.pdf) {
    menuItems.push({ text: 'Pdf', value: 'pdf', icon: 'CloudDownload' })
  }

  const productContextMenu = useMouseDropMenu<HTMLButtonElement>(
    menuItems,
    ({ value }) => {
      window.open(
        // @ts-ignore
        `${document.location.origin.replace('4000', '3000')}/${result[value]}`,
        '_blank',
      )
    },
  )

  return (
    <Button
      id={`report_row_action_${id}`}
      disabled={!row.result}
      decoration="text"
      color="gray500"
      p={0}
      w="2rem"
      h="2rem"
      {...productContextMenu}
    >
      <MoreVertical size="1.5rem" />
    </Button>
  )
}
