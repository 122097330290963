import React from 'react'

import { OrderStatusCode } from '@foods-n-goods/client/system/types'
import { Flexbox } from '@stage-ui/core'
import { StatusIndicator } from 'components/StatusIndicator'
import useSelector from 'hooks/useSelector'
import { useLocalizedString } from 'hooks/useLocalizedString'

export function PurchaseCount() {
  const counter = useSelector(
    (state) => state.purchases.statistics,
    (p, n) => p.inRepurchase === n.inRepurchase && p.failed === n.failed,
  )
  const ls = useLocalizedString()
  return (
    <Flexbox alignItems="center">
      {!!counter.inRepurchase && (
        <StatusIndicator
          text={String(counter.inRepurchase)}
          status={{
            text: String(counter.inRepurchase),
            value: OrderStatusCode.OUT_OF_STOCK,
          }}
          hint={ls.text.productsOutOfStock}
          ml="xs"
          p="0 s"
          h="1.25rem"
          type="fill"
        />
      )}
      {!!counter.failed && (
        <StatusIndicator
          text={String(counter.failed)}
          status={{
            text: String(counter.failed),
            value: OrderStatusCode.PURCHASE_FAILED,
          }}
          hint={ls.text.productsPurchaseFailed}
          ml="xs"
          p="0 s"
          h="1.25rem"
          type="fill"
        />
      )}
    </Flexbox>
  )
}
