import React from 'react'

import { Client } from '@foods-n-goods/server/generated/schema'
import { Flexbox, Text } from '@stage-ui/core'
import { QuestionMark } from '@stage-ui/icons'
import { useLocalizedString } from 'hooks/useLocalizedString'

type RatingExplanationProps = {
  rating: Client['rating']
}

export function RatingExplanation(props: RatingExplanationProps) {
  const { rating } = props
  const ls = useLocalizedString()
  if (!rating) {
    return (
      <Flexbox alignItems="center">
        <Flexbox
          w="2rem"
          h="2rem"
          borderRadius="50%"
          backgroundColor="red100"
          mr="s"
          justifyContent="center"
          alignItems="center"
        >
          <QuestionMark size="l" color="red500" />
        </Flexbox>

        <Text size="s" color="gray900" weight={600}>
          {ls.text.noRating}
        </Text>
      </Flexbox>
    )
  }

  if (rating < 0.5) {
    return (
      <Flexbox alignItems="center">
        <Flexbox centered w="2.5rem" h="2.5rem" borderRadius="50%" mr="m">
          <svg
            width="38"
            height="38"
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="38" height="38" rx="19" fill="#FEE2E2" />
            <path
              d="M18.9927 11.0834C14.6227 11.0834 11.084 14.63 11.084 19C11.084 23.37 14.6227 26.9167 18.9927 26.9167C23.3707 26.9167 26.9173 23.37 26.9173 19C26.9173 14.63 23.3707 11.0834 18.9927 11.0834ZM19.0007 25.3334C15.5015 25.3334 12.6673 22.4992 12.6673 19C12.6673 15.5009 15.5015 12.6667 19.0007 12.6667C22.4998 12.6667 25.334 15.5009 25.334 19C25.334 22.4992 22.4998 25.3334 19.0007 25.3334ZM21.7715 18.2084C22.4286 18.2084 22.959 17.678 22.959 17.0209C22.959 16.3638 22.4286 15.8334 21.7715 15.8334C21.1144 15.8334 20.584 16.3638 20.584 17.0209C20.584 17.678 21.1144 18.2084 21.7715 18.2084ZM16.2298 18.2084C16.8869 18.2084 17.4173 17.678 17.4173 17.0209C17.4173 16.3638 16.8869 15.8334 16.2298 15.8334C15.5727 15.8334 15.0423 16.3638 15.0423 17.0209C15.0423 17.678 15.5727 18.2084 16.2298 18.2084Z"
              fill="#EF4444"
            />
            <path
              d="M18.9995 20.1875C17.1549 20.1875 15.5874 21.1782 14.9541 22.5625H23.0449C22.4116 21.1782 20.8441 20.1875 18.9995 20.1875Z"
              fill="#EF4444"
            />
            <path
              d="M14.9541 22.5625C15.5874 21.1782 17.1549 20.1875 18.9995 20.1875C20.8441 20.1875 22.4116 21.1782 23.0449 22.5625H14.9541Z"
              fill="#EF4444"
            />
          </svg>
        </Flexbox>
        <Flexbox column>
          <Text size="s" color="gray900" weight={600}>
            {ls.text.ratingLow}
          </Text>
          <Text size="s" color="gray500" weight={400}>
            {ls.text.badClient}
          </Text>
        </Flexbox>
      </Flexbox>
    )
  }

  return (
    <Flexbox alignItems="center">
      <Flexbox centered w="2.5rem" h="2.5rem" borderRadius="50%" mr="m">
        <svg
          width="38"
          height="38"
          viewBox="0 0 38 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="38" height="38" rx="19" fill="#F1F8E9" />
          <path
            d="M18.9927 11.0834C14.6227 11.0834 11.084 14.63 11.084 19C11.084 23.37 14.6227 26.9167 18.9927 26.9167C23.3707 26.9167 26.9173 23.37 26.9173 19C26.9173 14.63 23.3707 11.0834 18.9927 11.0834ZM19.0007 25.3334C15.5015 25.3334 12.6673 22.4992 12.6673 19C12.6673 15.5009 15.5015 12.6667 19.0007 12.6667C22.4998 12.6667 25.334 15.5009 25.334 19C25.334 22.4992 22.4998 25.3334 19.0007 25.3334ZM21.7715 18.2084C22.4286 18.2084 22.959 17.678 22.959 17.0209C22.959 16.3638 22.4286 15.8334 21.7715 15.8334C21.1144 15.8334 20.584 16.3638 20.584 17.0209C20.584 17.678 21.1144 18.2084 21.7715 18.2084ZM16.2298 18.2084C16.8869 18.2084 17.4173 17.678 17.4173 17.0209C17.4173 16.3638 16.8869 15.8334 16.2298 15.8334C15.5727 15.8334 15.0423 16.3638 15.0423 17.0209C15.0423 17.678 15.5727 18.2084 16.2298 18.2084ZM19.0007 23.3542C20.8452 23.3542 22.4127 22.1984 23.0461 20.5834H14.9552C15.5886 22.1984 17.1561 23.3542 19.0007 23.3542Z"
            fill="#10B981"
          />
        </svg>
      </Flexbox>
      <Flexbox column>
        <Text size="s" color="gray900" weight={600}>
          {ls.text.ratingHigh}
        </Text>
        <Text size="s" color="gray500" weight={400}>
          {ls.text.goodClient}
        </Text>
      </Flexbox>
    </Flexbox>
  )
}
