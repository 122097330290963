import React from 'react'

import { Container } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { Edit } from '@stage-ui/icons'

import { getLocalizedString } from 'hooks/useLocalizedString'

import { updateContainerDialog } from './form'

export const getColumns = (): TableTypes.TableColumn<Container>[] => {
  const ls = getLocalizedString()
  return [
    { key: 'id', title: 'ID', width: '4rem' },
    { key: 'name', width: 200, title: ls.text.name },
    {
      key: '_',
      title: '',
      render: (ctx) => (
        <Flexbox justifyContent="flex-end">
          <Button
            ml="s"
            label={ls.text.change}
            decoration="plain"
            size="s"
            onClick={() => {
              updateContainerDialog(ctx.row)
            }}
            rightChild={<Edit />}
          />
        </Flexbox>
      ),
    },
  ]
}
