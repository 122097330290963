import React, { createRef, FC, useState } from 'react'

import { ChatTypes } from '@foods-n-goods/server/src/socket/chat/types'
import { Button, Flexbox, Text, TextField, useTheme } from '@stage-ui/core'
import TextFieldTypes from '@stage-ui/core/control/TextField/types'
import { Attach2, CloseCircle, PaperPlane } from '@stage-ui/icons'
import { useMouseDropMenu } from 'components/MouseMenuDrop'
import { useLocalizedString } from 'hooks/useLocalizedString'

type MessageInputProps = {
  connected: boolean
  onTyping: (message: string) => void
  onSend: (message: string, file?: ChatTypes.File) => void
}

const MessageInput: FC<MessageInputProps> = (props) => {
  const { onSend, onTyping, connected } = props
  const { color } = useTheme()
  const [currentMessage, setCurrentMessage] = useState('')
  const ls = useLocalizedString()
  const inputRef = createRef<TextFieldTypes.Ref>()
  const [file, setFile] = useState<File | null>()
  const chatCommandsMenu = useMouseDropMenu<HTMLDivElement>(
    [
      {
        text: ls.text.alertMessage,
        value: '/alert',
        icon: 'AlertTriangle',
        color: 'red500',
      },
      {
        text: ls.text.systemMessage,
        value: '/system',
        icon: 'Settings',
      },
    ],
    (value) => {
      const cmd = currentMessage.split(' ')[0]
      if (cmd[0] === '/') {
        setCurrentMessage(`${value.value}${currentMessage.replace(cmd, '')}`)
      } else {
        setCurrentMessage(`${value.value} ${currentMessage}`)
      }
      inputRef.current?.input?.focus()
    },
    {
      drop: {
        align: 'top',
        justify: 'start',
      },
    },
  )
  const send = () => {
    if (!connected) return
    if (file) {
      const reader = new FileReader()
      reader.onload = function load() {
        setFile(null)
        onSend(currentMessage, {
          name: file.name,
          type: file.type,
          size: file.size,
          buffer: this.result as ArrayBuffer,
        })
        if (currentMessage.trim()) {
          setCurrentMessage('')
        }
      }
      reader.readAsArrayBuffer(file)
    } else {
      onSend(currentMessage)
      if (currentMessage.trim()) {
        setCurrentMessage('')
      }
    }
  }

  if (!connected) {
    return (
      <Flexbox
        centered
        p="m"
        backgroundColor="surface"
        style={{
          position: 'absolute',
          zIndex: 3,
          bottom: 0,
          left: 0,
          right: 0,
          borderTop: `0.0625rem solid ${color.gray[200].hex()}`,
        }}
      >
        <Text size="s" color="gray400">
          {ls.text.joinChatToAnswer}
        </Text>
      </Flexbox>
    )
  }

  return (
    <Flexbox
      alignItems="center"
      p="s"
      backgroundColor="surface"
      style={{
        position: 'absolute',
        zIndex: 3,
        bottom: 0,
        left: 0,
        right: 0,
        borderTop: `0.0625rem solid ${color.gray[200].hex()}`,
      }}
    >
      <Flexbox
        ml="xs"
        centered
        w="1.25rem"
        h="1.25rem"
        borderRadius="s"
        backgroundColor="gray50"
        borderColor="gray200"
        borderStyle="solid"
        borderWidth="0.0675rem"
        {...chatCommandsMenu}
      >
        <Text color="gray300">/</Text>
      </Flexbox>
      <TextField
        ref={inputRef}
        autoFocus
        flex={1}
        placeholder={ls.text.message}
        color="gray400"
        value={currentMessage}
        onChange={(e) => {
          if (e.target.value !== currentMessage) {
            onTyping(currentMessage.trim())
          }
          setCurrentMessage(e.target.value)
        }}
        onKeyUp={(e) => {
          if (e.key === 'Enter' && (currentMessage.trim() || file)) {
            send()
          }
        }}
        overrides={{
          field: {
            border: 'none',
            boxShadow: 'none',
          },
        }}
      />
      {file && (
        <>
          <Text
            size="xs"
            weight={500}
            color="surface"
            backgroundColor="info"
            mr="0.125rem"
            style={{
              maxWidth: '10rem',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              borderRadius: '0.25rem',
              padding: '0.25rem 0.375rem',
            }}
          >
            {file.name}
          </Text>
          <CloseCircle
            size="1.5rem"
            type="filled"
            color="light"
            mr="s"
            onClick={() => setFile(null)}
          />
        </>
      )}
      <Button
        px="xs"
        style={{
          overflow: 'hidden',
          position: 'relative',
        }}
        decoration="text"
        color="gray400"
        label={
          <>
            <Attach2 size="xl" color="gray400" />
            <input
              type="file"
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                width: 999,
                height: 999,
                opacity: 0,
              }}
              onChange={(e) => {
                if (e.target.files) {
                  setFile(e.target.files?.[0])
                  e.target.value = ''
                }
              }}
            />
          </>
        }
      />
      <Button
        disabled={!currentMessage.trim() && !file}
        decoration="text"
        color="gray400"
        onClick={() => {
          if (currentMessage.trim() || file) {
            send()
          }
        }}
        label={<PaperPlane size="xl" color="gray400" />}
      />
    </Flexbox>
  )
}

export default MessageInput
