import React, { useMemo } from 'react'

import { Table } from '@stage-ui/core'
import { TableLoadingStab } from 'components/TableLoadingStab'
import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { getColumns } from './columns'

function ContainersDictionaryContent() {
  const { loading, data } = useSelector(
    (state) => ({
      loading: state.dictionaries.containersLoading,
      data: state.dictionaries.containersData,
    }),
    objectEqual,
  )
  const ls = useLocalizedString()
  const columns = useMemo(getColumns, [ls.id])
  return TableLoadingStab(loading) || <Table mb="1rem" columns={columns} data={data} />
}

export default ContainersDictionaryContent
