import React, { useEffect } from 'react'

import { JournalActions } from 'actions'
import LoadingData from 'components/LoadingData'
import { Section } from 'components/Section'
import useSelector from 'hooks/useSelector'
import { Route } from 'react-router-dom'

import { objectEqual } from 'utils/objectEqual'

import { useLocalizedString } from 'hooks/useLocalizedString'

import JournalTable from './Table'

export function Journal() {
  const dataResolved = useSelector(
    ({ journal: { loading } }) => loading === 'resolved',
    objectEqual,
  )
  const ls = useLocalizedString()
  useEffect(() => {
    if (!dataResolved) {
      JournalActions.getAll()
    }
  }, [])

  return (
    <Section title={ls.text.journal}>
      <LoadingData storeKey="journal">
        <Route exact path="/journal" component={JournalTable} />
      </LoadingData>
    </Section>
  )
}
