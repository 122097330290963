import React from 'react'

import TableTypes from '@stage-ui/core/data/Table/types'
import { Flexbox, Text } from '@stage-ui/core'
import { Order } from '@foods-n-goods/server/generated/schema'
import { useCurrency } from 'hooks/useCurrency'

export function OrderTotalCell(ctx: TableTypes.TableCellContext<Order>) {
  const { row } = ctx
  const { amount, amountAfter } = row
  const { valueFormatted } = useCurrency(amountAfter || amount)

  return (
    <Flexbox alignItems="center">
      <Text size="s" color="gray900">
        {valueFormatted}
      </Text>
    </Flexbox>
  )
}
