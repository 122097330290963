import React, { useEffect } from 'react'

import { ChatTypes } from '@foods-n-goods/server/src/socket/chat/types'
import { Flexbox, Paragraph, Text, useTheme } from '@stage-ui/core'
import { AllDone, Done, File } from '@stage-ui/icons'
import Avatar from 'components/Avatar'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import dayjs from '@foods-n-goods/client/utils/dayjs'

function bytesToSize(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}

type Props = {
  room: ChatTypes.Room
  message: ChatTypes.Message
  previousMessage?: ChatTypes.Message
  nextMessage?: ChatTypes.Message
  onRead: () => void
  userId: string
}

function Message(props: Props) {
  const { message, nextMessage, onRead, room, userId } = props
  const isMine = message.user?.type === 'STAFF' && userId === message.user?.id
  const isLast =
    message.user?.type !== nextMessage?.user?.type &&
    message.user?.id !== nextMessage?.user?.id
  const user = room.users.find(({ id }) => id === message.user?.id)

  const online = useSelector(
    (state) =>
      user?.type === 'STAFF' &&
      state.staff.data.some(({ id, online }) => id === user.id && !!online),
    objectEqual,
  )

  const theme = useTheme()

  const isImage = React.useMemo(() => {
    try {
      const filePath = message?.file?.url?.split('/') || []
      const fileExt = filePath[filePath.length - 1].split('.')[1]
      return !!['jpg', 'jpeg', 'png', 'gif'].includes(fileExt.toLowerCase())
    } catch (error) {
      return false
    }
  }, [message.file?.url])

  useEffect(() => {
    if (!isMine && !message.readTime) {
      onRead()
    }
  }, [])

  if (message.type === 1) {
    return (
      <Flexbox centered mt="m">
        <Flexbox
          centered
          backgroundColor="yellow100"
          borderRadius="0.25rem"
          p="0.125rem 0.75rem 0.25rem 0.75rem"
        >
          <Text size="s" color="yellow700">
            {message.msg}
          </Text>
        </Flexbox>
      </Flexbox>
    )
  }

  if (message.type === 2) {
    return (
      <Flexbox centered mt="m">
        <Flexbox
          column
          centered
          backgroundColor="red500"
          borderRadius="0.25rem"
          p="0.125rem 0.75rem 0.25rem 0.75rem"
        >
          <Text size="m" color="surface">
            {message.msg}
          </Text>
          {message.user && (
            <Text size="xs" weight={600} color="red100">
              {message.user.name}
            </Text>
          )}
        </Flexbox>
      </Flexbox>
    )
  }

  return (
    <Flexbox key={message.id} justifyContent={isMine ? 'flex-end' : 'flex-start'}>
      {!isMine && user && (
        <Flexbox ml="m" mt="m" mr="-0.5rem" alignItems="flex-start">
          <Avatar size="2rem" user={{ ...user, online }} />
        </Flexbox>
      )}
      <Flexbox
        p="1rem"
        pb="0.75rem"
        my="s"
        mx="m"
        column
        style={{
          maxWidth: '50%',
          wordBreak: 'break-all',
          backgroundColor: isMine
            ? theme.color.green[200].hex()
            : theme.color.surface.hex(),
          borderRadius: !isLast
            ? '0.75rem'
            : isMine
            ? '0.75rem 0.75rem 0 0.75rem'
            : '0.75rem 0.75rem 0.75rem 0',
          filter: isMine ? 'none' : 'drop-shadow(0 0 1px rgba(0,0,0,0.2))',
          ':after': {
            display: isLast ? 'block' : 'none',
            content: '""',
            width: 0,
            height: 0,
            position: 'absolute',
            right: isMine ? 0 : 'auto',
            left: !isMine ? 0 : 'auto',
            bottom: '-1rem',
            borderStyle: 'solid',
            borderWidth: isMine ? `0 1.25rem 1rem 0` : `1rem 1.25rem 0 0`,
            borderColor: isMine
              ? `transparent ${theme.color.green[200].hex()} transparent transparent`
              : `${theme.color.surface.hex()} transparent transparent transparent`,
          },
        }}
      >
        {!isMine && (
          <Text size="s" weight={600} mb="xs" color={message.user?.color || '#888'}>
            {message.user?.name}
          </Text>
        )}
        {!isImage && message.file && (
          <Flexbox
            p="s m"
            borderRadius="0.25rem"
            mb="0.5rem"
            backgroundColor={isMine ? 'green100' : 'gray100'}
            onClick={() => {
              window.open(CFG_HOST + message.file?.url, '_blank')
            }}
          >
            <File size="2rem" color="gray700" mr="s" />
            <Flexbox column>
              <Text size="xs" mb="xs" color="gray900">
                {message.file.name}
              </Text>
              <Text size="xs" color="lightGreen700">
                {bytesToSize(message.file.size)}
              </Text>
            </Flexbox>
          </Flexbox>
        )}
        {isImage && message.file && (
          <Flexbox
            shadow="xs"
            mb="0.5rem"
            borderRadius="0.375rem"
            style={{ overflow: 'hidden' }}
            backgroundColor={isMine ? 'green100' : 'gray100'}
            onClick={() => {
              window.open(CFG_HOST + message.file?.url, '_blank')
            }}
          >
            <img height={200} src={CFG_HOST + message.file?.url} />
          </Flexbox>
        )}
        <Paragraph m={0} color="gray900" weight={500} size="s">
          {message.msg}
        </Paragraph>
        <Flexbox justifyContent="flex-end" alignItems="center">
          <Paragraph m={0} size="xs" color="gray600">
            {dayjs(message.dateTime).format('HH:mm')}
          </Paragraph>
          {isMine &&
            (message.readTime ? (
              <AllDone size="xl" ml="xs" color="green600" />
            ) : (
              <Done size="xl" ml="xs" color="green500" />
            ))}
        </Flexbox>
      </Flexbox>
    </Flexbox>
  )
}
export default Message
