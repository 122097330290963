import {
  Client,
  MutationBroadcastPushMessageArgs,
  MutationClientMarketUpdateArgs,
  MutationClientUpdateArgs,
  MutationSendPushMessageArgs,
  QueryClientsArgs,
  QueryOrdersArgs,
} from '@foods-n-goods/server/generated/schema'
import { getLocalizedString } from 'hooks/useLocalizedString'
import request from 'requests/request'
import { ClientStore } from 'store'
import { ClientFilter } from 'store/client'
import actionNotify from 'utils/actionNotify'

export default {
  async fetch(args: QueryClientsArgs) {
    try {
      ClientStore.loading()
      ClientStore.resolve(await request('clients', args))
    } catch (error) {
      ClientStore.reject(error)
    }
  },

  async fetchById(clientId: Client['id'], onSuccess?: (id: Client['id']) => void) {
    const ls = getLocalizedString()
    try {
      const res = await request('clients', { filter: { clientId } })
      if (res.records.length !== 1) {
        throw new Error('Too many clients found!')
      }
      ClientStore.setCurrentlyViewed(res.records[0])
      onSuccess?.(clientId)
    } catch (error) {
      actionNotify({
        title: ls.text.client.replace('[1]', clientId),
        message: ls.text.clientErrorNotFound,
        type: 'error',
      })
    }
  },

  clearCurrentlyViewed() {
    ClientStore.setCurrentlyViewed(null)
    ClientStore.setClientOrders(null)
  },

  async broadcastPushMessage(
    args: MutationBroadcastPushMessageArgs & { cb?: () => void },
  ) {
    const { cb, ...rest } = args
    try {
      await request('broadcastPushMessage', rest)
    } catch (error) {
      actionNotify({
        title: getLocalizedString().text.error,
        message: error.message || error,
        type: 'error',
      })
    }
  },

  async sendPushMessage(args: MutationSendPushMessageArgs & { cb?: () => void }) {
    const { cb, ...rest } = args

    try {
      await request('sendPushMessage', rest)
      cb?.()
    } catch (error) {
      actionNotify({
        title: getLocalizedString().text.error,
        message: error.message || error,
        type: 'error',
      })
    }
  },

  async update(args: MutationClientUpdateArgs) {
    try {
      await request('clientUpdate', args)
      await this.fetchById(args.id)
    } catch (error) {
      actionNotify({
        title: getLocalizedString().text.error,
        message: error.message || error,
        type: 'error',
      })
    }
  },

  async marketUpdate(args: MutationClientMarketUpdateArgs) {
    try {
      await request('clientMarketUpdate', args)
      await this.fetchById(args.id)
    } catch (error) {
      actionNotify({
        title: getLocalizedString().text.error,
        message: error.message || error,
        type: 'error',
      })
    }
  },

  async changeReferal(clientId: string, referalWord: string) {
    try {
      const response = await request('changeClientReferal', {
        clientId,
        referalWord,
      })
      ClientStore.actionUpdateResolve(response)
    } catch (error) {
      actionNotify({
        title: getLocalizedString().text.error,
        message: error.message || error,
        type: 'error',
      })
    }
  },

  async loginOnClientBehalf(clientId: string) {
    try {
      const url = await request('b2bGetUserAuthorizedUrl', { clientId })
      window.open(url, '_blank')?.focus()
    } catch (error) {
      actionNotify({
        title: getLocalizedString().text.error,
        message: error.message || error,
        type: 'error',
      })
    }
  },

  async fetchClientOrders(args: QueryOrdersArgs) {
    try {
      ClientStore.loading()
      ClientStore.setClientOrders(await request('orders', args))
    } catch (error) {
      ClientStore.reject(error)
    }
  },

  async fetchClientAvarageCheck(clientId: string) {
    try {
      ClientStore.clientAvarageCheck(clientId)
      ClientStore.clientAvarageCheckResolve(
        await request('clientAverageCheck', {
          clientId,
        }),
      )
    } catch (error) {
      ClientStore.clientAvarageCheckReject(error)
    }
  },

  setFilter(filter: Partial<ClientFilter>) {
    ClientStore.setFilter(filter)
  },

  clearDetailsFilter() {
    ClientStore.clearDetailsFilter()
  },

  clearFilter() {
    ClientStore.clearFilter()
  },
}
