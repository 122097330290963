import React from 'react'

import { Order } from '@foods-n-goods/server/generated/schema'
import { Button } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { MoreVertical } from '@stage-ui/icons'
import { useMouseDropMenu } from 'components/MouseMenuDrop'
import { useHistory, useLocation } from 'react-router-dom'
import { openDevicePrinterModal } from 'components/Modals/DevicePrinterSelect'
import { OrderActions } from 'actions'
import { useLocalizedString } from 'hooks/useLocalizedString'

export function OrderListTableContextMenuCell(ctx: TableTypes.TableCellContext<Order>) {
  const history = useHistory()
  const location = useLocation()
  const params = location.search
  const ls = useLocalizedString()
  const { row } = ctx

  const contextMenu = useMouseDropMenu<HTMLButtonElement>(
    [
      { text: ls.text.orderShow, icon: 'ExternalLink', value: 'OPEN' },
      { text: ls.text.printEAC, icon: 'Printer', value: 'PRINT_EAC' },
    ],
    ({ value }) => {
      if (value === 'OPEN') {
        history.push(`/orders/${row.id}${params}`)
      }
      if (value === 'PRINT_EAC') {
        openDevicePrinterModal((deviceId) => {
          OrderActions.printInfo({
            deviceId,
            orderIds: [row.id],
          })
        })
      }
    },
  )

  return (
    <Button
      id={`order_row_action_${row.id}`}
      decoration="text"
      color="gray500"
      p={0}
      w="2rem"
      h="2rem"
      {...contextMenu}
    >
      <MoreVertical size="1.5rem" />
    </Button>
  )
}
