import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import utc from 'dayjs/plugin/utc'

const plugins = [relativeTime, duration, isBetween, utc]

plugins.forEach((plugin) => dayjs.extend(plugin))

export default dayjs
