import React from 'react'

import { OrderAddProductCustom } from '.'

import wordForNum from '@foods-n-goods/client/utils/wordForNum'
import { Grid, Text } from '@stage-ui/core'
import { useLocalizedString } from 'hooks/useLocalizedString'
import { useCurrency } from 'hooks/useCurrency'

type OrderAddPositionsSubtotalProps = {
  addProducts: OrderAddProductCustom[]
}

export const OrderAddPositionsSubtotal: React.FC<OrderAddPositionsSubtotalProps> = (
  props,
) => {
  const { addProducts } = props
  const ls = useLocalizedString()
  const total = addProducts.reduce((a, b) => {
    const avgWeight =
      b.availableUnits.find((au) => au.value === b.unit.value)?.weight || 1
    const price = parseFloat(b.price) * parseFloat(b.quantity || '0') * avgWeight

    return (a += price)
  }, 0)
  const { valueFormatted } = useCurrency(total)

  return (
    <Grid templateColumns="9rem 1fr" gap="0.25rem 1rem">
      <Text gridColumn="1" size="s" color="gray600">
        {ls.text.totalToAdd}
      </Text>
      <Text gridColumn="2" size="s" color="gray900" weight={700}>
        {`${addProducts.length} ${wordForNum(addProducts.length, [
          ls.text.product2.toLowerCase(),
          ls.text.product3,
          ls.text.product4,
        ])}`}
      </Text>
      <Text gridColumn="1" size="s" color="gray600">
        {ls.text.totalAmount}:
      </Text>
      <Text gridColumn="2" size="s" color="gray900" weight={700}>
        {valueFormatted}
      </Text>
    </Grid>
  )
}
