import {
  Product,
  ProductInput,
  QueryProductsArgs,
} from '@foods-n-goods/server/generated/schema'
import { getLocalizedString } from 'hooks/useLocalizedString'
import request from 'requests/request'
import store, { ProductStore } from 'store'
import { ProductFilter } from 'store/product'
import actionNotify from 'utils/actionNotify'

export default {
  async fetch(args: QueryProductsArgs = {}) {
    try {
      ProductStore.loading()
      const res = await request('products', args)
      ProductStore.resolve(res)
    } catch (error) {
      ProductStore.reject(error)
    }
  },

  async search(args?: QueryProductsArgs) {
    try {
      const products = await request('products', args)
      ProductStore.resolve(products)
    } catch (error) {
      ProductStore.reject(error)
    }
  },

  async getById(productId: Product['id'], onSuccess?: (id: Product['id']) => void) {
    const ls = getLocalizedString()
    try {
      const product = store
        .getState()
        .product.data.records.find((product) => product.id === productId)
      if (!product) {
        const res = await request('products', { filter: { productId } })
        if (res.records.length !== 1) throw new Error('Too many products found!')
        ProductStore.findResolve(res)
      }
      onSuccess?.(productId)
    } catch (error) {
      actionNotify({
        title: ls.text.product.replace('[1]', productId),
        message: ls.text.productErrorNotFound,
        type: 'error',
      })
    }
  },

  async create(payload: ProductInput, _cb?: () => void) {
    const ls = getLocalizedString()
    try {
      const response = await request('productCreate', { payload })
      ProductStore.actionCreateResolve(response)

      actionNotify({
        title: response.name || '',
        message: ls.text.productCreateSuccess,
        type: 'success',
      })

      _cb?.()
    } catch (error) {
      actionNotify({
        title: ls.text.productCreateError,
        message: `${error.message}`,
        type: 'error',
      })
    }
  },

  async delete(id: string) {
    const ls = getLocalizedString()
    try {
      const response = await request('productDelete', { id })
      ProductStore.actionDeleteResolve(response)

      actionNotify({
        title: response.name || '',
        message: ls.text.productDeleteSuccess,
        image: response.image,
        type: 'success',
      })
    } catch (error) {
      actionNotify({
        title: ls.text.productDeleteError,
        message: `${error.message}`,
        type: 'error',
      })
    }
  },

  async update(id: string, payload: ProductInput, _cb: () => void) {
    const ls = getLocalizedString()
    try {
      const response = await request('productUpdate', { id, payload })
      ProductStore.actionUpdateResolve(response)

      actionNotify({
        title: response.name || '',
        message: ls.text.productUpdateSuccess,
        image: response.image,
        type: 'success',
      })

      _cb()
    } catch (error) {
      actionNotify({
        title: ls.text.productUpdateError,
        message: `${error.message}`,
        type: 'error',
      })
    }
  },

  async imageUpdate(id: string, payload: string) {
    try {
      const response = await request('productImage', { id, payload })
      ProductStore.actionUpdateResolve(response)
    } catch (error) {
      actionNotify({
        title: getLocalizedString().text.productImageUpdateError,
        message: `${error.message}`,
        type: 'error',
      })
    }
  },

  setFilter(filter: Partial<ProductFilter>) {
    ProductStore.setFilter(filter)
  },

  clearFilter() {
    ProductStore.clearFilter()
  },
}
