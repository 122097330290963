import React, { useMemo } from 'react'

import { Button, Flexbox, Grid, useTheme } from '@stage-ui/core'
import { Email } from '@stage-ui/icons'
import FilterCommon from 'components/common/FilterCommon'
import MenuNavigation from 'components/MenuNavigation'
import { useMouseDrop } from 'components/MouseDrop'

import { openClientPushMessageModal } from 'components/Modals/ClientPushMessage'

import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { ClientActions } from 'actions'

import { getLocalizedString, useLocalizedString } from 'hooks/useLocalizedString'

import { ClientListFilter } from './ClientListFilter'

export const getClientListMenuItems = () => {
  const ls = getLocalizedString()
  return [
    { id: '', title: ls.text.clientListAll },
    { id: '1', status: ['AWAITING'], title: ls.text.clientListNew },
    { id: '2', status: ['DECLINED', 'BLOCKED'], title: ls.text.clientListBlocked },
  ]
}

export function ClientListToolbar() {
  const theme = useTheme()
  const filter = useSelector((state) => state.client.filter, objectEqual)
  const ls = useLocalizedString()
  const clientListMenuItems = useMemo(getClientListMenuItems, [ls.id])
  const clientBroadCastTooltip = useMouseDrop(ls.text.sendMessageToAllClients, {
    drop: {
      w: '13rem',
      align: 'bottom',
      justify: 'end',
    },
    popover: {
      align: 'top',
      arrowOffset: '5.25rem',
    },
  })

  return (
    <Flexbox
      justifyContent="space-between"
      pb="m"
      style={{
        position: 'sticky',
        top: '6rem',
        zIndex: 7,
        backgroundColor: theme.color.gray[50].hex(),
      }}
    >
      <MenuNavigation
        items={clientListMenuItems}
        currentSection={filter.section}
        onChange={(section) => ClientActions.setFilter({ section })}
      />
      <Grid ml="m" autoFlow="column" gap="0 0.5rem" w="max-content">
        <ClientListFilter />
        <FilterCommon
          onSearch={(st) => ClientActions.setFilter({ page: 1, search: st })}
          overrides={{ width: '15rem' }}
        />
        <Button
          size="m"
          label={<Email size="l" />}
          color="yellow400"
          textColor="surface"
          onClick={() => openClientPushMessageModal()}
          w="2.5rem"
          h="2.5rem"
          p={0}
          {...clientBroadCastTooltip}
        />
      </Grid>
    </Flexbox>
  )
}
