import React from 'react'

import { Flexbox } from '@stage-ui/core'

import { Staff } from '@foods-n-goods/server/generated/schema'

import { StaffRole } from '@foods-n-goods/server/src/shared/enum'

import { openStaffUpdateModal } from 'components/Modals/StaffUpdate'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { StaffDetailsInfoRowProps } from '../components/InfoRow'
import { StaffDetailsInfoBlock } from '../components/InfoBlock'

import { StaffDetailsAdditionalWarehouses } from './Warehouses'

interface StaffDetailsAdditionalProps {
  staff: Staff
  modal: boolean
}

export function StaffDetailsAdditional(props: StaffDetailsAdditionalProps) {
  const { staff, modal } = props
  const ls = useLocalizedString()
  const courierInfoRows: StaffDetailsInfoRowProps[] = [
    {
      param: ls.text.carModel,
      value: staff.vehicleModel,
      onChange: () => openStaffUpdateModal(staff, 'vehicle', 'vehicleModel'),
    },
    {
      param: ls.text.carRegPlate,
      value: staff.vehicleRegPlate,
      onChange: () => openStaffUpdateModal(staff, 'vehicle', 'vehicleRegPlate'),
    },
    {
      param: ls.text.cargoCapacity,
      value: staff.vehicleLoadCapacity,
      onChange: () => openStaffUpdateModal(staff, 'vehicle', 'vehicleLoadCapacity'),
    },
    {
      param: ls.text.cargoVolume,
      value: staff.vehicleVolumeCapacity,
      onChange: () => openStaffUpdateModal(staff, 'vehicle', 'vehicleVolumeCapacity'),
    },
  ]

  const warehousesAvailable = [
    StaffRole.PURCHASE,
    StaffRole.WAREHOUSE,
    StaffRole.STOREKEEPER,
  ]

  return (
    <Flexbox
      w="22rem"
      h="100%"
      ml="m"
      column
      style={{
        position: modal ? 'relative' : 'sticky',
        top: modal ? '6.5rem' : '10.25rem',
      }}
    >
      {staff.role.value === StaffRole.COURIER && (
        <StaffDetailsInfoBlock
          block="vehicle"
          title={ls.text.car}
          rows={courierInfoRows}
        />
      )}
      {warehousesAvailable.includes(staff.role.value) && (
        <StaffDetailsAdditionalWarehouses staff={staff} />
      )}
    </Flexbox>
  )
}
