import React, { useCallback, useState } from 'react'

import { TextField } from '@stage-ui/core'
import { Search } from '@stage-ui/icons'

import { debounce } from '@foods-n-goods/client/utils/debounce'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { OrderActions } from 'actions'
import { useLocalizedString } from 'hooks/useLocalizedString'

export function OrderListFilter() {
  const search = useSelector((state) => state.order.filter.search, objectEqual)
  const [searchText, setSearchText] = useState(search ?? '')
  const ls = useLocalizedString()
  const historyPushDebounce = useCallback(
    debounce((st: string) => OrderActions.setFilter({ search: st }), 500),
    [],
  )

  return (
    <TextField
      justifySelf="flex-end"
      w="20rem"
      size="s"
      clearable
      value={searchText}
      onChange={(e) => {
        setSearchText(e.target.value)
        historyPushDebounce(e.target.value)
      }}
      leftChild={<Search color="gray500" size="1rem" />}
      placeholder={ls.text.search}
      overrides={{
        field: {
          transition: 'background .2s ease-in-out',
          background: searchText ? 'surface' : 'transparent',
          ':focus-within': {
            background: 'white',
          },
        },
      }}
    />
  )
}
