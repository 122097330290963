/* eslint-disable import/no-cycle */
import React from 'react'

import { OrderPositionsStat } from '@foods-n-goods/server/generated/schema'
import TableTypes from '@stage-ui/core/data/Table/types'
import {
  AlertCircle,
  CheckmarkCircle,
  Clock,
  CloseCircle,
  Cube,
  MinusCircle,
} from '@stage-ui/icons'
import IconTypes from '@stage-ui/icons/Icon/types'
import { useMouseDrop } from 'components/MouseDrop'

import { getLocalizedString } from 'hooks/useLocalizedString'

import { OrderedPosition } from '..'

export type PosStatus = {
  Icon: typeof Clock
  type: IconTypes.IconProps['type']
  color: IconTypes.IconProps['color']
  tooltip: string
  priority: number
}

export function posStatus(key: keyof OrderPositionsStat): PosStatus {
  const ls = getLocalizedString()
  const statusObject: PosStatus = {
    Icon: Clock,
    color: 'gray400',
    type: 'outline',
    tooltip: ls.text.toPicking,
    priority: 5,
  }

  const setStatus = (
    i: PosStatus['Icon'],
    type: PosStatus['type'],
    color: PosStatus['color'],
    tooltip: PosStatus['tooltip'],
    priority: PosStatus['priority'],
  ) => {
    statusObject.Icon = i
    statusObject.color = color
    statusObject.type = type
    statusObject.tooltip = tooltip
    statusObject.priority = priority
  }

  if (key === 'posDeleted') {
    setStatus(MinusCircle, 'filled', 'red500', ls.string[key], 8)
  }
  if (key === 'posPurchaseCanceled') {
    setStatus(Cube, 'filled', 'red500', ls.string[key], 7)
  }
  if (key === 'posSorting') {
    setStatus(Clock, 'filled', 'gray400', ls.string[key], 2)
  }
  if (key === 'posSorted') {
    setStatus(CheckmarkCircle, 'outline', 'green500', ls.string[key], 1)
  }
  if (key === 'posPurchase') {
    setStatus(Cube, 'outline', 'yellow500', ls.string[key], 3)
  }
  if (key === 'posPurchaseConfirmed') {
    setStatus(Cube, 'filled', 'yellow500', ls.string[key], 4)
  }
  if (key === 'posPurchaseFailed') {
    setStatus(Cube, 'outline', 'red500', ls.string[key], 6)
  }
  if (key === 'posRejected') {
    setStatus(CloseCircle, 'outline', 'red500', ls.string[key], 11)
  }
  if (key === 'posDelivered') {
    setStatus(CheckmarkCircle, 'filled', 'green500', ls.string[key], 9)
  }
  if (key === 'posDeliveredPartial') {
    setStatus(AlertCircle, 'outline', 'orange500', ls.string[key], 10)
  }

  return statusObject

  // if (key === 'posCanceled') {
  //   setStatus(MinusCircle, 'filled', 'red500', 'Поставка отменена')
  // }
}

export function OrderPositionStatusCell({
  row,
}: TableTypes.TableCellContext<OrderedPosition>) {
  const { Icon, color, tooltip, type } = posStatus(
    row.statKey as keyof OrderPositionsStat,
  )
  const mouseDrop = useMouseDrop(tooltip)

  return <Icon color={color} type={type} size="1.5rem" {...mouseDrop} />
}
