import {
  Container,
  DeliveryIntervalData,
  MutationContainerCreateArgs,
  MutationContainerUpdateArgs,
  MutationDeliveryIntervalCreateArgs,
  MutationDeliveryIntervalUpdateArgs,
} from '@foods-n-goods/server/generated/schema'
import { getLocalizedString } from 'hooks/useLocalizedString'
import request from 'requests/request'
import { AppStore, DictionariesStore } from 'store'
import actionNotify from 'utils/actionNotify'

export default {
  async getDeliveryIntervals() {
    try {
      DictionariesStore.deliveryIntervalsLoading()
      const deliveryIntervals = await request('deliveryIntervals')
      DictionariesStore.deliveryIntervalsResolve(deliveryIntervals)
    } catch (error) {
      DictionariesStore.deliveryIntervalsReject(error)
    }
  },

  async updateDeliveryInterval(
    deliveryInterval: MutationDeliveryIntervalUpdateArgs,
    _cb: () => void,
  ) {
    const ls = getLocalizedString()
    try {
      AppStore.pending(true)
      const response = await request('deliveryIntervalUpdate', deliveryInterval)
      DictionariesStore.deliveryIntervalsActionUpdateResolve(response)

      actionNotify({
        title: `«${response.interval}»`,
        message: ls.text.deliveryIntervalUpdateSuccess,
        type: 'success',
      })

      _cb()
    } catch (error) {
      actionNotify({
        title: ls.text.deliveryIntervalUpdateError,
        message: error.message || error,
        type: 'error',
      })
    } finally {
      AppStore.pending(false)
    }
  },

  async createDeliveryInterval(
    deliveryInterval: MutationDeliveryIntervalCreateArgs,
    _cb: () => void,
  ) {
    const ls = getLocalizedString()
    try {
      AppStore.pending(true)
      const response = await request('deliveryIntervalCreate', deliveryInterval)
      DictionariesStore.deliveryIntervalsActionCreateResolve(response)

      actionNotify({
        title: `«${response.interval}»`,
        message: ls.text.deliveryIntervalCreateSuccess,
        type: 'success',
      })
      _cb()
    } catch (error) {
      actionNotify({
        title: ls.text.deliveryIntervalCreateError,
        message: error.message || error,
        type: 'error',
      })
    } finally {
      AppStore.pending(false)
    }
  },

  async deleteDeliveryInterval(item: DeliveryIntervalData, _cb: () => void) {
    const ls = getLocalizedString()
    try {
      AppStore.pending(true)
      await request('deliveryIntervalDelete', { id: item.id })
      DictionariesStore.deliveryIntervalsDeleteResolve(item.id)
      actionNotify({
        title: ls.text.deliveryIntervalDelete,
        message: ls.text.deliveryIntervalDeleteSuccess,
        type: 'success',
      })
      _cb()
    } catch (error) {
      actionNotify({
        title: ls.text.deliveryIntervalDeleteError,
        message: error.message || error,
        type: 'error',
      })
    } finally {
      AppStore.pending(false)
    }
  },

  async getContainers() {
    try {
      DictionariesStore.containersLoading()
      const containers = await request('containers')
      DictionariesStore.containersResolve(containers)
    } catch (error) {
      DictionariesStore.containersReject(error)
    }
  },

  async updateContainer(container: MutationContainerUpdateArgs, _cb: () => void) {
    const ls = getLocalizedString()
    try {
      AppStore.pending(true)
      const response = await request('containerUpdate', container)
      DictionariesStore.containersActionUpdateResolve(response)

      actionNotify({
        title: `«${response.name}»`,
        message: ls.text.containerUpdateSuccess,
        type: 'success',
      })

      _cb()
    } catch (error) {
      actionNotify({
        title: ls.text.containerUpdateError,
        message: error.message || error,
        type: 'error',
      })
    } finally {
      AppStore.pending(false)
    }
  },

  async createContainer(container: MutationContainerCreateArgs, _cb: () => void) {
    const ls = getLocalizedString()
    try {
      AppStore.pending(true)
      const response = await request('containerCreate', container)
      DictionariesStore.containersActionCreateResolve(response)

      actionNotify({
        title: `«${response.name}»`,
        message: ls.text.containerCreateSuccess,
        type: 'success',
      })

      _cb()
    } catch (error) {
      actionNotify({
        title: ls.text.containerCreateError,
        message: error.message || error,
        type: 'error',
      })
    } finally {
      AppStore.pending(false)
    }
  },

  async deleteContainer(container: Container, _cb: () => void) {
    const ls = getLocalizedString()
    try {
      AppStore.pending(true)
      await request('containerDelete', { id: container.id })
      DictionariesStore.containersActionDeleteResolve(container.id)

      actionNotify({
        title: `«${container.name}»`,
        message: ls.text.containerDeleteSuccess,
        type: 'success',
      })

      _cb()
    } catch (error) {
      actionNotify({
        title: ls.text.containerDeleteError,
        message: error.message || error,
        type: 'error',
      })
    } finally {
      AppStore.pending(false)
    }
  },
}
