import React, { useRef, useState } from 'react'

import { Button, Drop, Grid } from '@stage-ui/core'
import { Search } from '@stage-ui/icons'
import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'
import DropTypes from '@stage-ui/core/layout/Drop/types'
import { OrderActions } from 'actions'
import { OrderStatusCode } from '@foods-n-goods/client/system/types'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { getOrderMenuItems } from '../../Toolbar/misc'

export function OrderListStatusFilter() {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const dropRef = useRef<DropTypes.Ref>(null)
  const ls = useLocalizedString()
  const [dropVisible, setDropVisible] = useState(false)

  const { section, status, statuses } = useSelector(
    (state) => ({
      status: state.order.filter.status,
      statuses: state.app.meta?.statuses,
      section: state.order.filter.section,
    }),
    objectEqual,
  )

  if (!statuses) return null

  const tabStatusIds = getOrderMenuItems().find((m) => m.id === section)?.statusIds

  const statusOptions = tabStatusIds
    ? statuses.filter((s) => tabStatusIds.includes(s.value))
    : statuses

  const selectedStatus = statusOptions.find((s) => s.value === Number(status))?.value

  const handleSetStatus = (st?: OrderStatusCode) => {
    OrderActions.setFilter({
      status: st,
    })
    setDropVisible(false)
  }

  return (
    <>
      <Button
        ref={buttonRef}
        color={status ? 'green500' : 'gray500'}
        decoration="text"
        size="xs"
        p={0}
        w="1.25rem"
        h="1.25rem"
        onClick={() => setDropVisible(!dropVisible)}
      >
        <Search size="0.875rem" />
      </Button>
      <Drop
        ref={dropRef}
        target={buttonRef}
        visible={dropVisible}
        justify="start"
        spacing={8}
        onClickOutside={(e, outTarget) => {
          if (outTarget) {
            setDropVisible(false)
          }
        }}
      >
        <Grid
          autoFlow="row"
          gap="0.125rem"
          borderRadius="s"
          p="s"
          shadow="xl"
          backgroundColor="white"
        >
          <Button
            w="100%"
            textAlign="left"
            decoration={!selectedStatus ? 'filled' : 'text'}
            size="s"
            color={!selectedStatus ? 'green500' : 'gray900'}
            label={ls.text.all}
            p="xs s"
            h="unset"
            borderRadius="s"
            onClick={() => handleSetStatus()}
            overrides={{
              container: {
                justifyContent: 'flex-start',
              },
            }}
          />
          {statusOptions.map((opt) => (
            <Button
              key={opt.code}
              w="100%"
              decoration={selectedStatus === opt.value ? 'filled' : 'text'}
              size="s"
              p="xs s"
              h="unset"
              borderRadius="s"
              color={selectedStatus === opt.value ? 'green500' : 'gray900'}
              label={opt.text}
              onClick={() => handleSetStatus(opt.value)}
              overrides={{
                container: {
                  justifyContent: 'flex-start',
                },
              }}
            />
          ))}
        </Grid>
      </Drop>
    </>
  )
}
