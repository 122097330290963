import React, { FC } from 'react'

import { Block, Flexbox, Paragraph, Spinner, Text } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'
import { AlertTriangle } from '@stage-ui/icons'
import useSelector from 'hooks/useSelector'
import { State } from 'store'
import { objectEqual } from 'utils/objectEqual'
import { useLocalizedString } from 'hooks/useLocalizedString'

type DataState = Omit<State, 'dictionaries'>
interface LoadingData {
  storeKey: keyof DataState
}

const LoadingData: FC<LoadingData & FlexboxTypes.Props> = (props) => {
  const { storeKey, children, ...rest } = props
  const ls = useLocalizedString()
  const loading = useSelector((state) => state[storeKey].loading, objectEqual)

  if (loading === 'rejected') {
    return (
      <Flexbox {...rest} alignItems="center" w="35rem">
        <AlertTriangle size="10rem" color="error" />
        <Block ml="1rem">
          <Paragraph
            size="l"
            lineHeight="1.25rem"
            children={ls.text.loadingDataError.replace('[1]', storeKey)}
          />
        </Block>
      </Flexbox>
    )
  }

  return (
    <Block {...rest} h="100%">
      {(loading === 'idle' || loading === 'pending') && (
        <Flexbox alignItems="center">
          <Spinner />
          <Text size="s" pl="0.5rem" children={ls.text.loadingData} color="hard" />
        </Flexbox>
      )}
      {loading === 'resolved' && children}
    </Block>
  )
}

export default LoadingData
