import { Block, useTheme } from '@stage-ui/core'
import BlockTypes from '@stage-ui/core/layout/Block/types'

function Logo(props: BlockTypes.Props & { fillColor?: string }) {
  const { color } = useTheme()
  const fillColor = props.fillColor || color.onSurface.hex()

  return (
    <Block w="16rem" {...props}>
      <svg viewBox="0 0 432 181" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M392.034 86.9398V85.0698L414.474 60.2098H430.864L405.894 88.1498L405.234 82.3198L431.304 114H414.364L392.034 86.9398ZM381.914 39.0898H396.214V114H381.914V39.0898Z"
          fill="black"
        />
        <path
          d="M351.74 115.429C347.633 115.429 343.71 114.586 339.97 112.899C336.303 111.213 333.04 108.279 330.18 104.099L338.98 96.3993C341.326 99.0393 343.563 100.946 345.69 102.119C347.89 103.219 350.346 103.769 353.06 103.769C354.893 103.769 356.36 103.329 357.46 102.449C358.56 101.569 359.11 100.469 359.11 99.1493C359.11 98.2693 358.816 97.4993 358.23 96.8393C357.643 96.106 356.543 95.4093 354.93 94.7493C353.39 94.0893 351.116 93.3926 348.11 92.6593C342.536 91.1926 338.356 89.2126 335.57 86.7193C332.783 84.1526 331.39 80.5593 331.39 75.9393C331.39 72.786 332.196 69.926 333.81 67.3593C335.423 64.7193 337.733 62.6293 340.74 61.0893C343.82 59.5493 347.45 58.7793 351.63 58.7793C356.543 58.7793 360.723 59.7326 364.17 61.6393C367.69 63.546 370.513 65.856 372.64 68.5693L363.29 76.3793C361.896 74.9126 360.1 73.6293 357.9 72.5293C355.7 71.4293 353.133 70.8793 350.2 70.8793C348.586 70.8793 347.156 71.246 345.91 71.9793C344.663 72.7126 344.04 73.776 344.04 75.1693C344.04 76.4893 344.7 77.5893 346.02 78.4693C347.413 79.3493 350.053 80.266 353.94 81.2193C360.54 82.7593 365.27 84.8493 368.13 87.4893C370.99 90.1293 372.42 93.6493 372.42 98.0493C372.42 101.569 371.503 104.649 369.67 107.289C367.836 109.856 365.343 111.873 362.19 113.339C359.11 114.733 355.626 115.429 351.74 115.429Z"
          fill="black"
        />
        <path
          d="M291.647 115.32C286.734 115.32 282.407 114.11 278.667 111.69C274.927 109.27 272.031 105.933 269.977 101.68C267.924 97.4265 266.897 92.5499 266.897 87.0499C266.897 81.4765 267.924 76.5998 269.977 72.4198C272.104 68.1665 275.037 64.8665 278.777 62.5198C282.517 60.1732 286.807 58.9998 291.647 58.9998C296.414 58.9998 300.557 60.0998 304.077 62.2998C307.597 64.4998 310.347 67.6898 312.327 71.8699C314.307 76.0499 315.297 81.1099 315.297 87.0499C315.297 92.7699 314.307 97.7565 312.327 102.01C310.347 106.19 307.561 109.453 303.967 111.8C300.447 114.147 296.341 115.32 291.647 115.32ZM294.177 102.01C297.917 102.01 300.961 100.653 303.307 97.9398C305.654 95.1532 306.827 91.5598 306.827 87.1598C306.827 82.6865 305.654 79.0932 303.307 76.3798C300.961 73.5932 297.917 72.1998 294.177 72.1998C290.511 72.1998 287.467 73.5932 285.047 76.3798C282.701 79.0932 281.527 82.6499 281.527 87.0499C281.527 91.4499 282.701 95.0432 285.047 97.8298C287.467 100.617 290.511 102.01 294.177 102.01ZM309.797 114L306.167 101.24H307.157V74.2898H306.167V39.0898H320.577V114H309.797Z"
          fill="black"
        />
        <path
          d="M234.545 115.1C229.192 115.1 224.499 113.963 220.465 111.69C216.432 109.343 213.315 106.08 211.115 101.9C208.915 97.72 207.815 92.8067 207.815 87.16C207.815 81.5867 208.915 76.71 211.115 72.53C213.315 68.2767 216.432 64.9767 220.465 62.63C224.499 60.21 229.192 59 234.545 59C239.972 59 244.665 60.21 248.625 62.63C252.659 65.05 255.775 68.3867 257.975 72.64C260.175 76.82 261.275 81.6233 261.275 87.05C261.275 87.8567 261.239 88.6633 261.165 89.47C261.165 90.2033 261.092 90.8267 260.945 91.34H219.585V80.56H250.055L247.855 85.73C247.855 81.1833 246.755 77.48 244.555 74.62C242.429 71.76 239.092 70.33 234.545 70.33C230.585 70.33 227.395 71.5767 224.975 74.07C222.555 76.49 221.345 79.7533 221.345 83.86V89.69C221.345 94.0167 222.555 97.4267 224.975 99.92C227.469 102.34 230.842 103.55 235.095 103.55C238.835 103.55 241.769 102.78 243.895 101.24C246.022 99.7 247.855 97.7933 249.395 95.52L259.405 101.24C256.985 105.713 253.685 109.16 249.505 111.58C245.399 113.927 240.412 115.1 234.545 115.1Z"
          fill="black"
        />
        <path
          d="M184.94 60.21L188.57 75.06V114H174.16V60.21H184.94ZM185.6 82.43L182.96 81.22V70.22L183.95 69.01C184.757 67.69 185.967 66.26 187.58 64.72C189.267 63.18 191.173 61.86 193.3 60.76C195.5 59.5867 197.773 59 200.12 59C201.293 59 202.357 59.0733 203.31 59.22C204.263 59.3667 204.997 59.6233 205.51 59.99V73.08H201.99C197.223 73.08 193.557 73.85 190.99 75.39C188.423 76.8567 186.627 79.2033 185.6 82.43Z"
          fill="black"
        />
        <path
          d="M418.834 129H428.334V130.4H420.434V135.2H427.494V136.58H420.434V141.62H428.594V143H418.834V129Z"
          fill="black"
        />
        <path
          d="M414.971 132C414.437 131.454 413.804 131.027 413.071 130.72C412.351 130.4 411.604 130.24 410.831 130.24C410.044 130.24 409.304 130.387 408.611 130.68C407.917 130.974 407.311 131.387 406.791 131.92C406.271 132.44 405.864 133.047 405.571 133.74C405.277 134.434 405.131 135.18 405.131 135.98C405.131 136.767 405.277 137.507 405.571 138.2C405.864 138.894 406.271 139.5 406.791 140.02C407.311 140.54 407.917 140.954 408.611 141.26C409.304 141.554 410.044 141.7 410.831 141.7C411.604 141.7 412.344 141.554 413.051 141.26C413.771 140.954 414.411 140.534 414.971 140L415.951 141C415.497 141.427 414.984 141.8 414.411 142.12C413.851 142.44 413.264 142.687 412.651 142.86C412.037 143.034 411.404 143.12 410.751 143.12C409.737 143.12 408.791 142.94 407.911 142.58C407.044 142.207 406.277 141.694 405.611 141.04C404.957 140.387 404.444 139.627 404.071 138.76C403.697 137.894 403.511 136.967 403.511 135.98C403.511 134.994 403.697 134.074 404.071 133.22C404.444 132.367 404.964 131.614 405.631 130.96C406.297 130.307 407.071 129.8 407.951 129.44C408.831 129.08 409.784 128.9 410.811 128.9C411.451 128.9 412.077 128.98 412.691 129.14C413.304 129.3 413.884 129.534 414.431 129.84C414.977 130.134 415.477 130.494 415.931 130.92L414.971 132Z"
          fill="black"
        />
        <path
          d="M388.932 129H390.592L399.412 141.12L398.992 141.26V129H400.572V143H398.932L390.112 130.9L390.532 130.76V143H388.932V129Z"
          fill="black"
        />
        <path
          d="M375.904 129H385.404V130.4H377.504V135.2H384.564V136.58H377.504V141.62H385.664V143H375.904V129Z"
          fill="black"
        />
        <path
          d="M371.146 131.9C370.586 131.38 369.933 130.974 369.186 130.68C368.453 130.387 367.693 130.24 366.906 130.24C366.106 130.24 365.36 130.394 364.666 130.7C363.973 130.994 363.36 131.407 362.826 131.94C362.293 132.46 361.88 133.067 361.586 133.76C361.293 134.44 361.146 135.18 361.146 135.98C361.146 136.78 361.293 137.527 361.586 138.22C361.88 138.914 362.293 139.527 362.826 140.06C363.36 140.594 363.973 141.014 364.666 141.32C365.36 141.614 366.113 141.76 366.926 141.76C367.66 141.76 368.393 141.62 369.126 141.34C369.86 141.047 370.513 140.66 371.086 140.18L372.026 141.28C371.573 141.64 371.06 141.96 370.486 142.24C369.913 142.52 369.313 142.74 368.686 142.9C368.073 143.047 367.453 143.12 366.826 143.12C365.8 143.12 364.846 142.94 363.966 142.58C363.086 142.207 362.313 141.694 361.646 141.04C360.98 140.387 360.46 139.627 360.086 138.76C359.713 137.894 359.526 136.967 359.526 135.98C359.526 134.994 359.713 134.074 360.086 133.22C360.473 132.367 361 131.614 361.666 130.96C362.346 130.307 363.133 129.8 364.026 129.44C364.92 129.08 365.88 128.9 366.906 128.9C367.546 128.9 368.173 128.98 368.786 129.14C369.413 129.287 370 129.5 370.546 129.78C371.106 130.06 371.613 130.4 372.066 130.8L371.146 131.9ZM370.606 136.16H372.026V141.28H370.606V136.16Z"
          fill="black"
        />
        <path d="M355.006 129H356.606V143H355.006V129Z" fill="black" />
        <path d="M344.674 129H346.274V141.62H352.414V143H344.674V129Z" fill="black" />
        <path d="M334.342 129H335.942V141.62H342.082V143H334.342V129Z" fill="black" />
        <path
          d="M321.314 129H330.814V130.4H322.914V135.2H329.974V136.58H322.914V141.62H331.074V143H321.314V129Z"
          fill="black"
        />
        <path
          d="M308.263 129H319.003V130.4H314.423V143H312.823V130.4H308.263V129Z"
          fill="black"
        />
        <path
          d="M294.264 129H295.924L304.744 141.12L304.324 141.26V129H305.904V143H304.264L295.444 130.9L295.864 130.76V143H294.264V129Z"
          fill="black"
        />
        <path d="M288.424 129H290.024V143H288.424V129Z" fill="black" />
        <path d="M273.229 129H274.829V141.62H280.969V143H273.229V129Z" fill="black" />
        <path
          d="M263.114 129H264.754L271.014 143H269.294L263.894 130.64L258.534 143H256.854L263.114 129ZM259.614 137.98H268.114V139.36H259.614V137.98Z"
          fill="black"
        />
        <path d="M253.033 129H254.633V143H253.033V129Z" fill="black" />
        <path
          d="M249.17 132C248.637 131.454 248.003 131.027 247.27 130.72C246.55 130.4 245.803 130.24 245.03 130.24C244.243 130.24 243.503 130.387 242.81 130.68C242.117 130.974 241.51 131.387 240.99 131.92C240.47 132.44 240.063 133.047 239.77 133.74C239.477 134.434 239.33 135.18 239.33 135.98C239.33 136.767 239.477 137.507 239.77 138.2C240.063 138.894 240.47 139.5 240.99 140.02C241.51 140.54 242.117 140.954 242.81 141.26C243.503 141.554 244.243 141.7 245.03 141.7C245.803 141.7 246.543 141.554 247.25 141.26C247.97 140.954 248.61 140.534 249.17 140L250.15 141C249.697 141.427 249.183 141.8 248.61 142.12C248.05 142.44 247.463 142.687 246.85 142.86C246.237 143.034 245.603 143.12 244.95 143.12C243.937 143.12 242.99 142.94 242.11 142.58C241.243 142.207 240.477 141.694 239.81 141.04C239.157 140.387 238.643 139.627 238.27 138.76C237.897 137.894 237.71 136.967 237.71 135.98C237.71 134.994 237.897 134.074 238.27 133.22C238.643 132.367 239.163 131.614 239.83 130.96C240.497 130.307 241.27 129.8 242.15 129.44C243.03 129.08 243.983 128.9 245.01 128.9C245.65 128.9 246.277 128.98 246.89 129.14C247.503 129.3 248.083 129.534 248.63 129.84C249.177 130.134 249.677 130.494 250.13 130.92L249.17 132Z"
          fill="black"
        />
        <path d="M233.189 129H234.789V143H233.189V129Z" fill="black" />
        <path
          d="M221.92 129H230.82V130.4H223.52V135.56H230.06V136.94H223.52V143H221.92V129Z"
          fill="black"
        />
        <path d="M216.08 129H217.68V143H216.08V129Z" fill="black" />
        <path
          d="M203.028 129H213.768V130.4H209.188V143H207.588V130.4H203.028V129Z"
          fill="black"
        />
        <path
          d="M195.795 129C197.555 129 198.915 129.413 199.875 130.24C200.835 131.053 201.315 132.2 201.315 133.68C201.315 135.213 200.835 136.407 199.875 137.26C198.915 138.1 197.555 138.52 195.795 138.52H192.075V143H190.475V129H195.795ZM195.795 137.14C197.061 137.14 198.041 136.853 198.735 136.28C199.441 135.693 199.795 134.84 199.795 133.72C199.795 132.627 199.441 131.8 198.735 131.24C198.041 130.68 197.061 130.4 195.795 130.4H192.075V137.14H195.795ZM196.275 137.86H197.895L201.455 143H199.635L196.275 137.86Z"
          fill="black"
        />
        <path
          d="M180.36 129H182L188.26 143H186.54L181.14 130.64L175.78 143H174.1L180.36 129ZM176.86 137.98H185.36V139.36H176.86V137.98Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M146.333 67.0695C146.333 87.3298 136.533 105.492 121.041 117.79L152 149.697L120.053 180.695L61.6593 120.513L92.3039 90.7791L92.2911 90.7658C96.1458 86.3834 98.4997 80.6965 98.5704 74.4721C98.4527 60.2615 86.8965 48.7781 72.6581 48.7781C58.3966 48.7781 46.826 60.299 46.7454 74.5416V120.15L46.745 120.15V120.155L40.0215 126.878C39.9437 126.842 39.8659 126.806 39.7882 126.769L0 164.626V67.0704H6.10416e-05L6.10352e-05 67.0695C6.10352e-05 30.028 32.7579 0 73.1667 0C113.576 0 146.333 30.028 146.333 67.0695Z"
          fill="url(#paint0_linear_2063_80)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2063_80"
            x1="50.3022"
            y1="-9.14591"
            x2="103.145"
            y2="180.886"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F64B33" />
            <stop offset="1" stop-color="#8A0333" />
          </linearGradient>
        </defs>
      </svg>
    </Block>
  )
}

export default Logo
