import React from 'react'

import { Flexbox } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'
import { useMouseDrop } from 'components/MouseDrop'
import { useLocalizedString } from 'hooks/useLocalizedString'

type BatteryIndicatorProps = FlexboxTypes.Props & {
  batteryLife: number
}

export function BatteryIndicator(props: BatteryIndicatorProps) {
  const { batteryLife, ...restProps } = props
  const ls = useLocalizedString()
  const hint = useMouseDrop(
    batteryLife
      ? ls.text.batteryIndicator.replace('[1]', String(batteryLife))
      : ls.text.courierOnline,
  )

  return (
    <Flexbox centered ml="xs" {...restProps}>
      <Flexbox
        {...hint}
        w="0.75rem"
        h="0.375rem"
        p="0.0625rem"
        borderColor="lightGreen500"
        borderStyle="solid"
        borderWidth="0.0625rem"
        borderRadius="0.125rem"
      >
        {batteryLife ? (
          <Flexbox
            w={`${batteryLife}%`}
            backgroundColor={batteryLife > 10 ? 'lightGreen500' : 'red500'}
            borderRadius="0.0625rem"
          />
        ) : (
          <Flexbox w="100%" backgroundColor="orange300" borderRadius="0.0625rem" />
        )}
      </Flexbox>
      <Flexbox
        h="0.3125rem"
        w="0.125rem"
        backgroundColor="lightGreen500"
        style={{
          borderTopRightRadius: '0.125rem',
          borderBottomRightRadius: '0.125rem',
        }}
      />
    </Flexbox>
  )
}
