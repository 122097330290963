import React, { useEffect } from 'react'

import { Order, OrderedProduct } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox, Text } from '@stage-ui/core'
import { OrderActions } from 'actions'
import { useLocalizedString } from 'hooks/useLocalizedString'

type OrderPositionDeleteModalProps = {
  close: () => void
  id: OrderedProduct['id']
  orderId: Order['id']
  disabled: boolean
}

export const OrderPositionDeleteModal: React.FC<OrderPositionDeleteModalProps> = (
  props,
) => {
  const { close, id, orderId, disabled } = props
  const ls = useLocalizedString()
  const deleteProduct = () => {
    if (disabled) return
    OrderActions.deleteProduct({
      positionId: id,
      orderId,
    })
  }

  const handleConfirm = () => {
    deleteProduct()
    close()
  }

  useEffect(() => {
    window.addEventListener('keydown', handleConfirm)
    return () => {
      window.removeEventListener('keydown', handleConfirm)
    }
  }, [])

  return (
    <Flexbox column>
      <Text color="gray900">{ls.text.confirmItemRemove}</Text>
      <Flexbox justifyContent="flex-end" mt="xl">
        <Button
          mr="s"
          label={ls.text.cancel}
          color="gray500"
          textColor="gray900"
          decoration="text"
          onClick={close}
        />
        <Button color="red500" label={ls.text.delete} onClick={handleConfirm} />
      </Flexbox>
    </Flexbox>
  )
}
