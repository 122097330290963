import React, { useEffect } from 'react'

import { Device } from '@foods-n-goods/server/generated/schema'
import { SocketType } from '@foods-n-goods/server/src/socket/types'
import { Button, Flexbox } from '@stage-ui/core'
import { Plus } from '@stage-ui/icons'
import { AppActions, DevicesActions } from 'actions'
import MenuNavigation from 'components/MenuNavigation'
import { useMouseDropMenu } from 'components/MouseMenuDrop'
import { Section } from 'components/Section'
import useSelector from 'hooks/useSelector'
import { io } from 'requests/socket'
import { DevicesStore } from 'store'

import { objectEqual } from 'utils/objectEqual'

import { useLocalizedString } from 'hooks/useLocalizedString'

import CameraDevices from './CameraDevices'
import Containers from './Containers'
import { createContainerDialog } from './Containers/form'
import DeliveryIntervals from './DeliveryIntervals'
import { createDeliveryIntervalDataDialog } from './DeliveryIntervals/form'
import { GeneralSettings } from './General'
import PrinterDevices from './PrinterDevices'
import ScaleDevices from './ScaleDevices'
import { createDeviceDialog } from './shared/form'

export function Settings() {
  const filter = useSelector((state) => state.app.filter, objectEqual)
  const devicesLoading = useSelector((state) => state.devices.loading, objectEqual)
  const ls = useLocalizedString()
  const handleAction = () => {
    switch (filter.section) {
      case 'scales':
        return createDeviceDialog('SCALE')
      case 'cameras':
        return createDeviceDialog('CAMERA')
      case 'printers':
        return createDeviceDialog('PRINTER')
      case 'containers':
        return createContainerDialog()
      case 'deliveryIntervals':
        return createDeliveryIntervalDataDialog()
      default:
        return undefined
    }
  }

  const actionMenu = useMouseDropMenu<HTMLButtonElement>([
    {
      text: ls.text.scales,
      icon: 'Weight',
      value: 'Weight',
      onClick: () => createDeviceDialog('SCALE'),
    },
    {
      text: ls.text.camera,
      icon: 'Camera',
      value: 'Camera',
      onClick: () => createDeviceDialog('CAMERA'),
    },
    {
      text: ls.text.stickerPrinter,
      icon: 'Printer',
      value: 'Printer',
      onClick: () => createDeviceDialog('PRINTER'),
    },
    {
      text: ls.text.pallet,
      icon: 'Download',
      value: 'Container',
      onClick: () => createContainerDialog(),
    },
    {
      text: ls.text.deliveryInterval,
      icon: 'Car2',
      value: 'DeliveryInterval',
      onClick: () => createDeliveryIntervalDataDialog(),
    },
  ])

  useEffect(() => {
    const updateDevices = (data: Device[]) => {
      DevicesStore.updateDevices(data)
    }
    if (devicesLoading !== 'resolved') {
      DevicesActions.fetch()
    }
    io.on(SocketType.Broadcast.DevicesUpdate, updateDevices)
    return () => {
      io.off(SocketType.Broadcast.DevicesUpdate, updateDevices)
      AppActions.clearFilter()
    }
  }, [])

  return (
    <Section title={ls.text.settings}>
      <Flexbox justifyContent="space-between" alignItems="center" mb="l">
        <MenuNavigation
          currentSection={filter.section}
          onChange={(section) => AppActions.setFilter({ section })}
          items={[
            {
              id: '',
              title: ls.text.main,
            },
            {
              id: 'scales',
              title: ls.text.scales,
            },
            {
              id: 'cameras',
              title: ls.text.cameras,
            },
            {
              id: 'printers',
              title: ls.text.stickerPrinters,
            },
            {
              id: 'containers',
              title: ls.text.pallets,
            },
            {
              id: 'deliveryIntervals',
              title: ls.text.deliveryIntervals,
            },
          ]}
        />
        {filter.section && filter.section !== 'general' && (
          <Button
            key={`${filter.section}_action`}
            label={ls.text.create}
            leftChild={<Plus />}
            onClick={handleAction}
          />
        )}
        {!filter.section && (
          <Button
            key="general_action"
            label={ls.text.create}
            leftChild={<Plus />}
            {...actionMenu}
          />
        )}
      </Flexbox>
      {!filter.section && <GeneralSettings />}
      {filter.section === 'scales' && <ScaleDevices />}
      {filter.section === 'cameras' && <CameraDevices />}
      {filter.section === 'printers' && <PrinterDevices />}
      {filter.section === 'containers' && <Containers />}
      {filter.section === 'deliveryIntervals' && <DeliveryIntervals />}
    </Section>
  )
}
