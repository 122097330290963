import React from 'react'

import { Flexbox } from '@stage-ui/core'
import { ButtonGroup } from 'components/ButtonGroup'

import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'

import { DeliveryActions } from 'actions'

import { LogisticsOrderStatus } from 'store/delivery'
import { useLocalizedString } from 'hooks/useLocalizedString'

export function LogisticsOrdersFilter() {
  const filter = useSelector((state) => state.delivery.filter, objectEqual)
  const ls = useLocalizedString()
  const statuses: Record<LogisticsOrderStatus, string> = {
    all: ls.text.all,
    warehouse: ls.text.inWarehouse,
    delivery: ls.text.inDelivery,
    done: ls.text.doneOrders,
    unassigned: ls.text.noCourier,
  }
  return (
    <Flexbox justifyContent="space-between" mb="s" flex={1}>
      <ButtonGroup
        data={Object.keys(statuses).map((status) => ({
          active: filter.ordersTab === status,
          text: statuses[status as LogisticsOrderStatus],
          onClick: () => {
            DeliveryActions.setFilter({
              ordersTab: status as LogisticsOrderStatus,
            })
          },
        }))}
      />
    </Flexbox>
  )
}
