import React from 'react'

import { Journal } from '@foods-n-goods/server/generated/schema'
import { Flexbox, Paragraph, Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { DateTime } from 'components/DateTime'
import { getLocalizedString, useLocalizedString } from 'hooks/useLocalizedString'

function Action(ctx: TableTypes.TableCellContext) {
  const { action, isSystem } = ctx.row as Journal
  const ls = useLocalizedString()
  return (
    <Flexbox column>
      {isSystem && (
        <Paragraph m={0} size="s" color="warning" children={ls.text.systemMessage} />
      )}
      <Paragraph m={0} children={action} />
    </Flexbox>
  )
}

export const getColumns = (): TableTypes.TableColumn[] => {
  const ls = getLocalizedString()
  return [
    {
      key: 'createDate',
      title: ls.text.date,
      render: (ctx) => <DateTime date={ctx.row.createDate} />,
      width: '10rem',
    },
    {
      key: 'staff',
      title: ls.text.user,
      render: (ctx) => (
        <Text size="s" color="gray900">
          {ctx.row.staff.name || ctx.row.staff.login}
        </Text>
      ),
      width: '12rem',
    },
    { key: 'action', title: ls.text.event, render: Action },
  ]
}
