import React from 'react'

import { OnValueParamChangeFunction } from '.'

import { ProductInput } from '@foods-n-goods/server/generated/schema'
import { Flexbox } from '@stage-ui/core'
import Content from 'components/Content'
import ViewInputField from 'components/Fields/ViewInputField'
import ViewGridBlock from 'components/ViewGridBlock'
import { useLocalizedString } from 'hooks/useLocalizedString'

interface ProductViewEnergyValueProps {
  params: ProductInput['params']
  onChange: OnValueParamChangeFunction
}

function ProductViewEnergyValue(props: ProductViewEnergyValueProps) {
  const {
    onChange,
    params: { FATS, PROTEINS, CARBOHYDRATES, ENERGY },
  } = props
  const ls = useLocalizedString()
  return (
    <Content>
      <ViewGridBlock title={ls.text.energyValue}>
        <Flexbox gridColumnEnd="span 1">
          <ViewInputField
            label={ls.text.fatsG}
            value={FATS || ''}
            onChange={(value) => onChange('FATS', value)}
            digits={1}
            type="digits"
          />
          <ViewInputField
            label={ls.text.proteinsG}
            ml="0.5rem"
            value={PROTEINS || ''}
            onChange={(value) => onChange('PROTEINS', value)}
            digits={1}
            type="digits"
          />
          <ViewInputField
            label={ls.text.carbsG}
            ml="0.5rem"
            value={CARBOHYDRATES || ''}
            onChange={(value) => onChange('CARBOHYDRATES', value)}
            digits={1}
            type="digits"
          />
        </Flexbox>
        <ViewInputField
          label={ls.text.kcal}
          ml="0.5rem"
          value={ENERGY || ''}
          onChange={(value) => onChange('ENERGY', value)}
          type="formatInteger"
        />
      </ViewGridBlock>
    </Content>
  )
}

export default ProductViewEnergyValue
