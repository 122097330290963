import React from 'react'

import ChatTypes from '@foods-n-goods/server/src/socket/chat/types'
import { Flexbox } from '@stage-ui/core'
import { Settings } from '@stage-ui/icons'
import Avatar from 'components/Avatar'
import { useMouseDrop } from 'components/MouseDrop'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { useLocalizedString } from 'hooks/useLocalizedString'

type AvatarControlProps = {
  animated?: boolean
  hint?: boolean
  onEdit?: () => void
  onClick?: (
    user: ChatTypes.User,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void
  users: ChatTypes.User[]
}

const AvatarWrapper: React.FC<
  AvatarControlProps & { user: ChatTypes.User; index: number }
> = (props) => {
  const { user, users, animated, onClick, hint, index } = props
  const hintProps = useMouseDrop(hint ? user.name : '')
  const online = useSelector(
    (state) =>
      users.length === 2 &&
      user.type === 'STAFF' &&
      state.staff.data.some(({ id, online }) => id === user.id && !!online),
    objectEqual,
  )
  return (
    <Flexbox
      style={{
        transition: animated ? 'all 0.25s' : 'none',
        width: users.length === index + 1 ? '2.25rem' : '1rem',
      }}
    >
      <Avatar
        {...hintProps}
        size="2rem"
        user={{ ...user, online }}
        onClick={onClick ? (e) => onClick(user, e) : undefined}
      />
    </Flexbox>
  )
}

const AvatarControl: React.ForwardRefRenderFunction<
  HTMLDivElement,
  AvatarControlProps
> = (props, ref) => {
  const { animated, onEdit, onClick, users } = props
  const ls = useLocalizedString()
  const editHint = useMouseDrop(onEdit ? ls.text.chatMembersUpdate : '')

  return (
    <Flexbox flex={1} ref={ref}>
      {onEdit && (
        <Flexbox
          {...editHint}
          centered
          w="2rem"
          h="2rem"
          mr="s"
          decoration="surface"
          backgroundColor="gray50"
          borderColor="gray200"
          borderRadius="100%"
          onClick={onEdit}
        >
          <Settings size="1.25rem" color="gray500" />
        </Flexbox>
      )}
      <Flexbox
        style={{
          ':hover': {
            '>div': {
              width: animated ? '2.25rem' : '1rem',
            },
          },
        }}
      >
        {users.map((user, index) => (
          <AvatarWrapper {...props} user={user} index={index} key={user.id} />
        ))}
      </Flexbox>
    </Flexbox>
  )
}

export default React.forwardRef(AvatarControl)
