import React from 'react'

import { Flexbox, Grid, Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { AlertTriangle, ShoppingBag, Weight } from '@stage-ui/icons'
import { useMouseDrop } from 'components/MouseDrop'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { useCurrency } from 'hooks/useCurrency'

import { OrderedPosition } from '..'

enum ChangedCodeStatus {
  NOT_CHANGED = 0,
  CHANGED_BY_MARKET = 1,
  CHANGED_BY_CLIENT = 2,
  CLIENT_NOT_ACCEPTED = 3,
  CHANGED_BY_WAREHOUSE = 4,
}

export function OrderPositionQuantityAfterCell(
  ctx: TableTypes.TableCellContext<OrderedPosition>,
) {
  const { row } = ctx
  const ls = useLocalizedString()
  const {
    quantityAfter,
    wishfulUnitName,
    wishfulUnitQuantity,
    quantityBeforeAcceptance,
    deleteDate,
    orderContainerLabels,
    amountAfter,
    changed,
    unit,
    lineDate,
    scaleable,
    tareWeight,
  } = row
  const { valueFormatted } = useCurrency(amountAfter || 0)
  let warningText = ''

  if (changed === ChangedCodeStatus.CHANGED_BY_MARKET) {
    warningText = ls.text.changedByMarket
  }

  if (changed === ChangedCodeStatus.CHANGED_BY_WAREHOUSE) {
    warningText = ls.text.changedByWarehouse
  }

  if (!!deleteDate && orderContainerLabels) {
    warningText = ls.text.emptyPalletsReq.replace('[1]', orderContainerLabels)
  }

  const dropWarning = useMouseDrop(warningText)
  const fixedDateWarning = useMouseDrop(ls.text.fixedDateWarning)
  const tareWeightHint = useMouseDrop(
    tareWeight &&
      `${ls.text.container} ${tareWeight} ${
        scaleable ? ls.text.kg.toLocaleLowerCase() : ls.string[unit.code]
      } `,
  )

  const quantityBefore = quantityBeforeAcceptance
    ? ls.text.quantityWas.replace('[1]', quantityBeforeAcceptance?.toLocaleString())
    : ' '

  const quantityValue =
    (quantityAfter || '0').toLocaleString() +
    (quantityBefore || '0') +
    (scaleable ? ls.text.kg.toLocaleLowerCase() : ls.string[unit.code!])

  if (!quantityAfter && !quantityBeforeAcceptance) return null

  return (
    <Grid autoFlow="row" alignItems="start" gap="0.125rem">
      <Flexbox alignItems="center" ml="-3.375rem">
        <Grid
          gap="0.0625rem"
          autoFlow="column"
          mr="xs"
          w="3.125rem"
          justifyContent="end"
          alignItems="center"
        >
          {!!quantityAfter && !lineDate && (
            <AlertTriangle color="red500" {...fixedDateWarning} />
          )}
          {!!warningText && <Weight color="blue500" {...dropWarning} />}
          {!!tareWeight && <ShoppingBag color="gray400" {...tareWeightHint} />}
        </Grid>
        <Text size="s" color="gray900" style={{ whiteSpace: 'nowrap' }}>
          {quantityValue}
        </Text>
        {wishfulUnitQuantity && wishfulUnitName && (
          <Text size="s" color="gray900" style={{ whiteSpace: 'nowrap' }}>
            ({wishfulUnitQuantity} {wishfulUnitName})
          </Text>
        )}
      </Flexbox>
      <Text size="s" color="gray900" style={{ whiteSpace: 'nowrap' }}>
        {valueFormatted}
      </Text>
    </Grid>
  )
}
