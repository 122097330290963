import React, { useEffect, useMemo } from 'react'

import { Flexbox, Paragraph, Spinner, Table, Text, useTheme } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { List } from '@stage-ui/icons'
import { PageSwitch } from 'components/PageSwitch'
import makeId from 'utils/makeId'

import { PageLoading } from 'components/PageLoading'

import { SocketType } from '@foods-n-goods/server/src/socket/types'

import { io } from 'requests/socket'

import { PurchasesActions } from 'actions'

import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { PurchaseExtended, usePurchasesData } from '../../data'

import { ClientCell } from './cells/ClientCell'
import { ContextCell } from './cells/ContextCell'
import { ExpandCell } from './cells/ExpandCell'
import { NameCell } from './cells/NameCell'
import { OrderCell } from './cells/OrderCell'
import { PurchaserCell } from './cells/PurchaserCell'
import { QuantityCell } from './cells/QuantityCell'
import { StatusCell } from './cells/StatusCell'
import { PurchaseListTableCreateDateCell } from './cells/CreateDateCell'

type PurchasesTableProps = {
  expandByProductId?: string
}

export function PurchasesTable(props: PurchasesTableProps) {
  const { expandByProductId } = props

  const { color } = useTheme()
  const ls = useLocalizedString()
  const { tableData, rerenderTrigger, total, loading } =
    usePurchasesData(expandByProductId)

  const filter = useSelector((state) => state.purchases.filter, objectEqual)

  const tableKey = useMemo(() => makeId(6), [rerenderTrigger, expandByProductId, filter])

  const columns: TableTypes.TableColumn<PurchaseExtended>[] = [
    {
      key: '_expand',
      width: expandByProductId ? 12 : 24,
      render: (ctx) => <ExpandCell ctx={ctx} expandByProductId={expandByProductId} />,
    },
    { key: 'orderId', title: ls.text.number, width: 55, render: OrderCell },
    { key: 'name', title: ls.text.product2, render: NameCell },
    {
      key: 'createDate',
      title: ls.text.createDate,
      width: 90,
      render: PurchaseListTableCreateDateCell,
    },
    { key: 'quantity', title: ls.text.quantity, width: 80, render: QuantityCell },
    { key: 'status', title: ls.text.status, width: 50, render: StatusCell },
    { key: 'purchaser', title: ls.text.purchaser, width: 200, render: PurchaserCell },
    { key: 'clientAlias', title: ls.text.client1, width: 200, render: ClientCell },
    { key: '_context', width: 32, render: ContextCell },
  ]

  useEffect(() => {
    io.on(SocketType.Broadcast.PurchaseRemove, (ids: string[]) => {
      PurchasesActions.removeById(ids)
    })
    io.on(SocketType.Broadcast.PurchaseRemoveByPosition, (ids: string[]) => {
      PurchasesActions.removeByPositionId(ids)
    })

    return () => {
      io.off(SocketType.Broadcast.PurchaseRemove, (ids: string[]) => {
        PurchasesActions.removeById(ids)
      })
      io.off(SocketType.Broadcast.PurchaseRemoveByPosition, (ids: string[]) => {
        PurchasesActions.removeByPositionId(ids)
      })
    }
  }, [])

  if (loading) {
    return (
      <Flexbox centered p="xl" mt="m">
        <Spinner size="s" mr="s" />
        <Text size="s" color="gray500" weight={600}>
          {ls.text.restockDataLoading}
        </Text>
      </Flexbox>
    )
  }

  if (!total) {
    return (
      <Flexbox
        column
        centered
        p="m"
        decoration="surface"
        borderRadius="s"
        borderColor="gray200"
      >
        <List size="1.5rem" color="gray300" />
        <Paragraph size="s" color="gray400">
          {ls.text.listEmpty}
        </Paragraph>
      </Flexbox>
    )
  }

  return (
    <PageLoading loading={loading}>
      <Flexbox column h="100%">
        <Table
          key={tableKey}
          columns={columns}
          data={tableData}
          overrides={{
            headCell: {
              padding: '0.75rem 1.25rem',
              display: expandByProductId ? 'none' : 'table-cell',
            },
            row: expandByProductId && {
              td: {
                borderRadius: '0 !important',
              },
            },
            rowCell: {
              height: '3rem',
              verticalAlign: 'middle',
              padding: '0 1.25rem',
              borderTopWidth: expandByProductId ? 0 : '0.0625rem',
              ':first-of-type': {
                padding: expandByProductId
                  ? '0 0.125rem 0 0.625rem'
                  : '0 0.0625rem 0 1.5rem',
              },
              ':last-of-type': {
                borderRightWidth: expandByProductId ? 0 : '0.0625rem',
              },
              borderColor: expandByProductId
                ? color.gray[100].hex()
                : color.gray[200].hex(),
            },
            expandContainer: {
              padding: '0 0 1rem 1.5rem',
              backgroundColor: color.background.hex(),
              borderWidth: '0.0625rem 0.0625rem 0 0.0625rem',
              borderStyle: 'solid',
              borderColor: color.gray[200].hex(),
            },
          }}
        />
        {!expandByProductId && (
          <PageSwitch
            pageSize={filter.pageSize}
            page={filter.page}
            total={total}
            onChange={(args) => PurchasesActions.setFilter(args)}
          />
        )}
      </Flexbox>
    </PageLoading>
  )
}
