import React, { useEffect } from 'react'

import { Report } from '@foods-n-goods/server/generated/schema'
import { Flexbox, Paragraph, Table, useTheme } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { List } from '@stage-ui/icons'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import ReportActions from 'actions/report'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { CellContext } from './CellContext'
import { CellStatus } from './CellStatus'
import { CellDate } from './CellDate'

export function ReportTable() {
  const { color } = useTheme()
  const ls = useLocalizedString()
  const { reports, loading } = useSelector(
    ({ report }) => ({
      reports: report.reports,
      loading: report.reports.loading === 'idle' || report.reports.loading === 'pending',
    }),
    objectEqual,
  )
  const columns: TableTypes.TableColumn<Report>[] = [
    { key: 'createDate', title: ls.text.createDate, sort: 'DESC', render: CellDate },
    { key: 'name', title: ls.text.name },
    { key: 'finishDate', title: ls.text.finishDate, render: CellDate },

    { key: 'status', width: 32, render: CellStatus },
    { key: '_context', width: 32, render: CellContext },
  ]
  useEffect(() => {
    if (reports.loading === 'idle') {
      ReportActions.reportsFetch()
    }
  }, [reports.loading])

  return (
    <Flexbox column h="100%">
      {reports.data.length === 0 && (
        <Flexbox
          column
          centered
          p="m"
          decoration="surface"
          borderRadius="s"
          borderColor="gray200"
        >
          <List size="1.5rem" color="gray300" />
          <Paragraph size="s" color="gray400">
            {loading ? ls.text.loading : ls.text.listEmpty}
          </Paragraph>
        </Flexbox>
      )}
      {reports.data.length > 0 && (
        <Table
          data={reports.data}
          columns={columns}
          overrides={{
            container: {
              userSelect: 'none',
            },
            row: (props) => [
              props.selected && {
                backgroundColor: `${color.green[50].hex()} !important`,
              },
            ],
            headCell: () => [
              {
                backgroundColor: color.background.hex(),
                position: 'sticky',
                top: '9.5625rem',
                zIndex: 6,
              },
            ],
          }}
        />
      )}
    </Flexbox>
  )
}
