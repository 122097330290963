import React from 'react'

import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs'
import { useLocation } from 'react-router-dom'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { useLocalizedString } from 'hooks/useLocalizedString'

export function ClientsSectionHeader() {
  const location = useLocation()
  const sectionPath = location.pathname.split('/')[1]
  const ls = useLocalizedString()
  const client = useSelector((state) => state.client.currentlyViewed, objectEqual)

  const alias =
    client?.markets?.[0].alias ||
    client?.name ||
    client?.identifier ||
    ls.text.unidentifiedClient

  return (
    <BreadCrumbs
      path={client?.id || ''}
      selectedItem={alias}
      sectionName={ls.text.clients}
      sectionPath={sectionPath}
    />
  )
}
