import React from 'react'

import { Block, Flexbox, Text } from '@stage-ui/core'
import { Close, Pin } from '@stage-ui/icons'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { useLocalizedString } from 'hooks/useLocalizedString'

type RouteBannerProps = {
  onClose: () => void
  onClick: () => void
}

export function RouteBanner(props: RouteBannerProps) {
  const { onClose, onClick } = props
  const ls = useLocalizedString()
  const courierRoute = useSelector(
    (state) => ({
      timeWithTrafficJam: state.delivery.courierRoute?.timeWithTrafficJam,
      routeLength: state.delivery.courierRoute?.length,
    }),
    objectEqual,
  )

  return (
    <Block
      p="xs s"
      backgroundColor="green600"
      style={{
        position: 'absolute',
        borderRadius: '0.25rem',
        opacity: courierRoute.routeLength ? 1 : 0,
        visibility: courierRoute.routeLength ? 'visible' : 'hidden',
        top: '3rem',
        left: courierRoute.routeLength ? '0.5rem' : '3rem',
        transition: 'all 0.25s',
        boxShadow: '0px 0px 2rem rgb(29 29 29 / 10%)',
      }}
      onClick={onClick}
    >
      <Flexbox>
        <Pin size="1.5rem" color="green100" mr="s" />
        <Flexbox flex={1} column style={{ userSelect: 'none' }}>
          <Text color="surface" size="s" weight={600}>
            {courierRoute.timeWithTrafficJam}
            <Text size="s" color="green200">
              {' '}
              / {((courierRoute.routeLength || 0) / 1000).toFixed(1)} {ls.text.km}
            </Text>
          </Text>
          <Text color="green50" size="xs">
            {ls.text.travelTimeWithTraffic}
          </Text>
        </Flexbox>
        <Close color="green200" onClick={onClose} />
      </Flexbox>
    </Block>
  )
}
