import React, { useEffect } from 'react'

import { OrderActions } from 'actions'

import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

import dayjs from 'dayjs'

import { OrderListTable } from './components/Table'
import { OrderListToolbar } from './components/Toolbar'
import { getOrderMenuItems } from './components/Toolbar/misc'
import { OrderListTablePagination } from './components/Pagination'

export function OrderList() {
  const filter = useSelector((state) => state.order.filter, objectEqual)

  const {
    deliveryDateEnd,
    deliveryDateStart,
    section,
    createDateEnd,
    createDateStart,
    status,
    page,
    pageSize,
    id,
    search,
    client,
  } = filter

  const fetchOrders = () => {
    // if (!deliveryDateStart || !deliveryDateEnd) return

    const statusIds = getOrderMenuItems().find((i) => i.id === section)?.statusIds

    const makeSearchString = () => {
      let searchString = ''

      if (search) searchString += ` ${search}`
      if (client) searchString += ` ${client}`
      if (id) searchString += ` ${id}`

      return searchString.trim() || undefined
    }

    OrderActions.fetch({
      page,
      pageSize,
      search: makeSearchString(),
      filter: {
        statusIds,
        includePositions: false,
        includeContainers: true,
        includePositionStat: true,
        ...(status && { statusId: Number(status) }),
        ...(deliveryDateStart &&
          deliveryDateEnd && {
            preferredDeliveryDateFrom: dayjs(deliveryDateStart).startOf('day').toDate(),
            preferredDeliveryDateTo: dayjs(deliveryDateEnd).endOf('day').toDate(),
          }),
        ...(createDateStart &&
          createDateEnd && {
            createDateStart: dayjs(createDateStart).startOf('day').toDate(),
            createDateEnd: dayjs(createDateEnd).endOf('day').toDate(),
          }),
      },
    })
  }

  useEffect(() => {
    fetchOrders()
  }, [filter])

  return (
    <>
      <OrderListToolbar />
      <OrderListTable />
      <OrderListTablePagination />
    </>
  )
}
