import React from 'react'

import { OrderStatusCode } from '@foods-n-goods/client/system/types'
import { Button, modal } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { Weight } from '@stage-ui/icons'
import { useMouseDrop } from 'components/MouseDrop'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { OrderedPosition } from '..'

import { OrderPositionChangeQuantityModal } from './ChangeQuantityModal'

export function OrderPositionChangeQuantityCell(
  ctx: TableTypes.TableCellContext<OrderedPosition>,
) {
  const { row } = ctx
  const { id, quantityAfter, status, deleteDate, name, unit, parentStatusValue } = row
  const ls = useLocalizedString()
  const disabled =
    !!deleteDate ||
    !!(
      parentStatusValue &&
      ![OrderStatusCode.SORTING, OrderStatusCode.AWAIT_COURIER].includes(
        parentStatusValue,
      )
    ) ||
    [
      OrderStatusCode.PICKED_UP,
      OrderStatusCode.DELIVERY,
      OrderStatusCode.DELIVERED,
      OrderStatusCode.CANCELED,
      OrderStatusCode.REJECTED,
      OrderStatusCode.NOT_DELIVERED,
    ].includes(status.value)

  const hint = useMouseDrop(!disabled && ls.text.changeQuantity)

  const handleChangeQuantity = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    modal({
      title: ls.text.changeQuantity,
      subtitle: name || '',
      w: '25rem',
      overlayClose: false,
      render: (close) => (
        <OrderPositionChangeQuantityModal
          close={close}
          id={id}
          quantityAfter={quantityAfter}
          unit={unit}
          disabled={disabled}
        />
      ),
    })
  }

  return (
    <Button
      disabled={disabled}
      p={0}
      w="2.5rem"
      h="2.5rem"
      decoration="outline"
      color="blue500"
      onClick={handleChangeQuantity}
      {...hint}
    >
      <Weight size="1.5rem" />
    </Button>
  )
}
