import React, { ChangeEvent, useRef, useState } from 'react'

import {
  Client,
  ClientDeliveryAddress,
  ClientDeliveryAddressInput,
} from '@foods-n-goods/server/generated/schema'
import {
  Block,
  Button,
  Checkbox,
  Flexbox,
  modal,
  Spinner,
  TextField,
} from '@stage-ui/core'

import { GeoObjectGeometry, Map, YMaps, YMapsApi } from 'react-yandex-maps'

import request from 'requests/request'

import { ClientActions } from 'actions'

import actionNotify from 'utils/actionNotify'

import { getLocalizedString, useLocalizedString } from 'hooks/useLocalizedString'

import store from 'store'

import DeliveryAddressModalForm from './components/Form'
import DeliveryAddressPlacemark from './components/Placemark'

type ClientAddressChangeModalProps = {
  clientId: Client['id']
  address?: ClientDeliveryAddress
  close: () => void
}

export type Data = ClientDeliveryAddressInput & {
  busy: boolean
  manual: boolean
  deliverAbility: boolean
  disabled: boolean
  showPlacemark: boolean
}

let ymaps: YMapsApi
let map: any

function ClientAddressChangeModal(props: ClientAddressChangeModalProps) {
  const { clientId, close, address } = props
  const ls = useLocalizedString()
  const [mapReady, setMapReady] = useState(false)
  const [suggestions, setSuggestions] = useState<string[]>([])

  const placemarkRef = useRef<{ geometry: GeoObjectGeometry }>()

  const [data, setData] = useState<Data>({
    name: address?.name || '',
    address: address?.address || '',
    lat: address?.lat || '',
    lng: address?.lng || '',
    isDefault: address?.isDefault || false,
    floor: '',
    deliverAbility: false, // Адрес (координаты) в зоне доставки
    showPlacemark: false, // Показывать метку на карте
    busy: false, // Запрос данных с сервера,
    disabled: true,
    manual: false, // Ручной ввод адреса
  })

  const handleSave = async () => {
    if (!address) {
      await request('clientDeliveryAddressAdd', {
        clientId,
        payload: {
          name: data.name,
          address: data.address,
          entrance: data.entrance,
          lat: data.lat,
          lng: data.lng,
          apartment: data.apartment,
          floor: data.floor,
          intercom: data.intercom,
          isDefault: data.isDefault,
        },
        shouldBeDefault: data.isDefault,
      })
    } else if (address.id) {
      await request('clientDeliveryAddressUpdate', {
        id: address.id,
        clientId,
        payload: {
          name: data.name,
          address: data.address,
          entrance: data.entrance,
          lat: data.lat,
          lng: data.lng,
          apartment: data.apartment,
          floor: data.floor,
          intercom: data.intercom,
        },
        shouldBeDefault: data.isDefault,
      })
    }
    await ClientActions.fetchById(clientId)
    close()
  }

  const setBounds = () => {
    if (map && placemarkRef && ymaps) {
      const bounds = placemarkRef.current?.geometry.getBounds()
      const sz = map.container.getSize()
      if (bounds) {
        const { center, zoom } = ymaps.util.bounds.getCenterAndZoom(bounds, sz)
        map.setCenter(center, zoom > 17 ? 17 : zoom)
      }
    }
  }

  const setAddressOptions = async (e: ChangeEvent<HTMLInputElement>) => {
    const addressValue = e.target.value
    setData({
      ...data,
      address: addressValue,
      disabled: true,
      manual: Boolean(addressValue),
    })

    if (addressValue.length <= 3) {
      setSuggestions([])
      return
    }

    const response = await request('clientGetAddressOptions', { address: addressValue })

    if (response) {
      setSuggestions(response.options)
    }
  }

  const setAddressManual = async (address: string) => {
    if (address.length <= 3) {
      setSuggestions([])
      return
    }
    const city = store.getState().app.marketConfig?.city
    const res = await request('clientGeoResolve', {
      data: city ? `${city}, ${address}` : address,
    })

    setData({
      ...data,
      address,
      manual: Boolean(address),
      deliverAbility: res.deliverAbility,
      lat: res.lat,
      lng: res.lng,
      showPlacemark: true,
      busy: false,
      disabled: false,
    })

    setBounds()
  }

  const updateByCoords = async (coords: number[]) => {
    const lng = coords[1]
    const lat = coords[0]

    setData({
      ...data,
      busy: true,
      lng: lng.toString(),
      lat: lat.toString(),
      disabled: true,
      manual: false,
    })

    const res = await request('clientGeoResolve', { data: `${lng},${lat}` })

    setData({
      ...data,
      address: res.address,
      deliverAbility: res.deliverAbility,
      busy: false,
      disabled: false,
      lng: lng.toString(),
      lat: lat.toString(),
    })

    setBounds()
  }

  const handleDeleteAddress = async () => {
    if (!address?.id) return

    try {
      await request('clientDeliveryAddressDelete', {
        id: address.id,
        clientId,
      })
      await ClientActions.fetchById(clientId)
      close()
    } catch (error) {
      actionNotify({
        title: ls.text.error,
        message: error.message as string,
        type: 'error',
      })
    }
  }

  return (
    <Flexbox column>
      <DeliveryAddressModalForm
        data={data}
        disabled={data.busy}
        suggestions={suggestions}
        setAddressManual={setAddressManual}
        setAddressOptions={setAddressOptions}
      />
      <Flexbox my="m">
        <TextField
          value={data.name}
          disabled={data.busy}
          rightChild={data.busy && <Spinner />}
          placeholder={ls.text.clientAddressName}
          onChange={(e) => setData({ ...data, name: e.target.value })}
        />
        <Checkbox
          ml="m"
          checked={data.isDefault}
          disabled={data.busy}
          label={ls.text.setDefault}
          onChange={() => setData({ ...data, isDefault: !data.isDefault })}
        />
      </Flexbox>
      <Block
        h="20rem"
        w="100%"
        borderRadius="m"
        style={{
          overflow: 'hidden',
        }}
      >
        <YMaps
          key="737d18ae-6d68-4563-bc5a-915071f9369e"
          query={{ lang: ls.id === 'ru-RU' ? 'ru_RU' : 'en_RU', load: 'package.full' }}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <Map
            onLoad={(m) => {
              ymaps = m
              setMapReady(true)
            }}
            instanceRef={(ref) => (map = ref)}
            height="100%"
            width="100%"
            defaultState={{
              center: [Number(data.lat), Number(data.lng)],
              zoom: 11,
              controls: [],
            }}
            onClick={(e: any) => {
              if (!e || !e.get) return
              const coords = e.get('coords')
              updateByCoords(coords)
            }}
          >
            {mapReady && (
              <DeliveryAddressPlacemark
                updateByCoords={updateByCoords}
                address={data.address}
                lat={data.lat}
                lng={data.lng}
                ref={placemarkRef}
              />
            )}
          </Map>
        </YMaps>
      </Block>
      <Flexbox mt="xl" alignItems="center" justifyContent="space-between">
        <Button
          label={ls.text.clientAddressDelete}
          onClick={handleDeleteAddress}
          color="red500"
        />
        <Flexbox alignItems="center" justifyContent="flex-end">
          <Button
            decoration="text"
            label={ls.text.cancel}
            onClick={close}
            color="gray500"
          />
          <Button label={ls.text.confirm} onClick={handleSave} ml="m" />
        </Flexbox>
      </Flexbox>
    </Flexbox>
  )
}

export const openClientAddressChangeModal = (
  clientId: Client['id'],
  address?: ClientDeliveryAddress,
  e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  e?.stopPropagation()
  e?.preventDefault()

  modal({
    w: '40rem',
    title: getLocalizedString().text.clientAddressUpdateTitle,
    overlayClose: false,
    render: (close) => (
      <ClientAddressChangeModal clientId={clientId} address={address} close={close} />
    ),
  })
}
