import { OrderStatusCode } from '@foods-n-goods/client/system/types'
import { Order, Staff } from '@foods-n-goods/server/generated/schema'

import { getLocalizedString } from 'hooks/useLocalizedString'

import { ym } from '../MapView/hooks/useYandexMaps'

export const makeRoute = (courier: Staff, orders: Order[], focus = true) => {
  const ls = getLocalizedString()
  const data = orders
    .filter(
      (order) =>
        order.courier?.id === courier.id &&
        order.status.value !== OrderStatusCode.DELIVERED,
    )
    .slice()
    .sort((a, b) => ((a.sequence || 0) > (b.sequence || 0) ? 1 : -1))

  if (!data.length) {
    // eslint-disable-next-line no-alert
    return alert(ls.text.noOrdersForRouteBuilding)
  }
  if (!courier.lastGeoPosition?.coords) {
    // eslint-disable-next-line no-alert
    return alert(ls.text.driverGpsCoordinatesMissing)
  }

  const route: [number, number][] = [
    [
      Number(courier.lastGeoPosition.coords.latitude),
      Number(courier.lastGeoPosition.coords.longitude),
    ],
  ]
  data.forEach((order) => {
    const { lat, lng } = order.deliveryAddress
    if (lat && lng) {
      route.push([Number(lat), Number(lng)])
    }
  })
  ym.ref?.courierRouteCreate(courier.id, route, focus)
}
