import React from 'react'

import { OrderStatusCode } from '@foods-n-goods/client/system/types'
import { Button, Flexbox, Text } from '@stage-ui/core'
import { PurchasesActions } from 'actions'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { PurchaseExtended, usePurchasesData } from '../data'

type PurchaseDeleteModalProps = {
  purchaseRow: PurchaseExtended
  close: () => void
}

export const PurchaseDeleteModal: React.FC<PurchaseDeleteModalProps> = (props) => {
  const { purchaseRow, close } = props
  const ls = useLocalizedString()
  const { filteredData } = usePurchasesData()

  const handleSave = () => {
    const purchasesToDelete = purchaseRow._isExpandable
      ? filteredData.filter(
          (p) =>
            purchaseRow.product.id === p.product.id &&
            p.status.value !== OrderStatusCode.PURCHASE_CONFIRMED,
        )
      : [purchaseRow]

    for (const p of purchasesToDelete) {
      PurchasesActions.purchaseDelete({ ids: [p.id] })
    }
    close()
  }

  return (
    <Flexbox column>
      <Text mb="l">
        {purchaseRow._isExpandable
          ? ls.text.purchaseDeleteWarning2
          : ls.text.purchaseDeleteWarning1}
      </Text>
      <Flexbox justifyContent="flex-end">
        <Button
          decoration="text"
          color="gray500"
          label={ls.text.cancel}
          onClick={close}
          mr="m"
        />
        <Button color="red500" label={ls.text.confirm} onClick={handleSave} />
      </Flexbox>
    </Flexbox>
  )
}
