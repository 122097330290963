import React, { Fragment, useState } from 'react'

import { Client } from '@foods-n-goods/server/generated/schema'
import { Button, Divider, Flexbox, Header, modal, Text, TextField } from '@stage-ui/core'
import { Checkmark } from '@stage-ui/icons'
import { ClientActions } from 'actions'
import { getLocalizedString, useLocalizedString } from 'hooks/useLocalizedString'

type ClientStatusChangeModalProps = {
  client: Client
  close: () => void
}

function ClientStatusChangeModal(props: ClientStatusChangeModalProps) {
  const { client, close } = props
  const { id, markets, name, identifier } = client
  const ls = useLocalizedString()
  const clientName = markets[0]?.alias || name || identifier || ls.text.unidentifiedClient
  const isBlocked = markets[0]?.status === 'BLOCKED'
  const blockReasons = [
    { text: ls.text.clientBlockReasonDebt, value: 1 },
    { text: ls.text.clientBlockReasonRejectRate, value: 2 },
    { text: ls.text.other, value: -1 },
  ]
  const [reasonValue, setReasonValue] = useState(0)
  const [otherReasonText, setOtherReasonText] = useState('')

  const handleBlock = async () => {
    if (!reasonValue || (reasonValue === -1 && !otherReasonText)) return

    const reasonText =
      reasonValue === -1
        ? otherReasonText
        : blockReasons.find((br) => br.value === reasonValue)?.text

    if (!reasonText) return

    await ClientActions.marketUpdate({
      id,
      payload: {
        status: 'BLOCKED',
        comment: reasonText,
      },
    })
    close()
  }

  const handleUnblock = async () => {
    await ClientActions.marketUpdate({
      id,
      payload: {
        status: 'APPROVED',
        comment: '',
      },
    })
    close()
  }

  if (isBlocked) {
    return (
      <Flexbox column flex={1}>
        <Header size="xs" mb="xl" color="gray900">
          {clientName}
        </Header>
        <Text mb="l" color="gray500">
          {ls.text.clientUnblockHint}
        </Text>
        <Flexbox mt="xl" alignItems="center" justifyContent="flex-end">
          <Button
            decoration="text"
            label={ls.text.cancel}
            onClick={close}
            color="gray500"
          />
          <Button label={ls.text.unblock} onClick={handleUnblock} color="red500" ml="m" />
        </Flexbox>
      </Flexbox>
    )
  }

  return (
    <Flexbox column flex={1}>
      <Header size="xs" mb="xl" color="gray900">
        {clientName}
      </Header>
      <Text mb="l" color="gray500">
        {ls.text.clientBlockReason}
      </Text>
      <Flexbox
        column
        borderStyle="solid"
        borderWidth="1px"
        borderColor="gray200"
        borderRadius="m"
        backgroundColor="white"
      >
        {blockReasons.map((reason, reasonIndex) => (
          <Fragment key={reason.value}>
            <Flexbox
              justifyContent="space-between"
              alignItems="center"
              p="s m"
              onClick={() => setReasonValue(reason.value)}
            >
              <Text size="s" color="gray900">
                {reason.text}
              </Text>
              <Flexbox
                centered
                flexShrink={0}
                w="1.5rem"
                h="1.5rem"
                borderStyle="solid"
                borderWidth="1px"
                borderColor={reason.value === reasonValue ? 'green500' : 'gray200'}
                borderRadius="50%"
              >
                {reason.value === reasonValue && (
                  <Checkmark size="1.125rem" color="green500" />
                )}
              </Flexbox>
            </Flexbox>
            <Divider
              color="gray200"
              display={reasonIndex === blockReasons.length - 1 ? 'none' : 'block'}
            />
          </Fragment>
        ))}
      </Flexbox>
      {reasonValue === -1 && (
        <TextField
          mt="m"
          value={otherReasonText}
          placeholder={ls.text.clientBlockReason}
          onChange={(e) => setOtherReasonText(e.target.value)}
        />
      )}
      <Flexbox mt="xl" alignItems="center" justifyContent="flex-end">
        <Button
          decoration="text"
          label={ls.text.cancel}
          onClick={close}
          color="gray500"
        />
        <Button label={ls.text.block} onClick={handleBlock} color="red500" ml="m" />
      </Flexbox>
    </Flexbox>
  )
}

export const openClientStatusChangeModal = (
  client: Client,
  e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  e?.stopPropagation()
  e?.preventDefault()

  const isBlocked = client.markets[0]?.status === 'BLOCKED'
  const ls = getLocalizedString()
  modal({
    title: isBlocked ? ls.text.clientUnblockWarning : ls.text.clientBlockWarning,
    w: '35rem',
    overlayClose: false,
    overrides: {
      header: () => [
        {
          marginBottom: 0,
        },
      ],
    },
    render: (close) => <ClientStatusChangeModal client={client} close={close} />,
  })
}
