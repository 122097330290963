import React from 'react'

import TableTypes from '@stage-ui/core/data/Table/types'
import { Text } from '@stage-ui/core'
import { Order } from '@foods-n-goods/server/generated/schema'
import dayjs from '@foods-n-goods/client/utils/dayjs'

export function OrderCreateCell(ctx: TableTypes.TableCellContext<Order>) {
  const { row } = ctx
  const { createDate } = row
  return <Text size="s">{dayjs(createDate).format('DD.MM.YYYY')}</Text>
}
