import React, { useMemo } from 'react'

import { PageTable } from 'components/PageTable'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'

import { localizeMessageWithParams, useLocalizedString } from 'hooks/useLocalizedString'

import { getCurrencySymbol } from 'hooks/useCurrency'

import { getColumns } from './misc'

function JournalTable() {
  const { data, currencyCode } = useSelector(
    ({ journal, app }) => ({
      data: journal.data,
      currencyCode: app.marketConfig?.currency,
    }),
    objectEqual,
  )
  const ls = useLocalizedString()
  const columns = useMemo(getColumns, [ls.id])
  const currencySymbol = getCurrencySymbol(currencyCode, ls)
  const localizedData = useMemo(
    () =>
      data.map((item) => {
        if (item.parameters?.length) {
          return {
            ...item,
            action: localizeMessageWithParams(
              item.action,
              item.parameters,
              ls.string,
              currencySymbol,
            ),
          }
        }
        return { ...item, action: ls.string[item.action] }
      }),
    [data, ls.id],
  )
  return (
    <PageTable
      noDataText={ls.text.journalEmpty}
      data={localizedData}
      columns={columns}
      pagination={{
        pageSize: 40,
      }}
    />
  )
}

export default JournalTable
