import React from 'react'

import { Block, Button, Flexbox, modal, Paragraph, Text, useTheme } from '@stage-ui/core'
import * as Icons from '@stage-ui/icons'
import { AppActions } from 'actions'
import ImageController from 'components/ImageController'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { useLocalizedString } from 'hooks/useLocalizedString'

function User() {
  const theme = useTheme()
  const user = useSelector(({ app }) => app.user, objectEqual)
  const ls = useLocalizedString()
  if (!user) return null
  const name = user.name || user.login || ls.text.unidentifiedClient

  return (
    <Flexbox justifyContent="space-between" alignItems="center">
      <Flexbox column mr="1.5rem">
        <Text
          color="gray400"
          size="xs"
          mb="xs"
          weight={500}
          style={{ whiteSpace: 'nowrap' }}
          children={ls.string[user.role.code]}
        />
        <Text color="gray900" size="s" weight={500} w="8rem" ellipsis children={name} />
      </Flexbox>
      <ImageController
        id={user.id}
        url={user.photo}
        person
        size="3rem"
        round
        readonly
        hideTitle
        wrapperCss={{
          borderWidth: 0,
          backgroundColor: theme.color.gray[400].hex(),
        }}
      />
      <Button
        w="3rem"
        h="3rem"
        decoration="plain"
        color="hard"
        ml="1.5rem"
        borderRadius="s"
        borderColor="gray200"
        backgroundColor="gray50"
        children={<Icons.LogOut size="xl" color="gray500" />}
        onClick={() => {
          modal({
            title: ls.text.exit,
            w: '20rem',
            overlayClose: false,
            render: (close) => (
              <Block>
                <Paragraph children={ls.text.logoutConfirmRequest} />
                <Flexbox justifyContent="flex-end" mt="2rem">
                  <Button
                    children={ls.text.cancel}
                    color="onSurface"
                    decoration="text"
                    onClick={close}
                  />
                  <Button
                    children={ls.text.logout}
                    color="primary"
                    ml="0.5rem"
                    onClick={() => {
                      close()
                      AppActions.signOut()
                    }}
                  />
                </Flexbox>
              </Block>
            ),
          })
        }}
      />
    </Flexbox>
  )
}

export default User
