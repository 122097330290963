import React from 'react'

import { Button, Flexbox, Grid, Header, Text } from '@stage-ui/core'
import { openClientExternalIdChangeModal } from 'components/Modals/ClientExIdChange'
import { openClientDeclineModal } from 'components/Modals/ClientDecline'
import { Client } from '@foods-n-goods/server/generated/schema'
import { useLocalizedString } from 'hooks/useLocalizedString'

type ClientStatusBannerProps = {
  client: Client
}
export function ClientStatusBanner(props: ClientStatusBannerProps) {
  const { client } = props
  const ls = useLocalizedString()
  if (client.markets[0]?.status === 'AWAITING') {
    return (
      <Flexbox
        mt="xl"
        flex={1}
        p="l"
        backgroundColor="yellow500"
        borderRadius="s"
        justifyContent="space-between"
      >
        <Flexbox
          column
          style={{
            maxWidth: '33rem',
          }}
          mr="m"
        >
          <Header size="xs" color="white" mb="m">
            {ls.text.clientCannotPlaceOrder}
          </Header>
          <Text size="s" color="white">
            {ls.text.client1CSync}
          </Text>
        </Flexbox>

        <Grid
          flexShrink={0}
          gap="0.5rem"
          alignItems="center"
          style={{
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: '1fr',
            gridAutoFlow: 'dense',
            'button:first-of-type': {
              gridColumn: 2,
            },
            '@media(max-width: 1680px)': {
              gridTemplateColumns: '1fr',
              gridTemplateRows: 'min-content min-content',
              placeContent: 'center',
              'button:first-of-type': {
                gridColumn: 1,
              },
            },
          }}
        >
          <>
            <Button
              size="m"
              label={ls.text.issuePriceList}
              color="white"
              textColor="yellow500"
              onClick={() => openClientExternalIdChangeModal(client)}
            />
            <Button
              w="100%"
              size="m"
              label={ls.text.decline}
              color="yellow500"
              textColor="white"
              decoration="outline"
              borderColor="white"
              onClick={() => openClientDeclineModal(client)}
            />
          </>
        </Grid>
      </Flexbox>
    )
  }

  if (['DECLINED', 'BLOCKED'].includes(client.markets[0]?.status)) {
    return (
      <Flexbox mt="xl" flex={1} p="l" backgroundColor="red500" borderRadius="s" column>
        <Header size="xs" color="white">
          {client.markets[0].status === 'DECLINED'
            ? ls.text.clientDeclineStatus
            : ls.text.clientBlockStatus}
        </Header>
        {client.markets[0].comment && (
          <Text size="s" mt="m" color="white">
            {ls.text.reason.replace('[1]', client.markets[0].comment)}
          </Text>
        )}
      </Flexbox>
    )
  }

  return null
}
