/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'

import { Order, OrderPositionsStat } from '@foods-n-goods/server/generated/schema'
import { Flexbox, Grid, Text } from '@stage-ui/core'
import {
  posStatus,
  PosStatus,
} from 'pages/orders/OrderDetails/GeneralInformation/Positions/Table/components/StatusCell'
import { useLocalizedString } from 'hooks/useLocalizedString'

type OrderBadgeHintProps = {
  order: Order
}

export function OrderBadgeHint(props: OrderBadgeHintProps) {
  const { order } = props
  const ls = useLocalizedString()
  const posStatuses: Partial<Record<keyof OrderPositionsStat, [number, PosStatus]>> = {}

  Object.keys(order.positionsStat).forEach((key) => {
    const statusKey = key as keyof OrderPositionsStat

    if (statusKey.startsWith('pos')) {
      if (order.positionsStat[statusKey]) {
        posStatuses[statusKey] = [order.positionsStat[statusKey], posStatus(statusKey)]
      }
    }
  })

  return (
    <Grid autoFlow="row" gap="0.5rem" p="xs">
      {Object.keys(posStatuses).length === 0 && (
        <Text size="xs" weight={500} color="white">
          {ls.text.orderIsEmpty}
        </Text>
      )}
      {Object.keys(posStatuses).map((key) => {
        const Status = posStatuses[key as keyof OrderPositionsStat]![1]
        return (
          <Flexbox justifyContent="space-between" key={key} style={{ minWidth: '10rem' }}>
            <Flexbox alignItems="center">
              <Status.Icon color={Status.color} type={Status.type} size="1rem" />
              <Text size="xs" weight={500} color="white" ml="s">
                {Status.tooltip}
              </Text>
            </Flexbox>
            <Text size="xs" weight={500} color="white" ml="m">
              {posStatuses[key as keyof OrderPositionsStat]![0]}
            </Text>
          </Flexbox>
        )
      })}
    </Grid>
  )
}
