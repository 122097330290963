import { getLocalizedString } from 'hooks/useLocalizedString'

import actionNotify from './actionNotify'

export function copyElementToClipboard(id: string, notify: boolean = false) {
  const range = document.createRange()
  const element = document.getElementById(id)
  if (!element) return

  range.selectNode(element)
  const selection = window.getSelection()

  if (selection) {
    selection.removeAllRanges()

    selection.addRange(range)
    document.execCommand('copy')

    if (notify) {
      actionNotify({
        title: '',
        message: getLocalizedString().text.dataCopySuccess,
        type: 'success',
      })
    }

    selection.removeAllRanges()
  }
}
