import React, { useMemo } from 'react'

import { OrderStatusCode } from '@foods-n-goods/client/system/types'
import { DatePicker, Flexbox, Grid } from '@stage-ui/core'
import MenuNavigation from 'components/MenuNavigation'

import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

import { PurchasesActions } from 'actions'

import FilterCommon from 'components/common/FilterCommon'

import { getLocalizedString, useLocalizedString } from 'hooks/useLocalizedString'

import dayjs from 'dayjs'

import DatePickerTypes from '@stage-ui/core/control/DatePicker/types'

import { PurchaserFilter } from './PurchaserFilter'
import { PurchasesToolbarContextMenu } from './ContextMenu'

export const getPurchasesMenuItems = () => {
  const ls = getLocalizedString()
  return [
    { id: '', title: ls.text.all },
    {
      id: 'inRepurchase',
      statusIds: [OrderStatusCode.PURCHASE_PENDING, OrderStatusCode.OUT_OF_STOCK],
      title: ls.text.restock,
    },
    {
      id: 'confirmed',
      statusIds: [OrderStatusCode.PURCHASE_CONFIRMED],
      title: ls.text.purchased,
    },
    {
      id: 'failed',
      statusIds: [OrderStatusCode.PURCHASE_FAILED],
      title: ls.text.outOfStock,
    },
    { id: 'canceled', statusIds: [OrderStatusCode.CANCELED], title: ls.text.cancelOrder },
    { id: 'byProducts', statusIds: [], title: ls.text.rangeOfOrders },
  ]
}

export function PurchasesToolbar() {
  const filter = useSelector((state) => state.purchases.filter, objectEqual)
  const ls = useLocalizedString()
  const purchasesMenuItems = useMemo(getPurchasesMenuItems, [ls.id])
  return (
    <Flexbox justifyContent="space-between" alignItems="flex-end" mb="m">
      <MenuNavigation
        items={purchasesMenuItems}
        currentSection={filter.section}
        onChange={(section) => PurchasesActions.setFilter({ section })}
      />
      <Grid
        ml="m"
        autoFlow="column"
        gap="0.5rem"
        templateColumns="1fr 12rem 2.5rem 2.5rem"
      >
        <DatePicker
          locale={ls.id?.split('-')[0] as DatePickerTypes.Props['locale']}
          range
          size="s"
          type="day"
          format="DD MMM"
          placeholder={ls.text.period}
          value={[new Date(filter.createDateStart), new Date(filter.createDateEnd)]}
          onChangeRange={(range) => {
            if (range[0] && range[1]) {
              PurchasesActions.setFilter({
                createDateStart: dayjs(range[0]).format('YYYY-MM-DD'),
                createDateEnd: dayjs(range[1]).format('YYYY-MM-DD'),
              })
            }
          }}
          overrides={{
            field: {
              maxWidth: '11rem',
              transition: 'background .2s ease-in-out',
              background:
                filter.createDateStart && filter.createDateEnd ? 'white' : 'transparent',
              ':focus-within': {
                background: 'white',
              },
            },
          }}
        />
        <FilterCommon
          onSearch={(search) => PurchasesActions.setFilter({ search })}
          initial={filter.search}
        />
        <PurchaserFilter />
        <PurchasesToolbarContextMenu />
      </Grid>
    </Flexbox>
  )
}
