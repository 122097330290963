import React from 'react'

import { ProductReview } from '@foods-n-goods/server/generated/schema'
import Content from 'components/Content'
import ViewField from 'components/Fields/ViewField'
import Rater from 'components/Rater'
import ViewGridBlock from 'components/ViewGridBlock'

import { useLocalizedString } from 'hooks/useLocalizedString'

import dayjs from 'dayjs'

import ReviewViewHeader from './Header'

interface ReviewViewGeneralProps {
  modal?: boolean
  review: ProductReview
}

function ReviewViewGeneral(props: ReviewViewGeneralProps) {
  const {
    modal,
    review: {
      product,
      order,
      createDate,
      rating,
      moderateDate,
      published,
      description,
      text,
    },
  } = props
  const ls = useLocalizedString()
  return (
    <Content>
      <ReviewViewHeader modal={modal} />
      <ViewGridBlock templateColumns="repeat(3, 1fr)">
        <ViewField text={ls.text.order1} value={order.id} />
        <ViewField
          text={ls.text.reviewDate}
          value={dayjs(createDate).format('DD MMMM, YYYY')}
        />
        <ViewField
          text={ls.text.moderateDate}
          value={moderateDate && dayjs(moderateDate).format('DD MMMM, YYYY')}
        />
        <ViewField text={ls.text.product1} value={product.name} />
        <ViewField text={ls.text.rating} value={<Rater rating={rating} />} />
        <ViewField
          text={ls.text.published1}
          value={published ? ls.text.yes : ls.text.no}
        />
        <ViewField
          text={ls.text.shortDescription}
          value={description}
          gridColumnEnd="span 3"
        />
        <ViewField text={ls.text.review} value={text} gridColumnEnd="span 3" />
      </ViewGridBlock>
    </Content>
  )
}

export default ReviewViewGeneral
