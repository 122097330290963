import React, { useMemo } from 'react'

import { Block, Divider, Flexbox, Paragraph, Text, useTheme } from '@stage-ui/core'
import { Menu, Timer } from '@stage-ui/icons'
import { DeliveryActions } from 'actions'
import { useMouseDrop } from 'components/MouseDrop'
import { mapStatusToColor } from 'components/StatusIndicator'
import useSelector from 'hooks/useSelector'
import { ym } from 'pages/logistics/MapView/hooks/useYandexMaps'
import { OrderDelivery } from 'store/delivery'
import { humanize } from 'pages/logistics/utils/humanize'
import { objectEqual } from 'utils/objectEqual'
import { useLocalizedString } from 'hooks/useLocalizedString'
import dayjs from 'dayjs'

type DnDObject = {
  source: string
  target: string
}

const dndObject: DnDObject = {
  source: '',
  target: '',
}

type DragableOrderProps = {
  order: OrderDelivery
  orders: OrderDelivery[]
  index: number
  last: boolean
}

export function DragableOrder(props: DragableOrderProps) {
  const { order, index, orders, last } = props

  const theme = useTheme()
  const ls = useLocalizedString()
  const { courierRoute, currentOrderId, hideSimilarClientOrders } = useSelector(
    (state) => ({
      courierRoute: state.delivery.courierRoute,
      currentOrderId: state.delivery.currentOrder,
      hideSimilarClientOrders: Number(
        state.app.marketConfig?.logisticsCourierHideSimilarClientOrders || '1',
      ),
    }),
    objectEqual,
  )

  const routeHumanized = useMemo(() => {
    let length = 0
    let time = 0

    courierRoute?.segments.forEach((segment, idx) => {
      if (idx <= index) {
        length += segment.length
        time += segment.time
      }
    })

    if (length || time) {
      return {
        time: {
          at: dayjs().add(time, 'second').format('HH:mm'),
          humanized: time
            ? ls.text.inTime.replace('[1]', humanize.seconds(time))
            : ls.text.soon,
        },
        length: {
          humanized: humanize.seconds(length),
        },
      }
    }

    return null
  }, [courierRoute, index])

  const orderColor = mapStatusToColor(order.status.value, 'fill', theme)
  const statusHint = useMouseDrop(
    !hideSimilarClientOrders && `#${order.id} - ${order.status.text}`,
  )
  const routeHint = useMouseDrop(routeHumanized?.time.humanized)

  const getAssociatedOrderIds = orders
    .filter(
      (o) =>
        order.deliveryAddress.lat === o.deliveryAddress.lat &&
        order.deliveryAddress.lng === o.deliveryAddress.lng,
    )
    .map(({ id }) => id)

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement
    target.style.setProperty('border-top-color', 'rgba(255,255,255,0)')
    if (order.id !== dndObject.source) {
      dndObject.target = order.id

      const source = orders.find((o) => dndObject.source === o.id)
      const idx = orders.findIndex((o) => dndObject.target === o.id)

      const t1 = orders[idx]
      const t2 = orders[idx - 1]

      if (source && t1) {
        if (t1.sequence && t1.sequence !== t2?.sequence) {
          if (t2?.sequence) {
            DeliveryActions.setOrderSequence(source, (t1.sequence + t2.sequence) / 2)
          } else {
            DeliveryActions.setOrderSequence(source, t1.sequence / 2)
          }
        } else {
          DeliveryActions.setOrderSequence(source, (t1.sequence || 0) + 1)
        }
      }
    }
  }

  const onDropLast = (e: React.DragEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement
    target.style.setProperty('border-top-color', 'rgba(255,255,255,0)')

    const order = orders.find(({ id }) => id === dndObject.source)
    const sequence = Math.ceil(orders[orders.length - 1].sequence || 0) + 1
    if (order && sequence) {
      DeliveryActions.setOrderSequence(order, sequence)
    }
  }

  const onClick = () => {
    DeliveryActions.setActiveOrders(getAssociatedOrderIds)
    ym.ref?.focus(getAssociatedOrderIds, true)
    ym.ref?.trackClear()
  }

  const onMouseEnter = () => {
    ym.ref?.hover(getAssociatedOrderIds)
  }

  const onMouseLeave = () => {
    ym.ref?.hover(getAssociatedOrderIds, false)
  }

  const onDragStart = () => {
    dndObject.source = order.id
    dndObject.target = ''
  }

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement
    if (order.id !== dndObject.source) {
      target.style.setProperty('border-top-color', theme.color.blue[500].hex())
      dndObject.target = order.id
    }
  }

  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement
    target.style.setProperty('border-top-color', 'rgba(255,255,255,0)')
    dndObject.target = ''
  }

  const onDragEnterLast = (e) => {
    const target = e.target as HTMLDivElement
    target.style.setProperty('border-top-color', theme.color.blue[500].hex())
    dndObject.target = ''
  }

  const onDragLeaveLast = (e) => {
    const target = e.target as HTMLDivElement
    target.style.setProperty('border-top-color', 'rgba(255,255,255,0)')
  }

  return (
    <>
      <Flexbox
        draggable
        attributes={{
          'data-id': order.id,
          'data-hover': 'false',
        }}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onDragStart={onDragStart}
        onDragOver={onDragOver}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        p="m"
        alignItems="center"
        style={{
          cursor: 'grab',
          borderBottom: '0.0625rem',
          borderBottomStyle: 'solid',
          borderBottomColor: theme.color.gray[200]
            .alpha(last ? 0 : 1)
            .rgb()
            .string(),
          borderTopColor: 'rgba(255,255,255,0)',
          borderTopStyle: 'dashed',
          borderTopWidth: '0.125rem',
          background:
            currentOrderId && getAssociatedOrderIds.includes(currentOrderId)
              ? theme.color.green[50].hex()
              : theme.color.surface.hex(),
          '&[data-hover="true"]': {
            background: theme.color.gray[100].hex(),
          },
        }}
      >
        <Block flex={1}>
          <Flexbox alignItems="center">
            <Flexbox
              centered
              decoration="surface"
              w="1.5rem"
              h="1.5rem"
              mr="0.825rem"
              borderRadius="3rem"
              style={{
                background: orderColor.background,
                borderColor: orderColor.borderColor,
              }}
              {...statusHint}
            >
              <Text size="xs" color={orderColor.color}>
                {order.sequenceOrder}
              </Text>
            </Flexbox>
            <Text size="xs" weight={600} color="gray900">
              {order.client.markets?.[0]?.alias || order.client.defaultCompany?.shortName}
              {!hideSimilarClientOrders && ` [#${order.id}]`}
            </Text>
          </Flexbox>
          <Paragraph
            mt="xs"
            ml="2.4375rem"
            size="xs"
            weight={400}
            color={order.deliveryAddress.address ? 'gray500' : 'red500'}
            style={{ pointerEvents: 'none' }}
          >
            {order.deliveryAddress.address || ls.text.addressNotSpecified}
          </Paragraph>
          <Flexbox ml="2.4375rem" mt="s" alignItems="center">
            <Text
              size="xs"
              weight={500}
              color="gray900"
              style={{ pointerEvents: 'none' }}
            >
              {order.preferredDeliveryDate
                ? dayjs(order.preferredDeliveryDate).format('DD.MM')
                : '-'}
            </Text>
            {!!order.preferDeliveryInterval && (
              <Divider vertical w="0.0625rem" h="1rem" color="gray500" mx="s" />
            )}
            <Text
              size="xs"
              weight={500}
              color="gray900"
              style={{ pointerEvents: 'none' }}
            >
              {order.preferDeliveryInterval}
            </Text>
            <Block flex={1} />
            {routeHumanized && (
              <Flexbox {...routeHint} alignItems="center">
                <Timer color="gray300" mr="xs" />
                <Text size="xs" weight={500} color="gray900">
                  {routeHumanized?.time.at}
                </Text>
              </Flexbox>
            )}
          </Flexbox>
        </Block>
        <Menu size="0.875rem" color="gray400" ml="m" />
      </Flexbox>
      {last && (
        <Block
          h="2rem"
          style={{
            borderTopColor: 'rgba(255,255,255,0)',
            borderTopStyle: 'dashed',
            borderTopWidth: '0.125rem',
          }}
          onDragEnter={onDragEnterLast}
          onDragLeave={onDragLeaveLast}
          onDragOver={onDragOver}
          onDrop={onDropLast}
        />
      )}
    </>
  )
}
