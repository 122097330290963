import React, { useState } from 'react'

import { Divider, Flexbox, Text } from '@stage-ui/core'
import { MenuArrow } from '@stage-ui/icons'
import Logo from 'components/Logo'
import { ym } from 'pages/logistics/MapView/hooks/useYandexMaps'
import { useHistory } from 'react-router-dom'

import Menu from './Menu'

function Sidebar() {
  const [compact, setCompact] = useState(!!window.localStorage.getItem('compactMenu'))
  const history = useHistory()
  return (
    <Flexbox
      borderRadius={0}
      borderColor="gray200"
      borderWidth="0 1px 0 0"
      borderStyle="solid"
      backgroundColor="gray50"
      p="s"
      flexShrink={0}
      style={{
        width: compact ? '4rem' : '15.625rem',
        minWidth: compact ? '4rem' : '15.625rem',
        height: '100%',
        boxSizing: 'border-box',
        flexDirection: 'column',
        '@media print': {
          display: 'none',
        },
      }}
    >
      <Flexbox column alignItems={compact ? 'center' : 'auto'} p={compact ? 'm xs' : 'm'}>
        <Flexbox justifyContent="space-between" alignItems="center" mb="l">
          {!compact && (
            <Logo flexShrink={0} w="6rem" onClick={() => history.push('/home')} />
          )}
          <MenuArrow
            size="1.5rem"
            color="gray400"
            style={{
              transform: `rotate(${compact ? 180 : 0}deg)`,
            }}
            onClick={() => {
              setCompact((p) => {
                window.localStorage.setItem('compactMenu', p ? '' : 'Y')
                return !p
              })
              setTimeout(() => ym.ref?.fitToViewport(), 1)
            }}
          />
        </Flexbox>
        <Divider mt="xs" color="gray200" />
      </Flexbox>
      <Menu compact={compact} />
      <Text size="0.625rem" color="gray400">
        v.{document.head.querySelector('meta[name=app]')?.getAttribute('version')}.
        {document.head.querySelector('meta[name=app]')?.getAttribute('build')}
      </Text>
    </Flexbox>
  )
}

export default Sidebar
