/* eslint-disable no-nested-ternary */
import React from 'react'

import { Order } from '@foods-n-goods/server/generated/schema'
import { Flexbox, Paragraph } from '@stage-ui/core'
import { Car } from '@stage-ui/icons'
import { OrderStatusCode } from '@foods-n-goods/client/system/types'
import { openOrderCourierChangeModal } from 'components/Modals/OrderCourierChange'
import { useMouseDrop } from 'components/MouseDrop'
import { useStaff } from 'hooks/useStaff'
import { useLocalizedString } from 'hooks/useLocalizedString'

type CourierSelectProps = {
  order: Order
  onlyIcon?: boolean
  style?: { [key: string]: string | number }
}

const changeAvailableStatuses = [
  OrderStatusCode.NEW,
  OrderStatusCode.PAYED,
  OrderStatusCode.SORTING,
  OrderStatusCode.AWAIT_COURIER,
  OrderStatusCode.OUT_OF_STOCK,
  OrderStatusCode.PURCHASE_PENDING,
  OrderStatusCode.PURCHASE_CONFIRMED,
  OrderStatusCode.PURCHASE_FAILED,
]

export function CourierSelect(props: CourierSelectProps) {
  const { order, style, onlyIcon } = props
  const { status } = order
  const ls = useLocalizedString()
  const courier = useStaff(order.courier?.id)
  const color = courier?.color?.code || 'gray200'

  const changeAvailable = changeAvailableStatuses.includes(status.value)

  const text = changeAvailable ? ls.text.courierAssign : ls.text.notAssigned

  const hint = useMouseDrop(
    onlyIcon && ((courier?.name || courier?.login) ?? ls.text.courierNotAssigned),
  )

  const handleChange = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    if (!changeAvailable) return
    openOrderCourierChangeModal([order])
  }

  return (
    <Flexbox
      justifyContent="flex-end"
      alignItems="center"
      onClick={handleChange}
      {...hint}
      style={{
        cursor: changeAvailable ? 'pointer' : 'not-allowed',
      }}
    >
      <Car color={color} size="1.25rem" />
      {!onlyIcon && (
        <Paragraph
          ml="s"
          size="s"
          ellipsis
          borderColor={courier ? color : 'gray200'}
          color={courier ? color : changeAvailable ? 'primary' : 'gray500'}
          style={{
            borderBottom: changeAvailable ? '1px dashed' : 'none',
            maxWidth: '9.5rem',
            ...style,
          }}
        >
          {courier ? courier.name || courier.login : text}
        </Paragraph>
      )}
    </Flexbox>
  )
}

export default CourierSelect
