import React, { useMemo } from 'react'

import { DatePicker, Flexbox } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'
import MenuNavigation from 'components/MenuNavigation'

import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { DeliveryActions } from 'actions'

import { useLocalizedString } from 'hooks/useLocalizedString'

import dayjs from 'dayjs'

import DatePickerTypes from '@stage-ui/core/control/DatePicker/types'

import { LogisticsMenuContextMenu } from './ContextMenu'

type LogisticsMenuProps = FlexboxTypes.Props

export function LogisticsMenu(props: LogisticsMenuProps) {
  const filter = useSelector((state) => state.delivery.filter, objectEqual)
  const ls = useLocalizedString()
  const logisticsMenuItems = [
    { id: 'orders', title: ls.text.orders1 },
    { id: 'couriers', title: ls.text.couriers },
    { id: 'coordinator', title: ls.text.coordinator },
  ]
  const defaultValue = useMemo(() => {
    if (filter.date) {
      return dayjs(filter.date).toDate()
    }
    return dayjs().toDate()
  }, [])

  return (
    <Flexbox {...props} alignItems="center">
      <MenuNavigation
        items={logisticsMenuItems}
        currentSection={filter.section}
        onChange={(section) => DeliveryActions.setFilter({ section })}
      />
      <DatePicker
        locale={ls.id?.split('-')[0] as DatePickerTypes.Props['locale']}
        ml="s"
        size="s"
        flexBasis="5rem"
        placeholder={ls.text.delivery}
        defaultValue={defaultValue}
        onChange={(value) => {
          DeliveryActions.setFilter({
            date: value ? dayjs(value).format('YYYY-MM-DD') : '',
          })
        }}
        format="DD MMM"
        overrides={{
          field: {
            transition: 'background .2s ease-in-out',
            background: defaultValue ? 'white' : 'transparent',
            ':focus-within': {
              background: 'white',
            },
          },
        }}
      />
      <LogisticsMenuContextMenu />
    </Flexbox>
  )
}
