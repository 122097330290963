import React, { Fragment, useEffect, useRef } from 'react'

import { Block, Flexbox, ScrollView, Spinner, Table, Text } from '@stage-ui/core'
import ScrollViewType from '@stage-ui/core/layout/ScrollView/types'
import { ChatNew, MessageCircle } from '@stage-ui/icons'
import { ChatActions } from 'actions'
import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

import { useLocalizedString } from 'hooks/useLocalizedString'

import dayjs from '@foods-n-goods/client/utils/dayjs'

import Message from './components/Message'
import MessageInput from './components/MessageInput'
import MessageRoomHeader from './components/MessageRoomHeader'

let dateStickerPrev = ''
let dateFadeTimer: ReturnType<typeof window.setTimeout>

const StickyDate: React.FC<{ date: string }> = (props) => {
  const { date } = props
  const ls = useLocalizedString()
  let dateSticker = dayjs(date).format('DD MMMM')
  if (dayjs(date).format('DD MMMM') === dayjs().format('DD MMMM')) {
    dateSticker = ls.text.today
  }
  if (dayjs(date).format('DD MMMM') === dayjs().add(-1, 'day').format('DD MMMM')) {
    dateSticker = ls.text.yesterday
  }

  if (dateStickerPrev === dateSticker) {
    return null
  }

  dateStickerPrev = dateSticker

  return (
    <>
      <Flexbox
        centered
        attributes={{
          'data-id': 'dt',
        }}
        style={{
          zIndex: 2,
          pointerEvents: 'none',
          position: 'sticky',
          top: '6rem',
          transition: 'all 0.125s',
          opacity: 0,
          transform: 'translateY(1.5rem)',
        }}
      >
        <Flexbox
          centered
          backgroundColor="lightGreen100"
          borderRadius="0.25rem"
          p="0.125rem 0.75rem 0.25rem 0.75rem"
          style={{ minWidth: '6rem' }}
        >
          <Text size="s" color="lightGreen900">
            {dateSticker}
          </Text>
        </Flexbox>
      </Flexbox>
      <Flexbox
        centered
        style={{
          zIndex: 1,
          pointerEvents: 'none',
        }}
      >
        <Flexbox
          centered
          backgroundColor="lightGreen100"
          borderRadius="0.25rem"
          p="0.125rem 0.75rem 0.25rem 0.75rem"
          style={{ minWidth: '6rem' }}
        >
          <Text size="s" color="lightGreen900">
            {dateSticker}
          </Text>
        </Flexbox>
      </Flexbox>
    </>
  )
}

function MessagesView() {
  const { userId, activity, activeRoom } = useSelector(
    (state) => ({
      userId: state.app.user?.id,
      activity: state.chat.roomActivity[state.chat.activeRoomId],
      activeRoom: state.chat.rooms.find(
        ({ roomId }) => roomId === state.chat.activeRoomId,
      ),
    }),
    objectEqual,
  )
  const ls = useLocalizedString()
  const scrollView = useRef<ScrollViewType.Ref>(null)

  useEffect(() => {
    scrollView.current?.scrollBottom()
  }, [activeRoom?.messages.length])

  useEffect(() => {
    if (activeRoom?.roomId) {
      ChatActions.getMessages(activeRoom.roomId).then(() => {
        setTimeout(() => {
          scrollView.current?.scrollBottom()
        }, 1)
      })
    }
  }, [activeRoom?.roomId])

  const messages = activeRoom?.messages || []

  if (!userId) {
    return null
  }

  return (
    <Flexbox
      column
      flex={1}
      decoration="surface"
      borderColor="gray200"
      style={{
        overflow: 'hidden',
        minHeight: '10rem',
        borderRight: 'none',
        borderBottom: 'none',
        borderRadius: '0.25rem 0 0 0',
        background:
          'radial-gradient(126.94% 126.89% at -76.94% -26.89%, rgba(6, 154, 201, 0.2) 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(115.1% 85.11% at 125.5% 30.66%, rgba(245, 222, 101, 0.2) 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(100% 100% at 50% 100%, rgba(236, 253, 245, 0.83) 0%, rgba(236, 253, 245, 0) 100%);',
      }}
    >
      {!activeRoom && (
        <Flexbox column h="100%" alignItems="center" justifyContent="center">
          <ChatNew size="10rem" color="green200" mb="m" />
          <Text size="s" weight={600} color="green400">
            {ls.text.chatSelectOrCreate}
          </Text>
        </Flexbox>
      )}

      {activeRoom && (
        <>
          <MessageRoomHeader />
          {messages.length <= 0 && !activity.isFetchingHistory && (
            <Flexbox column h="100%" alignItems="center" justifyContent="center">
              <MessageCircle size="10rem" color="green200" mb="m" />
              <Text size="s" weight={600} color="green400">
                {ls.text.chatEmpty}
              </Text>
            </Flexbox>
          )}
          {messages.length > 0 && (
            <ScrollView
              mode="hidden"
              ref={scrollView}
              attributes={{
                'data-id': 'chat-scroll-view',
                'data-visible-date': 'false',
              }}
              style={{
                '&[data-visible-date="true"] [data-id="dt"]': {
                  opacity: 1,
                },
              }}
              onScroll={() => {
                document
                  .querySelector('[data-id="chat-scroll-view"]')
                  ?.setAttribute('data-visible-date', 'true')
                clearTimeout(dateFadeTimer)
                dateFadeTimer = setTimeout(() => {
                  document
                    .querySelector('[data-id="chat-scroll-view"]')
                    ?.setAttribute('data-visible-date', 'false')
                }, 500)
              }}
            >
              <Block h="6.5rem" />
              <Flexbox centered p="m">
                <Spinner
                  color="green400"
                  count={8}
                  size="1.5rem"
                  style={{
                    transition: 'all 0.25s',
                    opacity: activity.isFetchingHistory ? 1 : 0,
                    transform: activity.isFetchingHistory ? 'scale(1)' : 'scale(0.5)',
                  }}
                />
              </Flexbox>

              {/* @ts-ignore */}
              <Table
                borderWidth={0}
                backgroundColor="transparent"
                key={`${activeRoom.roomId}-${messages.length}`}
                data={messages}
                columns={[
                  {
                    title: 'id',
                    key: 'message',
                    render: (ctx, i) => (
                      <Fragment key={ctx.row.id}>
                        <StickyDate date={ctx.row.dateTime} />
                        <Message
                          key={ctx.row.id}
                          room={activeRoom}
                          userId={userId}
                          message={messages[i]}
                          previousMessage={messages[i - 1]}
                          nextMessage={messages[i + 1]}
                          onRead={() => {
                            ChatActions.messageRead(messages[i])
                          }}
                        />
                      </Fragment>
                    ),
                  },
                ]}
                style={{
                  thead: {
                    display: 'none',
                  },
                  'tr, td, tbody > tr': {
                    border: 'none',
                    padding: 0,
                    background: 'transparent',
                    display: 'contents',
                  },
                }}
              />
              <Block h="6rem" />
            </ScrollView>
          )}
          <MessageInput
            connected={activeRoom.users.some((u) => u.id === userId)}
            onSend={(message, file) => {
              ChatActions.sendMessage(activeRoom.roomId, message, file)
            }}
            onTyping={(message) => {
              ChatActions.typing(activeRoom.roomId, message)
            }}
          />
        </>
      )}
    </Flexbox>
  )
}

export default MessagesView
