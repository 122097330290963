import { PurchaseProduct } from '.'

import { Button, Flexbox, Table, Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { Trash2 } from '@stage-ui/icons'
import ViewInputField from 'components/Fields/ViewInputField'
import { useLocalizedString } from 'hooks/useLocalizedString'

type PurchaseProductTableProps = {
  products: PurchaseProduct[]
  onProductDelete: (p: PurchaseProduct) => void
  onProductUpdate: (p: PurchaseProduct) => void
}

export const PurchaseProductTable: React.FC<PurchaseProductTableProps> = (props) => {
  const { products, onProductDelete, onProductUpdate } = props
  const ls = useLocalizedString()
  const columns: TableTypes.TableColumn<PurchaseProduct>[] = [
    {
      key: 'name',
      title: ls.text.name,
      render: ({ row }) => (
        <Text size="s" color="gray900">
          {row.name}
        </Text>
      ),
    },
    {
      key: 'quantity',
      title: ls.text.quantity,
      width: '5rem',
      render: ({ row }) => {
        const onChange = (quantity: string) => {
          onProductUpdate({ ...row, quantity })
        }
        return (
          <ViewInputField
            w="5rem"
            placeholder={ls.text.quantity}
            value={row.quantity}
            onChange={onChange}
            type={row.unit.value == 1 ? 'digits' : 'integer'}
            digits={2}
            size="s"
          />
        )
      },
    },
    {
      key: 'unit',
      title: ls.text.container,
      width: '5rem',
      render: ({ row }) => (
        <Text size="s" color="gray900">
          {ls.string[row.unit.code]}
        </Text>
      ),
    },
    {
      key: '_delete',
      width: '2rem',
      render: ({ row }) => (
        <Button
          w="2rem"
          h="2rem"
          color="red500"
          decoration="text"
          onClick={() => onProductDelete(row)}
        >
          <Trash2 size="1.125rem" />
        </Button>
      ),
    },
  ]

  if (!products.length) {
    return (
      <Flexbox centered my="l" p="l" decoration="surface">
        <Text size="s" color="orange500">
          {ls.text.listEmptyEnterProductName}
        </Text>
      </Flexbox>
    )
  }

  return (
    <Flexbox column my="l">
      <Text weight={600} color="gray900" mb="m">
        {ls.text.productListForRestock}
      </Text>
      <Table data={products} columns={columns} />
    </Flexbox>
  )
}
