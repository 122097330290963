import React from 'react'

import { Button, Divider, Flexbox, Header } from '@stage-ui/core'

import { MouseDropMenuItem, useMouseDropMenu } from 'components/MouseMenuDrop'
import { openClientAddressChangeModal } from 'components/Modals/ClientAddressChange'
import { copyElementToClipboard } from 'utils/copyElementToClipboard'
import { printElement } from 'utils/printElement'

import { Client } from '@foods-n-goods/server/generated/schema'

import TwoDots from 'components/Icons/TwoDots'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { ClientDetailsInfoRow, ClientDetailsInfoRowProps } from './InfoRow'

type ClientDetailsInfoBlockProps = {
  clientId: Client['id']
  block: string
  title: string
  rows: ClientDetailsInfoRowProps[]
  noDataComponent?: React.ReactNode
}

export function ClientDetailsInfoBlock(props: ClientDetailsInfoBlockProps) {
  const { clientId, block, title, rows, noDataComponent } = props
  const ls = useLocalizedString()
  const ctxElements = [
    { text: ls.text.clientAddressAdd, icon: 'Pin', value: 'NEW_ADDRESS' },
    { text: ls.text.copyData, icon: 'Copy', value: 'COPY' },
    { text: ls.text.print, icon: 'Printer', value: 'PRINT' },
  ].filter(
    (el) => !(el.value === 'NEW_ADDRESS' && block !== 'address'),
  ) as MouseDropMenuItem[]

  const contextMenu = useMouseDropMenu<HTMLButtonElement>(ctxElements, ({ value }) => {
    if (value === 'NEW_ADDRESS') {
      return openClientAddressChangeModal(clientId)
    }
    if (value === 'COPY') {
      return copyElementToClipboard(`${block}_block`)
    }
    if (value === 'PRINT') {
      return printElement(`${block}_block`)
    }
  })

  return (
    <Flexbox
      id={`${block}_block`}
      column
      p="1.25rem"
      pt="2.5rem"
      decoration="surface"
      borderRadius="s"
      borderColor="gray200"
    >
      <Flexbox justifyContent="space-between" alignItems="flex-start">
        <Header size="xs" weight="600 !important">
          {title}
        </Header>
        <Button
          w="1.75rem"
          h="1.75rem"
          p={0}
          size="s"
          decoration="text"
          color="gray900"
          {...contextMenu}
        >
          <TwoDots fillColor="gray900" size="1.125rem" />
        </Button>
      </Flexbox>

      <Divider color="gray200" mt="m" />
      {rows.map((row, i) => (
        <ClientDetailsInfoRow key={`${block}_${i.toString()}`} {...row} />
      ))}
      {!rows.length && noDataComponent}
    </Flexbox>
  )
}
