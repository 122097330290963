/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useMemo } from 'react'

import { Divider, Flexbox, Header, Spinner, Text, useTheme } from '@stage-ui/core'
import { ArrowLeftUp, ArrowRightDown, Info } from '@stage-ui/icons'

import { Client } from '@foods-n-goods/server/generated/schema'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { ClientActions } from 'actions'
import { Line } from 'react-chartjs-2'
import { getMonths } from 'store/app'
import { useLocalizedString } from 'hooks/useLocalizedString'
import { getCurrency, useCurrency } from 'hooks/useCurrency'

type ClientAverageCheckProps = {
  client: Client
}

export function ClientAverageCheck(props: ClientAverageCheckProps) {
  const { client } = props
  const ls = useLocalizedString()

  const { color } = useTheme()
  const { clientAverageCheck, currencyCode } = useSelector(
    (state) => ({
      clientAverageCheck: state.client.clientAvarageCheck,
      currencyCode: state.app.marketConfig?.currency,
    }),
    objectEqual,
  )
  const MONTHS = useMemo(getMonths, [ls.id])
  useEffect(() => {
    ClientActions.fetchClientAvarageCheck(client.id)
  }, [])

  let amount = 0
  let direction = 0

  if (clientAverageCheck.data.length) {
    amount = clientAverageCheck.data[clientAverageCheck.data.length - 1].value
    const amount2 = clientAverageCheck.data[clientAverageCheck.data.length - 2]?.value
    if (amount2) {
      direction = amount > amount2 ? 1 : -1
    }
  }
  const { valueFormatted } = useCurrency(amount, currencyCode)
  return (
    <Flexbox
      column
      decoration="surface"
      borderRadius="m"
      p="1.25rem"
      pt="2.5rem"
      borderColor="gray200"
    >
      <Flexbox column mb="m" justifyContent="space-between">
        <Header size="s">{ls.text.averageCheck}</Header>
        <Divider color="gray200" my="0.75rem" />
        <Flexbox alignItems="center">
          {clientAverageCheck.loading !== 'resolved' ? (
            <Flexbox alignItems="center">
              <Spinner mr="s" my="s" />
              <Text size="s" color="gray500">
                {ls.text.loading}
              </Text>
            </Flexbox>
          ) : (
            <>
              {direction === -1 && (
                <Flexbox
                  borderRadius="0.125rem"
                  backgroundColor="red500"
                  h="1rem"
                  w="1rem"
                  centered
                >
                  <ArrowRightDown color="white" size="s" />
                </Flexbox>
              )}
              {direction === 0 && (
                <Flexbox
                  borderRadius="0.125rem"
                  backgroundColor="gray300"
                  h="1rem"
                  w="1rem"
                  centered
                >
                  <Info color="white" size="s" />
                </Flexbox>
              )}
              {direction === 1 && (
                <Flexbox
                  borderRadius="0.125rem"
                  backgroundColor="green500"
                  h="1rem"
                  w="1rem"
                  centered
                >
                  <ArrowLeftUp color="white" size="s" />
                </Flexbox>
              )}

              <Header size="s" ml="s">
                {valueFormatted}
              </Header>
            </>
          )}
        </Flexbox>
      </Flexbox>
      {clientAverageCheck.data.length > 1 && (
        <Line
          options={{
            scales: {
              y: {
                display: false,
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              title: {
                display: false,
              },
              tooltip: {
                displayColors: false,
                callbacks: {
                  label: (item) => {
                    return getCurrency(item.raw as string).valueFormatted
                  },
                },
              },
            },
          }}
          data={{
            labels: clientAverageCheck.data.map((x) => MONTHS[x.month - 1]),
            datasets: [
              {
                label: ls.text.averageCheck,
                borderWidth: 2,
                pointRadius: 4,
                pointHoverRadius: 6,
                data: clientAverageCheck.data.map((x) => x.value),
                borderColor:
                  direction > 0 ? color.green[500].hex() : color.red[500].hex(),
                backgroundColor:
                  direction > 0 ? color.green[200].hex() : color.red[200].hex(),
              },
            ],
          }}
        />
      )}
      <Divider my="m" color="gray100" />
      <Text size="s" color="gray500" weight={400}>
        {ls.text.considerAllDeliveredOrders}
      </Text>
    </Flexbox>
  )
}
