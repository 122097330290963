import React, { useMemo } from 'react'

import { Button, Flexbox, modal } from '@stage-ui/core'
import { PlusCircle, Printer } from '@stage-ui/icons'
import FilterCommon from 'components/common/FilterCommon'
import MenuNavigation from 'components/MenuNavigation'
import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

import { openDevicePrinterModal } from 'components/Modals/DevicePrinterSelect'

import { OrderActions, ProductActions } from 'actions'

import { useLocalizedString } from 'hooks/useLocalizedString'

import NomenclatureTable from '../components/NomenclatureTable'
import CategoriesTable from '../components/CategoriesTable'
import CategoryView from '../View/CategoriesView'
import ProductView from '../View/ProductsView'

function ProductsTable() {
  const { types, filter } = useSelector(
    (state) => ({
      types:
        state.app.meta?.productGroupType
          ?.slice()
          .sort((a, b) => (a.value > b.value ? -1 : 1)) || [],
      filter: state.product.filter,
    }),
    objectEqual,
  )
  const ls = useLocalizedString()
  const menuTypes = useMemo(
    () => [
      { id: 'products', title: ls.text.items },
      ...types.map((type) => ({
        id: type.code!.toLowerCase(),
        title: type.text,
      })),
    ],
    [filter.section],
  )

  const handleSearch = (search: string) => {
    ProductActions.setFilter({
      search,
    })
  }

  const handleCreateModal = () => {
    if (!filter.section) return

    const titles: Record<string, string> = {
      products: ls.text.productAdd,
      b2b: ls.text.b2bAdd,
      warehouse: ls.text.warehouseAdd,
    }

    const render = (close: () => void) => {
      if (filter.section === 'products') {
        return <ProductView modal close={close} />
      }
      return <CategoryView modal close={close} />
    }

    modal({
      decoration: 'panel',
      w: '100%',
      title: titles[filter.section],
      render,
      overrides: { window: { marginTop: 0, alignSelf: 'center' } },
    })
  }

  return (
    <>
      <Flexbox mb="m">
        <MenuNavigation
          items={menuTypes}
          currentSection={filter.section}
          onChange={(section) => {
            ProductActions.setFilter({ section, search: undefined })
          }}
        />
        <Flexbox flex={1} ml="m" justifyContent="flex-end">
          <FilterCommon onSearch={handleSearch} initial={filter.search} />
          <Button
            ml="s"
            decoration="plain"
            children={ls.text.printAllEAC}
            leftChild={<Printer />}
            onClick={() => {
              openDevicePrinterModal((deviceId) => {
                OrderActions.printInfo({
                  deviceId,
                  printAll: true,
                })
              })
            }}
          />
          <Button
            ml="s"
            textColor="onPrimary"
            children={ls.text.add}
            leftChild={<PlusCircle />}
            onClick={handleCreateModal}
          />
        </Flexbox>
      </Flexbox>
      {filter.section === 'products' && <NomenclatureTable />}
      {(filter.section === 'b2b' || filter.section === 'warehouse') && (
        <CategoriesTable />
      )}
    </>
  )
}

export default ProductsTable
