import React from 'react'

import { Client } from '@foods-n-goods/server/generated/schema'
import { Button } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { MoreVertical } from '@stage-ui/icons'
import { useMouseDropMenu } from 'components/MouseMenuDrop'
import useSelector from 'hooks/useSelector'
import { useHistory } from 'react-router-dom'
import { selectUserRole } from 'utils/selectors'
import { objectEqual } from 'utils/objectEqual'
import { useLocalizedString } from 'hooks/useLocalizedString'

export function ContextCell(ctx: TableTypes.TableCellContext<Client>) {
  const history = useHistory()
  const { row } = ctx
  const { id } = row
  const ls = useLocalizedString()
  const userRole = useSelector(selectUserRole, objectEqual)

  const productContextMenu = useMouseDropMenu<HTMLButtonElement>(
    [{ text: ls.text.clientProfileShow, value: 'PROFILE', icon: 'Person' }],
    ({ value }) => {
      if (value === 'PROFILE') {
        return history.push(`/clients/${id}`)
      }
    },
  )

  if (userRole?.code === 'OPERATOR') return null

  return (
    <Button
      id={`client_row_action_${id}`}
      decoration="text"
      color="gray500"
      p={0}
      w="2rem"
      h="2rem"
      {...productContextMenu}
    >
      <MoreVertical size="1.5rem" />
    </Button>
  )
}
