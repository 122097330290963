import Queue from '@foods-n-goods/client/utils/queue'
import { MarketConfig, MarketConfigInput } from '@foods-n-goods/server/generated/schema'
import { SocketType } from '@foods-n-goods/server/src/socket/types'
import { AppActions, CategoryActions, StaffActions } from 'actions'
import { EXPRESS_HOST } from 'env'
import { getLocalizedString } from 'hooks/useLocalizedString'
import request from 'requests/request'
import { io } from 'requests/socket'
import store, {
  AppStore,
  CategoryStore,
  ChatStore,
  ClientStore,
  DeliveryStore,
  DictionariesStore,
  JournalStore,
  OrderStore,
  ProductStore,
  PromotionStore,
  ReviewStore,
  StaffStore,
  VariablesStore,
} from 'store'
import { SettingsFilter } from 'store/app'
import localStorage from 'utils/localStorage'

export default {
  async init() {
    try {
      AppStore.loading()
      await window.fetch(`${EXPRESS_HOST}/ping`)
      AppStore.resolve()
      AppActions.auth()
    } catch (error) {
      const ls = getLocalizedString()
      AppStore.reject({
        code: -1,
        message: error.message || ls.text.serviceUnavailableTitle,
      })
    }
  },

  async meta() {
    try {
      AppStore.metaLoading()
      const result = await request('meta')
      AppStore.metaResolve(result)
    } catch (error) {
      AppStore.metaReject(error)
    }
  },

  async marketIndividuals() {
    const { user } = store.getState().app
    if (!user) return

    try {
      AppStore.marketIndividualsLoading()
      const marketIndividuals = await request('marketIndividuals', {
        marketId: user.market.value.toString(),
      })
      AppStore.marketIndividualsResolve(marketIndividuals)
    } catch (error) {
      AppStore.marketIndividualsReject(error)
    }
  },

  async marketConfig() {
    try {
      AppStore.marketConfigLoading()
      const result = await request('marketConfig')
      AppStore.marketConfigResolve(result)
    } catch (error) {
      AppStore.marketConfigReject(error)
    }
  },

  async marketConfigSet(config: MarketConfigInput) {
    try {
      AppStore.marketConfigLoading()
      const result = await request('marketConfigUpdate', {
        config,
      })
      AppStore.marketConfigResolve(result)
    } catch (error) {
      AppStore.marketConfigReject(error)
    }
  },

  async signIn(login: string, pwd: string) {
    try {
      const user = await request('staffSignIn', { login, pwd })
      localStorage.setPreference('token', user.token)
      AppStore.userResolve(user)
      AppActions.fetchData()
    } catch (error) {
      AppStore.userReject(error)
    }
  },

  async signOut() {
    try {
      await request('staffSignOut')
      localStorage.setPreference('token', '')
      this.clear()
    } catch (error) {
      // nope
    }
  },

  async auth() {
    const token = localStorage.getPreference('token')
    if (!token) {
      AppStore.userReject(null)
      return
    }

    try {
      AppStore.userLoading()
      const user = await request('staffAuth')
      localStorage.setPreference('token', user.token)
      AppStore.userResolve(user)
      AppActions.fetchData()
    } catch (error) {
      AppStore.userReject(error)
    }
  },

  fetchData() {
    AppActions.meta()
    AppActions.marketConfig()
    AppActions.marketIndividuals()
    StaffActions.fetch()
    CategoryActions.fetch()
  },

  setFilter(filter: Partial<SettingsFilter>) {
    AppStore.setFilter(filter)
  },

  clearFilter() {
    AppStore.clearFilter()
  },

  clear() {
    Queue.dropAll()

    // CLEAR ALL STORAGES
    AppStore.clear()
    StaffStore.clear()
    CategoryStore.clear()
    ClientStore.clear()
    ProductStore.clear()
    OrderStore.clear()
    DeliveryStore.clear()
    ChatStore.clear()
    DictionariesStore.clear()
    JournalStore.clear()
    PromotionStore.clear()
    ReviewStore.clear()
    VariablesStore.clear()
  },
}

io.on(SocketType.Broadcast.MarketConfigUpdate, (marketConfig: MarketConfig) => {
  AppStore.marketConfigResolve(marketConfig)
})
