import React, { useEffect, useState } from 'react'

import { Flexbox } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'
import { AlertTriangle } from '@stage-ui/icons'
import { useMouseDrop } from 'components/MouseDrop'
import { useLocalizedString } from 'hooks/useLocalizedString'

type SelfPhoneInfoData = {
  backgroundModeEnabled: boolean
  batteryLife: number
  device: string
  gpsAvailable: boolean
  locationServicesEnabled: boolean
  networkAvailable: boolean
  os: string
  staffId: string
  uptime: number
}

type ErrorIndicatorProps = FlexboxTypes.Props & {
  courierId: string
  selfPhoneInfo: string
}

export function ErrorIndicator(props: ErrorIndicatorProps) {
  const { courierId, selfPhoneInfo, ...restProps } = props
  const [error, setError] = useState('')
  const hint = useMouseDrop(error)
  const ls = useLocalizedString()
  useEffect(() => {
    try {
      const info: SelfPhoneInfoData = JSON.parse(selfPhoneInfo)
      if (Object.keys(info).length === 0) {
        throw new Error(ls.text.phoneDataErrorNoData)
      }
      if (!info.networkAvailable) {
        setError(ls.text.phoneDataErrorNoNetwork)
      }
      if (!info.backgroundModeEnabled) {
        setError(ls.text.phoneDataErrorBackgroundModeDisabled)
      }
      if (!info.locationServicesEnabled) {
        setError(ls.text.phoneDataErrorLocationServicesDisabled)
      }
      if (!info.gpsAvailable) {
        setError(ls.text.phoneDataErrorGPSnotAvailable)
      }
    } catch (error) {
      setError(error.messsage)
    }
  }, [courierId, selfPhoneInfo])

  if (!error) {
    return null
  }

  return (
    <Flexbox centered ml="xs" {...hint} {...restProps}>
      <AlertTriangle color="orange500" />
    </Flexbox>
  )
}
