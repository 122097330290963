import React, { useMemo } from 'react'

import { PageTable } from 'components/PageTable'
import useSelector from 'hooks/useSelector'
import { useHistory } from 'react-router-dom'

import { objectEqual } from 'utils/objectEqual'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { getColumns } from './misc'

function PromotionsTable() {
  const history = useHistory()
  const data = useSelector(({ promotion }) => promotion.data, objectEqual)
  const ls = useLocalizedString()
  const columns = useMemo(getColumns, [ls.id])
  return (
    <PageTable
      noDataText={ls.text.promotionsNotFound}
      data={data}
      columns={columns}
      onRowClick={(ctx) => {
        const { row } = ctx
        history.push(`/promotions/${row.id}`)
      }}
    />
  )
}

export default PromotionsTable
