import React, { useEffect, useRef } from 'react'

import { Button, Modal } from '@stage-ui/core'
import ModalTypes from '@stage-ui/core/layout/Modal/types'
import { PlusCircle } from '@stage-ui/icons'
import { PromotionActions } from 'actions'
import LoadingData from 'components/LoadingData'
import { Section } from 'components/Section'
import useSelector from 'hooks/useSelector'
import { Route, Switch, useLocation } from 'react-router-dom'

import { objectEqual } from 'utils/objectEqual'

import { useLocalizedString } from 'hooks/useLocalizedString'

import PromotionsTable from './Table'
import PromotionView from './View'

export function Promotions() {
  const dataResolved = useSelector(
    ({ promotion: { loading } }) => loading === 'resolved',
    objectEqual,
  )
  const ls = useLocalizedString()
  const location = useLocation()
  const modalRef = useRef<ModalTypes.Ref>(null)

  const details = location.pathname.split('/')[2]

  useEffect(() => {
    if (!dataResolved) {
      PromotionActions.getAll()
    }
  }, [])

  const actions = !details && (
    <Button
      ml="1.5rem"
      textColor="onPrimary"
      children={ls.text.add}
      leftChild={<PlusCircle type="filled" />}
      onClick={() =>
        modalRef.current?.open(<PromotionView modal close={modalRef.current.close} />)
      }
    />
  )

  return (
    <Section title={ls.text.promotions} actions={actions}>
      <LoadingData storeKey="promotion">
        <Switch location={location}>
          <Route exact path="/promotions" component={PromotionsTable} />
          <Route exact path="/promotions/:id" component={PromotionView} />
        </Switch>
      </LoadingData>
      <Modal
        overlayClose={false}
        decoration="panel"
        ref={modalRef}
        w="100%"
        title={ls.text.promotionAdd}
      />
    </Section>
  )
}
