import React, { useState } from 'react'

import { Product, SelectItemWithCode } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox } from '@stage-ui/core'
import { PurchasesActions } from 'actions'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { PurchaseProductSearch } from './SearchProducts'
import { PurchaseProductTable } from './Table'

type PurchaseCreateModalProps = {
  close: () => void
}

export type PurchaseProduct = {
  id: string
  name: Product['name']
  quantity: string
  unit: SelectItemWithCode
  availableUnits: (SelectItemWithCode & { weight: number })[]
}

export const PurchaseCreateModal: React.FC<PurchaseCreateModalProps> = (props) => {
  const { close } = props
  const ls = useLocalizedString()
  const [products, setProducts] = useState<PurchaseProduct[]>([])

  const onProductSelect = (product: Product) => {
    setProducts((pr) => {
      if (pr.some((ap) => ap.id === product.id)) {
        return pr.filter((ap) => ap.id !== product.id)
      }
      return pr.concat({
        id: product.id,
        name: product.name,
        quantity: '1',
        unit: product.unit,
        availableUnits: product.avarageWeight
          .map((aw) => ({
            text: aw.unitName,
            value: Number(aw.unitId),
            weight: aw.value,
          }))
          .concat([
            { text: ls.string[product.unit.code], value: product.unit.value, weight: 1 },
          ]),
      })
    })
  }

  const onProductDelete = (product: PurchaseProduct) => {
    setProducts((pr) => pr.filter((p) => p.id !== product.id))
  }

  const onProductUpdate = (product: PurchaseProduct) => {
    setProducts((pr) => pr.map((p) => (p.id === product.id ? product : p)))
  }

  const performCreatePurchases = () => {
    const data = products
      .filter((ap) => parseFloat(ap.quantity) > 0)
      .map((ap) => ({
        id: ap.id,
        quantity: ap.quantity,
        unitId: ap.unit.value,
      }))

    if (!data.length) return

    // TODO: rewrite create purchase for array
    data.map((product) =>
      PurchasesActions.createPurchase({
        productId: Number(product.id),
        quantity: Number(product.quantity),
      }),
    )
    close()
  }

  return (
    <Flexbox column>
      <PurchaseProductSearch products={products} onProductSelect={onProductSelect} />
      <PurchaseProductTable
        products={products}
        onProductDelete={onProductDelete}
        onProductUpdate={onProductUpdate}
      />
      <Flexbox justifyContent="flex-end" alignItems="flex-end">
        <Button
          decoration="text"
          color="gray500"
          label={ls.text.cancel}
          onClick={close}
          size="s"
          mr="m"
        />
        <Button
          label={ls.text.addToRestock}
          onClick={performCreatePurchases}
          size="s"
          w="10rem"
        />
      </Flexbox>
    </Flexbox>
  )
}
