/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable no-for-of-loops/no-for-of-loops */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable no-bitwise */
import React, { useState } from 'react'

import { Global } from '@emotion/react'
import { OrderStatusCode } from '@foods-n-goods/client/system/types'
import { Staff } from '@foods-n-goods/server/generated/schema'
import GeoUtils from '@foods-n-goods/server/src/utils/GeoUtils'
import { Flexbox, Header, Text } from '@stage-ui/core'
import { AlertTriangle } from '@stage-ui/icons'
import { OrderDelivery } from 'store/delivery'

import { getLocalizedString } from 'hooks/useLocalizedString'

import { Templates, useTemplates } from './useTemplates'

type UseYandexMapParams = {
  id?: string
  width: string
  height: string
  onMapClick: (e: any) => void
  onMapZoomChange?: (e: any) => void
  onMapBoundsChange?: (e: any) => void
  onClusterZoom: (e: any) => void
  onRouteSet: (route: ymaps.Route) => void
  onRouteRemove: () => void
  onPlacemarkSelect: (orders: OrderDelivery[], placemark: ymaps.Placemark, e: any) => void
  onCourierSelect: (courier: Staff, placemark: ymaps.Placemark, e: any) => void
  onClusterSelect: (
    orders: OrderDelivery[],
    placemarks: ymaps.Placemark[],
    e: any,
  ) => void
  onCourierClusterSelect: (
    couriers: Staff[],
    placemarks: ymaps.Placemark[],
    e: any,
  ) => void
}

type YMControllerProps = UseYandexMapParams & {
  onReady: (fn: YMControllerFn) => void
  templates: Templates
}

type YMControllerFn = Pick<
  YMController,
  | 'placemarkGet'
  | 'courierPlacemarkCreate'
  | 'courierPlacemarkUpdate'
  | 'courierPlacemarkRemove'
  | 'courierPlacemarkRemoveAll'
  | 'courierPlacemarkRemove'
  | 'removeAll'
  | 'orderPlacemarkCreate'
  | 'orderPlacemarkRemove'
  | 'orderPlacemarkRemoveAll'
  | 'fitCenter'
  | 'fitToViewport'
  | 'courierRouteCanUpdate'
  | 'courierRouteCreate'
  | 'courierRouteFocus'
  | 'courierRouteRemove'
  | 'focus'
  | 'trackClear'
  | 'trackSetFocus'
  | 'trackUpdate'
  | 'trackCreate'
  | 'hover'
>

class YMController extends React.Component<YMControllerProps> {
  private id = 'yandex-map-container'

  /**
   * Main instance of yandex map
   */
  private map!: ymaps.Map

  /**
   * visibility some items on map
   */
  private visibility = {
    orders: !window.localStorage.getItem('logisticsMapHideOrders'),
    couriers: !window.localStorage.getItem('logisticsMapHideCouriers'),
  }

  private ls = getLocalizedString()

  /**
   * All orders stores here
   * as Clusterer object
   */
  private orderCluster!: ymaps.Clusterer

  /**
   * All couriers stores here
   * as Clusterer object
   */
  private courierCluster!: ymaps.Clusterer

  /**
   * Cached id need to keep hover state when
   * placemark will rerender
   */
  private cachedPlaceholderHoverIds: string[] = []

  /**
   * Cached id need to keep focus state when
   * placemark will rerender
   */
  private cachedPlaceholderFocusIds: string[] = []

  /**
   * Route path with traffic stores here
   * will draw on map if not null
   */
  private courierRoute: { key: string; courierId: string; route: ymaps.Route } | null =
    null

  /**
   * Route cache to optimize requests
   * key hash of waypoints
   */
  private routeCache: Record<string, YMController['courierRoute']> = {}

  /**
   * Track route polyline of courier history
   * only one at time can be rendered
   */
  private courierTrack: {
    id: string
    focus: boolean
    boundsWillChange?: boolean
    polyline: ymaps.Polyline
  } | null = null

  constructor(props: YMControllerProps) {
    super(props)
    if (props.id) {
      this.id = props.id
    }
  }

  componentDidMount() {
    window.ymaps?.ready(() => this.initMap())
  }

  /**
   * Creates simple hash of anything
   */
  private createHash(data: [number, number][]) {
    const string = JSON.stringify(data)
    let res = 0
    if (string.length > 0) {
      for (let i = 0; i < string.length; i++) {
        res = (res << 5) - res + string.charCodeAt(i)
        res &= res
      }
    }
    return res.toString(16).replace('-', '0')
  }

  public fitCenter() {
    this.map.setBounds(this.orderCluster.getBounds())
    const zoom = this.map.getZoom()
    if (zoom > 2) {
      this.map.setZoom(zoom - 1)
    }
  }

  public fitToViewport() {
    this.map.container.fitToViewport()
  }

  private visibilityListRender() {
    const listBox = new ymaps.control.ListBox({
      data: {
        content: this.ls.text.show,
      },
      // @ts-expect-error
      items: [
        new ymaps.control.ListBoxItem({
          data: { content: this.ls.text.orders.replace('[1]', '').trim() },
          state: { selected: this.visibility.orders },
        }),
        new ymaps.control.ListBoxItem({
          data: { content: this.ls.text.couriers },
          state: { selected: this.visibility.couriers },
        }),
      ],
    })

    listBox.events.add('click', (e) => {
      // @ts-expect-error
      const listItem: string = e.get('target').data._data.content
      if (listItem === this.ls.text.orders.replace('[1]', '').trim()) {
        this.visibility.orders = !this.visibility.orders
        if (this.visibility.orders) {
          this.map.geoObjects.add(this.orderCluster)
          window.localStorage.removeItem('logisticsMapHideOrders')
        } else {
          window.localStorage.setItem('logisticsMapHideOrders', 'Y')
          this.map.geoObjects.remove(this.orderCluster)
        }
      }
      if (listItem === this.ls.text.couriers) {
        this.visibility.couriers = !this.visibility.couriers
        if (this.visibility.couriers) {
          this.map.geoObjects.add(this.courierCluster)
          window.localStorage.removeItem('logisticsMapHideCouriers')
        } else {
          this.map.geoObjects.remove(this.courierCluster)
          window.localStorage.setItem('logisticsMapHideCouriers', 'Y')
        }
      }
    })

    this.map.controls.add(listBox)
  }

  private zoomOutButtonRender() {
    const button = new ymaps.control.Button({
      data: {
        content: this.ls.text.showAll,
      },
      options: {
        selectOnClick: false,
        maxWidth: 200,
        floatIndex: 0,
      },
    })
    button.events.add('click', () => {
      this.trackSetFocus(false)
      this.fitCenter()
    })
    this.map.controls.add(button)
  }

  private initMap() {
    this.map = new ymaps.Map(
      this.id,
      {
        center: [55.75, 37.6],
        zoom: 11,
        controls: [
          'fullscreenControl',
          'zoomControl',
          'rulerControl',
          'trafficControl',
          'typeSelector',
        ],
      },
      {
        zoomControlSize: 'large',
      },
    )

    this.orderCluster = new ymaps.Clusterer({
      hasBalloon: false,
      gridSize: 0,
      groupByCoordinates: false,
      preset: 'islands#invertedNightClusterIcons',
    })

    this.courierCluster = new ymaps.Clusterer({
      hasBalloon: false,
      gridSize: 0,
      groupByCoordinates: false,
      preset: 'islands#invertedDarkGreenClusterIcons',
    })

    this.map.events.add('click', (e) => {
      this.trackSetFocus(false)
      this.props.onMapClick(e)
    })

    this.map.events.add('boundschange', (e: any) => {
      if (e.get('newZoom') !== e.get('oldZoom')) {
        this.props.onMapZoomChange?.(e)
      }
      this.props.onMapBoundsChange?.(e)
    })

    this.map.events.add('actiontick', (e: any) => {
      // skip actiontick if courier track will change bounds right now
      if (this.courierTrack?.boundsWillChange === true) {
        return
      }
      this.trackSetFocus(false)
    })

    this.orderCluster.events.add('click', (e) => {
      this.trackSetFocus(false)
      if (this.map.getZoom() > 20) {
        // @ts-expect-error
        const objects = e.get('target').properties.get('geoObjects')
        this.props.onClusterSelect(
          // @ts-expect-error
          objects.map((o) => o.properties.get('order')),
          objects,
          e,
        )
      } else {
        this.props.onClusterZoom(e)
      }
    })

    this.courierCluster.events.add('click', (e) => {
      this.trackSetFocus(false)
      if (this.map.getZoom() > 20) {
        // @ts-expect-error
        const objects = e.get('target').properties.get('geoObjects')
        this.props.onCourierClusterSelect(
          // @ts-expect-error
          objects.map((o) => o.properties.get('courier')),
          objects,
          e,
        )
      } else {
        this.props.onClusterZoom(e)
      }
    })

    if (this.visibility.orders) {
      this.map.geoObjects.add(this.orderCluster)
    }

    if (this.visibility.couriers) {
      this.map.geoObjects.add(this.courierCluster)
    }

    this.visibilityListRender()
    this.zoomOutButtonRender()

    this.props.onReady(this)
  }

  public removeAll() {
    this.courierPlacemarkRemoveAll()
    this.orderPlacemarkRemoveAll()
  }

  public orderPlacemarkCreate(coords: ymaps.Coordinate, orders: OrderDelivery[]) {
    const black = '#000000'
    const white = '#FFFFFF'
    const red = '#f00'
    const gray600 = '#4B5563'
    const green500 = '#10B981'

    const order = orders
      .slice()
      .sort((a, b) => (a.status.value > b.status.value ? 1 : -1))[0]

    const deliverySuccess = order.status.value === 7
    const deliveryFailed = order.status.value === 8

    const style = {
      containerWidth: '3rem',
      backgroundColor: order.courier ? white : gray600,
      dotColor: order.courier?.color?.code || white,
      dotBorderColor: order.courier ? white : gray600,
      courierColor: order.courier ? order.courier?.color?.code || gray600 : white,
      carDisplay: order.sequenceOrder ? 'none' : 'flex',
      successIconDisplay:
        order.status.value === OrderStatusCode.DELIVERED ? 'flex' : 'none',
      sequenceOrderDisplay: order.sequenceOrder ? 'inline-block' : 'none',
      deliverySuccess: 'none',
      deliveryFailed: 'none',
      neighborsDisplay: orders?.length > 1 ? 'flex' : 'none',
    }
    if (deliverySuccess) {
      style.backgroundColor = white
      style.dotColor = green500
      style.dotBorderColor = green500
      style.deliverySuccess = 'flex'
    }
    if (deliveryFailed) {
      style.dotColor = red
      style.backgroundColor = white
      style.dotBorderColor = red
      style.deliveryFailed = 'flex'
    }

    const placemark = new ymaps.Placemark(
      coords,
      {
        order,
        style,
        count: orders.length,
        hover: this.cachedPlaceholderHoverIds.includes(order.id),
        focus: this.cachedPlaceholderFocusIds.includes(order.id),
      },
      {
        iconLayout: 'default#imageWithContent',
        iconImageSize: [52, 26],
        iconImageOffset: [-13, -13],
        iconContentOffset: [4, 0],
        iconContentLayout: this.props.templates.placemark.html(),
      },
    )

    placemark.events.add('click', (e) => {
      this.focus(orders.map(({ id }) => id))
      this.props.onPlacemarkSelect(orders, placemark, e)
    })
    placemark.events.add('mouseenter', (e) => {
      this.hover(orders.map(({ id }) => id))
    })
    placemark.events.add('mouseleave', (e) => {
      this.hover(
        orders.map(({ id }) => id),
        false,
      )
    })
    this.orderCluster.add(placemark)
  }

  public orderPlacemarkRemove(placemark: ymaps.Placemark) {
    this.orderCluster.remove(placemark)
  }

  public orderPlacemarkRemoveAll() {
    // @ts-expect-error
    this.orderCluster.removeAll()
  }

  public courierPlacemarkCreate(
    coords: ymaps.Coordinate,
    heading: number,
    courier: Staff,
  ) {
    if (heading === 0 && this.courierTrack?.id === courier.id) {
      const previousCoords =
        this.courierTrack.polyline.geometry.getCoordinates<Array<[number, number]>>()
      if (previousCoords.length >= 2) {
        heading = GeoUtils.bearing(previousCoords[previousCoords.length - 2], coords)
      }
    }
    const style = {
      color: courier.color?.code || 'gray',
      rotate: heading,
    }

    const placemark = new ymaps.Placemark(
      coords,
      {
        courier,
        style,
        hover: String(this.cachedPlaceholderHoverIds.includes(courier.id)),
        focus: String(this.cachedPlaceholderFocusIds.includes(courier.id)),
      },
      {
        iconLayout: 'default#imageWithContent',
        iconImageHref: 'images/myIcon.gif',
        iconImageSize: [24, 24],
        iconImageOffset: [-12, -12],
        iconContentLayout: this.props.templates.courier.html(),
      },
    )

    placemark.events.add('click', (e) => {
      this.props.onCourierSelect(courier, placemark, e)
    })
    placemark.events.add('mouseenter', (e) => {
      this.hover([courier.id])
    })
    placemark.events.add('mouseleave', (e) => {
      this.hover([courier.id], false)
    })
    this.courierCluster.add(placemark)
  }

  public courierPlacemarkUpdate(
    courier: Staff,
    coords: [number, number],
    heading: number = 0,
  ): boolean {
    if (this.placemarkGet(courier.id)) {
      this.courierPlacemarkRemove(courier.id)
      this.courierPlacemarkCreate(coords, heading, courier)
      return true
    }
    return false
  }

  public courierRouteCanUpdate(courierId: string) {
    return this.courierRoute?.courierId === courierId
  }

  public courierRouteCreate(
    courierId: string,
    points: Array<[number, number]>,
    mapStateAutoApply = true,
  ) {
    const key = this.createHash(points)
    const cache = this.routeCache[key]

    const handleRoute = (route: ymaps.Route) => {
      this.courierRoute = {
        key,
        courierId,
        route,
      }
      route.getPaths().options.set({
        strokeColor: '#3e9eff',
        opacity: 0.8,
      })
      // hide placemarks of route
      route.getWayPoints().options.set({
        visible: false,
      })

      this.map.geoObjects.add(this.courierRoute.route)

      this.props.onRouteSet(route)

      if (!cache) {
        this.routeCache[key] = this.courierRoute
      }
    }

    // route the same, skip
    if (this.courierRoute?.key === key) {
      return
    }

    this.courierRouteRemove()

    if (cache) {
      handleRoute(cache.route)
      return
    }

    const waypoints = points.map((point) => ({ type: 'wayPoint', point }))

    // @ts-ignore
    ymaps
      .route(waypoints, {
        routingMode: 'auto',
        avoidTrafficJams: true,
        mapStateAutoApply,
      })
      .then(handleRoute)
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert(this.ls.text.routeCreateError)
      })
  }

  public courierRouteFocus() {
    const bounds = this.courierRoute?.route.getPaths().getBounds()
    if (bounds) {
      this.map.setBounds(bounds, {
        duration: 250,
        useMapMargin: true,
      })
    }
  }

  public courierRouteRemove() {
    if (this.courierRoute) {
      this.props.onRouteRemove()
      this.map.geoObjects.remove(this.courierRoute.route)
      this.courierRoute = null
    }
  }

  public courierPlacemarkRemove(id: string): boolean {
    const placemark = this.placemarkGet(id)
    if (placemark) {
      this.courierCluster.remove(placemark)
      return true
    }
    return false
  }

  public courierPlacemarkRemoveAll() {
    // @ts-expect-error
    this.courierCluster.removeAll()
  }

  public placemarkGet(id: string): ymaps.Placemark | null {
    const objects = [
      ...this.courierCluster.getGeoObjects(),
      ...this.orderCluster.getGeoObjects(),
    ]
    for (const object of objects) {
      if (
        object.properties._data?.id === id ||
        object.properties._data.order?.id === id ||
        object.properties._data.courier?.id === id
      ) {
        return object
      }
    }
    return null
  }

  public focus(ids: string[], state = true) {
    this.cachedPlaceholderHoverIds = state ? ids : []
    document.querySelectorAll('[data-focus="true"]').forEach((el) => {
      el.setAttribute('data-focus', 'false')
    })
    ids.forEach((id) => {
      document.querySelectorAll(`[data-id="${id}"]`).forEach((el) => {
        el.setAttribute('data-focus', state.toString())
      })
    })
    if (state) {
      setTimeout(() => {
        let placemark: ymaps.Placemark | null = null
        for (let i = 0; i < ids.length; i++) {
          placemark = this.placemarkGet(ids[i])
          if (placemark) {
            break
          }
        }
        if (placemark) {
          const c = placemark.geometry.getCoordinates<[number, number]>()
          this.map.setCenter([c[0] - 0.0001, c[1]], undefined, {
            duration: 250,
          })
        }
      }, 1)
    }
  }

  public trackCreate(
    courier: Staff,
    points: Array<[number, number]>,
    options: ymaps.IPlacemarkOptions = {},
  ) {
    this.courierTrack = {
      id: courier.id,
      focus: true,
      polyline: new ymaps.Polyline(
        points.slice(0, -1),
        {},
        {
          strokeColor: '#3e9eff',
          strokeWidth: 4,
          ...options,
        },
      ),
    }
    this.map.geoObjects.add(this.courierTrack.polyline)
    this.courierPlacemarkUpdate(courier, points.slice(-1)[0])
  }

  public trackUpdate(id: string, point: [number, number], speed = 0) {
    if (this.courierTrack?.id === id) {
      const len = this.courierTrack.polyline.editor.geometry.getLength()
      this.courierTrack.polyline.editor.geometry.insert(len, point)
      if (this.courierTrack.focus) {
        this.courierTrack.boundsWillChange = true
        this.focus([id], true)
        this.courierTrack.boundsWillChange = false
      }
    }
  }

  public trackClear() {
    if (this.courierTrack) {
      this.map.geoObjects.remove(this.courierTrack.polyline)
      this.courierTrack = null
    }
  }

  public trackSetFocus(focus: boolean) {
    if (this.courierTrack) {
      this.courierTrack.focus = focus
    }
  }

  public hover(ids: string[], state = true) {
    this.cachedPlaceholderHoverIds = state ? ids : []
    if (state) {
      document.querySelectorAll('[data-hover="true"]').forEach((el) => {
        el.setAttribute('data-hover', 'false')
      })
    }
    ids.forEach((id) => {
      document.querySelectorAll(`[data-id="${id}"]`).forEach((el) => {
        el.setAttribute('data-hover', state.toString())
      })
    })
  }

  render() {
    if (!window.ymaps) {
      return (
        <Flexbox w="100%" h="100%" centered>
          <AlertTriangle color="red500" size="6rem" mr="m" />
          <Flexbox column>
            <Header>{this.ls.text.mapsUnavailable}</Header>
            <Text color="gray900">{this.ls.text.tryLater}</Text>
          </Flexbox>
        </Flexbox>
      )
    }
    return (
      <>
        <Global
          styles={{
            '#app': {
              overflow: 'hidden',
            },
            ymaps: {
              '[class*="_checked"], [class*="_list-item"]:hover': {
                backgroundColor: '#D1FAE5 !important',
              },
            },
            [`#${this.id} > ymaps > ymaps > ymaps`]: {
              '> ymaps:nth-of-type(0)': {
                pointerEvents: 'none',
              },
              '> ymaps:nth-of-type(1)': {
                boxShadow: '0.25rem 0.25rem 1.5rem 0 inset rgba(0,0,0,0.2)',
              },
              '> ymaps:nth-of-type(2) > ymaps:nth-of-type(1)': {
                filter: 'saturate(0.4)',
              },
              '> ymaps:nth-of-type(3)': {
                display: 'none',
              },
            },
          }}
        />
        <div
          id={this.id}
          style={{ width: this.props.width, height: this.props.height }}
        />
      </>
    )
  }
}

export const ym: { ref: YMControllerFn | null } = {
  ref: null,
}

export const useYandexMap = (
  params: UseYandexMapParams,
): [React.ReactNode, YMControllerFn | null] => {
  const [templates, renderTemplates] = useTemplates()
  const [ref, setRef] = useState<YMControllerFn | null>(null)
  return [
    <>
      {renderTemplates}
      <YMController
        {...params}
        templates={templates}
        onReady={(fn) => {
          setRef(fn)
          ym.ref = fn
        }}
      />
    </>,
    ref,
  ]
}
