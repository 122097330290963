import React from 'react'

import { Divider, Flexbox, Header, Select, Text } from '@stage-ui/core'

import { Client } from '@foods-n-goods/server/generated/schema'
import SelectTypes from '@stage-ui/core/control/Select/types'
import { ClientActions } from 'actions'
import { useLocalizedString } from 'hooks/useLocalizedString'

type ClientPriorityProps = {
  client: Client
}

export function ClientPriority(props: ClientPriorityProps) {
  const { client } = props
  const ls = useLocalizedString()
  const handleChangePriority = (values: SelectTypes.Option<number>[]) => {
    ClientActions.update({
      id: client.id,
      payload: {
        priority: values[0].value,
      },
    })
  }
  const priorityOptions: (SelectTypes.Option<number> & { description: string })[] = [
    {
      text: ls.text.high,
      value: 1,
      description: ls.text.clientPriorityHighHint,
    },
    {
      text: ls.text.average,
      value: 2,
      description: ls.text.clientPriorityAverageHint,
    },
    {
      text: ls.text.low,
      value: 3,
      description: ls.text.clientPriorityLowHint,
    },
  ]
  const description = priorityOptions.find(
    (p) => p.value === client.priority,
  )?.description

  return (
    <Flexbox
      column
      decoration="surface"
      borderRadius="m"
      p="2.5rem 1.25rem 1.25rem 1.25rem"
      borderColor="gray200"
    >
      <Header size="s">{ls.text.orderPriority}</Header>
      <Divider color="gray200" my="0.75rem" />
      <Select
        options={priorityOptions}
        values={priorityOptions.filter((p) => p.value === client.priority)}
        // @ts-ignore
        onChange={handleChangePriority}
        mb="m"
      />
      <Text size="s" color="gray500" weight={400}>
        {description}
      </Text>
    </Flexbox>
  )
}
