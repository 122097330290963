import TableTypes from '@stage-ui/core/data/Table/types'

import { Staff } from '@foods-n-goods/server/generated/schema'

import { getLocalizedString } from 'hooks/useLocalizedString'

import { StaffListNameCell } from './StaffNameCell'
import { StaffListRoleCell } from './StaffRoleCell'
import { StaffListLoginCell } from './StaffLoginCell'
import { StaffListCreateDateCell } from './StaffCreateDateCell'
import { StaffListContextMenuCell } from './StaffContextMenu'

type StaffSectionsType = '' | 'office' | 'warehouse' | 'purchases' | 'couriers'

type StaffSection = {
  id: StaffSectionsType
  title: string
}

export type StaffExtended = Staff & {
  roleText: string
}

export const getSections = (): StaffSection[] => {
  const ls = getLocalizedString()
  return [
    { id: '', title: ls.text.all },
    { id: 'office', title: ls.text.office },
    { id: 'warehouse', title: ls.text.warehouse },
    { id: 'purchases', title: ls.text.purchases },
    { id: 'couriers', title: ls.text.couriers },
  ]
}

export const getColumns = (): TableTypes.TableColumn<StaffExtended>[] => {
  const ls = getLocalizedString()
  return [
    {
      key: 'name',
      title: ls.text.user,
      sort: 'ASC',
      render: StaffListNameCell,
    },
    {
      key: 'roleText',
      title: ls.text.role,
      sort: 'DEFAULT',
      width: '12rem',
      render: StaffListRoleCell,
    },
    {
      key: 'login',
      title: ls.text.login,
      sort: 'DEFAULT',
      width: '12rem',
      render: StaffListLoginCell,
    },
    {
      key: 'createDate',
      title: ls.text.createDate,
      sort: 'DEFAULT',
      width: '10rem',
      render: StaffListCreateDateCell,
    },
    { key: '_context', width: '1.5rem', render: StaffListContextMenuCell },
  ]
}
