import {
  MutationStaffSendPushMessageArgs,
  QueryStaffArgs,
  Staff,
  StaffInput,
  StaffWarehouseInput,
} from '@foods-n-goods/server/generated/schema'
import { SocketType } from '@foods-n-goods/server/src/socket/types'
import { StaffActions } from 'actions'
import { getLocalizedString } from 'hooks/useLocalizedString'
import request from 'requests/request'
import { io } from 'requests/socket'
import store, { AppStore, StaffStore } from 'store'
import { StaffFilter } from 'store/staff'
import actionNotify from 'utils/actionNotify'

let interval: NodeJS.Timer

export default {
  async fetch(search?: QueryStaffArgs['search']) {
    try {
      StaffStore.loading()
      const staff = await request('staff', { search })
      StaffStore.resolve(staff)
      this.checkOnlineInterval()
    } catch (error) {
      StaffStore.reject(error)
    }
  },

  async fetchById(staffId: Staff['id'], onSuccess?: (id: Staff['id']) => void) {
    try {
      const staff = store.getState().staff.data.find((staff) => staff.id === staffId)
      if (!staff) {
        const res = await request('staff', { staffId })
        if (res.length !== 1) {
          throw new Error('Too many staff found!')
        }
        StaffStore.findResolve(res[0])
      } else {
        StaffStore.findResolve(staff)
      }
    } catch (error) {
      const ls = getLocalizedString()
      actionNotify({
        title: ls.text.userWithId.replace('[1]', staffId),
        message: ls.text.staffErrorNotFound,
        type: 'error',
      })
    }
  },

  clearCurrentlyViewed() {
    StaffStore.setCurrentlyViewed(null)
  },

  async sendPushMessage(args: MutationStaffSendPushMessageArgs) {
    try {
      const result = await request('staffSendPushMessage', args)
      return result
    } catch (error) {
      actionNotify({
        title: getLocalizedString().text.pushMessage,
        message: error.message,
        type: 'error',
      })
      return false
    }
  },

  async create(payload: StaffInput, _cb?: () => void) {
    try {
      await request('staffCreate', { payload })
      _cb?.()
    } catch (error) {
      actionNotify({
        title: getLocalizedString().text.staffCreate,
        message: error.message || error,
        type: 'error',
      })
    }
  },

  async delete(id: string, onSuccess?: () => void) {
    try {
      await request('staffDelete', { id })
      onSuccess?.()
    } catch (error) {
      actionNotify({
        title: getLocalizedString().text.staffDelete,
        message: error.message || error,
        type: 'error',
      })
    }
  },

  async update(id: Staff['id'], payload: StaffInput, onSuccess?: () => void) {
    try {
      await request('staffUpdate', { id, payload })
      onSuccess?.()
    } catch (error) {
      actionNotify({
        title: getLocalizedString().text.staffUpdate,
        message: error.message || error,
        type: 'error',
      })
    }
  },

  async warehouseUpdate(
    id: Staff['id'],
    warehouses: StaffWarehouseInput[],
    onSuccess?: () => void,
  ) {
    try {
      await request('staffWarehouseUpdate', { id, warehouses })
      onSuccess?.()
    } catch (error) {
      actionNotify({
        title: getLocalizedString().text.staffWarehouseUpdate,
        message: error.message || error,
        type: 'error',
      })
    }
  },

  async photoUpdate(id: string, payload: string) {
    try {
      await request('staffPhoto', { id, payload })
    } catch (error) {
      actionNotify({
        title: getLocalizedString().text.staffPhotoUpdate,
        message: error.message || error,
        type: 'error',
      })
    }
  },

  // async updatePrefs(prefs: string, id: string) {
  //   try {
  //     const response = await request('staffPrefsUpdate', { prefs, staffId: id })
  //     if (response) StaffStore.actionUpdatePrefs({ prefs, id })
  //   } catch (error) {
  //     actionNotify({
  //       title: `Обновление параметров персонала`,
  //       message: error.message || error,
  //       type: 'error',
  //     })
  //   }
  // },

  async containersClear(containers: string[], markDelivered: boolean) {
    try {
      await request('containersClear', {
        containers,
        markDelivered,
      })
    } catch (error) {
      actionNotify({
        title: getLocalizedString().text.containersClearError,
        message: error.message,
        type: 'error',
      })
    }
  },

  checkOnlineInterval() {
    interval = setInterval(() => {
      StaffStore.checkOnline()
    }, 2 * 60 * 1000)
  },

  updateResolve(staff: Staff) {
    const userId = store.getState().app.user?.id

    StaffStore.updateResolve(staff)
    if (staff.id === userId) AppStore.userResolve(staff)
  },

  setFilter(filter: Partial<StaffFilter>) {
    StaffStore.setFilter(filter)
  },

  clearFilter() {
    StaffStore.clearFilter()
  },
}
/**
 * Update online status
 */
io.on(SocketType.Events.Online, StaffStore.updateOnline)
io.on(SocketType.Broadcast.StaffUpdate, (staff) => StaffActions.updateResolve(staff))
