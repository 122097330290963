import { OrderStatusCode } from '@foods-n-goods/client/system/types'
import { getLocalizedString } from 'hooks/useLocalizedString'

export const getOrderMenuItems = () => {
  const ls = getLocalizedString()
  return [
    {
      id: '',
      title: ls.text.all,
    },
    {
      id: 'new',
      title: ls.text.new,
      statusIds: [OrderStatusCode.NEW, OrderStatusCode.PAYED],
    },
    {
      id: 'assemble',
      title: ls.text.toPicking,
      statusIds: [
        OrderStatusCode.SORTING,
        OrderStatusCode.OUT_OF_STOCK,
        OrderStatusCode.PURCHASE_PENDING,
        OrderStatusCode.PURCHASE_CONFIRMED,
        OrderStatusCode.PURCHASE_FAILED,
      ],
    },
    {
      id: 'delivery',
      title: ls.text.inDelivery,
      statusIds: [
        OrderStatusCode.AWAIT_COURIER,
        OrderStatusCode.PICKED_UP,
        OrderStatusCode.DELIVERY,
      ],
    },
    {
      id: 'completed',
      title: ls.text.completed,
      statusIds: [
        OrderStatusCode.DELIVERED,
        OrderStatusCode.NOT_DELIVERED,
        OrderStatusCode.REJECTED,
        OrderStatusCode.CANCELED,
      ],
    },
  ]
}
