import React from 'react'

import { Divider, Flexbox, Grid, Text, Block } from '@stage-ui/core'
import * as Icons from '@stage-ui/icons'
import { usePrivateRoutes } from 'Router/MainView'
import { useHistory } from 'react-router-dom'
import { PageHeader } from 'components/common/PageHeader'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { useLocalizedString } from 'hooks/useLocalizedString'

function StatisticPage() {
  const user = useSelector((state) => state.app.user, objectEqual)
  const ls = useLocalizedString()
  const name = user?.name || user?.login || ls.text.unidentifiedClient
  const privateRoutes = usePrivateRoutes()

  const history = useHistory()

  const onMenuItemClick = (path?: string | number, locationSearch: string = '') => {
    if (typeof path === 'string') {
      history.push(`${path}${locationSearch}`)
    }
  }
  return (
    <>
      <PageHeader title={ls.text.greeting.replace('[1]', name)} />
      <Block p="0 2rem" backgroundColor="gray50">
        <Divider color="gray200" />
      </Block>
      <Block p="0 3rem" backgroundColor="gray50">
        <Flexbox my="1.4rem" column>
          <Text weight={500} size="s" color="gray400">
            {ls.text.operatorCabinetSectionSelect}
          </Text>
        </Flexbox>
        <Divider mb="2rem" color="gray200" />
        <Grid
          style={{
            justifyContent: 'space-between',
            gap: '1.5rem',
            gridTemplateColumns: 'repeat(auto-fit, 16.5rem)',
          }}
        >
          {privateRoutes.slice(1).map((route) => {
            const Icon = Icons[route.icon]
            return (
              <Flexbox
                key={route.value}
                decoration="surface"
                p="l"
                column
                borderRadius="4px"
                onClick={() => onMenuItemClick(route.value, route.locationSearch)}
              >
                <Icon size="1.875rem" color="green300" mb="m" />
                <Text size="m" weight={700} mb="s">
                  {route.title}
                </Text>
                <Text weight={500} size="s" color="hard">
                  {route.description}
                </Text>
              </Flexbox>
            )
          })}
        </Grid>
      </Block>
    </>
  )
}

export default StatisticPage
