import React, { useRef } from 'react'

import { Button, Flexbox, Modal, Paragraph } from '@stage-ui/core'
import ButtonTypes from '@stage-ui/core/control/Button/types'
import ModalTypes from '@stage-ui/core/layout/Modal/types'
import { useLocalizedString } from 'hooks/useLocalizedString'

interface AcceptDialogProps {
  title: string
  text: string
  actionText?: string
  onSuccess?: () => void
  onFailure?: () => void
  action: () => void
}

function AcceptDialog(props: ButtonTypes.Props & AcceptDialogProps) {
  const modalRef = useRef<ModalTypes.Ref>(null)
  const ls = useLocalizedString()
  const { title, text, actionText, action, onSuccess, onFailure, ...buttonProps } = props

  const openModal = () => modalRef.current?.open()
  const closeModal = () => modalRef.current?.close()

  const _action = () => {
    closeModal()
    action()
  }

  return (
    <>
      <Button onClick={openModal} flexShrink={0} {...buttonProps} />
      <Modal ref={modalRef} title={title} w="40rem">
        <Paragraph children={text} />
        <Flexbox justifyContent="flex-end" mt="3rem">
          <Button
            children={ls.text.cancel}
            mr="1.5rem"
            decoration="text"
            onClick={closeModal}
          />
          <Button
            onClick={_action}
            color={props.color}
            children={actionText || props.children}
          />
        </Flexbox>
      </Modal>
    </>
  )
}

export default AcceptDialog
