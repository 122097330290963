import React, { useState } from 'react'

import { Button, Flexbox, TextField } from '@stage-ui/core'
import { PurchasesActions } from 'actions'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { PurchaseExtended } from '../data'

type PurchaseUpdateCommentModalProps = {
  purchaseRow: PurchaseExtended
  close: () => void
}

export const PurchaseUpdateCommentModal: React.FC<PurchaseUpdateCommentModalProps> = (
  props,
) => {
  const { purchaseRow, close } = props
  const ls = useLocalizedString()
  const [pending, setPending] = useState(false)
  const [comment, setComment] = useState(purchaseRow.comment || '')

  const handleSave = () => {
    if (pending) return
    setPending(true)
    PurchasesActions.update({ id: purchaseRow.id, comment }, close)
  }

  return (
    <Flexbox column>
      <TextField
        size="s"
        multiline
        rows={4}
        placeholder={ls.text.purchaseComment}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        autoFocus
        onFocus={(e) => {
          const { value } = e.target
          e.target.setSelectionRange(value.length, value.length)
        }}
      />
      <Flexbox mt="l" justifyContent="flex-end">
        <Button
          decoration="text"
          color="gray500"
          label={ls.text.cancel}
          onClick={close}
          mr="m"
        />
        <Button
          label={purchaseRow.comment ? ls.text.save : ls.text.add}
          onClick={handleSave}
        />
      </Flexbox>
    </Flexbox>
  )
}
