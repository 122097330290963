import React from 'react'

import { Button, Flexbox, Grid, useTheme } from '@stage-ui/core'
import FilterCommon from 'components/common/FilterCommon'
import MenuNavigation from 'components/MenuNavigation'

import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { ReportActions } from 'actions'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { newReportDisplay } from './NewReport'

export function Toolbar() {
  const theme = useTheme()
  const filter = useSelector((state) => state.report.filter, objectEqual)
  const ls = useLocalizedString()
  return (
    <Flexbox
      justifyContent="space-between"
      pb="m"
      style={{
        position: 'sticky',
        top: '6rem',
        zIndex: 7,
        backgroundColor: theme.color.gray[50].hex(),
      }}
    >
      <MenuNavigation
        items={[{ id: '', title: ls.text.all }]}
        currentSection={filter.section}
        onChange={(section) => ReportActions.setFilter({ section })}
      />
      <Grid ml="m" autoFlow="column" gap="0 0.5rem" w="max-content">
        <FilterCommon
          onSearch={(st) => ReportActions.setFilter({ search: st })}
          overrides={{ width: '15rem' }}
        />
        <Button label={ls.text.newReport} onClick={newReportDisplay} />
      </Grid>
    </Flexbox>
  )
}
