import React from 'react'

import { Flexbox, Spinner, Text } from '@stage-ui/core'
import { AlertTriangle } from '@stage-ui/icons'
import { useLocalizedString } from 'hooks/useLocalizedString'

export function TableLoadingStab(loading: 'idle' | 'pending' | 'resolved' | 'rejected') {
  const ls = useLocalizedString()
  if (loading === 'idle' || loading === 'pending') {
    return (
      <Flexbox column alignItems="center" justifyContent="center" p="m" mb="m">
        <Spinner color="light" />
        <Text mt="0.25rem" size="s" color="light" children={ls.text.loading} />
      </Flexbox>
    )
  }
  if (loading === 'rejected') {
    return (
      <Flexbox column alignItems="center" justifyContent="center" p="m" mb="m">
        <AlertTriangle size="2rem" type="filled" color="error" />
        <Text size="s" mt="0.125rem" color="light" children={ls.text.loadingError} />
      </Flexbox>
    )
  }
  return null
}
