import React, { useEffect, useMemo } from 'react'

import { ClientActions } from 'actions'

import { MarketStatus } from '@foods-n-goods/server/generated/schema'

import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { ClientListTable } from './components/Table'
import { ClientListToolbar, getClientListMenuItems } from './components/ClientListToolbar'

function ClientList() {
  const filter = useSelector((state) => state.client.filter, objectEqual)
  const ls = useLocalizedString()
  const clientListMenuItems = useMemo(getClientListMenuItems, [ls.id])
  useEffect(() => {
    const status =
      (clientListMenuItems.find((clmi) => clmi.id === filter.section)
        ?.status as Array<MarketStatus>) || undefined

    ClientActions.fetch({
      filter: {
        status,
        debt: filter.debt,
        ...(filter.registerDateStart &&
          filter.registerDateEnd && {
            registerDateStart: new Date(filter.registerDateStart),
            registerDateEnd: new Date(filter.registerDateEnd),
          }),
      },
      page: filter.page,
      pageSize: filter.pageSize,
      search: filter.search,
    })
  }, [
    filter.page,
    filter.search,
    filter.section,
    filter.debt,
    filter.registerDateStart,
    filter.registerDateEnd,
    filter.pageSize,
  ])

  return (
    <>
      <ClientListToolbar />
      <ClientListTable />
    </>
  )
}

export default ClientList
