import React, { useEffect, useState } from 'react'

import { Client } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox, Text, modal, Select, Spinner } from '@stage-ui/core'
import { ClientActions } from 'actions'
import SelectTypes from '@stage-ui/core/control/Select/types'
import request from 'requests/request'
import { getLocalizedString, useLocalizedString } from 'hooks/useLocalizedString'

type ClientExternalIdChangeModalProps = {
  client: Client
  close: () => void
}

function ClientExternalIdChangeModal(props: ClientExternalIdChangeModalProps) {
  const { client, close } = props
  const { id, defaultCompany, markets, name, identifier } = client
  const ls = useLocalizedString()
  const inn = defaultCompany?.inn
  const clientName = markets[0]?.alias || name || identifier || ls.text.unidentifiedClient

  const [marketUsersOptions, setMarketUsersOptions] = useState<
    SelectTypes.Option<string>[]
  >([])
  const [selected, setSelected] = useState<SelectTypes.Option<string>>()

  const getMarketUsers = async () => {
    if (!inn) return
    const clients = await request('marketUsers', { inn })
    if (clients) {
      const options = clients.map((c) => ({
        text: `${c.name} - ${c.exId}`,
        value: c.exId,
      }))

      setMarketUsersOptions(options)

      if (!selected) {
        setSelected(options.find((o) => o.value === markets[0].exId))
      }
    }
  }

  useEffect(() => {
    getMarketUsers()
  }, [])

  const handleApprove = async () => {
    if (!selected || !selected?.value) return

    await ClientActions.marketUpdate({
      id,
      payload: {
        exId: selected.value,
        status: 'APPROVED',
      },
    })
    close()
  }

  const handleSelectChange = (values: SelectTypes.Option<any>[]) => {
    setSelected(values[0])
  }

  return (
    <Flexbox column flex={1}>
      <Text size="s" mb="m" color="gray900">
        {clientName}
      </Text>
      <Select
        mt="m"
        label={ls.text.clientExternalIdInputLabel}
        disabled={!marketUsersOptions.length}
        values={selected ? [selected] : []}
        options={marketUsersOptions}
        onChange={handleSelectChange}
        rightChild={!marketUsersOptions.length && <Spinner mr="s" />}
      />
      <Flexbox mt="xl" alignItems="center" justifyContent="flex-end">
        <Button
          decoration="text"
          label={ls.text.cancel}
          onClick={close}
          color="gray500"
        />
        <Button label={ls.text.confirm} onClick={handleApprove} ml="m" />
      </Flexbox>
    </Flexbox>
  )
}

export const openClientExternalIdChangeModal = (
  client: Client,
  e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  e?.stopPropagation()
  e?.preventDefault()

  modal({
    title: getLocalizedString().text.clientExternalIdUpdateTitle,
    w: '32rem',
    overlayClose: false,
    overrides: {
      header: () => [
        {
          marginBottom: 0,
        },
      ],
    },
    render: (close) => <ClientExternalIdChangeModal client={client} close={close} />,
  })
}
