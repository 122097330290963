import React from 'react'

import { OrderStatusCode } from '@foods-n-goods/client/system/types'
import { Button, modal, useTheme } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { MoreVertical } from '@stage-ui/icons'
import { openClientModal } from 'components/Modals/ClientView'
import { openOrderModal } from 'components/Modals/OrderView'
import { MouseDropMenuItem, useMouseDropMenu } from 'components/MouseMenuDrop'
import { PurchaseExtended } from 'pages/purchases/data'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { PurchaseDeleteModal } from '../../DeleteModal'
import { PurchaseUpdateCommentModal } from '../../UpdateCommentModal'
import { PurchaseUpdatePurchaserModal } from '../../UpdatePurchaserModal'
import { PurchaseUpdateQuantityModal } from '../../UpdateQuantityModal'

export function ContextCell(ctx: TableTypes.TableCellContext<PurchaseExtended>) {
  const { color } = useTheme()
  const ls = useLocalizedString()
  const { row } = ctx
  const {
    id,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _isExpandable,
    comment,
    product,
    parentOrderId,
    client,
    status,
    orderId,
  } = row

  const { name: productName } = product

  const handleUpdateComment = () => {
    modal({
      title: comment ? ls.text.commentEdit : ls.text.addComment,
      subtitle: `${productName || ''} ${parentOrderId ? `[#${parentOrderId}]` : ''}`,
      w: '30rem',
      overlayClose: false,
      render: (close) => <PurchaseUpdateCommentModal close={close} purchaseRow={row} />,
    })
  }

  const handleUpdateQuantity = () => {
    modal({
      title: ls.text.quantityEdit,
      subtitle: `${productName || ''} ${parentOrderId ? `[#${parentOrderId}]` : ''}`,
      w: '30rem',
      overlayClose: false,
      render: (close) => <PurchaseUpdateQuantityModal close={close} purchaseRow={row} />,
    })
  }

  const handleUpdatePurchaser = () => {
    modal({
      title: _isExpandable ? ls.text.purchaserGroupAssign : ls.text.purchaserAssign,
      subtitle: `${productName || ''} ${parentOrderId ? `[#${parentOrderId}]` : ''}`,
      w: '30rem',
      overlayClose: false,
      render: (close) => <PurchaseUpdatePurchaserModal close={close} purchaseRow={row} />,
    })
  }

  const handleDelete = () => {
    modal({
      title: _isExpandable ? ls.text.purchaseGroupDelete : ls.text.purchaseDelete,
      subtitle: `${productName || ''} ${parentOrderId ? `[#${parentOrderId}]` : ''}`,
      w: '30rem',
      overlayClose: false,
      render: (close) => <PurchaseDeleteModal close={close} purchaseRow={row} />,
      overrides: {
        header: {
          h1: {
            color: color.red[500].hex(),
          },
        },
      },
    })
  }

  // @ts-expect-error idk why filter breaks this
  const ctxMenu: Array<MouseDropMenuItem> = [
    {
      text: ls.text.purchaserAssign,
      icon: 'PersonAdd',
      value: 'PURCHASER',
    },
    {
      text: ls.text.purchaserGroupAssign,
      icon: 'PersonAdd',
      value: 'PURCHASER_GROUP',
    },
    { text: ls.text.orderDetailsLabel, icon: 'ShoppingCart', value: 'ORDER' },
    { text: ls.text.clientCard, icon: 'People', value: 'CLIENT' },
    {
      text: comment ? ls.text.commentEdit : ls.text.addComment,
      icon: 'MessageCircle',
      value: 'COMMENT',
    },
    { text: ls.text.quantityEdit, icon: 'ShoppingCartPlus', value: 'QUANTITY' },
    {
      text: ls.text.purchaseDelete,
      icon: 'Trash2',
      value: 'DELETE',
      color: 'red500',
    },
  ].filter((i) => {
    if (!i.value || typeof i.value !== 'string') return false
    if (
      _isExpandable &&
      ['ORDER', 'CLIENT', 'COMMENT', 'DELETE', 'PURCHASER'].includes(i.value)
    ) {
      return false
    }
    if (!_isExpandable && ['PURCHASER_GROUP'].includes(i.value)) {
      return false
    }

    if (i.value === 'QUANTITY' && !!orderId) return false
    if (i.value === 'CLIENT' && !client) return false
    if (i.value === 'ORDER' && !parentOrderId) return false
    if (
      i.value === 'PURCHASER' &&
      status &&
      [OrderStatusCode.PURCHASE_CONFIRMED, OrderStatusCode.CANCELED].includes(
        status.value,
      )
    ) {
      return false
    }
    if (
      i.value === 'DELETE' &&
      status &&
      [OrderStatusCode.PURCHASE_CONFIRMED].includes(status.value)
    ) {
      return false
    }
    return true
  })

  const purchaseCtxMenu = useMouseDropMenu<HTMLButtonElement>(ctxMenu, ({ value }) => {
    if (value === 'QUANTITY') {
      return handleUpdateQuantity()
    }
    if (value === 'PURCHASER' || value === 'PURCHASER_GROUP') {
      return handleUpdatePurchaser()
    }
    if (value === 'DELETE') {
      return handleDelete()
    }
    if (value === 'ORDER' && parentOrderId) {
      return openOrderModal(parentOrderId)
    }
    if (value === 'CLIENT' && client) {
      return openClientModal(client.id)
    }
    if (value === 'COMMENT') {
      return handleUpdateComment()
    }
  })

  if (!ctxMenu.length) return null

  return (
    <Button
      id={`purchase_row_action_${id}`}
      decoration="text"
      color="gray500"
      p={0}
      w="2rem"
      h="2rem"
      {...purchaseCtxMenu}
    >
      <MoreVertical size="1.5rem" />
    </Button>
  )
}
