import React from 'react'

import { OrderStatusCode } from '@foods-n-goods/client/system/types'
import { Order } from '@foods-n-goods/server/generated/schema'
import { Block } from '@stage-ui/core'
import { Sync } from '@stage-ui/icons'
import { ColorText } from 'components/ColorText'
import { useMouseDrop } from 'components/MouseDrop'
import { useLocalizedString } from 'hooks/useLocalizedString'

export function OrderListTableSynchronizationCell(props: {
  order: Order
  disaplayAlways?: boolean
}) {
  const { order, disaplayAlways } = props
  const ls = useLocalizedString()
  const {
    source,
    exSync,
    exInvoiceId,
    status: { value: statusValue },
  } = order
  let hint = ls.text.synced
  if (statusValue !== OrderStatusCode.CANCELED) {
    if (!exSync) {
      hint = ls.text.syncing
    }
    if (!exInvoiceId && statusValue > OrderStatusCode.SORTING) {
      hint = ls.text.noSales
    }
  }

  const hintProps = useMouseDrop(hint, {
    drop: {
      align: 'top',
    },
    popover: {
      align: 'bottom',
    },
  })

  if (order.exSync && !disaplayAlways) {
    return null
  }

  return (
    <Block w="1.5rem" {...hintProps}>
      <ColorText
        h="1.5rem"
        backgroundColor={(c) => c.yellow.A400}
        color="gray900"
        size="xs"
        weight={600}
      >
        {order.exSync ? (
          '1C'
        ) : (
          <Sync
            size="1rem"
            mt="0.125rem"
            color="gray900"
            style={{
              animation: 'rotate-center 3s linear infinite',
            }}
          />
        )}
      </ColorText>
    </Block>
  )
}
