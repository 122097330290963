import React, { useEffect, useState } from 'react'

import { Order } from '@foods-n-goods/server/generated/schema'
import { Block, Divider, Flexbox, Spinner, Text } from '@stage-ui/core'
import { OrderActions } from 'actions'
import { localizeMessageWithParams, useLocalizedString } from 'hooks/useLocalizedString'
import dayjs from 'dayjs'
import { getCurrencySymbol } from 'hooks/useCurrency'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'

interface OrderHistoryProps {
  order: Order
}

export function OrderHistory(props: OrderHistoryProps) {
  const { order } = props
  const { workflow } = order
  const [loading, setLoading] = useState(false)
  const ls = useLocalizedString()
  const currencyCode = useSelector(
    (state) => state.app.marketConfig?.currency,
    objectEqual,
  )
  const currencySymbol = getCurrencySymbol(currencyCode, ls)
  useEffect(() => {
    setLoading(true)
    OrderActions.fetchOrderWorkflow(order.id)
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [order.id])

  if (loading) {
    return (
      <Flexbox centered p="xl">
        <Spinner mr="s" />
        <Text color="gray500" size="s">
          {ls.text.historyLoading}
        </Text>
      </Flexbox>
    )
  }
  if (!workflow.length) {
    return (
      <Flexbox column centered p="xl">
        <Text color="gray900">{ls.text.historyEmpty}</Text>
      </Flexbox>
    )
  }

  return (
    <Block p="0 m" w="50rem">
      {workflow.map((w, index) => {
        const message = localizeMessageWithParams(
          w.action,
          w.parameters as string[] | undefined,
          ls.string,
          currencySymbol,
        )
        return (
          <Flexbox key={w.id} style={{ minHeight: '4rem' }}>
            <Flexbox column centered mr="m">
              <Divider
                flex={1}
                vertical
                color={index === 0 ? 'transparent' : 'gray300'}
                dash="2px"
              />
              <Block
                flexShrink={0}
                w="0.75rem"
                h="0.75rem"
                borderRadius="50%"
                backgroundColor={index === 0 ? 'green500' : 'gray300'}
              />
              <Divider
                flex={1}
                vertical
                color={index + 1 === workflow.length ? 'transparent' : 'gray300'}
                dash="2px"
              />
            </Flexbox>
            <Flexbox
              p="m"
              flex={1}
              style={
                index === 0
                  ? {
                      borderRadius: '4px',
                      boxShadow: '0px 12px 16px rgba(0, 0, 0, 0.08)',
                    }
                  : {
                      borderBottom: '1px solid #D1D5DB',
                    }
              }
            >
              <Flexbox flex={1} alignItems="center" mr="s">
                <Text
                  weight={500}
                  color="gray900"
                  style={{
                    wordBreak: 'break-word',
                  }}
                >
                  {message}
                </Text>
              </Flexbox>
              <Flexbox flexShrink={0} column textAlign="right" alignSelf="flex-end">
                <Text size="xs" weight={500} color="gray500" mb="0.3125rem">
                  {dayjs(w.createDate).format('HH:mm')}
                </Text>
                <Text size="xs" weight={500} color="gray500" mb="0.125rem">
                  {dayjs(w.createDate).format('DD MMMM YYYY')}
                </Text>
              </Flexbox>
            </Flexbox>
          </Flexbox>
        )
      })}
    </Block>
  )
}
