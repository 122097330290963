import React from 'react'

import { Device } from '@foods-n-goods/server/generated/schema'
import { Block, Button, Flexbox, Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { Edit, Link2 } from '@stage-ui/icons'

import { getLocalizedString, useLocalizedString } from 'hooks/useLocalizedString'

import { updateDeviceDialog } from '../shared/form'

function ConnectionCell(props: { ctx: TableTypes.TableCellContext<Device> }) {
  const { ctx } = props
  const ls = useLocalizedString()
  if (!ctx.row.active) {
    return (
      <Text size="s" ml="xs" weight={600} color="light">
        {ls.text.turnedOff}
      </Text>
    )
  }
  return (
    <Flexbox alignItems="center">
      <Link2 size="1.5rem" color={ctx.row.scale?.online ? 'success' : 'error'} />
      <Flexbox column ml="xs">
        <Text size="s" weight={600} color={ctx.row.scale?.online ? 'success' : 'error'}>
          {ctx.row.protocol}
        </Text>
        <Text size="xs" weight={600} color="gray400">
          {ctx.row.host}
        </Text>
      </Flexbox>
    </Flexbox>
  )
}

function ValueCell(props: { ctx: TableTypes.TableCellContext<Device> }) {
  const { ctx } = props
  const ls = useLocalizedString()
  if (!ctx.row.scale?.online) {
    return (
      <Text weight="bold" color="light">
        -
      </Text>
    )
  }
  return (
    <Text weight="bold" color={ctx.row.scale?.stable ? 'primary' : 'light'}>
      {ctx.row.scale?.value.toFixed(3)} {ls.text.kg}
    </Text>
  )
}

export const getColumns = (): TableTypes.TableColumn<Device>[] => {
  const ls = getLocalizedString()
  return [
    { key: 'id', title: 'ID', width: '4rem', sort: 'ASC' },
    { key: 'name', width: 200, title: ls.text.name, sort: 'ASC' },
    {
      key: 'online',
      width: 200,
      title: ls.text.connection,
      render: (ctx) => <ConnectionCell ctx={ctx} />,
    },
    {
      key: 'valve',
      title: ls.text.currentWeight,
      render: (ctx) => <ValueCell ctx={ctx} />,
    },
    {
      key: '_',
      title: '',
      render: (ctx) => (
        <Flexbox justifyContent="flex-end">
          <Button
            ml="s"
            label={ls.text.change}
            decoration="plain"
            size="s"
            onClick={() => {
              updateDeviceDialog(ctx.row)
            }}
            rightChild={<Edit />}
          />
        </Flexbox>
      ),
    },
  ]
}
