import React from 'react'

import { StaffExtended } from '.'

import { Text } from '@stage-ui/core'

import TableTypes from '@stage-ui/core/data/Table/types'

import dayjs from 'dayjs'

export const StaffListCreateDateCell = (
  ctx: TableTypes.TableCellContext<StaffExtended>,
) => {
  const { row } = ctx

  return (
    <Text size="s" color="gray900">
      {dayjs(row.createDate).format('DD.MM.YYYY')}
    </Text>
  )
}
