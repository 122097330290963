/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useMemo } from 'react'

import { Block, Flexbox, Paragraph, TextField } from '@stage-ui/core'
import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

import { useLocalizedString } from 'hooks/useLocalizedString'

import SelectTypes from '@stage-ui/core/control/Select/types'

import { AppActions } from 'actions'

import { supportedLocales } from '@foods-n-goods/client/localization'

import { SettingsSelect } from './SettingsSelect'

const listSupportedCurrencys = ['RUB', 'USD', 'EUR', 'AED']

const listSupportedCountries = ['RU', 'UAE']

export function Localization() {
  const { marketConfig, staff } = useSelector(
    (state) => ({
      staff: state.app.user,
      marketConfig: state.app.marketConfig,
    }),
    objectEqual,
  )

  const ls = useLocalizedString()
  const defaultOption = { text: ls.text.notSelected, value: '-' }
  const languageOptions = supportedLocales
  const currencyOptions = useMemo(
    (): SelectTypes.Option<string>[] =>
      listSupportedCurrencys.map((l) => ({ text: ls.string[l], value: l })),
    [ls.id, listSupportedCurrencys],
  )
  const countryOptions = useMemo(
    (): SelectTypes.Option<string>[] =>
      listSupportedCountries.map((c) => ({ text: ls.string[c], value: c })),
    [],
  )
  const languageValue = useMemo((): SelectTypes.Option<string>[] => {
    const value = marketConfig?.localization || '-'
    return [languageOptions.find((option) => option.value === value) || defaultOption]
  }, [languageOptions, marketConfig?.localization])

  const currencyValue = useMemo((): SelectTypes.Option<string>[] => {
    const value = marketConfig?.currency || '-'
    return [
      currencyOptions.find((option) => option.value === value) || {
        text: ls.string.RUB,
        value: 'RUB',
      },
    ]
  }, [currencyOptions, marketConfig?.currency])

  const countryValue = useMemo((): SelectTypes.Option<string>[] => {
    const value = marketConfig?.country || '-'
    return [
      countryOptions.find((option) => option.value === value) || {
        text: ls.string.RU,
        value: 'RU',
      },
    ]
  }, [marketConfig?.country, countryOptions])

  return (
    <Block decoration="surface" borderColor="gray100" p="l">
      <Paragraph weight={500} size="l" color="gray900" mb="m">
        {ls.text.localization}
      </Paragraph>
      <SettingsSelect
        key={ls.text.language}
        label={ls.text.language}
        values={languageValue}
        options={languageOptions}
        onChange={(_, value) => {
          AppActions.marketConfigSet({
            localization: value?.value as string,
          })
        }}
      />
      <SettingsSelect
        label={ls.text.currency}
        values={currencyValue}
        options={currencyOptions}
        onChange={(_, value) => {
          AppActions.marketConfigSet({
            currency: value?.value as string,
          })
        }}
      />
      <SettingsSelect
        label={ls.text.country}
        values={countryValue}
        options={countryOptions}
        onChange={(_, value) => {
          AppActions.marketConfigSet({
            country: value?.value as string,
          })
        }}
      />
      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.3} mr="m">
          {ls.text.city}
        </Paragraph>
        <TextField
          decoration="none"
          p={0}
          defaultValue={marketConfig?.sync1cWhitelist || ''}
          onChange={(e) => {
            AppActions.marketConfigSet({
              city: e.target.value || '',
            })
          }}
        />
      </Flexbox>
    </Block>
  )
}
