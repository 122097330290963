import { useMemo } from 'react'

import store from 'store'

import { Maybe } from '@foods-n-goods/server/generated/schema'

import { LocalizedString } from '@foods-n-goods/client/localization/locale.json'

import { getLocalizedString, useLocalizedString } from './useLocalizedString'

const getResult = (input: {
  currencyValue: number | string
  ls: LocalizedString
  code: string | null | undefined
  maximumFractionDigits: number
}) => {
  const { currencyValue, ls, code, maximumFractionDigits } = input
  const value = Number(currencyValue)
  let currencyCode = code || localStorage.getItem('currency_code') || 'RUB'

  if (currencyCode === 'undefined') {
    currencyCode = 'RUB'
  }

  if (currencyCode) {
    localStorage.setItem('currency_code', currencyCode)
  }

  let currencyLocale = `${ls.id.slice(0, 2)}-${currencyCode.slice(0, 2)}`

  // fix T currency sign
  if (currencyCode === 'KZ') {
    currencyLocale = 'ru-RU'
  }
  return {
    pending: !code,
    raw: currencyValue,
    currencyCode,
    value,
    valueFormatted: value.toLocaleString(currencyLocale, {
      maximumFractionDigits,
      minimumFractionDigits: 0,
      style: 'currency',
      currency: currencyCode,
    }),
  }
}

export const getCurrency = (value: string | number, maximumFractionDigits = 2) => {
  const currencyCode = store.getState().app.marketConfig?.currency
  const ls = getLocalizedString()

  return getResult({
    currencyValue: value,
    maximumFractionDigits,
    ls,
    code: currencyCode,
  })
}

export const useCurrency = (
  currencyValue: string | number,
  currencyCode?: Maybe<string> | undefined,
  maximumFractionDigits = 2,
) => {
  const ls = useLocalizedString()

  return useMemo(
    () => getResult({ code: currencyCode, ls, currencyValue, maximumFractionDigits }),
    [currencyValue, currencyCode, ls.id],
  )
}

export const getCurrencySymbol = (
  currencyCode: Maybe<string> | undefined,
  ls: LocalizedString,
) => {
  const currency = currencyCode || localStorage.getItem('currency_code') || 'RUB'
  const locale = `${ls.id.slice(0, 2)}-${currency.slice(0, 2)}`
  return (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim()
}
