import {
  MutationOrderAddProductArgs,
  MutationOrderDeleteArgs,
  MutationOrderDeletePositionArgs,
  MutationOrderPriceActualizationArgs,
  MutationOrderRestoreDeletedProductArgs,
  MutationOrderSetCourierArgs,
  MutationOrderSetStatusArgs,
  MutationOrderSortArgs,
  MutationOrderUpdateCommentArgs,
  MutationOrderUpdateDeliveryDataArgs,
  MutationOrderUpdateQuantityArgs,
  MutationProductPrintInfoArgs,
  Order,
  QueryOrdersArgs,
} from '@foods-n-goods/server/generated/schema'
import { SocketType } from '@foods-n-goods/server/src/socket/types'
import { DeliveryActions } from 'actions'
import { EXPRESS_HOST } from 'env'
import { getLocalizedString } from 'hooks/useLocalizedString'
import { getOrderMenuItems } from 'pages/orders/OrderList/components/Toolbar/misc'
import Push from 'push.js'
import request from 'requests/request'
import { io } from 'requests/socket'
import dayjs from '@foods-n-goods/client/utils/dayjs'
import store, { DeliveryStore, OrderStore } from 'store'
import { OrderFilter } from 'store/order'
import actionNotify from 'utils/actionNotify'
import declOfNum from 'utils/declOfNum'
import localStorage from 'utils/localStorage'

export const rowsPrePage = 20

export default {
  async fetch(args: QueryOrdersArgs = {}) {
    try {
      OrderStore.fetch()
      OrderStore.resolve(await request('orders', args))
    } catch (error) {
      OrderStore.reject(error)
    }
  },

  async fetchById(id: Order['id']) {
    const ls = getLocalizedString()
    try {
      const res = await request('orders', {
        filter: {
          orderId: id,
          includePositions: true,
          includeContainers: true,
        },
      })

      if (res.total !== 1) throw new Error(ls.text.orderErrorBadId)

      OrderStore.setCurrent({
        ...res.records[0],
        workflow: [],
      })
    } catch (error) {
      actionNotify({
        title: ls.text.order.replace('[1]', id),
        message: error.message as string,
        type: 'error',
      })
    }
  },

  async sort(args: MutationOrderSortArgs) {
    const ls = getLocalizedString()
    try {
      await request('orderSort', args)
      actionNotify({
        title: ls.text.orderSort,
        message: declOfNum(args.ids.length, [
          ls.text.orderSortSuccess,
          ls.text.ordersSortSuccess,
          ls.text.ordersSortSuccess,
        ]).replace('[1]', args.ids.join(', ')),
        type: 'success',
      })
      OrderStore.clearSelected()
    } catch (error) {
      actionNotify({
        title: declOfNum(args.ids.length, [
          ls.text.order,
          ls.text.orders,
          ls.text.orders,
        ]).replace('[1]', args.ids.join(', ')),
        message: error.message as string,
        type: 'error',
      })
    }
  },

  async actualizePrice(args: MutationOrderPriceActualizationArgs) {
    const ls = getLocalizedString()
    try {
      await request('orderPriceActualization', args)
      actionNotify({
        title: ls.text.orderPriceActualization,
        message: ls.text.orderPriceActualizationSuccess,
        type: 'success',
      })
      OrderStore.clearSelected()
    } catch (error) {
      actionNotify({
        title: declOfNum(args.ids.length, [
          ls.text.order,
          ls.text.orders,
          ls.text.orders,
        ]).replace('[1]', args.ids.join(', ')),
        message: error.message as string,
        type: 'error',
      })
    }
  },

  async delete(args: MutationOrderDeleteArgs) {
    const ls = getLocalizedString()
    try {
      await request('orderDelete', args)
      OrderStore.clearSelected()
      this.fetch()
    } catch (error) {
      actionNotify({
        title: declOfNum(args.ids.length, [
          ls.text.order,
          ls.text.orders,
          ls.text.orders,
        ]).replace('[1]', args.ids.join(', ')),
        message: error.message as string,
        type: 'error',
      })
    }
  },

  async setStatus(args: MutationOrderSetStatusArgs) {
    const ls = getLocalizedString()
    try {
      await request('orderSetStatus', args)
      OrderStore.clearSelected()
    } catch (error) {
      actionNotify({
        title: declOfNum(args.ids.length, [
          ls.text.order,
          ls.text.orders,
          ls.text.orders,
        ]).replace('[1]', args.ids.join(', ')),
        message: error.message as string,
        type: 'error',
      })
      throw error
    }
  },

  async setCourier(args: MutationOrderSetCourierArgs) {
    const ls = getLocalizedString()
    try {
      await request('orderSetCourier', args)
      DeliveryStore.coordinatorClearOrders()
    } catch (error) {
      actionNotify({
        title: declOfNum(args.ids.length, [
          ls.text.order,
          ls.text.orders,
          ls.text.orders,
        ]).replace('[1]', args.ids.join(', ')),
        message: error.message as string,
        type: 'error',
      })
    }
  },

  async addProducts(args: MutationOrderAddProductArgs, _cb: () => void) {
    try {
      await request('orderAddProduct', args)
      _cb()
    } catch (error) {
      actionNotify({
        title: getLocalizedString().text.order.replace('[1]', args.id),
        message: `${error.message as string}`,
        type: 'error',
      })
    }
  },

  async orderUpdateQuantity(args: MutationOrderUpdateQuantityArgs, _cb?: () => void) {
    try {
      await request('orderUpdateQuantity', args)
      _cb?.()
    } catch (error) {
      actionNotify({
        title: getLocalizedString().text.order.replace('[1]', args.positionId),
        message: `${error.message as string}`,
        type: 'error',
      })
    }
  },

  async deleteProduct(args: MutationOrderDeletePositionArgs, _cb?: () => void) {
    try {
      await request('orderDeletePosition', args)
      _cb?.()
    } catch (error) {
      actionNotify({
        title: getLocalizedString().text.order.replace('[1]', args.orderId),
        message: `${error.message as string}`,
        type: 'error',
      })
    }
  },

  async restorePosition(args: MutationOrderRestoreDeletedProductArgs) {
    try {
      await request('orderRestoreDeletedProduct', args)
    } catch (error) {
      actionNotify({
        title: getLocalizedString().text.order.replace('[1]', args.positionId),
        message: `${error.message as string}`,
        type: 'error',
      })
    }
  },

  async updateComment(args: MutationOrderUpdateCommentArgs, _cb?: () => void) {
    try {
      await request('orderUpdateComment', args)
      _cb?.()
    } catch (error) {
      actionNotify({
        title: getLocalizedString().text.order.replace('[1]', args.id),
        message: `${error.message as string}`,
        type: 'error',
      })
    }
  },

  async fetchOrderWorkflow(orderId: string) {
    try {
      const workflow = await request('orderWorkflow', {
        orderId,
      })
      OrderStore.setCurrentWorkflow(workflow)
      return workflow
    } catch (error) {
      actionNotify({
        title: getLocalizedString().text.orderWorkflow.replace('[1]', orderId),
        message: `${error.message as string}`,
        type: 'error',
      })
      return null
    }
  },

  async orderUpdateDeliveryData(args: MutationOrderUpdateDeliveryDataArgs) {
    const ls = getLocalizedString()
    try {
      await request('orderUpdateDeliveryData', args)
      actionNotify({
        title: ls.text.orderDeliveryUpdateSuccess.replace('[1]', args.id),
        message: '',
        type: 'success',
      })
      return true
    } catch (error) {
      actionNotify({
        title: ls.text.orderDeliveryUpdateError.replace('[1]', args.id),
        message: `${error.message as string}`,
        type: 'error',
      })
      return false
    }
  },

  setTableDisplayMode(displayMode: 'full' | 'short') {
    localStorage.save('orders/displayMode', displayMode)
    OrderStore.setTableDisplayMode(displayMode)
  },

  setSelected(orders: Array<{ order: Order; isSelected: boolean }>) {
    OrderStore.setSelected(orders)
  },

  clearSelected() {
    OrderStore.clearSelected()
  },

  setCurrent(order: Order) {
    OrderStore.setCurrent(order)
  },

  async printInfo(args: MutationProductPrintInfoArgs): Promise<void> {
    const ls = getLocalizedString()
    try {
      const count = await request('productPrintInfo', args)
      if (count) {
        actionNotify({
          title: ls.text.labelPrint,
          message: declOfNum(count, [
            ls.text.labelPrintInProgress1,
            ls.text.labelPrintInProgress2,
            ls.text.labelPrintInProgress2,
          ]),
        })
      } else {
        actionNotify({
          title: ls.text.labelPrint,
          message: ls.text.labelPrintErrorNoItems,
        })
      }
    } catch (error) {
      actionNotify({
        title: ls.text.labelPrintError,
        message: `${error.message as string}`,
        type: 'error',
      })
    }
  },

  setFilter(filter: Partial<OrderFilter>) {
    OrderStore.setFilter(filter)
  },

  clearFilter() {
    OrderStore.clearFilter()
  },

  clearCurrent() {
    OrderStore.setCurrent(null)
  },
}

const browserNotification = (order: Order) => {
  const index = store
    .getState()
    .order.orderPage.records.findIndex(({ id }) => id === order.id)

  // Push notification whenever u can be
  if (order.status.value === 1 && index < 0 && !order.deleteDate) {
    const alias = order.client.markets[0]?.alias
    const ls = getLocalizedString()
    const title = alias
      ? ls.text.newOrderWithClienAlias.replace('[1]', order.id).replace('[2]', alias)
      : ls.text.newOrder.replace('[1]', order.id)
    const onClick = () => {
      window.open(`${EXPRESS_HOST}/orders/${order.id}`)
    }
    Push.create(title, { onClick })
  }
}

io.on(SocketType.Broadcast.OrdersUpdate, (orders: Order[]) => {
  const ordersLoading = store.getState().order.loading
  if (ordersLoading === 'resolved' || !!store.getState().order.currentlyViewed) {
    const { status, section, search, deliveryDateEnd, deliveryDateStart } =
      store.getState().order.filter

    orders.forEach((order) => {
      browserNotification(order)

      const isCurrentlyViewed = store.getState().order.currentlyViewed?.id === order.id
      if (isCurrentlyViewed) OrderStore.updateCurrentlyViewed(order)

      /**
       * Prevent re-renders for searchable data
       * if search or date range set
       */
      if (search) return

      /**
       * Do logic for different pages and statuses
       */
      const availableStatuses = getOrderMenuItems().find(
        (i) => i.id === section,
      )?.statusIds
      const exactStatus = availableStatuses
        ? availableStatuses.includes(order.status.value)
        : true

      const onSelectedDates = dayjs(order.preferredDeliveryDate).isBetween(
        dayjs(deliveryDateStart).startOf('day'),
        dayjs(deliveryDateEnd).endOf('day'),
        null,
        '[]',
      )

      if (
        onSelectedDates &&
        ((!status && exactStatus) || (status && Number(status) === order.status.value))
      ) {
        OrderStore.upsert(order)
      } else {
        // remove order from store (pagination problem?)
        OrderStore.remove(order)
      }
    })
  }

  const deliveryLoading = store.getState().delivery.loading
  if (deliveryLoading === 'resolved') {
    // FIXME: possible problem because of type
    // @ts-ignore
    DeliveryActions.updateOrders(orders)
  }
})

io.on(SocketType.Broadcast.OrdersStat, OrderStore.setStatCounters)
