import dayjs from 'dayjs'
import { getLocalizedString } from 'hooks/useLocalizedString'

export const humanize = {
  seconds: (sec: number = 0): string => {
    const time = dayjs.duration(sec, 'seconds')
    const ls = getLocalizedString()
    if (time.years() > 0) {
      return ls.text.yearMonth
        .replace('[1]', String(time.years()))
        .replace('[2]', String(time.months()))
    }
    if (time.months() > 0) {
      return ls.text.monthDay
        .replace('[1]', String(time.months()))
        .replace('[1]', String(time.days()))
    }
    if (time.days() > 0) {
      return ls.text.dayHour
        .replace('[1]', String(time.days()))
        .replace('[2]', String(time.hours()))
    }
    if (time.hours() > 0) {
      return ls.text.hourMin
        .replace('[1]', String(time.hours()))
        .replace('[2]', String(time.minutes()))
    }
    if (time.minutes() > 0) {
      return ls.text.minutes.replace('[1]', String(time.minutes()))
    }
    return ls.text.lessThanMinute
  },
  meters: (len: number = 0): string => {
    const ls = getLocalizedString()
    return `${(len / 1000).toFixed(1)} ${ls.text.km}`
  },
}
