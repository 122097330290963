import React, { useState } from 'react'

import { Block, Button, Flexbox, TextField } from '@stage-ui/core'
import { ProductAttribute } from '@foods-n-goods/server/generated/schema'
import { useLocalizedString } from 'hooks/useLocalizedString'

interface Props {
  attribute?: ProductAttribute
  onClose: (attribute?: ProductAttribute) => void
}

export function AttributeEditDialog(props: Props) {
  const { attribute, onClose } = props
  const ls = useLocalizedString()
  const [name, setName] = useState(attribute?.name || '')
  const [value, setValue] = useState(attribute?.value || '')

  const save = () => {
    if (name && value) {
      onClose({ name, value })
    }
  }

  return (
    <Block w="24rem">
      <TextField
        labelType="outside"
        disabled={!!attribute}
        mt="0.5rem"
        label={ls.text.name}
        defaultValue={name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        labelType="outside"
        mt="0.5rem"
        label={ls.text.value}
        defaultValue={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <Flexbox mt="2rem" justifyContent="flex-end">
        <Button
          children={ls.text.cancel}
          mx="0.5rem"
          decoration="text"
          onClick={() => onClose()}
        />
        <Button
          children={attribute ? ls.text.edit : ls.text.add}
          textColor="onPrimary"
          onClick={save}
          disabled={!name || !value}
        />
      </Flexbox>
    </Block>
  )
}
