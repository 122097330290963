import React, { useState } from 'react'

import { Button, Flexbox } from '@stage-ui/core'
import { PurchasesActions } from 'actions'
import ViewInputField from 'components/Fields/ViewInputField'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { PurchaseExtended } from '../data'

type PurchaseUpdateQuantityModalProps = {
  purchaseRow: PurchaseExtended
  close: () => void
}

export const PurchaseUpdateQuantityModal: React.FC<PurchaseUpdateQuantityModalProps> = (
  props,
) => {
  const { purchaseRow, close } = props
  const ls = useLocalizedString()
  const [pending, setPending] = useState(false)
  const [quantity, setQuantity] = useState(String(purchaseRow.quantity || ''))

  const handleSave = () => {
    if (pending) return
    setPending(true)

    PurchasesActions.update(
      {
        id: purchaseRow.id,
        quantity: Number(quantity),
      },
      close,
    )
  }

  return (
    <Flexbox column>
      <ViewInputField
        autoFocus
        label={ls.text.productQuantity}
        value={quantity}
        onChange={(v) => setQuantity(v)}
        onEnter={handleSave}
        type={purchaseRow.product.unit.value == 1 ? 'digits' : 'integer'}
        digits={2}
        rightChild={ls.string[purchaseRow.product.unit.code]}
      />
      <Flexbox mt="l" justifyContent="flex-end">
        <Button
          decoration="text"
          color="gray500"
          label={ls.text.cancel}
          onClick={close}
          mr="m"
        />
        <Button label={ls.text.save} onClick={handleSave} />
      </Flexbox>
    </Flexbox>
  )
}
