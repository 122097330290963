import React, { useState } from 'react'

import { Client } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox, modal, Text, TextField } from '@stage-ui/core'
import { Info } from '@stage-ui/icons'
import { ClientActions } from 'actions'
import { getLocalizedString, useLocalizedString } from 'hooks/useLocalizedString'

type ClientCredentialsChangeModalProps = {
  client: Client
  close: () => void
}

function ClientCredentialsChangeModal(props: ClientCredentialsChangeModalProps) {
  const { client, close } = props
  const { id } = client
  const ls = useLocalizedString()
  const [identifier, setIdentifier] = useState(client.identifier)
  const [password, setPassword] = useState('')

  const handleChangeLogin = async () => {
    if (!client || !identifier || !password || password.length <= 3) return

    await ClientActions.update({
      id,
      payload: {
        identifier,
        password,
      },
    })
    close()
  }

  return (
    <Flexbox column>
      <Flexbox
        p="m"
        borderStyle="solid"
        borderWidth="1px"
        borderColor="gray200"
        borderRadius="m"
        backgroundColor="gray100"
        alignItems="center"
      >
        <Info color="orange500" mr="m" size="1.125rem" />
        <Text size="s" color="orange500">
          {ls.text.clientLoginUpdateWarning}
        </Text>
      </Flexbox>
      <TextField
        mt="m"
        value={identifier}
        label={ls.text.clientLoginNew}
        placeholder={ls.text.clientLoginPlaceholder}
        onChange={(e) => setIdentifier(e.target.value)}
      />
      <TextField
        mt="m"
        value={password}
        label={ls.text.clientPasswordNew}
        placeholder={ls.text.clientPasswordPlaceholder}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Flexbox mt="xl" alignItems="center" justifyContent="flex-end">
        <Button
          decoration="text"
          label={ls.text.cancel}
          onClick={close}
          color="gray500"
        />
        <Button label={ls.text.confirm} onClick={handleChangeLogin} ml="m" />
      </Flexbox>
    </Flexbox>
  )
}

export const openClientCredentialsChangeModal = (
  client: Client,
  e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  e?.stopPropagation()
  e?.preventDefault()

  modal({
    w: '35rem',
    title: getLocalizedString().text.clientLoginUpdate,
    overlayClose: false,
    render: (close) => <ClientCredentialsChangeModal client={client} close={close} />,
  })
}
