import React from 'react'

import { Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { PurchaseProduct } from '@foods-n-goods/server/generated/schema'
import dayjs from 'dayjs'

export function UpdateDateCell(ctx: TableTypes.TableCellContext<PurchaseProduct>) {
  const { row } = ctx
  const { updateDate } = row

  if (!updateDate) return null

  return (
    <Text color="gray500" size="s">
      {dayjs(updateDate).format('DD MMMM')}
    </Text>
  )
}
