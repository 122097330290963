import React from 'react'

import { OrderStatusCode } from '@foods-n-goods/client/system/types'
import { StatusIndicator } from 'components/StatusIndicator'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { useLocalizedString } from 'hooks/useLocalizedString'

export function DialogCount() {
  let unread = 0

  const { userId, rooms } = useSelector(
    (state) => ({
      userId: state.app.user?.id,
      rooms: state.chat.rooms,
    }),
    objectEqual,
  )
  const ls = useLocalizedString()
  rooms.forEach((room) => {
    unread += room.messages.some((m) => !m.readTime && (!m.user || m.user.id !== userId))
      ? 1
      : 0
  })

  if (!unread) return null

  return (
    <StatusIndicator
      text={String(unread)}
      status={{ text: String(unread), value: OrderStatusCode.REJECTED }}
      hint={ls.text.chatMessageNew}
      ml="xs"
      p="0 s"
      h="1.25rem"
      type="fill"
    />
  )
}
