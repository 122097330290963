import { WeightRounding } from '@foods-n-goods/server/generated/schema'
import { getLocalizedString } from 'hooks/useLocalizedString'

export const getScaleAcceptOptions = () => {
  const ls = getLocalizedString()
  return [
    {
      text: ls.text.doNothing,
      value: '0',
    },
    {
      text: ls.text.warn,
      value: '1',
    },
    {
      text: ls.text.forbid,
      value: '2',
    },
  ]
}

export const getAlertTypeOptions = () => {
  const ls = getLocalizedString()
  return [
    {
      text: ls.text.alwaysAllow,
      value: '0',
    },
    {
      text: ls.text.missingItemsWarn,
      value: '1',
    },
    {
      text: ls.text.forbidWhileUnassembledItems,
      value: '2',
    },
  ]
}

export const getOnOffLabels = () => {
  const ls = getLocalizedString()
  return [
    {
      text: ls.text.isDisabled,
      value: '0',
    },
    {
      text: ls.text.isEnabled,
      value: '1',
    },
  ]
}

type WeightRoundingLabel = {
  text: string
  value: WeightRounding
}

export const getWeightRoundingLabels = (): Array<WeightRoundingLabel> => {
  const ls = getLocalizedString()
  return [
    {
      text: ls.text.dontRound,
      value: 'dontRound',
    },
    {
      text: ls.text.mathRound,
      value: 'math',
    },
    {
      text: ls.text.roundUp,
      value: 'roundUp',
    },
    {
      text: ls.text.roundDown,
      value: 'roundDown',
    },
  ]
}
