import { MutationProductReviewModerateArgs } from '@foods-n-goods/server/generated/schema'
import { getLocalizedString } from 'hooks/useLocalizedString'
import request from 'requests/request'
import store, { AppStore, ReviewStore } from 'store'
import actionNotify from 'utils/actionNotify'

export const rowsPrePage = 20

export default {
  async getAll() {
    try {
      ReviewStore.loading()
      const reviews = await request('productReviews')
      ReviewStore.resolve(reviews)
    } catch (error) {
      ReviewStore.reject(error)
    }
  },

  async update(args: MutationProductReviewModerateArgs, _cb: () => void) {
    try {
      AppStore.pending(true)
      const response = await request('productReviewModerate', args)
      ReviewStore.actionUpdateResolve(response)
      const ls = getLocalizedString()
      actionNotify({
        title: ls.text.productReview.replace('[1]', response.order.id),
        message: ls.text.productReviewUpdateSuccess,
      })

      _cb()
    } catch (error) {
      ReviewStore.actionReject(error.message || error)
    } finally {
      AppStore.pending(false)
    }
  },

  async setPage(page: number) {
    try {
      if (page === store.getState().review.page) {
        return
      }
      AppStore.pending(true)
      const data = await request('productReviews', {
        skip: rowsPrePage * (page - 1),
      })
      ReviewStore.resolvePage({ data, page })
    } catch (error) {
      ReviewStore.actionReject(error)
    } finally {
      AppStore.pending(false)
    }
  },
}
