import React from 'react'

import { Order } from '@foods-n-goods/server/generated/schema'
import { Flexbox, Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { useLocalizedString } from 'hooks/useLocalizedString'
import { useCurrency } from 'hooks/useCurrency'

export function OrderListAmountCellRight(ctx: TableTypes.TableCellContext<Order>) {
  const { row } = ctx
  const { amount, amountAfter } = row
  const { valueFormatted } = useCurrency(amountAfter || amount)
  const ls = useLocalizedString()
  const currencyCode = useSelector(
    (state) => state.app.marketConfig?.currency,
    objectEqual,
  )
  const currentDisplayMode = useSelector((state) => state.order.displayMode, objectEqual)

  return (
    <Flexbox alignItems="center">
      <Text
        size={currentDisplayMode === 'short' ? 'xs' : 's'}
        color="gray900"
        weight={600}
        mr="0.375rem"
      >
        {valueFormatted}
      </Text>
    </Flexbox>
  )
}
