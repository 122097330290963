import React, { useState } from 'react'

import { Order } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox, modal, TextField } from '@stage-ui/core'
import { OrderActions } from 'actions'
import { getLocalizedString, useLocalizedString } from 'hooks/useLocalizedString'

type OrderCommentChangeModalProps = {
  order: Order
  close: () => void
}

function OrderCommentChangeModal(props: OrderCommentChangeModalProps) {
  const { order, close } = props
  const { comment, id } = order
  const ls = useLocalizedString()
  const [value, setValue] = useState(comment ?? '')
  const hasChangedComment = value !== comment

  const updateComment = () => {
    OrderActions.updateComment({ id, comment: value })
    close()
  }

  return (
    <Flexbox column>
      <TextField
        w="100%"
        flexBasis="100%"
        multiline
        placeholder={ls.text.orderCommentAdd}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <Flexbox justifyContent="flex-end" mt="xl">
        <Button
          color="gray500"
          textColor="gray500"
          decoration="text"
          label={ls.text.cancel}
          mr="m"
          onClick={close}
        />
        <Button
          label={ls.text.confirm}
          disabled={!hasChangedComment}
          onClick={updateComment}
        />
      </Flexbox>
    </Flexbox>
  )
}

export const openOrderCommentChangeModal = (
  order: Order,
  e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  e?.stopPropagation()
  e?.preventDefault()
  const ls = getLocalizedString()
  modal({
    title: ls.text.orderCommentTitle,
    subtitle: ls.text.orderDetails
      .replace('[1]', order.id)
      .replace('[2]', order.client.name ?? '-'),
    w: '30rem',
    overrides: {
      header: { marginBottom: '1rem' },
    },
    overlayClose: false,
    render: (close) => <OrderCommentChangeModal close={close} order={order} />,
  })
}
