import ClientSystem from '@foods-n-goods/client/system/types'
import { Staff } from '@foods-n-goods/server/generated/schema'
import { MobileData, SocketOnlineData } from '@foods-n-goods/server/src/shared/staff'
import { GeoData } from '@foods-n-goods/server/src/socket/geo'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs from '@foods-n-goods/client/utils/dayjs'

export type StaffFilter = {
  section?: string
  search?: string
}

export interface StaffState {
  loading: ClientSystem.Loading
  error: RequestError | null

  data: Staff[]
  currentlyViewed: Staff | null
  filter: StaffFilter
}

export const initialState: StaffState = {
  loading: 'idle',
  error: null,

  data: [],
  currentlyViewed: null,
  filter: {},
}

const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    loading(state) {
      state.loading = 'pending'
    },

    resolve(state, action: PayloadAction<Staff[]>) {
      state.loading = 'resolved'
      state.data = action.payload.map((staff) => ({
        ...staff,
        roleText: staff.role.text,
      }))
      state.error = initialState.error
    },

    findResolve(state, action: PayloadAction<Staff>) {
      state.currentlyViewed = action.payload
    },

    reject(state, action: PayloadAction<StaffState['error']>) {
      state.loading = 'rejected'
      state.error = action.payload
    },

    setCurrentlyViewed(state, action: PayloadAction<Staff | null>) {
      state.currentlyViewed = action.payload
    },

    /* ACTION */
    updateResolve(state, { payload }: PayloadAction<Staff>) {
      if (state.currentlyViewed?.id === payload.id) {
        state.currentlyViewed = payload
      }

      const currentIndex = state.data.findIndex((staff) => staff.id === payload.id)
      const removal = !!payload.deleteDate

      if (currentIndex < 0) {
        state.data = [payload].concat(state.data)
      } else if (removal) {
        state.data = state.data.filter((staff) => staff.id !== payload.id)
      } else {
        state.data[currentIndex] = payload
      }
    },

    updateOnline(state, action: PayloadAction<SocketOnlineData>) {
      action.payload.forEach((item) => {
        state.data = state.data.map((staff) => {
          if (staff.id === item.id) {
            staff.online = item.online ? 1 : 0

            if (item.lastOnlineDate) {
              staff.lastOnlineDate = item.lastOnlineDate
            }

            if (item.selfPhoneInfo) {
              const data: MobileData = JSON.parse(item.selfPhoneInfo)
              staff.batteryLife = data.batteryLife
              staff.selfPhoneInfo = item.selfPhoneInfo
            }
          }
          return staff
        })
      })
    },

    checkOnline(state) {
      const currenDate = dayjs().utc()
      state.data = state.data.map((staff) => {
        const diff = currenDate.diff(dayjs(staff.lastOnlineDate).utc(), 'm')
        return { ...staff, online: diff < 1 ? 1 : 0 }
      })
    },

    actionUpdatePrefs(state, action: PayloadAction<{ prefs: string; id: string }>) {
      const staff = state.data.find((staff) => staff.id === action.payload.id)
      if (staff) {
        location.reload()
        return {
          ...state,
          ...staff,
          prefs: action.payload.prefs,
        }
      }
      return state
    },

    updateCourierPosition(state, action: PayloadAction<GeoData>) {
      state.data = state.data.map((courier) => {
        if (courier.id === action.payload.user.id && courier.lastGeoPosition) {
          return {
            ...courier,
            lastGeoPosition: {
              timestamp: action.payload.info.timestamp,
              coords: {
                ...action.payload.info.coords,
                heading:
                  action.payload.info.heading || action.payload.info.coords.heading || 0,
              },
            },
          }
        }
        return courier
      })
    },

    setFilter(state, action: PayloadAction<Partial<StaffFilter>>) {
      state.filter = {
        ...state.filter,
        ...action.payload,
      }
    },

    clearFilter(state) {
      state.filter = {}
    },

    /* COMMON */
    clear(state) {
      return initialState
    },
  },
})

export default staffSlice
