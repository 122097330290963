import React from 'react'

import { OrderStatusCode } from '@foods-n-goods/client/system/types'
import { Order } from '@foods-n-goods/server/generated/schema'
import { Button, Grid, modal, useTheme } from '@stage-ui/core'
import { Archive, ArrowRight, Pricetag, Printer, Swap } from '@stage-ui/icons'
import { OrderActions } from 'actions'
import { useMouseDrop } from 'components/MouseDrop'

import { openOrderStatusChangeModal } from 'components/Modals/OrderStatusChange'

import { openDevicePrinterModal } from 'components/Modals/DevicePrinterSelect'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { OrderArchive } from '../../components/OrderArchiveModal'

type OrderControlsProps = {
  order: Order
}

export function OrderControls(props: OrderControlsProps) {
  const { order } = props
  const theme = useTheme()
  const ls = useLocalizedString()
  const handleArchive = () => {
    modal({
      title: ls.text.sendToArchive,
      overlayClose: false,
      subtitle: ls.text.orderDetails
        .replace('[1]', String(order.id))
        .replace('[2]', order.client.name ?? '-'),
      w: '31rem',
      backgroundColor: 'gray50',
      overrides: { header: { marginBottom: theme.spacing.l } },
      render: (close) => <OrderArchive close={close} orders={[order]} />,
    })
  }

  const handleStatusChange = () => {
    openOrderStatusChangeModal([order])
  }

  const handleSort = () => {
    OrderActions.sort({ ids: [order.id] })
  }

  const handleActualizePrice = () => {
    OrderActions.actualizePrice({ ids: [order.id] })
  }

  const archiveAvailable =
    !order.deleteDate && order.status.value === OrderStatusCode.CANCELED
  const hintActionSetStatus = useMouseDrop(ls.text.changeStatus)
  const hintActionPriceActualize = useMouseDrop(
    order.actionPermissions.actualize && ls.text.updatePrice,
  )
  const hintEacPrint = useMouseDrop(ls.text.eacStickerPrinting)
  const hintActionArchive = useMouseDrop(archiveAvailable && ls.text.sendToArchive)

  return (
    <Grid autoFlow="column" gap="0.5rem">
      <Button
        {...hintActionSetStatus}
        decoration="text"
        p="0 s"
        onClick={handleStatusChange}
      >
        <Swap size="1.5rem" />
      </Button>
      <Button
        disabled={!order.actionPermissions.actualize}
        {...hintActionPriceActualize}
        decoration="text"
        p="0 s"
        onClick={handleActualizePrice}
      >
        <Pricetag size="1.5rem" />
      </Button>

      <Button
        {...hintEacPrint}
        decoration="text"
        p="0 s"
        onClick={() => {
          openDevicePrinterModal((deviceId) => {
            OrderActions.printInfo({
              deviceId,
              orderIds: [order.id],
            })
          })
        }}
      >
        <Printer size="1.5rem" />
      </Button>

      <Button
        disabled={!archiveAvailable}
        {...hintActionArchive}
        decoration="text"
        p="0 s"
        onClick={handleArchive}
        color="red500"
      >
        <Archive size="1.5rem" type="outline" />
      </Button>
      <Button
        px="l"
        disabled={!order.actionPermissions.sorting}
        label={ls.text.sendToPicking}
        rightChild={<ArrowRight size="1.5rem" />}
        onClick={handleSort}
      />
    </Grid>
  )
}
