/* eslint-disable react/no-unstable-nested-components */
import React from 'react'

import { ProductGroup } from '@foods-n-goods/server/generated/schema'
import { Block, Flexbox, Paragraph, Text, useTheme } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { Camera, Home, Layers } from '@stage-ui/icons'
import { PageTable } from 'components/PageTable'
import CActionCell from 'components/TableCells/CActionCell'
import { CDatetimeFullCell } from 'components/TableCells/CDatetimeCell'
import useSelector from 'hooks/useSelector'
import { useHistory } from 'react-router-dom'
import { objectEqual } from 'utils/objectEqual'
import { useLocalizedString } from 'hooks/useLocalizedString'

function ActionCell(ctx: TableTypes.TableCellContext<ProductGroup>) {
  const history = useHistory()
  const { row } = ctx
  const { id } = row
  const onDetailsClick = () => history.push(`/categories/${id}`)

  return <CActionCell open={onDetailsClick} />
}

function NameCell(ctx: TableTypes.TableCellContext<ProductGroup>) {
  const { row } = ctx
  const { name, parentId } = row
  const { parentName } = useSelector(
    ({ category }) => ({
      parentName: category.data.records.find((c) => c.id === parentId)?.name || '',
    }),
    objectEqual,
  )

  return (
    <Block>
      <Paragraph
        m={0}
        weight={!parentId ? 'bold' : 'normal'}
        color={!parentId ? 'onBackground' : 'gray500'}
        children={name}
      />
      {parentName && <Paragraph m={0} size="xs" color="light" children={parentName} />}
    </Block>
  )
}

function ImageCell(ctx: TableTypes.TableCellContext<ProductGroup>) {
  const {
    color: { surface },
  } = useTheme()
  const { image, type, parentId, published } = ctx.row

  function PublishedIndicator() {
    return (
      <Block
        w="0.625rem"
        h="0.625rem"
        backgroundColor={published ? 'success' : 'light'}
        borderRadius="50%"
        style={{
          position: 'absolute',
          top: '0.25rem',
          right: '0.25rem',
          zIndex: 1,
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: surface.hex(),
        }}
      />
    )
  }

  function Image() {
    if (!image) {
      return <Camera size="calc(4.5rem * 0.75)" color="lightest" />
    }
    return (
      <div
        style={{
          width: '4.5rem',
          height: '4.5rem',
          background: image ? `url(${image})` : '',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 50%',
        }}
      />
    )
  }
  return (
    <Flexbox
      borderColor="surface"
      backgroundColor="surface"
      style={{
        overflow: 'hidden',
        width: '4.5rem',
        height: '4.5rem',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '0.5rem',
        borderWidth: 0,
        borderStyle: 'solid',
      }}
    >
      <PublishedIndicator />
      {type.code === 'FRONT_MARKET' &&
        (parentId ? <Image /> : <Layers size="calc(4.5rem * 0.75)" color="onSurface" />)}
      {type.code === 'WAREHOUSE' && <Home size="calc(4.5rem * 0.75)" color="orange500" />}
      {type.code === 'CATEGORY' && <Image />}
    </Flexbox>
  )
}

function CategoriesTable() {
  const history = useHistory()
  const ls = useLocalizedString()
  const filter = useSelector(({ product }) => product.filter, objectEqual)
  const columns: TableTypes.TableColumn<ProductGroup>[] = [
    { key: 'image', title: '', width: 72, render: ImageCell },
    { key: 'name', title: ls.text.name, render: NameCell },
    {
      key: 'productCount',
      title: ls.text.productCount,
      render: (ctx) => <Text children={ctx.value} />,
    },
    {
      key: 'sortWeight',
      title: ls.text.sorting,
      render: (ctx) => <Text children={ctx.value} />,
    },
    { key: 'createDate', title: ls.text.createDate, render: CDatetimeFullCell },
    { key: '___actionCell', title: '', width: 64, render: ActionCell },
  ]
  const { data, loading } = useSelector(
    ({ category }) => ({
      data: category.data.records.filter(
        (category) =>
          filter.section === category.type.code?.toLowerCase() &&
          (filter.search
            ? category.name.trim().toLowerCase().match(filter.search.trim().toLowerCase())
            : true),
      ),
      loading: category.loading === 'pending',
      total: category.data.total,
      type: category.productCategoryArgs.type,
    }),
    objectEqual,
  )

  return (
    <PageTable
      noDataText={loading ? ls.text.loadingData : ls.text.noData}
      data={data}
      columns={columns}
      onRowClick={(ctx) => {
        history.push(`/products/categories/${ctx.row.id}`)
      }}
    />
  )
}

export default CategoriesTable
