/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useMemo, useState } from 'react'

import { Device, DeviceInput, DeviceType } from '@foods-n-goods/server/generated/schema'
import { Button, Checkbox, Flexbox, Grid, modal, Select, TextField } from '@stage-ui/core'
import { Trash2 } from '@stage-ui/icons'
import { DevicesActions } from 'actions'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { getLocalizedString, useLocalizedString } from 'hooks/useLocalizedString'

const getDeviceNameMap = (): Record<DeviceType, string> => {
  const ls = getLocalizedString()
  return {
    CAMERA: ls.text.camera,
    PRINTER: ls.text.printer,
    SCALE: ls.text.scales,
  }
}

function Form(props: { type: DeviceType; device?: Device; close: () => void }) {
  const { type, device, close } = props
  const ls = useLocalizedString()
  const scaleOptions = useSelector(
    (state) =>
      state.devices.data
        .filter((item) => !!item.scale)
        .map((item) => ({
          text: item.name,
          value: item.id,
        })),
    objectEqual,
  )
  const deviceNameMap = useMemo(getDeviceNameMap, [ls.id])
  const protocolOptions = [
    { text: 'TCP (Redsk)', value: 'TCP-REDSK' },
    { text: 'TCP (Мера)', value: 'TCP-MERA' },
    { text: 'HTTP (Мера)', value: 'HTTP-MERA' },
    { text: 'FAKEIP', value: 'FAKEIP' },
  ]
  let portPlaceholder = '80'
  let port = 80
  if (type === 'PRINTER') {
    port = 9100
    portPlaceholder = '9100'
  }
  if (type === 'SCALE' && device?.protocol.startsWith('TCP')) {
    port = 1001
    portPlaceholder = '1001'
  }
  const [payload, setPayload] = useState<DeviceInput>({
    type,
    port,
    protocol: device?.protocol || 'HTTP',
    active: Boolean(device?.active),
    name: device?.name || '',
    host: device?.host || '',
    credentials: device?.credentials || '',
    linkedDeviceId: device?.linkedDevice?.id || null,
    data: device?.data || '{}',
  })

  const onSave = () => {
    if (device) {
      DevicesActions.update({ id: device.id, payload }, close)
    } else {
      DevicesActions.create({ payload }, close)
    }
  }

  const onDelete = () => {
    if (device) {
      DevicesActions.delete(device, close)
    }
  }

  const setCredentials = (input: DeviceInput, login?: string, password?: string) => {
    if (input.credentials) {
      let [l, p] = atob(input.credentials).split(':')
      if (login !== undefined) {
        l = login
      }
      if (password !== undefined) {
        p = password
      }
      setPayload({ ...input, credentials: btoa(`${l}:${p}`) })
    } else {
      setPayload({
        ...input,
        credentials: btoa(`${login || ''}:${password || ''}`),
      })
    }
  }

  return (
    <Flexbox column>
      <Grid gap="1rem" templateColumns="1fr 1fr">
        <Checkbox
          checked={!!payload.active}
          onChange={() => setPayload({ ...payload, active: !payload.active })}
          label={ls.text.active}
        />
        <TextField
          value={payload.name}
          placeholder={deviceNameMap[type]}
          onChange={(e) => setPayload({ ...payload, name: e.target.value })}
          label={ls.text.name}
        />
        <TextField
          value={payload.host}
          placeholder="127.0.0.1"
          onChange={(e) => setPayload({ ...payload, host: e.target.value })}
          label={ls.text.host}
        />
        <TextField
          type="number"
          disabled={payload.type === 'SCALE' && payload?.protocol.startsWith('TCP')}
          placeholder={portPlaceholder}
          value={payload.port}
          onChange={(e) => setPayload({ ...payload, port: Number(e.target.value) })}
          label={ls.text.port}
        />
        {type === 'CAMERA' && (
          <>
            <TextField
              placeholder=""
              value={payload.credentials ? atob(payload.credentials).split(':')[0] : ''}
              onChange={(e) => setCredentials(payload, e.target.value, undefined)}
              label={ls.text.login}
            />
            <TextField
              placeholder=""
              value={payload.credentials ? atob(payload.credentials).split(':')[1] : ''}
              label={ls.text.password}
              onChange={(e) => setCredentials(payload, undefined, e.target.value)}
            />
            <Select
              clearable
              placeholder={ls.text.selectScales}
              options={scaleOptions}
              values={scaleOptions.filter(
                (option) => option.value.toString() === payload.linkedDeviceId,
              )}
              label={ls.text.linkToScales}
              onChange={(value) =>
                setPayload({
                  ...payload,
                  linkedDeviceId: value[0]?.value.toString() || null,
                })
              }
            />
          </>
        )}
        {type === 'PRINTER' && (
          <Select
            clearable
            placeholder={ls.text.selectScales}
            options={scaleOptions}
            values={scaleOptions.filter(
              (option) => option.value.toString() === payload.linkedDeviceId,
            )}
            label={ls.text.linkToScales}
            onChange={(value) =>
              setPayload({
                ...payload,
                linkedDeviceId: value[0]?.value.toString() || null,
              })
            }
          />
        )}
        {type === 'SCALE' && (
          <Select
            options={protocolOptions}
            values={protocolOptions.filter((option) => option.value === payload.protocol)}
            label={ls.text.protocol}
            onChange={(values) =>
              setPayload({
                ...payload,
                port: String(values[0]?.value)?.startsWith('TCP') ? 1001 : payload.port,
                protocol: String(values[0]?.value || 'HTTP'),
              })
            }
          />
        )}
        {type === 'SCALE' && payload?.protocol === 'FAKEIP' && (
          <>
            <TextField
              type="number"
              label={ls.text.weight}
              placeholder="0"
              defaultValue={JSON.parse(payload.data || '{}')?.value || '0'}
              onChange={(e) => {
                const json = payload.data || '{}'
                setPayload({
                  ...payload,
                  data: JSON.stringify({
                    ...JSON.parse(json),
                    value: Number(e.target.value),
                  }),
                })
              }}
            />
            <Checkbox
              label={ls.text.onlineStatus}
              checked={JSON.parse(payload.data || '{}')?.online}
              onChange={(state) => {
                const json = payload.data || '{}'
                const data = JSON.parse(json)
                setPayload({
                  ...payload,
                  data: JSON.stringify({
                    ...data,
                    online: !data.online,
                  }),
                })
              }}
            />
            <Checkbox
              label={ls.text.weightStabilized}
              checked={JSON.parse(payload.data || '{}')?.stable}
              onChange={(state) => {
                const json = payload.data || '{}'
                const data = JSON.parse(json)
                setPayload({
                  ...payload,
                  data: JSON.stringify({
                    ...data,
                    stable: !data.stable,
                  }),
                })
              }}
            />
          </>
        )}
      </Grid>
      <Flexbox mt="1rem" justifyContent="space-between">
        <Button
          decoration="text"
          color="red500"
          children={ls.text.delete}
          rightChild={<Trash2 />}
          disabled={!device}
          onClick={onDelete}
        />
        <Flexbox justifyContent="flex-end">
          <Button
            decoration="text"
            color="gray500"
            children={ls.text.cancel}
            onClick={close}
          />
          <Button ml="0.25rem" children={ls.text.save} onClick={onSave} />
        </Flexbox>
      </Flexbox>
    </Flexbox>
  )
}

export const createDeviceDialog = (type: DeviceType) => {
  modal({
    title: getDeviceNameMap()[type],
    render: (close) => <Form type={type} close={close} />,
    overlayClose: false,
    style: {
      window: {
        width: '25rem',
        padding: '1rem',
      },
    },
  })
}

export const updateDeviceDialog = (device: Device) => {
  modal({
    title: getDeviceNameMap()[device.type],
    render: (close) => <Form type={device.type} device={device} close={close} />,
    overlayClose: false,
    style: {
      window: {
        width: '25rem',
        padding: '1rem',
      },
    },
  })
}
