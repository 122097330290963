import React, { ChangeEvent, useRef } from 'react'

import { Button, Flexbox } from '@stage-ui/core'
import { Trash2 } from '@stage-ui/icons'
import { useLocalizedString } from 'hooks/useLocalizedString'

interface ImageControllerInputProps {
  id?: string
  uploadAction?: (id: string, payload: string, _cb?: () => void) => void
  url?: string | null
  readonly?: boolean
  size?: string
  disabled?: boolean
}

function ImageControllerInput(props: ImageControllerInputProps) {
  const { id, uploadAction, url, readonly, disabled } = props
  const ls = useLocalizedString()
  const inputRef = useRef<HTMLInputElement>(null)

  if (readonly) return null

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (!id || disabled) return

    const { files } = event.target
    const reader = new FileReader()

    reader.onload = () => {
      if (typeof reader.result === 'string') {
        uploadAction?.(id, reader.result)
      }
    }

    if (files) {
      reader.readAsDataURL(files[0])
    }
  }

  const handleImageDelete = () => {
    if (!id) return
    uploadAction?.(id, '')
  }

  return (
    <Flexbox column>
      <input
        ref={inputRef}
        id="file"
        type="file"
        name="file"
        style={{ display: 'none' }}
        onChange={handleImageUpload}
        accept="image/jpg,image/png,image/jpeg"
      />
      <Button
        disabled={disabled}
        ml="m"
        p="0.25rem"
        size="s"
        decoration="text"
        onClick={() => inputRef.current?.click()}
        children={ls.text.upload}
      />
      {url && (
        <Button
          ml="m"
          disabled={disabled}
          color="error"
          decoration="text"
          style={{ minHeight: 0 }}
          children={ls.text.delete}
          leftChild={<Trash2 p={0} type="filled" color="error" size="1.5rem" />}
          onClick={handleImageDelete}
        />
      )}
    </Flexbox>
  )
}

export default ImageControllerInput
