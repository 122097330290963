import React, { useEffect, useState } from 'react'

import { Button, Checkbox, modal, Divider, Grid, Spinner, Text } from '@stage-ui/core'
import ButtonTypes from '@stage-ui/core/control/Button/types'
import { Download } from '@stage-ui/icons'
import { DictionariesActions, StaffActions } from 'actions'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { useLocalizedString } from 'hooks/useLocalizedString'

function ClearContainersContent(props: { close: () => void }) {
  const { close } = props
  const ls = useLocalizedString()
  const [moveToDelivered, setMoveToDelivered] = useState(false)
  const [selectedConteiners, setSelectedContainers] = useState<string[]>([])
  const dictionaries = useSelector((state) => state.dictionaries, objectEqual)

  useEffect(() => {
    DictionariesActions.getContainers()
  }, [])

  const containers = dictionaries.containersData
  return (
    <>
      <Text weight={600}>{ls.text.selectPalletsToClear}</Text>
      <Divider my="s" />
      {dictionaries.containersLoading === 'pending' && <Spinner />}
      {!!containers.length && (
        <Checkbox
          mb="s"
          label={ls.text.all}
          checked={selectedConteiners.length === containers.length}
          onChange={(cheched) => {
            if (!cheched) {
              setSelectedContainers(containers.map((c) => c.id))
            } else {
              setSelectedContainers([])
            }
          }}
        />
      )}
      <Grid gap="0.5rem" templateColumns="1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr">
        {containers.map((container) => {
          return (
            <Checkbox
              key={container.id}
              label={container.name}
              checked={selectedConteiners.some((c) => c === container.id)}
              onChange={(cheched) => {
                if (!cheched) {
                  setSelectedContainers(selectedConteiners.concat(container.id))
                } else {
                  setSelectedContainers(
                    selectedConteiners.filter((c) => c !== container.id),
                  )
                }
              }}
            />
          )
        })}
      </Grid>
      <Divider my="m" />
      <Checkbox
        label={ls.text.moveOrdersToDelivered}
        checked={moveToDelivered}
        onChange={() => setMoveToDelivered(!moveToDelivered)}
      />
      <Divider my="m" />
      <Button
        color="error"
        label={ls.text.complete}
        onClick={async () => {
          await StaffActions.containersClear(selectedConteiners, moveToDelivered)
          close()
        }}
      />
    </>
  )
}
function ContainersClear(props: ButtonTypes.Props) {
  const ls = useLocalizedString()
  const handleClick = () => {
    modal({
      title: ls.text.palletsClear,
      overlayClose: false,
      render: (close) => <ClearContainersContent close={close} />,
    })
  }

  return (
    <Button
      rightChild={<Download />}
      color="warning"
      children={ls.text.palletsClear}
      onClick={handleClick}
      {...props}
    />
  )
}

export default ContainersClear
