import { LocalizedStringID, LocalizedStringOptions } from './locale'
import { LocalizedString } from './locale.json'

export const LOCALIZATION_VERSION = 1
export const supportedLocales: Array<LocalizedStringOptions> = [
  {
    value: 'ru-RU',
    text: 'Русский',
  },
  {
    value: 'en-US',
    text: 'English',
  },
  {
    value: 'uz-UZ',
    text: 'Oʻzbek',
  },
]
export const cache: Partial<Record<LocalizedStringID, LocalizedString>> = {}

export const loadLanguage = async (id: LocalizedStringID) => {
  if (!cache[id]) {
    if (id === 'ru-RU') {
      await import('dayjs/locale/ru')
      cache[id] = (await import(
        '@foods-n-goods/client/localization/resources/ru-RU.json'
      )) as unknown as LocalizedString
    }
    if (id === 'en-US') {
      await import('dayjs/locale/en')
      cache[id] = (await import(
        '@foods-n-goods/client/localization/resources/en-US.json'
      )) as unknown as LocalizedString
    }
    if (id === 'uz-UZ') {
      await import('dayjs/locale/uz')
      cache[id] = (await import(
        '@foods-n-goods/client/localization/resources/uz-UZ.json'
      )) as unknown as LocalizedString
    }
  }

  return cache[id]!
}
