import React from 'react'

import { Flexbox, Text } from '@stage-ui/core'
import { List } from '@stage-ui/icons'
import { useLocalizedString } from 'hooks/useLocalizedString'

const Empty: React.FC<{ text?: string; icon?: React.ReactNode }> = (props) => {
  const ls = useLocalizedString()
  const Icon = props.icon || <List size="2rem" color="gray400" />
  return (
    <Flexbox centered p="m" column>
      {Icon}
      <Text size="s" color="gray500" mt="s">
        {props.text || ls.text.noData}
      </Text>
    </Flexbox>
  )
}

export default Empty
