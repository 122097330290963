import React from 'react'

import { OrderStatusCode } from '@foods-n-goods/client/system/types'
import { Block, Grid, modal } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { Cube, MessageCircle } from '@stage-ui/icons'
import { useMouseDrop } from 'components/MouseDrop'
import { PurchaseExtended } from 'pages/purchases/data'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { PurchaseUpdateCommentModal } from '../../UpdateCommentModal'

export function StatusCell(ctx: TableTypes.TableCellContext<PurchaseExtended>) {
  const { row } = ctx
  const ls = useLocalizedString()
  const { product, parentOrderId } = row

  const idle = useMouseDrop(ls.text.restock)
  const done = useMouseDrop(ls.text.purchased)
  const fail = useMouseDrop(ls.text.outOfStock)
  const canceled = useMouseDrop(ls.text.cancelOrder)
  const comment = useMouseDrop(row.comment, {
    popover: {
      style: {
        maxWidth: '35vw',
      },
    },
  })

  if (row._isExpandable || !row.status) return null

  const handleUpdateComment = () => {
    modal({
      title: comment ? ls.text.commentEdit : ls.text.addComment,
      subtitle: `${product.name} ${parentOrderId ? `[#${parentOrderId}]` : ''}`,
      w: '30rem',
      overlayClose: false,
      render: (close) => <PurchaseUpdateCommentModal close={close} purchaseRow={row} />,
    })
  }

  return (
    <Grid alignItems="center" gap="0.625rem">
      <Block w="1.25rem" h="1.25rem">
        {[OrderStatusCode.OUT_OF_STOCK, OrderStatusCode.PURCHASE_PENDING].includes(
          row.status.value,
        ) && <Cube gridColumn="1" size="1.25rem" color="orange500" {...idle} />}
        {OrderStatusCode.PURCHASE_CONFIRMED === row.status.value && (
          <Cube gridColumn="1" type="filled" size="1.25rem" color="orange500" {...done} />
        )}
        {OrderStatusCode.PURCHASE_FAILED === row.status.value && (
          <Cube gridColumn="1" size="1.25rem" color="red500" {...fail} />
        )}
        {OrderStatusCode.CANCELED === row.status.value && (
          <Cube
            gridColumn="1"
            type="filled"
            size="1.25rem"
            color="red500"
            {...canceled}
          />
        )}
      </Block>
      <MessageCircle
        gridColumn="2"
        size="1.25rem"
        color={row.comment ? 'blue500' : 'gray400'}
        {...comment}
        onClick={handleUpdateComment}
      />
    </Grid>
  )
}
