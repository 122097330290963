import React, { useEffect, useState } from 'react'

import {
  ProductGroup,
  Staff,
  StaffWarehouseInput,
} from '@foods-n-goods/server/generated/schema'
import {
  Block,
  Button,
  Checkbox,
  Divider,
  Flexbox,
  modal,
  ScrollView,
  Spinner,
  Text,
} from '@stage-ui/core'
import { StaffActions } from 'actions'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { getLocalizedString, useLocalizedString } from 'hooks/useLocalizedString'

type StaffWarehouseUpdateModalProps = {
  staff: Staff
  close: () => void
}

function StaffWarehouseUpdateModal(props: StaffWarehouseUpdateModalProps) {
  const { staff, close } = props
  const { id } = staff
  const ls = useLocalizedString()
  const { data, loading } = useSelector(
    (state) => ({
      data: state.category.data.records.filter(
        (category) => category.type.code === 'WAREHOUSE',
      ),
      loading: state.category.loading,
    }),
    objectEqual,
  )
  const warehouses = data
    .slice()
    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))

  const [staffWarehouses, setStaffWarehouses] = useState<StaffWarehouseInput[]>([])

  useEffect(() => {
    const whs: StaffWarehouseInput[] = staff.warehouses.map((w) => ({
      id: w.warehouseId,
      priority: w.priority,
    }))

    setStaffWarehouses(whs)
  }, [])

  const handleWarehousesUpdate = async () => {
    if (!staff) return
    await StaffActions.warehouseUpdate(id, staffWarehouses, close)
  }

  const onChange = (p: ProductGroup) => {
    const exists = staffWarehouses.findIndex((sw) => sw.id === Number(p.id)) >= 0
    if (exists) {
      setStaffWarehouses(staffWarehouses.filter((sw) => sw.id !== Number(p.id)))
    } else {
      setStaffWarehouses(
        staffWarehouses.concat({
          id: Number(p.id),
          priority: staffWarehouses.length + 1,
        }),
      )
    }
  }

  return (
    <Flexbox column>
      <Text size="s" mb="l" color="gray500" textAlign="right">
        {ls.text.selected.replace('[1]', String(staffWarehouses.length))}
      </Text>
      <Block
        decoration="surface"
        borderRadius="m"
        borderColor="gray200"
        h="35vh"
        style={{
          overflow: 'hidden',
        }}
      >
        {(loading === 'idle' || loading === 'pending') && (
          <Flexbox w="100%" h="100%" centered>
            <Spinner mr="s" />
            <Text size="s" color="gray500">
              {ls.text.loading}
            </Text>
          </Flexbox>
        )}
        <ScrollView xBarPosition="none" size="xs" barOffset={4}>
          {warehouses.map((warehouse, index) => (
            <React.Fragment key={warehouse.id}>
              <Flexbox p="s m" onClick={() => onChange(warehouse)} alignItems="center">
                <Checkbox
                  mr="m"
                  checked={
                    staffWarehouses.findIndex((v) => v.id === Number(warehouse.id)) >= 0
                  }
                />
                <Text mr="s" size="s" color="gray900" weight={600}>
                  {warehouse.name}
                </Text>
              </Flexbox>
              {warehouses.length !== index + 1 && (
                <Divider h="0.0625rem" color="gray200" />
              )}
            </React.Fragment>
          ))}
        </ScrollView>
      </Block>
      <Flexbox mt="xl" alignItems="center" justifyContent="flex-end">
        <Button
          decoration="text"
          label={ls.text.cancel}
          onClick={close}
          color="gray500"
        />
        <Button
          w="8.5rem"
          label={ls.text.confirm}
          onClick={handleWarehousesUpdate}
          ml="m"
        />
      </Flexbox>
    </Flexbox>
  )
}

export const openStaffWarehouseUpdateModal = (
  staff: Staff,
  e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  e?.stopPropagation()
  e?.preventDefault()

  modal({
    w: '30rem',
    title: getLocalizedString().text.warehouseUpdateTitle,
    overlayClose: false,
    render: (close) => <StaffWarehouseUpdateModal staff={staff} close={close} />,
  })
}
