import React, { FC } from 'react'

import { Order } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox, Grid, modal, Text } from '@stage-ui/core'
import { PlusCircle } from '@stage-ui/icons'
import { ColorText } from 'components/ColorText'

import { useLocalizedString } from 'hooks/useLocalizedString'

import { OrderAddPositionsModal } from './AddPositions'
import { OrderPositionsTable } from './Table'

type OrderPositionsProps = {
  order: Order
}

export const OrderPositions: FC<OrderPositionsProps> = (props) => {
  const { order } = props
  const { positionsStat } = order
  const ls = useLocalizedString()
  const handleAddPositions = () => {
    modal({
      title: ls.text.addProductsToOrder,
      w: '42rem',
      overlayClose: false,
      render: (close) => <OrderAddPositionsModal close={close} order={order} />,
    })
  }

  return (
    <Flexbox column mt="l">
      <Flexbox justifyContent="space-between" mb="1.5rem" pl="m">
        <Grid autoFlow="column" gap="2.5rem">
          <Flexbox alignItems="center">
            <Text size="m" weight={600} mr="s">
              {ls.text.picked1}
            </Text>
            <ColorText
              background="white"
              borderColor="gray200"
              borderWidth="1px"
              borderStyle="solid"
              p="s"
              color="gray900"
              size="s"
            >
              {ls.text.from
                .replace('[1]', String(positionsStat.readyForDelivery))
                .replace(
                  '[2]',
                  String(
                    positionsStat.notReadyForDelivery + positionsStat.readyForDelivery,
                  ),
                )}
            </ColorText>
          </Flexbox>
          <Flexbox alignItems="center">
            <Text size="m" weight={600} mr="s">
              {ls.text.restock}
            </Text>
            <ColorText
              background="white"
              borderColor="gray200"
              borderWidth="1px"
              borderStyle="solid"
              p="s"
              color="gray900"
              size="s"
            >
              {positionsStat.inPurchase}
            </ColorText>
          </Flexbox>
          {positionsStat.excess > 0 && (
            <Flexbox alignItems="center">
              <Text size="m" weight={600} mr="s" color="red500">
                {ls.text.removeFromPallet}
              </Text>
              <ColorText
                background="white"
                borderColor="red500"
                borderWidth="1px"
                borderStyle="solid"
                p="s"
                color="red500"
                size="s"
              >
                {positionsStat.excess}
              </ColorText>
            </Flexbox>
          )}
        </Grid>
        <Button
          label={ls.text.addItems}
          color="gray500"
          decoration="text"
          size="s"
          disabled={order.status.value >= 5 && order.status.value !== 7}
          leftChild={<PlusCircle />}
          onClick={handleAddPositions}
        />
      </Flexbox>
      <OrderPositionsTable order={order} />
    </Flexbox>
  )
}
