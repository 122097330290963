import React from 'react'

import { Flexbox, Paragraph, Select } from '@stage-ui/core'
import SelectTypes from '@stage-ui/core/control/Select/types'

export const SettingsSelect = (props: SelectTypes.Props & { label: string }) => {
  const { label, ...selectProps } = props
  return (
    <Flexbox
      alignItems="center"
      mb="m"
      style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
    >
      <Paragraph color="gray500" size="s" flex={0.3} mr="m">
        {label}
      </Paragraph>
      <Select decoration="none" {...selectProps} />
    </Flexbox>
  )
}
